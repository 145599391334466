import { useEffect } from 'react';
import { useRecoilSnapshot, useGetRecoilValueInfo_UNSTABLE } from 'recoil';
import { authAtom } from 'data/atoms';

export function DebugRecoil() {
  const snapshot = useRecoilSnapshot();
  const getRecoilValueInfo = useGetRecoilValueInfo_UNSTABLE();
  const { subscribers, loadable } = getRecoilValueInfo(authAtom);

  useEffect(() => {
    for (const node of snapshot.getNodes_UNSTABLE({ isModified: true })) {
      console.debug(node.key, snapshot.getLoadable(node));
    }
  }, [snapshot, subscribers, loadable]);

  return <></>;
}
