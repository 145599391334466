export const mappingCityPostalCode = [
    {
        "zip": "1000",
        "city": "Bruxelles",
        "lng": 4.351697,
        "lat": 50.8465573
    },
    {
        "zip": "1020",
        "city": "Laeken",
        "lng": 4.3487134,
        "lat": 50.883392
    },
    {
        "zip": "1030",
        "city": "Schaerbeek",
        "lng": 4.3737121,
        "lat": 50.8676041
    },
    {
        "zip": "1040",
        "city": "Etterbeek",
        "lng": 4.3895104,
        "lat": 50.8368511
    },
    {
        "zip": "1050",
        "city": "Ixelles",
        "lng": 4.3815707,
        "lat": 50.8222854
    },
    {
        "zip": "1060",
        "city": "Saint-Gilles",
        "lng": 4.345668,
        "lat": 50.8267409
    },
    {
        "zip": "1070",
        "city": "Anderlecht",
        "lng": 4.3123401,
        "lat": 50.8381411
    },
    {
        "zip": "1080",
        "city": "Molenbeek-Saint-Jean",
        "lng": 4.3227779,
        "lat": 50.8543551
    },
    {
        "zip": "1081",
        "city": "Koekelberg",
        "lng": 4.3257084,
        "lat": 50.8622633
    },
    {
        "zip": "1082",
        "city": "Berchem-Sainte-Agathe",
        "lng": 4.292702,
        "lat": 50.8639835
    },
    {
        "zip": "1083",
        "city": "Ganshoren",
        "lng": 4.3175103,
        "lat": 50.8712396
    },
    {
        "zip": "1090",
        "city": "Jette",
        "lng": 4.3260903,
        "lat": 50.8777634
    },
    {
        "zip": "1120",
        "city": "Neder-Over-Heembeek",
        "lng": 4.3904888684211,
        "lat": 50.89779605
    },
    {
        "zip": "1130",
        "city": "Haren",
        "lng": 4.4125708732431,
        "lat": 50.8919661
    },
    {
        "zip": "1140",
        "city": "Evere",
        "lng": 4.4021602,
        "lat": 50.8704524
    },
    {
        "zip": "1150",
        "city": "Woluwe-Saint-Pierre",
        "lng": 4.4432967,
        "lat": 50.8292429
    },
    {
        "zip": "1160",
        "city": "Auderghem",
        "lng": 4.4331389,
        "lat": 50.8156571
    },
    {
        "zip": "1170",
        "city": "Watermael-Boitsfort",
        "lng": 4.4158177,
        "lat": 50.799394
    },
    {
        "zip": "1180",
        "city": "Uccle",
        "lng": 4.3372348,
        "lat": 50.8018201
    },
    {
        "zip": "1190",
        "city": "Forest",
        "lng": 4.3177512,
        "lat": 50.8091426
    },
    {
        "zip": "1200",
        "city": "Woluwe-Saint-Lambert",
        "lng": 4.4284842,
        "lat": 50.8466928
    },
    {
        "zip": "1210",
        "city": "Saint-Josse-Ten-Noode",
        "lng": 4.3723364,
        "lat": 50.8530739
    },
    {
        "zip": "1300",
        "city": "Limal",
        "lng": 4.5738899,
        "lat": 50.6930127
    },
    {
        "zip": "1300",
        "city": "Wavre",
        "lng": 4.607744,
        "lat": 50.7164189
    },
    {
        "zip": "1301",
        "city": "Bierges",
        "lng": 4.5894025,
        "lat": 50.7111796
    },
    {
        "zip": "1310",
        "city": "La Hulpe",
        "lng": 4.4796542,
        "lat": 50.7315053
    },
    {
        "zip": "1315",
        "city": "Glimes",
        "lng": 4.8384018,
        "lat": 50.6799948
    },
    {
        "zip": "1315",
        "city": "Incourt",
        "lng": 4.798944,
        "lat": 50.6918004
    },
    {
        "zip": "1315",
        "city": "Opprebais",
        "lng": 4.7957745,
        "lat": 50.681817
    },
    {
        "zip": "1315",
        "city": "Piétrebais",
        "lng": 4.7433685,
        "lat": 50.7311108
    },
    {
        "zip": "1315",
        "city": "Roux-Miroir",
        "lng": 4.7848246,
        "lat": 50.7085122
    },
    {
        "zip": "1320",
        "city": "Beauvechain",
        "lng": 4.7716774,
        "lat": 50.7810287
    },
    {
        "zip": "1320",
        "city": "Hamme-Mille",
        "lng": 4.7205562,
        "lat": 50.7819118
    },
    {
        "zip": "1320",
        "city": "L'ecluse",
        "lng": 4.8320079,
        "lat": 50.7717493
    },
    {
        "zip": "1320",
        "city": "Nodebais",
        "lng": 4.733891,
        "lat": 50.7725266
    },
    {
        "zip": "1320",
        "city": "Tourinnes-La-Grosse",
        "lng": 4.7472104,
        "lat": 50.7777654
    },
    {
        "zip": "1325",
        "city": "Bonlez",
        "lng": 4.6896145,
        "lat": 50.7021372
    },
    {
        "zip": "1325",
        "city": "Chaumont-Gistoux",
        "lng": 4.6974182,
        "lat": 50.6839808
    },
    {
        "zip": "1325",
        "city": "Corroy-Le-Grand",
        "lng": 4.6747213,
        "lat": 50.6617107
    },
    {
        "zip": "1325",
        "city": "Dion-Valmont",
        "lng": 4.6614812,
        "lat": 50.7158849
    },
    {
        "zip": "1325",
        "city": "Longueville",
        "lng": 4.7392389,
        "lat": 50.7024404
    },
    {
        "zip": "1330",
        "city": "Rixensart",
        "lng": 4.5272896887008,
        "lat": 50.7133548
    },
    {
        "zip": "1331",
        "city": "Rosiéres",
        "lng": 4.5463113019639,
        "lat": 50.73713045
    },
    {
        "zip": "1332",
        "city": "Genval",
        "lng": 4.4971389344121,
        "lat": 50.72074495
    },
    {
        "zip": "1340",
        "city": "Ottignies",
        "lng": 4.5690502,
        "lat": 50.666357
    },
    {
        "zip": "1340",
        "city": "Ottignies-Louvain-La-Neuve",
        "lng": 4.5804937701303,
        "lat": 50.6726725
    },
    {
        "zip": "1341",
        "city": "Céroux-Mousty",
        "lng": 4.5291742660159,
        "lat": 50.6625713
    },
    {
        "zip": "1342",
        "city": "Limelette",
        "lng": 4.5549022761211,
        "lat": 50.6851551
    },
    {
        "zip": "1348",
        "city": "Louvain-La-Neuve",
        "lng": 4.6141567317211,
        "lat": 50.6741689
    },
    {
        "zip": "1350",
        "city": "Enines",
        "lng": 4.9260633,
        "lat": 50.6943193
    },
    {
        "zip": "1350",
        "city": "Folx-Les-Caves",
        "lng": 4.9370984,
        "lat": 50.6610719
    },
    {
        "zip": "1350",
        "city": "Jandrain-Jandrenouille",
        "lng": 4.9792652,
        "lat": 50.6740112
    },
    {
        "zip": "1350",
        "city": "Jauche",
        "lng": 4.9552091,
        "lat": 50.6816078
    },
    {
        "zip": "1350",
        "city": "Marilles",
        "lng": 4.9529173,
        "lat": 50.7072943
    },
    {
        "zip": "1350",
        "city": "Noduwez",
        "lng": 4.9640083,
        "lat": 50.7293197
    },
    {
        "zip": "1350",
        "city": "Orp-Jauche",
        "lng": 4.9889872,
        "lat": 50.7001001
    },
    {
        "zip": "1350",
        "city": "Orp-Le-Grand",
        "lng": 4.9912852,
        "lat": 50.7034875
    },
    {
        "zip": "1357",
        "city": "Hélécine",
        "lng": 4.9822395,
        "lat": 50.7514034
    },
    {
        "zip": "1357",
        "city": "Linsmeau",
        "lng": 5.0030144,
        "lat": 50.7322517
    },
    {
        "zip": "1357",
        "city": "Neerheylissem",
        "lng": 4.9890154,
        "lat": 50.7567681
    },
    {
        "zip": "1357",
        "city": "Opheylissem",
        "lng": 4.9780806,
        "lat": 50.7478407
    },
    {
        "zip": "1360",
        "city": "Maléves-Sainte-Marie-Wastines",
        "lng": 4.7801225,
        "lat": 50.6564717
    },
    {
        "zip": "1360",
        "city": "Orbais",
        "lng": 4.7631073,
        "lat": 50.6376269
    },
    {
        "zip": "1360",
        "city": "Perwez",
        "lng": 4.8134719,
        "lat": 50.6234469
    },
    {
        "zip": "1360",
        "city": "Thorembais-Les-Béguines",
        "lng": 4.8183242,
        "lat": 50.6584082
    },
    {
        "zip": "1360",
        "city": "Thorembais-Saint-Trond",
        "lng": 4.7839333,
        "lat": 50.6357579
    },
    {
        "zip": "1367",
        "city": "Autre-Eglise",
        "lng": 4.9249295,
        "lat": 50.6631583
    },
    {
        "zip": "1367",
        "city": "Bomal",
        "lng": 4.8735246,
        "lat": 50.6681389
    },
    {
        "zip": "1367",
        "city": "Gérompont",
        "lng": 4.8807352,
        "lat": 50.6467516
    },
    {
        "zip": "1367",
        "city": "Grand-Rosiére-Hottomont",
        "lng": 4.8674622,
        "lat": 50.6316098
    },
    {
        "zip": "1367",
        "city": "Huppaye",
        "lng": 4.8921703,
        "lat": 50.6927557
    },
    {
        "zip": "1367",
        "city": "Mont-Saint-André",
        "lng": 4.8635811,
        "lat": 50.6550272
    },
    {
        "zip": "1367",
        "city": "Ramillies",
        "lng": 4.9148994,
        "lat": 50.6366892
    },
    {
        "zip": "1370",
        "city": "Dongelberg",
        "lng": 4.8203333,
        "lat": 50.7016251
    },
    {
        "zip": "1370",
        "city": "Jauchelette",
        "lng": 4.8435069,
        "lat": 50.6866092
    },
    {
        "zip": "1370",
        "city": "Jodoigne",
        "lng": 4.8709616,
        "lat": 50.7218206
    },
    {
        "zip": "1370",
        "city": "Jodoigne-Souveraine",
        "lng": 4.8405644,
        "lat": 50.7081169
    },
    {
        "zip": "1370",
        "city": "Lathuy",
        "lng": 4.824622,
        "lat": 50.7230097
    },
    {
        "zip": "1370",
        "city": "Mélin",
        "lng": 4.8286208,
        "lat": 50.7400918
    },
    {
        "zip": "1370",
        "city": "Piétrain",
        "lng": 4.9175477,
        "lat": 50.7255018
    },
    {
        "zip": "1370",
        "city": "Saint-Jean-Geest",
        "lng": 4.8957659,
        "lat": 50.7397177
    },
    {
        "zip": "1370",
        "city": "Saint-Remy-Geest",
        "lng": 4.8583707,
        "lat": 50.7471089
    },
    {
        "zip": "1370",
        "city": "Zétrud-Lumay",
        "lng": 4.8838518,
        "lat": 50.7592057
    },
    {
        "zip": "1380",
        "city": "Couture-Saint-Germain",
        "lng": 4.4825691512066,
        "lat": 50.6628104
    },
    {
        "zip": "1380",
        "city": "Lasne",
        "lng": 4.4844157,
        "lat": 50.6860037
    },
    {
        "zip": "1380",
        "city": "Lasne-Chapelle-Saint-Lambert",
        "lng": 4.5004885,
        "lat": 50.6927451
    },
    {
        "zip": "1380",
        "city": "Maransart",
        "lng": 4.4559942489341,
        "lat": 50.6561328
    },
    {
        "zip": "1380",
        "city": "Ohain",
        "lng": 4.4505534162388,
        "lat": 50.695114
    },
    {
        "zip": "1380",
        "city": "Plancenoit",
        "lng": 4.4212124182736,
        "lat": 50.66254755
    },
    {
        "zip": "1390",
        "city": "Archennes",
        "lng": 4.6702286,
        "lat": 50.7516077
    },
    {
        "zip": "1390",
        "city": "Biez",
        "lng": 4.7033973,
        "lat": 50.7267076
    },
    {
        "zip": "1390",
        "city": "Bossut-Gottechain",
        "lng": 4.6990692,
        "lat": 50.7616193
    },
    {
        "zip": "1390",
        "city": "Grez-Doiceau",
        "lng": 4.6962324,
        "lat": 50.7386182
    },
    {
        "zip": "1390",
        "city": "Nethen",
        "lng": 4.6736552349571,
        "lat": 50.78382875
    },
    {
        "zip": "1400",
        "city": "Monstreux",
        "lng": 4.2860216341575,
        "lat": 50.5931607
    },
    {
        "zip": "1400",
        "city": "Nivelles",
        "lng": 4.3319091758631,
        "lat": 50.58916295
    },
    {
        "zip": "1401",
        "city": "Baulers",
        "lng": 4.3586482570372,
        "lat": 50.6172472
    },
    {
        "zip": "1402",
        "city": "Thines",
        "lng": 4.3825954093731,
        "lat": 50.58678735
    },
    {
        "zip": "1404",
        "city": "Bornival",
        "lng": 4.2649262209555,
        "lat": 50.6048658
    },
    {
        "zip": "1410",
        "city": "Waterloo",
        "lng": 4.3978047,
        "lat": 50.7173563
    },
    {
        "zip": "1420",
        "city": "Braine-L'alleud",
        "lng": 4.3548145440335,
        "lat": 50.6940942
    },
    {
        "zip": "1421",
        "city": "Ophain-Bois-Seigneur-Isaac",
        "lng": 4.3507902353934,
        "lat": 50.65630215
    },
    {
        "zip": "1428",
        "city": "Lillois-Witterzée",
        "lng": 4.3670582,
        "lat": 50.6483743
    },
    {
        "zip": "1430",
        "city": "Bierghes",
        "lng": 4.1227257983451,
        "lat": 50.69657845
    },
    {
        "zip": "1430",
        "city": "Quenast",
        "lng": 4.1627021400155,
        "lat": 50.6719076
    },
    {
        "zip": "1430",
        "city": "Rebecq",
        "lng": 4.1355466,
        "lat": 50.6645894
    },
    {
        "zip": "1430",
        "city": "Rebecq-Rognon",
        "lng": 4.1350275503194,
        "lat": 50.67355825
    },
    {
        "zip": "1435",
        "city": "Corbais",
        "lng": 4.6408286063936,
        "lat": 50.6491686
    },
    {
        "zip": "1435",
        "city": "Hévillers",
        "lng": 4.6167772,
        "lat": 50.6220893
    },
    {
        "zip": "1435",
        "city": "Mont-Saint-Guibert",
        "lng": 4.6127019,
        "lat": 50.6365628
    },
    {
        "zip": "1440",
        "city": "Braine-Le-Château",
        "lng": 4.2666690726132,
        "lat": 50.68088165
    },
    {
        "zip": "1440",
        "city": "Wauthier-Braine",
        "lng": 4.3133036337455,
        "lat": 50.68083185
    },
    {
        "zip": "1450",
        "city": "Chastre",
        "lng": 4.636678,
        "lat": 50.6080835
    },
    {
        "zip": "1450",
        "city": "Chastre-Villeroux-Blanmont",
        "lng": 4.6377941108725,
        "lat": 50.6106296
    },
    {
        "zip": "1450",
        "city": "Cortil-Noirmont",
        "lng": 4.6406799,
        "lat": 50.5908074
    },
    {
        "zip": "1450",
        "city": "Gentinnes",
        "lng": 4.5927221009772,
        "lat": 50.57939665
    },
    {
        "zip": "1450",
        "city": "Saint-Géry",
        "lng": 4.6202848419069,
        "lat": 50.57660545
    },
    {
        "zip": "1457",
        "city": "Nil-Saint-Vincent-Saint-Martin",
        "lng": 4.6743285,
        "lat": 50.6407147
    },
    {
        "zip": "1457",
        "city": "Tourinnes-Saint-Lambert",
        "lng": 4.7220185,
        "lat": 50.6395479
    },
    {
        "zip": "1457",
        "city": "Walhain",
        "lng": 4.6952724,
        "lat": 50.6182838
    },
    {
        "zip": "1457",
        "city": "Walhain-Saint-Paul",
        "lng": 4.6952241,
        "lat": 50.6167794
    },
    {
        "zip": "1460",
        "city": "Ittre",
        "lng": 4.2611675641255,
        "lat": 50.6463367
    },
    {
        "zip": "1460",
        "city": "Virginal-Samme",
        "lng": 4.2199759440369,
        "lat": 50.6413403
    },
    {
        "zip": "1461",
        "city": "Haut-Ittre",
        "lng": 4.2964719513591,
        "lat": 50.6488039
    },
    {
        "zip": "1470",
        "city": "Baisy-Thy",
        "lng": 4.4880778189719,
        "lat": 50.6040225
    },
    {
        "zip": "1470",
        "city": "Bousval",
        "lng": 4.5192034141632,
        "lat": 50.63205335
    },
    {
        "zip": "1470",
        "city": "Genappe",
        "lng": 4.4479459851741,
        "lat": 50.60800575
    },
    {
        "zip": "1471",
        "city": "Loupoigne",
        "lng": 4.4319644959116,
        "lat": 50.5929226
    },
    {
        "zip": "1472",
        "city": "Vieux-Genappe",
        "lng": 4.4015030981612,
        "lat": 50.62902485
    },
    {
        "zip": "1473",
        "city": "Glabais",
        "lng": 4.4464886939844,
        "lat": 50.63691535
    },
    {
        "zip": "1474",
        "city": "Ways",
        "lng": 4.4729429472611,
        "lat": 50.6269861
    },
    {
        "zip": "1476",
        "city": "Houtain-Le-Val",
        "lng": 4.4110339517433,
        "lat": 50.5762935
    },
    {
        "zip": "1480",
        "city": "Clabecq",
        "lng": 4.2214131,
        "lat": 50.6893737
    },
    {
        "zip": "1480",
        "city": "Oisquercq",
        "lng": 4.2167874,
        "lat": 50.6700726
    },
    {
        "zip": "1480",
        "city": "Saintes",
        "lng": 4.1600612857846,
        "lat": 50.70737205
    },
    {
        "zip": "1480",
        "city": "Tubize",
        "lng": 4.204696,
        "lat": 50.69302
    },
    {
        "zip": "1490",
        "city": "Court-Saint-Etienne",
        "lng": 4.5685766,
        "lat": 50.6443208
    },
    {
        "zip": "1495",
        "city": "Marbais",
        "lng": 4.5380975970878,
        "lat": 50.55047955
    },
    {
        "zip": "1495",
        "city": "Mellery",
        "lng": 4.5691118,
        "lat": 50.5817537
    },
    {
        "zip": "1495",
        "city": "Sart-Dames-Avelines",
        "lng": 4.4895548874612,
        "lat": 50.554167
    },
    {
        "zip": "1495",
        "city": "Tilly",
        "lng": 4.5522187220517,
        "lat": 50.57640235
    },
    {
        "zip": "1495",
        "city": "Villers-La-Ville",
        "lng": 4.516035801462,
        "lat": 50.5640258
    },
    {
        "zip": "1500",
        "city": "Halle",
        "lng": 4.2374349,
        "lat": 50.7360524
    },
    {
        "zip": "1501",
        "city": "Buizingen",
        "lng": 4.2605080818496,
        "lat": 50.73793415
    },
    {
        "zip": "1502",
        "city": "Lembeek",
        "lng": 4.2143430588907,
        "lat": 50.7092954
    },
    {
        "zip": "1540",
        "city": "Herfelingen",
        "lng": 4.0974868,
        "lat": 50.747729
    },
    {
        "zip": "1540",
        "city": "Herne",
        "lng": 4.0260115663497,
        "lat": 50.72329555
    },
    {
        "zip": "1541",
        "city": "Sint-Pieters-Kapelle",
        "lng": 3.9985766720315,
        "lat": 50.70721955
    },
    {
        "zip": "1547",
        "city": "Biévéne",
        "lng": 3.943147,
        "lat": 50.7164481
    },
    {
        "zip": "1560",
        "city": "Hoeilaart",
        "lng": 4.474407,
        "lat": 50.7674846
    },
    {
        "zip": "1570",
        "city": "Galmaarden",
        "lng": 3.9709983,
        "lat": 50.7527192
    },
    {
        "zip": "1570",
        "city": "Tollembeek",
        "lng": 4.004789341376,
        "lat": 50.7430853
    },
    {
        "zip": "1570",
        "city": "Vollezele",
        "lng": 4.0247359,
        "lat": 50.76088
    },
    {
        "zip": "1600",
        "city": "Oudenaken",
        "lng": 4.1958992,
        "lat": 50.7804875
    },
    {
        "zip": "1600",
        "city": "Sint-Laureins-Berchem",
        "lng": 4.1993838,
        "lat": 50.7877313
    },
    {
        "zip": "1600",
        "city": "Sint-Pieters-Leeuw",
        "lng": 4.2452186173064,
        "lat": 50.78118355
    },
    {
        "zip": "1601",
        "city": "Ruisbroek",
        "lng": 4.298610469732,
        "lat": 50.7848617
    },
    {
        "zip": "1602",
        "city": "Vlezenbeek",
        "lng": 4.2360410720845,
        "lat": 50.805593
    },
    {
        "zip": "1620",
        "city": "Drogenbos",
        "lng": 4.3173542,
        "lat": 50.786532
    },
    {
        "zip": "1630",
        "city": "Linkebeek",
        "lng": 4.3345641,
        "lat": 50.7716092
    },
    {
        "zip": "1640",
        "city": "Rhode-Saint-Genése",
        "lng": 4.3463687,
        "lat": 50.74503
    },
    {
        "zip": "1650",
        "city": "Beersel",
        "lng": 4.3051321494591,
        "lat": 50.7684432
    },
    {
        "zip": "1651",
        "city": "Lot",
        "lng": 4.2729986221483,
        "lat": 50.76514035
    },
    {
        "zip": "1652",
        "city": "Alsemberg",
        "lng": 4.3281703413079,
        "lat": 50.7449476
    },
    {
        "zip": "1653",
        "city": "Dworp",
        "lng": 4.2950152833964,
        "lat": 50.73423975
    },
    {
        "zip": "1654",
        "city": "Huizingen",
        "lng": 4.2732545968764,
        "lat": 50.74931535
    },
    {
        "zip": "1670",
        "city": "Bogaarden",
        "lng": 4.1367803,
        "lat": 50.7398062
    },
    {
        "zip": "1670",
        "city": "Heikruis",
        "lng": 4.1126567,
        "lat": 50.7348458
    },
    {
        "zip": "1670",
        "city": "Pepingen",
        "lng": 4.1785882891377,
        "lat": 50.7511702
    },
    {
        "zip": "1671",
        "city": "Elingen",
        "lng": 4.174198071757,
        "lat": 50.7793527
    },
    {
        "zip": "1673",
        "city": "Beert",
        "lng": 4.1853449364222,
        "lat": 50.7354888
    },
    {
        "zip": "1674",
        "city": "Bellingen",
        "lng": 4.1601450278998,
        "lat": 50.73779765
    },
    {
        "zip": "1700",
        "city": "Dilbeek",
        "lng": 4.2657299667643,
        "lat": 50.84408625
    },
    {
        "zip": "1700",
        "city": "Sint-Martens-Bodegem",
        "lng": 4.2150592,
        "lat": 50.8627233
    },
    {
        "zip": "1700",
        "city": "Sint-Ulriks-Kapelle",
        "lng": 4.2206526,
        "lat": 50.8800337
    },
    {
        "zip": "1701",
        "city": "Itterbeek",
        "lng": 4.2501187,
        "lat": 50.8395994
    },
    {
        "zip": "1702",
        "city": "Groot-Bijgaarden",
        "lng": 4.2635668372339,
        "lat": 50.8714357
    },
    {
        "zip": "1703",
        "city": "Schepdaal",
        "lng": 4.195149072634,
        "lat": 50.8363077
    },
    {
        "zip": "1730",
        "city": "Asse",
        "lng": 4.1804375072845,
        "lat": 50.913594
    },
    {
        "zip": "1730",
        "city": "Bekkerzeel",
        "lng": 4.2381789,
        "lat": 50.8853713
    },
    {
        "zip": "1730",
        "city": "Kobbegem",
        "lng": 4.2496669,
        "lat": 50.9091629
    },
    {
        "zip": "1730",
        "city": "Mollem",
        "lng": 4.2239826,
        "lat": 50.9328497
    },
    {
        "zip": "1731",
        "city": "Relegem",
        "lng": 4.2790265927797,
        "lat": 50.9019808
    },
    {
        "zip": "1731",
        "city": "Zellik",
        "lng": 4.2744185,
        "lat": 50.8842475
    },
    {
        "zip": "1740",
        "city": "Ternat",
        "lng": 4.1834171401216,
        "lat": 50.87155835
    },
    {
        "zip": "1741",
        "city": "Wambeek",
        "lng": 4.1646372476962,
        "lat": 50.85672975
    },
    {
        "zip": "1742",
        "city": "Sint-Katherina-Lombeek",
        "lng": 4.1535568,
        "lat": 50.8726659
    },
    {
        "zip": "1745",
        "city": "Mazenzele",
        "lng": 4.1717632,
        "lat": 50.9428791
    },
    {
        "zip": "1745",
        "city": "Opwijk",
        "lng": 4.1896665,
        "lat": 50.9690529
    },
    {
        "zip": "1750",
        "city": "Gaasbeek",
        "lng": 4.1888025,
        "lat": 50.7998923
    },
    {
        "zip": "1750",
        "city": "Lennik",
        "lng": 4.1648626,
        "lat": 50.8085632
    },
    {
        "zip": "1750",
        "city": "Sint-Kwintens-Lennik",
        "lng": 4.1456163063499,
        "lat": 50.81145495
    },
    {
        "zip": "1750",
        "city": "Sint-Martens-Lennik",
        "lng": 4.1695303908975,
        "lat": 50.8115319
    },
    {
        "zip": "1755",
        "city": "Gooik",
        "lng": 4.1029840674511,
        "lat": 50.7979738
    },
    {
        "zip": "1755",
        "city": "Kester",
        "lng": 4.1212845,
        "lat": 50.7631284
    },
    {
        "zip": "1755",
        "city": "Leerbeek",
        "lng": 4.1192641,
        "lat": 50.7773538
    },
    {
        "zip": "1755",
        "city": "Oetingen",
        "lng": 4.0614624,
        "lat": 50.7720719
    },
    {
        "zip": "1760",
        "city": "Onze-Lieve-Vrouw-Lombeek",
        "lng": 4.1119573,
        "lat": 50.8220387
    },
    {
        "zip": "1760",
        "city": "Pamel",
        "lng": 4.0820664787057,
        "lat": 50.83471975
    },
    {
        "zip": "1760",
        "city": "Roosdaal",
        "lng": 4.0972238,
        "lat": 50.8341383
    },
    {
        "zip": "1760",
        "city": "Strijtem",
        "lng": 4.1145703,
        "lat": 50.8405541
    },
    {
        "zip": "1761",
        "city": "Borchtlombeek",
        "lng": 4.1369148649611,
        "lat": 50.84817805
    },
    {
        "zip": "1770",
        "city": "Liedekerke",
        "lng": 4.0860754,
        "lat": 50.8711313
    },
    {
        "zip": "1780",
        "city": "Wemmel",
        "lng": 4.3108723,
        "lat": 50.9145955
    },
    {
        "zip": "1785",
        "city": "Brussegem",
        "lng": 4.2658869,
        "lat": 50.9277713
    },
    {
        "zip": "1785",
        "city": "Hamme",
        "lng": 4.1202807810427,
        "lat": 51.0881618
    },
    {
        "zip": "1785",
        "city": "Merchtem",
        "lng": 4.2327769,
        "lat": 50.9591981
    },
    {
        "zip": "1790",
        "city": "Affligem",
        "lng": 4.1174675,
        "lat": 50.9035063
    },
    {
        "zip": "1790",
        "city": "Essene",
        "lng": 4.1390421,
        "lat": 50.8973851
    },
    {
        "zip": "1790",
        "city": "Hekelgem",
        "lng": 4.1118354895388,
        "lat": 50.9137991
    },
    {
        "zip": "1790",
        "city": "Teralfene",
        "lng": 4.09834,
        "lat": 50.8921419
    },
    {
        "zip": "1800",
        "city": "Peutie",
        "lng": 4.4551981,
        "lat": 50.9271744
    },
    {
        "zip": "1800",
        "city": "Vilvoorde",
        "lng": 4.4329052514838,
        "lat": 50.92813655
    },
    {
        "zip": "1820",
        "city": "Melsbroek",
        "lng": 4.4758811,
        "lat": 50.9170725
    },
    {
        "zip": "1820",
        "city": "Perk",
        "lng": 4.4960702,
        "lat": 50.9331623
    },
    {
        "zip": "1820",
        "city": "Steenokkerzeel",
        "lng": 4.5110471,
        "lat": 50.9093488
    },
    {
        "zip": "1830",
        "city": "Machelen",
        "lng": 4.4353614,
        "lat": 50.9124219
    },
    {
        "zip": "1831",
        "city": "Diegem",
        "lng": 4.4365042,
        "lat": 50.8945555
    },
    {
        "zip": "1840",
        "city": "Londerzeel",
        "lng": 4.2990729416338,
        "lat": 51.0122466
    },
    {
        "zip": "1840",
        "city": "Malderen",
        "lng": 4.2437819,
        "lat": 51.0198436
    },
    {
        "zip": "1840",
        "city": "Steenhuffel",
        "lng": 4.2624672,
        "lat": 50.9944384
    },
    {
        "zip": "1850",
        "city": "Grimbergen",
        "lng": 4.4013732626459,
        "lat": 50.95030565
    },
    {
        "zip": "1851",
        "city": "Humbeek",
        "lng": 4.3777599282045,
        "lat": 50.9728229
    },
    {
        "zip": "1852",
        "city": "Beigem",
        "lng": 4.3638682,
        "lat": 50.9527061
    },
    {
        "zip": "1853",
        "city": "Strombeek-Bever",
        "lng": 4.342355501959,
        "lat": 50.91096285
    },
    {
        "zip": "1860",
        "city": "Meise",
        "lng": 4.3287322,
        "lat": 50.9341625
    },
    {
        "zip": "1861",
        "city": "Wolvertem",
        "lng": 4.3085198,
        "lat": 50.9510266
    },
    {
        "zip": "1880",
        "city": "Kapelle-Op-Den-Bos",
        "lng": 4.3599725,
        "lat": 51.0131904
    },
    {
        "zip": "1880",
        "city": "Nieuwenrode",
        "lng": 4.3512293,
        "lat": 50.9799835
    },
    {
        "zip": "1880",
        "city": "Ramsdonk",
        "lng": 4.3374964007802,
        "lat": 51.0114961
    },
    {
        "zip": "1910",
        "city": "Berg",
        "lng": 5.1521172,
        "lat": 51.1695659
    },
    {
        "zip": "1910",
        "city": "Buken",
        "lng": 4.617418,
        "lat": 50.937546
    },
    {
        "zip": "1910",
        "city": "Kampenhout",
        "lng": 4.5711630873694,
        "lat": 50.94924405
    },
    {
        "zip": "1910",
        "city": "Nederokkerzeel",
        "lng": 4.5645096,
        "lat": 50.9190127
    },
    {
        "zip": "1930",
        "city": "Nossegem",
        "lng": 4.5081025949464,
        "lat": 50.88499735
    },
    {
        "zip": "1930",
        "city": "Zaventem",
        "lng": 4.4745515,
        "lat": 50.8804355
    },
    {
        "zip": "1932",
        "city": "Sint-Stevens-Woluwe",
        "lng": 4.4359939711919,
        "lat": 50.8662434
    },
    {
        "zip": "1933",
        "city": "Sterrebeek",
        "lng": 4.5157438438279,
        "lat": 50.85395875
    },
    {
        "zip": "1950",
        "city": "Kraainem",
        "lng": 4.4691371,
        "lat": 50.8606105
    },
    {
        "zip": "1970",
        "city": "Wezembeek-Oppem",
        "lng": 4.4871796,
        "lat": 50.8440554
    },
    {
        "zip": "1980",
        "city": "Eppegem",
        "lng": 4.4556575,
        "lat": 50.961429
    },
    {
        "zip": "1980",
        "city": "Zemst",
        "lng": 4.44412333945,
        "lat": 50.9865028
    },
    {
        "zip": "1981",
        "city": "Hofstade",
        "lng": 4.5042526607237,
        "lat": 50.9939033
    },
    {
        "zip": "1982",
        "city": "Elewijt",
        "lng": 4.5075638918541,
        "lat": 50.96953845
    },
    {
        "zip": "1982",
        "city": "Weerde",
        "lng": 4.479869,
        "lat": 50.9722782
    },
    {
        "zip": "2000",
        "city": "Antwerpen",
        "lng": 4.3997081,
        "lat": 51.2211097
    },
    {
        "zip": "2018",
        "city": "Antwerpen",
        "lng": 4.3997081,
        "lat": 51.2211097
    },
    {
        "zip": "2020",
        "city": "Antwerpen",
        "lng": 4.3997081,
        "lat": 51.2211097
    },
    {
        "zip": "2030",
        "city": "Antwerpen",
        "lng": 4.3997081,
        "lat": 51.2211097
    },
    {
        "zip": "2040",
        "city": "Antwerpen",
        "lng": 4.3997081,
        "lat": 51.2211097
    },
    {
        "zip": "2040",
        "city": "Berendrecht",
        "lng": 4.319401,
        "lat": 51.3455396
    },
    {
        "zip": "2040",
        "city": "Lillo",
        "lng": 4.2900122,
        "lat": 51.3045534
    },
    {
        "zip": "2040",
        "city": "Zandvliet",
        "lng": 4.2836261784487,
        "lat": 51.35984975
    },
    {
        "zip": "2050",
        "city": "Antwerpen",
        "lng": 4.3997081,
        "lat": 51.2211097
    },
    {
        "zip": "2060",
        "city": "Antwerpen",
        "lng": 4.3997081,
        "lat": 51.2211097
    },
    {
        "zip": "2070",
        "city": "Burcht",
        "lng": 4.3416362,
        "lat": 51.2028046
    },
    {
        "zip": "2070",
        "city": "Zwijndrecht",
        "lng": 4.3188693282188,
        "lat": 51.23062085
    },
    {
        "zip": "2100",
        "city": "Deurne",
        "lng": 4.4695254092764,
        "lat": 51.2115284
    },
    {
        "zip": "2110",
        "city": "Wijnegem",
        "lng": 4.5225318,
        "lat": 51.2270761
    },
    {
        "zip": "2140",
        "city": "Borgerhout",
        "lng": 4.4403895064496,
        "lat": 51.21029905
    },
    {
        "zip": "2150",
        "city": "Borsbeek",
        "lng": 4.488953,
        "lat": 51.1928907
    },
    {
        "zip": "2160",
        "city": "Wommelgem",
        "lng": 4.5238022,
        "lat": 51.2031044
    },
    {
        "zip": "2170",
        "city": "Merksem",
        "lng": 4.4453401635115,
        "lat": 51.24994295
    },
    {
        "zip": "2180",
        "city": "Ekeren",
        "lng": 4.4322870782182,
        "lat": 51.2842045
    },
    {
        "zip": "2200",
        "city": "Herentals",
        "lng": 4.83464460985,
        "lat": 51.1855514
    },
    {
        "zip": "2200",
        "city": "Morkhoven",
        "lng": 4.8207801,
        "lat": 51.1203399
    },
    {
        "zip": "2200",
        "city": "Noorderwijk",
        "lng": 4.8401277,
        "lat": 51.1415564
    },
    {
        "zip": "2220",
        "city": "Hallaar",
        "lng": 4.7191120718788,
        "lat": 51.08755725
    },
    {
        "zip": "2220",
        "city": "Heist-Op-Den-Berg",
        "lng": 4.7193765534389,
        "lat": 51.06251415
    },
    {
        "zip": "2221",
        "city": "Booischot",
        "lng": 4.7692867998141,
        "lat": 51.0471677
    },
    {
        "zip": "2222",
        "city": "Itegem",
        "lng": 4.731894344605,
        "lat": 51.10403005
    },
    {
        "zip": "2222",
        "city": "Wiekevorst",
        "lng": 4.7853558986493,
        "lat": 51.10609435
    },
    {
        "zip": "2223",
        "city": "Schriek",
        "lng": 4.4361161,
        "lat": 51.2907379
    },
    {
        "zip": "2230",
        "city": "Herselt",
        "lng": 4.9018080031354,
        "lat": 51.0554081
    },
    {
        "zip": "2230",
        "city": "Ramsel",
        "lng": 4.8336098,
        "lat": 51.0324718
    },
    {
        "zip": "2235",
        "city": "Houtvenne",
        "lng": 4.8012932645531,
        "lat": 51.04605145
    },
    {
        "zip": "2235",
        "city": "Hulshout",
        "lng": 4.7885461,
        "lat": 51.0753923
    },
    {
        "zip": "2235",
        "city": "Westmeerbeek",
        "lng": 4.8351334,
        "lat": 51.0603638
    },
    {
        "zip": "2240",
        "city": "Massenhoven",
        "lng": 4.6356924683019,
        "lat": 51.19902125
    },
    {
        "zip": "2240",
        "city": "Viersel",
        "lng": 4.6503063,
        "lat": 51.187272
    },
    {
        "zip": "2240",
        "city": "Zandhoven",
        "lng": 4.6595103,
        "lat": 51.2147918
    },
    {
        "zip": "2242",
        "city": "Pulderbos",
        "lng": 4.7033852099385,
        "lat": 51.2257319
    },
    {
        "zip": "2243",
        "city": "Pulle",
        "lng": 4.6975445276147,
        "lat": 51.2011609
    },
    {
        "zip": "2250",
        "city": "Olen",
        "lng": 4.8597257,
        "lat": 51.1438611
    },
    {
        "zip": "2260",
        "city": "Oevel",
        "lng": 4.9053101,
        "lat": 51.1377568
    },
    {
        "zip": "2260",
        "city": "Tongerlo",
        "lng": 4.9012622781849,
        "lat": 51.1220734
    },
    {
        "zip": "2260",
        "city": "Westerlo",
        "lng": 4.9168675,
        "lat": 51.086694
    },
    {
        "zip": "2260",
        "city": "Zoerle-Parwijs",
        "lng": 4.8729402,
        "lat": 51.0880889
    },
    {
        "zip": "2270",
        "city": "Herenthout",
        "lng": 4.7543972,
        "lat": 51.1392427
    },
    {
        "zip": "2275",
        "city": "Gierle",
        "lng": 4.8676797,
        "lat": 51.26758
    },
    {
        "zip": "2275",
        "city": "Lille",
        "lng": 4.8242404,
        "lat": 51.238218
    },
    {
        "zip": "2275",
        "city": "Poederlee",
        "lng": 4.8359528,
        "lat": 51.2267185
    },
    {
        "zip": "2275",
        "city": "Wechelderzande",
        "lng": 4.7910582697468,
        "lat": 51.26541275
    },
    {
        "zip": "2280",
        "city": "Grobbendonk",
        "lng": 4.7318844301963,
        "lat": 51.18870335
    },
    {
        "zip": "2288",
        "city": "Bouwel",
        "lng": 4.7370614385825,
        "lat": 51.16210045
    },
    {
        "zip": "2290",
        "city": "Vorselaar",
        "lng": 4.769482,
        "lat": 51.2023448
    },
    {
        "zip": "2300",
        "city": "Turnhout",
        "lng": 4.948461,
        "lat": 51.3233812
    },
    {
        "zip": "2310",
        "city": "Rijkevorsel",
        "lng": 4.760708,
        "lat": 51.3495046
    },
    {
        "zip": "2320",
        "city": "Hoogstraten",
        "lng": 4.7440656414186,
        "lat": 51.39574085
    },
    {
        "zip": "2321",
        "city": "Meer",
        "lng": 4.7372548575575,
        "lat": 51.46092495
    },
    {
        "zip": "2322",
        "city": "Minderhout",
        "lng": 4.8070790487489,
        "lat": 51.43155825
    },
    {
        "zip": "2323",
        "city": "Wortel",
        "lng": 4.8085930879726,
        "lat": 51.3975366
    },
    {
        "zip": "2328",
        "city": "Meerle",
        "lng": 4.8051679,
        "lat": 51.4743516
    },
    {
        "zip": "2330",
        "city": "Merksplas",
        "lng": 4.8627541,
        "lat": 51.3580597
    },
    {
        "zip": "2340",
        "city": "Beerse",
        "lng": 4.837570452313,
        "lat": 51.3134921
    },
    {
        "zip": "2340",
        "city": "Vlimmeren",
        "lng": 4.7821797,
        "lat": 51.2988018
    },
    {
        "zip": "2350",
        "city": "Vosselaar",
        "lng": 4.8878026,
        "lat": 51.3129153
    },
    {
        "zip": "2360",
        "city": "Oud-Turnhout",
        "lng": 4.9817086,
        "lat": 51.3178319
    },
    {
        "zip": "2370",
        "city": "Arendonk",
        "lng": 5.0864557,
        "lat": 51.3202315
    },
    {
        "zip": "2380",
        "city": "Ravels",
        "lng": 5.0198122919518,
        "lat": 51.3808625
    },
    {
        "zip": "2381",
        "city": "Weelde",
        "lng": 5.0182445420616,
        "lat": 51.41206885
    },
    {
        "zip": "2382",
        "city": "Poppel",
        "lng": 5.0488069670452,
        "lat": 51.45063155
    },
    {
        "zip": "2387",
        "city": "Baarle-Hertog",
        "lng": 4.9004051710883,
        "lat": 51.412750431352
    },
    {
        "zip": "2390",
        "city": "Malle",
        "lng": 4.7149828,
        "lat": 51.3001124
    },
    {
        "zip": "2390",
        "city": "Oostmalle",
        "lng": 4.7331618,
        "lat": 51.3019209
    },
    {
        "zip": "2390",
        "city": "Westmalle",
        "lng": 4.6713095211155,
        "lat": 51.29940565
    },
    {
        "zip": "2400",
        "city": "Mol",
        "lng": 5.1155267,
        "lat": 51.1840421
    },
    {
        "zip": "2430",
        "city": "Eindhout",
        "lng": 4.9972890275518,
        "lat": 51.10171295
    },
    {
        "zip": "2430",
        "city": "Laakdal",
        "lng": 5.0073022,
        "lat": 51.0802695
    },
    {
        "zip": "2430",
        "city": "Vorst",
        "lng": 5.0188161,
        "lat": 51.0790117
    },
    {
        "zip": "2431",
        "city": "Varendonk",
        "lng": 4.9552253,
        "lat": 51.0801317
    },
    {
        "zip": "2431",
        "city": "Veerle",
        "lng": 4.9805536902848,
        "lat": 51.0592049
    },
    {
        "zip": "2440",
        "city": "Geel",
        "lng": 4.9903187,
        "lat": 51.1610826
    },
    {
        "zip": "2450",
        "city": "Meerhout",
        "lng": 5.0772388,
        "lat": 51.1317433
    },
    {
        "zip": "2460",
        "city": "Kasterlee",
        "lng": 4.967812,
        "lat": 51.2407915
    },
    {
        "zip": "2460",
        "city": "Lichtaart",
        "lng": 4.9037390737022,
        "lat": 51.2309182
    },
    {
        "zip": "2460",
        "city": "Tielen",
        "lng": 4.8965455,
        "lat": 51.2422595
    },
    {
        "zip": "2470",
        "city": "Retie",
        "lng": 5.0843883,
        "lat": 51.2675862
    },
    {
        "zip": "2480",
        "city": "Dessel",
        "lng": 5.113243,
        "lat": 51.2395713
    },
    {
        "zip": "2490",
        "city": "Balen",
        "lng": 5.1937730761009,
        "lat": 51.171804
    },
    {
        "zip": "2491",
        "city": "Olmen",
        "lng": 5.1648214462792,
        "lat": 51.13368515
    },
    {
        "zip": "2500",
        "city": "Koningshooikt",
        "lng": 4.6033828110877,
        "lat": 51.0929433
    },
    {
        "zip": "2500",
        "city": "Lier",
        "lng": 4.5696516,
        "lat": 51.131069
    },
    {
        "zip": "2520",
        "city": "Broechem",
        "lng": 4.5967162,
        "lat": 51.1813951
    },
    {
        "zip": "2520",
        "city": "Emblem",
        "lng": 4.6047327,
        "lat": 51.1625761
    },
    {
        "zip": "2520",
        "city": "Oelegem",
        "lng": 4.5972162,
        "lat": 51.2110687
    },
    {
        "zip": "2520",
        "city": "Ranst",
        "lng": 4.5637563614009,
        "lat": 51.1961732
    },
    {
        "zip": "2530",
        "city": "Boechout",
        "lng": 4.510406865149,
        "lat": 51.1596941
    },
    {
        "zip": "2531",
        "city": "Vremde",
        "lng": 4.5226229,
        "lat": 51.1753099
    },
    {
        "zip": "2540",
        "city": "Hove",
        "lng": 4.477387,
        "lat": 51.1486117
    },
    {
        "zip": "2547",
        "city": "Lint",
        "lng": 4.4922187,
        "lat": 51.1268305
    },
    {
        "zip": "2550",
        "city": "Kontich",
        "lng": 4.4456359,
        "lat": 51.134377
    },
    {
        "zip": "2550",
        "city": "Waarloos",
        "lng": 4.453,
        "lat": 51.1052659
    },
    {
        "zip": "2560",
        "city": "Bevel",
        "lng": 4.6922875770195,
        "lat": 51.1364316
    },
    {
        "zip": "2560",
        "city": "Kessel",
        "lng": 4.6393456001407,
        "lat": 51.14453105
    },
    {
        "zip": "2560",
        "city": "Nijlen",
        "lng": 4.6709517317049,
        "lat": 51.1611887
    },
    {
        "zip": "2570",
        "city": "Duffel",
        "lng": 4.5061988,
        "lat": 51.0957176
    },
    {
        "zip": "2580",
        "city": "Beerzel",
        "lng": 4.6630807412987,
        "lat": 51.06299735
    },
    {
        "zip": "2580",
        "city": "Putte",
        "lng": 4.6310473,
        "lat": 51.0570823
    },
    {
        "zip": "2590",
        "city": "Berlaar",
        "lng": 4.6640387634597,
        "lat": 51.10303735
    },
    {
        "zip": "2590",
        "city": "Gestel",
        "lng": 4.6646313199891,
        "lat": 51.12758685
    },
    {
        "zip": "2600",
        "city": "Berchem",
        "lng": 4.4317026387884,
        "lat": 51.1918493
    },
    {
        "zip": "2610",
        "city": "Wilrijk",
        "lng": 4.3876309610455,
        "lat": 51.1638451
    },
    {
        "zip": "2620",
        "city": "Hemiksem",
        "lng": 4.3420555,
        "lat": 51.1442105
    },
    {
        "zip": "2627",
        "city": "Schelle",
        "lng": 4.3361875,
        "lat": 51.1245635
    },
    {
        "zip": "2630",
        "city": "Aartselaar",
        "lng": 4.3870241,
        "lat": 51.133297
    },
    {
        "zip": "2640",
        "city": "Mortsel",
        "lng": 4.4593335,
        "lat": 51.1741789
    },
    {
        "zip": "2650",
        "city": "Edegem",
        "lng": 4.4384284,
        "lat": 51.1574786
    },
    {
        "zip": "2660",
        "city": "Hoboken",
        "lng": 4.3511502171395,
        "lat": 51.1757824
    },
    {
        "zip": "2800",
        "city": "Mechelen",
        "lng": 4.471321199996,
        "lat": 51.02803335
    },
    {
        "zip": "2800",
        "city": "Walem",
        "lng": 4.4568748,
        "lat": 51.0657941
    },
    {
        "zip": "2801",
        "city": "Heffen",
        "lng": 4.4124762563322,
        "lat": 51.0519355
    },
    {
        "zip": "2811",
        "city": "Hombeek",
        "lng": 4.4211910518318,
        "lat": 51.01166405
    },
    {
        "zip": "2811",
        "city": "Leest",
        "lng": 4.4150058,
        "lat": 51.0350559
    },
    {
        "zip": "2812",
        "city": "Muizen",
        "lng": 4.5182999731643,
        "lat": 51.0083206
    },
    {
        "zip": "2820",
        "city": "Bonheiden",
        "lng": 4.5378217779072,
        "lat": 51.0261294
    },
    {
        "zip": "2820",
        "city": "Rijmenam",
        "lng": 4.5842221,
        "lat": 51.0010818
    },
    {
        "zip": "2830",
        "city": "Blaasveld",
        "lng": 4.3723517,
        "lat": 51.0565194
    },
    {
        "zip": "2830",
        "city": "Heindonk",
        "lng": 4.4066584,
        "lat": 51.0670264
    },
    {
        "zip": "2830",
        "city": "Tisselt",
        "lng": 4.3598407,
        "lat": 51.033992
    },
    {
        "zip": "2830",
        "city": "Willebroek",
        "lng": 4.3572324644033,
        "lat": 51.0626964
    },
    {
        "zip": "2840",
        "city": "Reet",
        "lng": 4.4058899586301,
        "lat": 51.1044854
    },
    {
        "zip": "2840",
        "city": "Rumst",
        "lng": 4.4240472,
        "lat": 51.0792853
    },
    {
        "zip": "2840",
        "city": "Terhagen",
        "lng": 4.3993623,
        "lat": 51.078277
    },
    {
        "zip": "2845",
        "city": "Niel",
        "lng": 4.3303396,
        "lat": 51.1098649
    },
    {
        "zip": "2850",
        "city": "Boom",
        "lng": 4.3667216,
        "lat": 51.0873789
    },
    {
        "zip": "2860",
        "city": "Sint-Katelijne-Waver",
        "lng": 4.5114278140147,
        "lat": 51.06232435
    },
    {
        "zip": "2861",
        "city": "Onze-Lieve-Vrouw-Waver",
        "lng": 4.5799614,
        "lat": 51.0624913
    },
    {
        "zip": "2870",
        "city": "Breendonk",
        "lng": 4.3136108625549,
        "lat": 51.0452687
    },
    {
        "zip": "2870",
        "city": "Liezele",
        "lng": 4.2808516096232,
        "lat": 51.05486435
    },
    {
        "zip": "2870",
        "city": "Puurs",
        "lng": 4.2861928678797,
        "lat": 51.07199845
    },
    {
        "zip": "2870",
        "city": "Ruisbroek",
        "lng": 4.3379815821239,
        "lat": 51.0802059
    },
    {
        "zip": "2880",
        "city": "Bornem",
        "lng": 4.2423260051519,
        "lat": 51.0924649
    },
    {
        "zip": "2880",
        "city": "Hingene",
        "lng": 4.2930862930359,
        "lat": 51.103114
    },
    {
        "zip": "2880",
        "city": "Mariekerke",
        "lng": 4.2010309553894,
        "lat": 51.06647005
    },
    {
        "zip": "2880",
        "city": "Weert",
        "lng": 4.1855882750296,
        "lat": 51.10151195
    },
    {
        "zip": "2890",
        "city": "Lippelo",
        "lng": 4.2519213925908,
        "lat": 51.0451478
    },
    {
        "zip": "2890",
        "city": "Oppuurs",
        "lng": 4.2389889523045,
        "lat": 51.06220625
    },
    {
        "zip": "2890",
        "city": "Sint-Amands",
        "lng": 4.2107348196593,
        "lat": 51.0463409
    },
    {
        "zip": "2900",
        "city": "Schoten",
        "lng": 4.4980446,
        "lat": 51.2516344
    },
    {
        "zip": "2910",
        "city": "Essen",
        "lng": 4.4701309,
        "lat": 51.4677957
    },
    {
        "zip": "2920",
        "city": "Kalmthout",
        "lng": 4.4763255,
        "lat": 51.3832723
    },
    {
        "zip": "2930",
        "city": "Brasschaat",
        "lng": 4.489307,
        "lat": 51.2930933
    },
    {
        "zip": "2940",
        "city": "Hoevenen",
        "lng": 4.4046996,
        "lat": 51.3066168
    },
    {
        "zip": "2940",
        "city": "Stabroek",
        "lng": 4.370312016044,
        "lat": 51.33518735
    },
    {
        "zip": "2950",
        "city": "Kapellen",
        "lng": 4.4312318305558,
        "lat": 51.332759
    },
    {
        "zip": "2960",
        "city": "Brecht",
        "lng": 4.5972381789962,
        "lat": 51.3347679
    },
    {
        "zip": "2960",
        "city": "Sint-Job-In-'t-Goor",
        "lng": 4.5759347,
        "lat": 51.2955923
    },
    {
        "zip": "2960",
        "city": "Sint-Lenaarts",
        "lng": 4.6782162,
        "lat": 51.3453831
    },
    {
        "zip": "2970",
        "city": "'s Gravenwezel",
        "lng": 4.5556038,
        "lat": 51.2624856
    },
    {
        "zip": "2970",
        "city": "Schilde",
        "lng": 4.5955157359888,
        "lat": 51.257095
    },
    {
        "zip": "2980",
        "city": "Halle",
        "lng": 4.6455565344486,
        "lat": 51.2428446
    },
    {
        "zip": "2980",
        "city": "Zoersel",
        "lng": 4.7132557,
        "lat": 51.2716725
    },
    {
        "zip": "2990",
        "city": "Loenhout",
        "lng": 4.6428328,
        "lat": 51.3994474
    },
    {
        "zip": "2990",
        "city": "Wuustwezel",
        "lng": 4.5651743450402,
        "lat": 51.38525
    },
    {
        "zip": "3000",
        "city": "Leuven",
        "lng": 4.6929903215189,
        "lat": 50.8812533
    },
    {
        "zip": "3001",
        "city": "Heverlee",
        "lng": 4.6931307510281,
        "lat": 50.85172925
    },
    {
        "zip": "3010",
        "city": "Kessel Lo",
        "lng": 4.7307614946269,
        "lat": 50.88991545
    },
    {
        "zip": "3012",
        "city": "Wilsele",
        "lng": 4.7136294,
        "lat": 50.9095362
    },
    {
        "zip": "3018",
        "city": "Wijgmaal",
        "lng": 4.7001214,
        "lat": 50.926428
    },
    {
        "zip": "3020",
        "city": "Herent",
        "lng": 4.672714,
        "lat": 50.9079329
    },
    {
        "zip": "3020",
        "city": "Veltem-Beisem",
        "lng": 4.6255138,
        "lat": 50.9020979
    },
    {
        "zip": "3020",
        "city": "Winksele",
        "lng": 4.6404471957661,
        "lat": 50.9124843
    },
    {
        "zip": "3040",
        "city": "Huldenberg",
        "lng": 4.5828587400028,
        "lat": 50.7950895
    },
    {
        "zip": "3040",
        "city": "Loonbeek",
        "lng": 4.6067572,
        "lat": 50.8070345
    },
    {
        "zip": "3040",
        "city": "Neerijse",
        "lng": 4.6290291125613,
        "lat": 50.81115135
    },
    {
        "zip": "3040",
        "city": "Ottenburg",
        "lng": 4.6258024576448,
        "lat": 50.7540955
    },
    {
        "zip": "3040",
        "city": "Sint-Agatha-Rode",
        "lng": 4.6329556561262,
        "lat": 50.7794761
    },
    {
        "zip": "3050",
        "city": "Oud-Heverlee",
        "lng": 4.6678971,
        "lat": 50.8217682
    },
    {
        "zip": "3051",
        "city": "Sint-Joris-Weert",
        "lng": 4.6458289787229,
        "lat": 50.8051035
    },
    {
        "zip": "3052",
        "city": "Blanden",
        "lng": 4.7056796612254,
        "lat": 50.8281673
    },
    {
        "zip": "3053",
        "city": "Haasrode",
        "lng": 4.7283706944623,
        "lat": 50.81785645
    },
    {
        "zip": "3054",
        "city": "Vaalbeek",
        "lng": 4.6852713590102,
        "lat": 50.82408165
    },
    {
        "zip": "3060",
        "city": "Bertem",
        "lng": 4.6322907,
        "lat": 50.8706469
    },
    {
        "zip": "3060",
        "city": "Korbeek-Dijle",
        "lng": 4.6299439737893,
        "lat": 50.847010125156
    },
    {
        "zip": "3061",
        "city": "Leefdaal",
        "lng": 4.5905043649411,
        "lat": 50.8419887
    },
    {
        "zip": "3070",
        "city": "Kortenberg",
        "lng": 4.5364707039624,
        "lat": 50.88326665
    },
    {
        "zip": "3071",
        "city": "Erps-Kwerps",
        "lng": 4.5750864125531,
        "lat": 50.9104969
    },
    {
        "zip": "3078",
        "city": "Everberg",
        "lng": 4.5545949589597,
        "lat": 50.87266065
    },
    {
        "zip": "3078",
        "city": "Meerbeek",
        "lng": 4.5972692536847,
        "lat": 50.8782935
    },
    {
        "zip": "3080",
        "city": "Duisburg",
        "lng": 4.545208431698,
        "lat": 50.8110528
    },
    {
        "zip": "3080",
        "city": "Tervuren",
        "lng": 4.5144027,
        "lat": 50.8227718
    },
    {
        "zip": "3080",
        "city": "Vossem",
        "lng": 4.5565660886042,
        "lat": 50.83488335
    },
    {
        "zip": "3090",
        "city": "Overijse",
        "lng": 4.5385226,
        "lat": 50.7729254
    },
    {
        "zip": "3110",
        "city": "Rotselaar",
        "lng": 4.7143205960258,
        "lat": 50.9543637
    },
    {
        "zip": "3111",
        "city": "Wezemaal",
        "lng": 4.7683306885409,
        "lat": 50.9482552
    },
    {
        "zip": "3118",
        "city": "Werchter",
        "lng": 4.7026877230529,
        "lat": 50.9707189
    },
    {
        "zip": "3120",
        "city": "Tremelo",
        "lng": 4.6755134,
        "lat": 50.9900457
    },
    {
        "zip": "3128",
        "city": "Baal",
        "lng": 4.7505367662714,
        "lat": 51.0075269
    },
    {
        "zip": "3130",
        "city": "Begijnendijk",
        "lng": 4.7826403117004,
        "lat": 51.02422075
    },
    {
        "zip": "3130",
        "city": "Betekom",
        "lng": 4.7908301771478,
        "lat": 50.99602795
    },
    {
        "zip": "3140",
        "city": "Keerbergen",
        "lng": 4.6265551,
        "lat": 51.0049242
    },
    {
        "zip": "3150",
        "city": "Haacht",
        "lng": 4.6386824,
        "lat": 50.9769286
    },
    {
        "zip": "3150",
        "city": "Tildonk",
        "lng": 4.6595165776069,
        "lat": 50.94081955
    },
    {
        "zip": "3150",
        "city": "Wespelaar",
        "lng": 4.6325890563885,
        "lat": 50.95645295
    },
    {
        "zip": "3190",
        "city": "Boortmeerbeek",
        "lng": 4.575800525,
        "lat": 50.97828585
    },
    {
        "zip": "3191",
        "city": "Hever",
        "lng": 4.5553278,
        "lat": 50.9925865
    },
    {
        "zip": "3200",
        "city": "Aarschot",
        "lng": 4.8245687,
        "lat": 50.9841594
    },
    {
        "zip": "3200",
        "city": "Gelrode",
        "lng": 4.8042730440984,
        "lat": 50.9670761
    },
    {
        "zip": "3201",
        "city": "Langdorp",
        "lng": 4.8856989624279,
        "lat": 51.0118664
    },
    {
        "zip": "3202",
        "city": "Rillaar",
        "lng": 4.8970494266873,
        "lat": 50.97591095
    },
    {
        "zip": "3210",
        "city": "Linden",
        "lng": 4.7756847507269,
        "lat": 50.89891075
    },
    {
        "zip": "3210",
        "city": "Lubbeek",
        "lng": 4.8400599406417,
        "lat": 50.8821122
    },
    {
        "zip": "3211",
        "city": "Binkom",
        "lng": 4.8908885858013,
        "lat": 50.8721422
    },
    {
        "zip": "3212",
        "city": "Pellenberg",
        "lng": 4.7894086649098,
        "lat": 50.87211325
    },
    {
        "zip": "3220",
        "city": "Holsbeek",
        "lng": 4.7557296717686,
        "lat": 50.92172015
    },
    {
        "zip": "3220",
        "city": "Kortrijk-Dutsel",
        "lng": 4.7854555627527,
        "lat": 50.91851005
    },
    {
        "zip": "3220",
        "city": "Sint-Pieters-Rode",
        "lng": 4.8291872982184,
        "lat": 50.9208131
    },
    {
        "zip": "3221",
        "city": "Nieuwrode",
        "lng": 4.8338045392342,
        "lat": 50.9470265
    },
    {
        "zip": "3270",
        "city": "Scherpenheuvel",
        "lng": 4.9547327768843,
        "lat": 50.9725745
    },
    {
        "zip": "3270",
        "city": "Scherpenheuvel-Zichem",
        "lng": 4.9754325657663,
        "lat": 50.9689147
    },
    {
        "zip": "3271",
        "city": "Averbode",
        "lng": 4.9770845794874,
        "lat": 51.0278406
    },
    {
        "zip": "3271",
        "city": "Zichem",
        "lng": 4.984958799435,
        "lat": 51.0085771
    },
    {
        "zip": "3272",
        "city": "Messelbroek",
        "lng": 4.924667681525,
        "lat": 50.98948425
    },
    {
        "zip": "3272",
        "city": "Testelt",
        "lng": 4.9484707287374,
        "lat": 51.0192772
    },
    {
        "zip": "3290",
        "city": "Deurne",
        "lng": 5.0964572,
        "lat": 51.0392379
    },
    {
        "zip": "3290",
        "city": "Diest",
        "lng": 5.052873496255,
        "lat": 50.98456645
    },
    {
        "zip": "3290",
        "city": "Schaffen",
        "lng": 5.086792549946,
        "lat": 51.0130411
    },
    {
        "zip": "3290",
        "city": "Webbekom",
        "lng": 5.054414061955,
        "lat": 50.9649291
    },
    {
        "zip": "3293",
        "city": "Kaggevinne",
        "lng": 5.0299404926122,
        "lat": 50.9776976
    },
    {
        "zip": "3294",
        "city": "Molenstede",
        "lng": 5.0247465287649,
        "lat": 51.0071839
    },
    {
        "zip": "3300",
        "city": "Bost",
        "lng": 4.9339239586832,
        "lat": 50.7846322
    },
    {
        "zip": "3300",
        "city": "Goetsenhoven",
        "lng": 4.9603301209235,
        "lat": 50.77139835
    },
    {
        "zip": "3300",
        "city": "Hakendover",
        "lng": 4.9886552612345,
        "lat": 50.7971718
    },
    {
        "zip": "3300",
        "city": "Kumtich",
        "lng": 4.8863798378559,
        "lat": 50.8245511
    },
    {
        "zip": "3300",
        "city": "Oorbeek",
        "lng": 4.9137602382894,
        "lat": 50.79215565
    },
    {
        "zip": "3300",
        "city": "Oplinter",
        "lng": 4.9950692073381,
        "lat": 50.8284931
    },
    {
        "zip": "3300",
        "city": "Sint-Margriete-Houtem",
        "lng": 4.9623805201831,
        "lat": 50.83464645
    },
    {
        "zip": "3300",
        "city": "Tienen",
        "lng": 4.9464292134294,
        "lat": 50.80843175
    },
    {
        "zip": "3300",
        "city": "Vissenaken",
        "lng": 4.9111431209758,
        "lat": 50.84379005
    },
    {
        "zip": "3320",
        "city": "Hoegaarden",
        "lng": 4.88114193614,
        "lat": 50.7877912
    },
    {
        "zip": "3320",
        "city": "Meldert",
        "lng": 4.8294566856141,
        "lat": 50.78956675
    },
    {
        "zip": "3321",
        "city": "Outgaarden",
        "lng": 4.9193571,
        "lat": 50.7665968
    },
    {
        "zip": "3350",
        "city": "Drieslinter",
        "lng": 5.0533455578997,
        "lat": 50.84486375
    },
    {
        "zip": "3350",
        "city": "Linter",
        "lng": 5.0409717,
        "lat": 50.8291537
    },
    {
        "zip": "3350",
        "city": "Melkwezer",
        "lng": 5.0585292480567,
        "lat": 50.8249402
    },
    {
        "zip": "3350",
        "city": "Neerhespen",
        "lng": 5.0579336298623,
        "lat": 50.78801405
    },
    {
        "zip": "3350",
        "city": "Neerlinter",
        "lng": 5.0283876,
        "lat": 50.843352
    },
    {
        "zip": "3350",
        "city": "Orsmaal-Gussenhoven",
        "lng": 5.0674769,
        "lat": 50.8054143
    },
    {
        "zip": "3350",
        "city": "Overhespen",
        "lng": 5.0300432882597,
        "lat": 50.7968421
    },
    {
        "zip": "3350",
        "city": "Wommersom",
        "lng": 5.0183106289924,
        "lat": 50.812361
    },
    {
        "zip": "3360",
        "city": "Bierbeek",
        "lng": 4.7714318076029,
        "lat": 50.8241245
    },
    {
        "zip": "3360",
        "city": "Korbeek-Lo",
        "lng": 4.7561775068454,
        "lat": 50.86176115
    },
    {
        "zip": "3360",
        "city": "Lovenjoel",
        "lng": 4.7866873360324,
        "lat": 50.85475565
    },
    {
        "zip": "3360",
        "city": "Opvelp",
        "lng": 4.7912847996064,
        "lat": 50.80732735
    },
    {
        "zip": "3370",
        "city": "Boutersem",
        "lng": 4.8336178052558,
        "lat": 50.8473461
    },
    {
        "zip": "3370",
        "city": "Kerkom",
        "lng": 4.8643358054912,
        "lat": 50.85699665
    },
    {
        "zip": "3370",
        "city": "Neervelp",
        "lng": 4.8136699565725,
        "lat": 50.8166881
    },
    {
        "zip": "3370",
        "city": "Roosbeek",
        "lng": 4.8620269340011,
        "lat": 50.84022445
    },
    {
        "zip": "3370",
        "city": "Vertrijk",
        "lng": 4.8270179962162,
        "lat": 50.83400235
    },
    {
        "zip": "3370",
        "city": "Willebringen",
        "lng": 4.8387022883011,
        "lat": 50.80664425
    },
    {
        "zip": "3380",
        "city": "Bunsbeek",
        "lng": 4.9601107928553,
        "lat": 50.8510351
    },
    {
        "zip": "3380",
        "city": "Glabbeek",
        "lng": 4.9513009,
        "lat": 50.8725203
    },
    {
        "zip": "3381",
        "city": "Kapellen",
        "lng": 4.9608776189751,
        "lat": 50.88734525
    },
    {
        "zip": "3384",
        "city": "Attenrode",
        "lng": 4.9167554140969,
        "lat": 50.8694607
    },
    {
        "zip": "3390",
        "city": "Houwaart",
        "lng": 4.8605997436813,
        "lat": 50.93414405
    },
    {
        "zip": "3390",
        "city": "Sint-Joris-Winge",
        "lng": 4.8754116537611,
        "lat": 50.9121762
    },
    {
        "zip": "3390",
        "city": "Tielt",
        "lng": 4.9155513317582,
        "lat": 50.9346235
    },
    {
        "zip": "3390",
        "city": "Tielt-Winge",
        "lng": 4.8863781815823,
        "lat": 50.9242724
    },
    {
        "zip": "3391",
        "city": "Meensel-Kiezegem",
        "lng": 4.9173431325348,
        "lat": 50.8950541
    },
    {
        "zip": "3400",
        "city": "Eliksem",
        "lng": 5.0074922319452,
        "lat": 50.78710135
    },
    {
        "zip": "3400",
        "city": "Ezemaal",
        "lng": 4.997333783131,
        "lat": 50.7766242
    },
    {
        "zip": "3400",
        "city": "Laar",
        "lng": 4.4410406,
        "lat": 51.2687557
    },
    {
        "zip": "3400",
        "city": "Landen",
        "lng": 5.0790966118688,
        "lat": 50.7493167
    },
    {
        "zip": "3400",
        "city": "Neerwinden",
        "lng": 5.0409975765006,
        "lat": 50.7652288
    },
    {
        "zip": "3400",
        "city": "Overwinden",
        "lng": 5.0448025257015,
        "lat": 50.75242545
    },
    {
        "zip": "3400",
        "city": "Rumsdorp",
        "lng": 5.0695564232804,
        "lat": 50.76917005
    },
    {
        "zip": "3400",
        "city": "Wange",
        "lng": 5.0302723897201,
        "lat": 50.7838394
    },
    {
        "zip": "3401",
        "city": "Waasmont",
        "lng": 5.0609420487376,
        "lat": 50.72608345
    },
    {
        "zip": "3401",
        "city": "Walsbets",
        "lng": 5.0878535498936,
        "lat": 50.7385007
    },
    {
        "zip": "3401",
        "city": "Walshoutem",
        "lng": 5.0869975953341,
        "lat": 50.71820555
    },
    {
        "zip": "3401",
        "city": "Wezeren",
        "lng": 5.1094961371256,
        "lat": 50.732168
    },
    {
        "zip": "3404",
        "city": "Attenhoven",
        "lng": 5.1006534330464,
        "lat": 50.7653693
    },
    {
        "zip": "3404",
        "city": "Neerlanden",
        "lng": 5.0793874624497,
        "lat": 50.7821026
    },
    {
        "zip": "3440",
        "city": "Budingen",
        "lng": 5.0871237674967,
        "lat": 50.8653457
    },
    {
        "zip": "3440",
        "city": "Dormaal",
        "lng": 5.0920421498928,
        "lat": 50.8063449
    },
    {
        "zip": "3440",
        "city": "Halle-Booienhoven",
        "lng": 5.1205761783641,
        "lat": 50.8127265
    },
    {
        "zip": "3440",
        "city": "Helen-Bos",
        "lng": 5.0802259143417,
        "lat": 50.83603965
    },
    {
        "zip": "3440",
        "city": "Zoutleeuw",
        "lng": 5.1186447480147,
        "lat": 50.8415653
    },
    {
        "zip": "3450",
        "city": "Geetbets",
        "lng": 5.1015810029407,
        "lat": 50.89519195
    },
    {
        "zip": "3450",
        "city": "Grazen",
        "lng": 5.1299277800431,
        "lat": 50.8754117
    },
    {
        "zip": "3454",
        "city": "Rummen",
        "lng": 5.1564917481959,
        "lat": 50.88594765
    },
    {
        "zip": "3460",
        "city": "Assent",
        "lng": 5.0136688435307,
        "lat": 50.94677135
    },
    {
        "zip": "3460",
        "city": "Bekkevoort",
        "lng": 4.984616971522,
        "lat": 50.9338977
    },
    {
        "zip": "3461",
        "city": "Molenbeek-Wersbeek",
        "lng": 4.9541212,
        "lat": 50.9212106
    },
    {
        "zip": "3470",
        "city": "Kortenaken",
        "lng": 5.068392495735,
        "lat": 50.90566935
    },
    {
        "zip": "3470",
        "city": "Ransberg",
        "lng": 5.0360646174319,
        "lat": 50.8758246
    },
    {
        "zip": "3470",
        "city": "Sint-Margriete-Houtem",
        "lng": 4.9623805201831,
        "lat": 50.83464645
    },
    {
        "zip": "3471",
        "city": "Hoeleden",
        "lng": 5.0014628020046,
        "lat": 50.8668096
    },
    {
        "zip": "3472",
        "city": "Kersbeek-Miskom",
        "lng": 5.0065116045983,
        "lat": 50.8930932577
    },
    {
        "zip": "3473",
        "city": "Waanrode",
        "lng": 5.0037716328589,
        "lat": 50.9129375
    },
    {
        "zip": "3500",
        "city": "Hasselt",
        "lng": 5.3368383970676,
        "lat": 50.930358
    },
    {
        "zip": "3500",
        "city": "Sint-Lambrechts-Herk",
        "lng": 5.3119462785891,
        "lat": 50.90358435
    },
    {
        "zip": "3501",
        "city": "Wimmertingen",
        "lng": 5.3512906396928,
        "lat": 50.8769276
    },
    {
        "zip": "3510",
        "city": "Kermt",
        "lng": 5.2509784643733,
        "lat": 50.9524495
    },
    {
        "zip": "3510",
        "city": "Spalbeek",
        "lng": 5.2277944313068,
        "lat": 50.9490449
    },
    {
        "zip": "3511",
        "city": "Kuringen",
        "lng": 5.3052007,
        "lat": 50.9439384
    },
    {
        "zip": "3511",
        "city": "Stokrooie",
        "lng": 5.2747491372719,
        "lat": 50.97085355
    },
    {
        "zip": "3512",
        "city": "Stevoort",
        "lng": 5.2444291944072,
        "lat": 50.91698375
    },
    {
        "zip": "3520",
        "city": "Zonhoven",
        "lng": 5.3677683,
        "lat": 50.9909835
    },
    {
        "zip": "3530",
        "city": "Helchteren",
        "lng": 5.3844821648536,
        "lat": 51.0743268
    },
    {
        "zip": "3530",
        "city": "Houthalen",
        "lng": 5.4121547136036,
        "lat": 51.036563
    },
    {
        "zip": "3530",
        "city": "Houthalen-Helchteren",
        "lng": 5.3713044,
        "lat": 51.0281818
    },
    {
        "zip": "3540",
        "city": "Berbroek",
        "lng": 5.2098576659451,
        "lat": 50.94876395
    },
    {
        "zip": "3540",
        "city": "Donk",
        "lng": 4.9053567,
        "lat": 50.9582088
    },
    {
        "zip": "3540",
        "city": "Herk-De-Stad",
        "lng": 5.1884301868953,
        "lat": 50.9222911
    },
    {
        "zip": "3540",
        "city": "Schulen",
        "lng": 5.171672949688,
        "lat": 50.9533364
    },
    {
        "zip": "3545",
        "city": "Halen",
        "lng": 5.1073913444455,
        "lat": 50.9384339
    },
    {
        "zip": "3545",
        "city": "Loksbergen",
        "lng": 5.0656234079577,
        "lat": 50.9321193
    },
    {
        "zip": "3545",
        "city": "Zelem",
        "lng": 5.1092670735921,
        "lat": 50.980158
    },
    {
        "zip": "3550",
        "city": "Heusden",
        "lng": 5.2835531292784,
        "lat": 51.04153335
    },
    {
        "zip": "3550",
        "city": "Heusden-Zolder",
        "lng": 5.2750485,
        "lat": 51.0233485
    },
    {
        "zip": "3550",
        "city": "Zolder",
        "lng": 5.3135203,
        "lat": 51.0236553
    },
    {
        "zip": "3560",
        "city": "Linkhout",
        "lng": 5.1372552,
        "lat": 50.9657859
    },
    {
        "zip": "3560",
        "city": "Lummen",
        "lng": 5.2025589998731,
        "lat": 51.00313255
    },
    {
        "zip": "3560",
        "city": "Meldert",
        "lng": 5.1472099707378,
        "lat": 51.0067479
    },
    {
        "zip": "3570",
        "city": "Alken",
        "lng": 5.3079873,
        "lat": 50.8754731
    },
    {
        "zip": "3580",
        "city": "Beringen",
        "lng": 5.2208090823008,
        "lat": 51.0502026
    },
    {
        "zip": "3581",
        "city": "Beverlo",
        "lng": 5.2443565507181,
        "lat": 51.08973005
    },
    {
        "zip": "3582",
        "city": "Koersel",
        "lng": 5.271159654589,
        "lat": 51.06978945
    },
    {
        "zip": "3583",
        "city": "Paal",
        "lng": 5.1616725572109,
        "lat": 51.04746375
    },
    {
        "zip": "3590",
        "city": "Diepenbeek",
        "lng": 5.4200307,
        "lat": 50.9078485
    },
    {
        "zip": "3600",
        "city": "Genk",
        "lng": 5.5001456,
        "lat": 50.9654864
    },
    {
        "zip": "3620",
        "city": "Gellik",
        "lng": 5.6091315,
        "lat": 50.8839456
    },
    {
        "zip": "3620",
        "city": "Lanaken",
        "lng": 5.6513208,
        "lat": 50.8892784
    },
    {
        "zip": "3620",
        "city": "Neerharen",
        "lng": 5.680982,
        "lat": 50.907778
    },
    {
        "zip": "3620",
        "city": "Veldwezelt",
        "lng": 5.6323869,
        "lat": 50.8655982
    },
    {
        "zip": "3621",
        "city": "Rekem",
        "lng": 5.6626094755366,
        "lat": 50.9284078
    },
    {
        "zip": "3630",
        "city": "Eisden",
        "lng": 5.7128515,
        "lat": 50.9863045
    },
    {
        "zip": "3630",
        "city": "Leut",
        "lng": 5.7355142,
        "lat": 50.9921686
    },
    {
        "zip": "3630",
        "city": "Maasmechelen",
        "lng": 5.696445,
        "lat": 50.9635024
    },
    {
        "zip": "3630",
        "city": "Mechelen-Aan-De-Maas",
        "lng": 5.6456979557208,
        "lat": 50.9764002
    },
    {
        "zip": "3630",
        "city": "Meeswijk",
        "lng": 5.7468352,
        "lat": 51.001413
    },
    {
        "zip": "3630",
        "city": "Opgrimbie",
        "lng": 5.6810305,
        "lat": 50.9436011
    },
    {
        "zip": "3630",
        "city": "Vucht",
        "lng": 5.7134261,
        "lat": 50.9770425
    },
    {
        "zip": "3631",
        "city": "Boorsem",
        "lng": 5.7147232,
        "lat": 50.9408858
    },
    {
        "zip": "3631",
        "city": "Uikhoven",
        "lng": 5.7199198507341,
        "lat": 50.9264738
    },
    {
        "zip": "3640",
        "city": "Kessenich",
        "lng": 5.8089243331137,
        "lat": 51.1492029
    },
    {
        "zip": "3640",
        "city": "Kinrooi",
        "lng": 5.7414564606985,
        "lat": 51.14313325
    },
    {
        "zip": "3640",
        "city": "Molenbeersel",
        "lng": 5.7260763418645,
        "lat": 51.1684178
    },
    {
        "zip": "3640",
        "city": "Ophoven",
        "lng": 5.7881529659009,
        "lat": 51.13225635
    },
    {
        "zip": "3650",
        "city": "Dilsen",
        "lng": 5.6991443859626,
        "lat": 51.0328615
    },
    {
        "zip": "3650",
        "city": "Dilsen-Stokkem",
        "lng": 5.7311405,
        "lat": 51.0284779
    },
    {
        "zip": "3650",
        "city": "Elen",
        "lng": 5.7404005925371,
        "lat": 51.06941595
    },
    {
        "zip": "3650",
        "city": "Lanklaar",
        "lng": 5.6747570297643,
        "lat": 51.01085805
    },
    {
        "zip": "3650",
        "city": "Rotem",
        "lng": 5.7205216032604,
        "lat": 51.053086
    },
    {
        "zip": "3650",
        "city": "Stokkem",
        "lng": 5.7508381619462,
        "lat": 51.0197939
    },
    {
        "zip": "3660",
        "city": "Opglabbeek",
        "lng": 5.581962,
        "lat": 51.0429949
    },
    {
        "zip": "3665",
        "city": "As",
        "lng": 5.5722025852462,
        "lat": 51.0005396
    },
    {
        "zip": "3668",
        "city": "Niel-Bij-As",
        "lng": 5.6161660037867,
        "lat": 51.0182495
    },
    {
        "zip": "3670",
        "city": "Ellikom",
        "lng": 5.5289463613944,
        "lat": 51.1303479
    },
    {
        "zip": "3670",
        "city": "Gruitrode",
        "lng": 5.5813814347439,
        "lat": 51.08477665
    },
    {
        "zip": "3670",
        "city": "Meeuwen",
        "lng": 5.5029170798132,
        "lat": 51.07822635
    },
    {
        "zip": "3670",
        "city": "Meeuwen-Gruitrode",
        "lng": 5.5540751,
        "lat": 51.0933149
    },
    {
        "zip": "3670",
        "city": "Neerglabbeek",
        "lng": 5.6181697688959,
        "lat": 51.09677995
    },
    {
        "zip": "3670",
        "city": "Wijshagen",
        "lng": 5.532541372001,
        "lat": 51.0770409
    },
    {
        "zip": "3680",
        "city": "Maaseik",
        "lng": 5.791733,
        "lat": 51.0947181
    },
    {
        "zip": "3680",
        "city": "Neeroeteren",
        "lng": 5.7027136767689,
        "lat": 51.0946499
    },
    {
        "zip": "3680",
        "city": "Opoeteren",
        "lng": 5.6487312543959,
        "lat": 51.05340005
    },
    {
        "zip": "3690",
        "city": "Zutendaal",
        "lng": 5.5725548,
        "lat": 50.9318992
    },
    {
        "zip": "3700",
        "city": "'s Herenelderen",
        "lng": 5.5030479,
        "lat": 50.8064756
    },
    {
        "zip": "3700",
        "city": "Berg",
        "lng": 5.1521172,
        "lat": 51.1695659
    },
    {
        "zip": "3700",
        "city": "Diets-Heur",
        "lng": 5.4843527,
        "lat": 50.7450193
    },
    {
        "zip": "3700",
        "city": "Haren",
        "lng": 4.4125708732431,
        "lat": 50.8919661
    },
    {
        "zip": "3700",
        "city": "Henis",
        "lng": 5.4696959,
        "lat": 50.7993981
    },
    {
        "zip": "3700",
        "city": "Kolmont",
        "lng": 5.4206775,
        "lat": 50.8027416
    },
    {
        "zip": "3700",
        "city": "Koninksem",
        "lng": 5.4417282,
        "lat": 50.7678379
    },
    {
        "zip": "3700",
        "city": "Lauw",
        "lng": 5.4147831,
        "lat": 50.7399124
    },
    {
        "zip": "3700",
        "city": "Mal",
        "lng": 5.5223906,
        "lat": 50.7689604
    },
    {
        "zip": "3700",
        "city": "Neerrepen",
        "lng": 5.4449386,
        "lat": 50.8121319
    },
    {
        "zip": "3700",
        "city": "Nerem",
        "lng": 5.5102044,
        "lat": 50.7630913
    },
    {
        "zip": "3700",
        "city": "Overrepen",
        "lng": 5.4301056,
        "lat": 50.8067083
    },
    {
        "zip": "3700",
        "city": "Piringen",
        "lng": 5.4198462,
        "lat": 50.7873928
    },
    {
        "zip": "3700",
        "city": "Riksingen",
        "lng": 5.4616724,
        "lat": 50.8065023
    },
    {
        "zip": "3700",
        "city": "Rutten",
        "lng": 5.4421316,
        "lat": 50.747053
    },
    {
        "zip": "3700",
        "city": "Sluizen",
        "lng": 2.7568247,
        "lat": 51.133268
    },
    {
        "zip": "3700",
        "city": "Tongeren",
        "lng": 5.4648131,
        "lat": 50.7810151
    },
    {
        "zip": "3700",
        "city": "Vreren",
        "lng": 5.495696,
        "lat": 50.7520293
    },
    {
        "zip": "3700",
        "city": "Widooie",
        "lng": 5.409701,
        "lat": 50.7719092
    },
    {
        "zip": "3717",
        "city": "Herstappe",
        "lng": 5.4260923,
        "lat": 50.7273829
    },
    {
        "zip": "3720",
        "city": "Kortessem",
        "lng": 5.3770000287821,
        "lat": 50.86195255
    },
    {
        "zip": "3721",
        "city": "Vliermaalroot",
        "lng": 5.4298574663068,
        "lat": 50.8667966
    },
    {
        "zip": "3722",
        "city": "Wintershoven",
        "lng": 5.4073456316551,
        "lat": 50.85566395
    },
    {
        "zip": "3723",
        "city": "Guigoven",
        "lng": 5.4005632276197,
        "lat": 50.84255235
    },
    {
        "zip": "3724",
        "city": "Vliermaal",
        "lng": 5.4259091735787,
        "lat": 50.8326029
    },
    {
        "zip": "3730",
        "city": "Hoeselt",
        "lng": 5.4865309,
        "lat": 50.8500788
    },
    {
        "zip": "3730",
        "city": "Romershoven",
        "lng": 5.4593316,
        "lat": 50.8580527
    },
    {
        "zip": "3730",
        "city": "Sint-Huibrechts-Hern",
        "lng": 5.4487057,
        "lat": 50.8292918
    },
    {
        "zip": "3730",
        "city": "Werm",
        "lng": 5.4804957,
        "lat": 50.8335665
    },
    {
        "zip": "3732",
        "city": "Schalkhoven",
        "lng": 5.4481669278797,
        "lat": 50.8422859
    },
    {
        "zip": "3740",
        "city": "Beverst",
        "lng": 5.4734935,
        "lat": 50.8921566
    },
    {
        "zip": "3740",
        "city": "Bilzen",
        "lng": 5.5163011,
        "lat": 50.8713356
    },
    {
        "zip": "3740",
        "city": "Eigenbilzen",
        "lng": 5.5755614,
        "lat": 50.8743904
    },
    {
        "zip": "3740",
        "city": "Grote-Spouwen",
        "lng": 5.5538483,
        "lat": 50.832524
    },
    {
        "zip": "3740",
        "city": "Hees",
        "lng": 5.6124793,
        "lat": 50.8460673
    },
    {
        "zip": "3740",
        "city": "Kleine-Spouwen",
        "lng": 5.5473874,
        "lat": 50.8379653
    },
    {
        "zip": "3740",
        "city": "Mopertingen",
        "lng": 5.5760673,
        "lat": 50.862295
    },
    {
        "zip": "3740",
        "city": "Munsterbilzen",
        "lng": 5.5270242,
        "lat": 50.8883348
    },
    {
        "zip": "3740",
        "city": "Rijkhoven",
        "lng": 5.5163495,
        "lat": 50.8342984
    },
    {
        "zip": "3740",
        "city": "Rosmeer",
        "lng": 5.5753682,
        "lat": 50.8458315
    },
    {
        "zip": "3740",
        "city": "Waltwilder",
        "lng": 5.5464648,
        "lat": 50.8638819
    },
    {
        "zip": "3742",
        "city": "Martenslinde",
        "lng": 5.5340486411054,
        "lat": 50.8523326
    },
    {
        "zip": "3746",
        "city": "Hoelbeek",
        "lng": 5.556142701219,
        "lat": 50.883259
    },
    {
        "zip": "3770",
        "city": "Genoelselderen",
        "lng": 5.5369013,
        "lat": 50.8017668
    },
    {
        "zip": "3770",
        "city": "Herderen",
        "lng": 5.5744881,
        "lat": 50.8071071
    },
    {
        "zip": "3770",
        "city": "Kanne",
        "lng": 5.6692203,
        "lat": 50.8141622
    },
    {
        "zip": "3770",
        "city": "Membruggen",
        "lng": 5.5391564,
        "lat": 50.8178133
    },
    {
        "zip": "3770",
        "city": "Millen",
        "lng": 5.560302,
        "lat": 50.7844721
    },
    {
        "zip": "3770",
        "city": "Riemst",
        "lng": 5.5978716,
        "lat": 50.8115825
    },
    {
        "zip": "3770",
        "city": "Val-Meer",
        "lng": 5.5971013,
        "lat": 50.7871228
    },
    {
        "zip": "3770",
        "city": "Vlijtingen",
        "lng": 5.5895873,
        "lat": 50.8329146
    },
    {
        "zip": "3770",
        "city": "Vroenhoven",
        "lng": 5.6374847,
        "lat": 50.828018
    },
    {
        "zip": "3770",
        "city": "Zichen-Zussen-Bolder",
        "lng": 5.6153404,
        "lat": 50.7943475
    },
    {
        "zip": "3790",
        "city": "Fouron-Saint-Martin",
        "lng": 5.8429342279842,
        "lat": 50.74087835
    },
    {
        "zip": "3790",
        "city": "Fourons",
        "lng": 5.839052712207,
        "lat": 50.7465898
    },
    {
        "zip": "3790",
        "city": "Mouland",
        "lng": 5.7148469912943,
        "lat": 50.7551858
    },
    {
        "zip": "3791",
        "city": "Remersdaal",
        "lng": 5.8818776028446,
        "lat": 50.72838165
    },
    {
        "zip": "3792",
        "city": "Fouron-Saint-Pierre",
        "lng": 5.8242427123549,
        "lat": 50.72830265
    },
    {
        "zip": "3793",
        "city": "Teuven",
        "lng": 5.8695304694514,
        "lat": 50.75322225
    },
    {
        "zip": "3798",
        "city": "Fouron-Le-Comte",
        "lng": 5.7719461900567,
        "lat": 50.7611375
    },
    {
        "zip": "3800",
        "city": "Aalst",
        "lng": 5.2127604,
        "lat": 50.7813901
    },
    {
        "zip": "3800",
        "city": "Brustem",
        "lng": 5.230273192058,
        "lat": 50.79912435
    },
    {
        "zip": "3800",
        "city": "Engelmanshoven",
        "lng": 5.2518063089799,
        "lat": 50.77280875
    },
    {
        "zip": "3800",
        "city": "Gelinden",
        "lng": 5.2509345230414,
        "lat": 50.7602497
    },
    {
        "zip": "3800",
        "city": "Groot-Gelmen",
        "lng": 5.2621170756458,
        "lat": 50.78320635
    },
    {
        "zip": "3800",
        "city": "Halmaal",
        "lng": 5.1499121468986,
        "lat": 50.80055285
    },
    {
        "zip": "3800",
        "city": "Kerkom-Bij-Sint-Truiden",
        "lng": 5.1787834432079,
        "lat": 50.77333875
    },
    {
        "zip": "3800",
        "city": "Ordingen",
        "lng": 5.2381902322176,
        "lat": 50.8137716
    },
    {
        "zip": "3800",
        "city": "Sint-Truiden",
        "lng": 5.2034249116034,
        "lat": 50.82694775
    },
    {
        "zip": "3800",
        "city": "Zepperen",
        "lng": 5.2579163290148,
        "lat": 50.840318
    },
    {
        "zip": "3803",
        "city": "Duras",
        "lng": 5.1461581286779,
        "lat": 50.83411245
    },
    {
        "zip": "3803",
        "city": "Gorsem",
        "lng": 5.1658988739064,
        "lat": 50.8380493
    },
    {
        "zip": "3803",
        "city": "Runkelen",
        "lng": 5.1519921,
        "lat": 50.8477735
    },
    {
        "zip": "3803",
        "city": "Wilderen",
        "lng": 5.1423025876022,
        "lat": 50.81773655
    },
    {
        "zip": "3806",
        "city": "Velm",
        "lng": 5.1322112767812,
        "lat": 50.78000075
    },
    {
        "zip": "3830",
        "city": "Berlingen",
        "lng": 5.3111748927674,
        "lat": 50.82338225
    },
    {
        "zip": "3830",
        "city": "Wellen",
        "lng": 5.3421653176727,
        "lat": 50.8434693
    },
    {
        "zip": "3831",
        "city": "Herten",
        "lng": 5.3322359627296,
        "lat": 50.82704785
    },
    {
        "zip": "3832",
        "city": "Ulbeek",
        "lng": 5.2936725006792,
        "lat": 50.84319895
    },
    {
        "zip": "3840",
        "city": "Bommershoven",
        "lng": 5.3823531554707,
        "lat": 50.78522885
    },
    {
        "zip": "3840",
        "city": "Borgloon",
        "lng": 5.3569929252994,
        "lat": 50.8004351
    },
    {
        "zip": "3840",
        "city": "Broekom",
        "lng": 5.3324575,
        "lat": 50.7819071
    },
    {
        "zip": "3840",
        "city": "Gors-Opleeuw",
        "lng": 5.3849412792168,
        "lat": 50.8308593
    },
    {
        "zip": "3840",
        "city": "Gotem",
        "lng": 5.3055250033294,
        "lat": 50.80105865
    },
    {
        "zip": "3840",
        "city": "Groot-Loon",
        "lng": 5.3659957408679,
        "lat": 50.7938946
    },
    {
        "zip": "3840",
        "city": "Haren",
        "lng": 5.3972985,
        "lat": 50.7913472
    },
    {
        "zip": "3840",
        "city": "Hendrieken",
        "lng": 5.3287966873717,
        "lat": 50.797246
    },
    {
        "zip": "3840",
        "city": "Hoepertingen",
        "lng": 5.2852748851765,
        "lat": 50.81158915
    },
    {
        "zip": "3840",
        "city": "Jesseren",
        "lng": 5.3905151,
        "lat": 50.8058717
    },
    {
        "zip": "3840",
        "city": "Kerniel",
        "lng": 5.3616421143445,
        "lat": 50.82372165
    },
    {
        "zip": "3840",
        "city": "Kolmont",
        "lng": 5.4206775,
        "lat": 50.8027416
    },
    {
        "zip": "3840",
        "city": "Kuttekoven",
        "lng": 5.3310338354044,
        "lat": 50.8140833
    },
    {
        "zip": "3840",
        "city": "Rijkel",
        "lng": 5.2612885523105,
        "lat": 50.80429285
    },
    {
        "zip": "3840",
        "city": "Voort",
        "lng": 4.6797906,
        "lat": 51.1674467
    },
    {
        "zip": "3850",
        "city": "Binderveld",
        "lng": 5.1684479293466,
        "lat": 50.86548295
    },
    {
        "zip": "3850",
        "city": "Kozen",
        "lng": 5.2414389,
        "lat": 50.8752909
    },
    {
        "zip": "3850",
        "city": "Nieuwerkerken",
        "lng": 5.1909741787579,
        "lat": 50.87457545
    },
    {
        "zip": "3850",
        "city": "Wijer",
        "lng": 5.2233097,
        "lat": 50.8972323
    },
    {
        "zip": "3870",
        "city": "Batsheers",
        "lng": 5.2807989,
        "lat": 50.7381794
    },
    {
        "zip": "3870",
        "city": "Bovelingen",
        "lng": 5.2551977,
        "lat": 50.7375944
    },
    {
        "zip": "3870",
        "city": "Heers",
        "lng": 5.3017509,
        "lat": 50.750995
    },
    {
        "zip": "3870",
        "city": "Heks",
        "lng": 5.3567635,
        "lat": 50.7687571
    },
    {
        "zip": "3870",
        "city": "Horpmaal",
        "lng": 5.3328245,
        "lat": 50.7582823
    },
    {
        "zip": "3870",
        "city": "Klein-Gelmen",
        "lng": 5.2763925,
        "lat": 50.7713536
    },
    {
        "zip": "3870",
        "city": "Mechelen-Bovelingen",
        "lng": 5.2629291,
        "lat": 50.7427937
    },
    {
        "zip": "3870",
        "city": "Mettekoven",
        "lng": 5.2896635,
        "lat": 50.7803053
    },
    {
        "zip": "3870",
        "city": "Opheers",
        "lng": 5.2944311,
        "lat": 50.7366903
    },
    {
        "zip": "3870",
        "city": "Rukkelingen-Loon",
        "lng": 5.2539162,
        "lat": 50.7278444
    },
    {
        "zip": "3870",
        "city": "Vechmaal",
        "lng": 5.373733,
        "lat": 50.7608875
    },
    {
        "zip": "3870",
        "city": "Veulen",
        "lng": 5.3063809,
        "lat": 50.7633827
    },
    {
        "zip": "3890",
        "city": "Boekhout",
        "lng": 5.2314326932833,
        "lat": 50.7456072
    },
    {
        "zip": "3890",
        "city": "Gingelom",
        "lng": 5.1325789475434,
        "lat": 50.7513838
    },
    {
        "zip": "3890",
        "city": "Jeuk",
        "lng": 5.2095391018082,
        "lat": 50.73257605
    },
    {
        "zip": "3890",
        "city": "Kortijs",
        "lng": 5.1485502564418,
        "lat": 50.7052793
    },
    {
        "zip": "3890",
        "city": "Montenaken",
        "lng": 5.1386239079133,
        "lat": 50.71838125
    },
    {
        "zip": "3890",
        "city": "Niel-Bij-Sint-Truiden",
        "lng": 5.14193594683,
        "lat": 50.7404614
    },
    {
        "zip": "3890",
        "city": "Vorsen",
        "lng": 5.1713288748516,
        "lat": 50.705232
    },
    {
        "zip": "3891",
        "city": "Borlo",
        "lng": 5.1743785728014,
        "lat": 50.7345174
    },
    {
        "zip": "3891",
        "city": "Buvingen",
        "lng": 5.1788950800586,
        "lat": 50.75022405
    },
    {
        "zip": "3891",
        "city": "Mielen-Boven-Aalst",
        "lng": 5.2141041746035,
        "lat": 50.7573342
    },
    {
        "zip": "3891",
        "city": "Muizen",
        "lng": 5.1781361499823,
        "lat": 50.7614659
    },
    {
        "zip": "3900",
        "city": "Overpelt",
        "lng": 5.422976,
        "lat": 51.2113458
    },
    {
        "zip": "3910",
        "city": "Neerpelt",
        "lng": 5.4314419,
        "lat": 51.2289746
    },
    {
        "zip": "3910",
        "city": "Sint-Huibrechts-Lille",
        "lng": 5.4937001151083,
        "lat": 51.2253075
    },
    {
        "zip": "3920",
        "city": "Lommel",
        "lng": 5.3076895,
        "lat": 51.2305657
    },
    {
        "zip": "3930",
        "city": "Achel",
        "lng": 5.4792895720018,
        "lat": 51.2684314
    },
    {
        "zip": "3930",
        "city": "Hamont",
        "lng": 5.5312873136301,
        "lat": 51.25458535
    },
    {
        "zip": "3930",
        "city": "Hamont-Achel",
        "lng": 5.5128696,
        "lat": 51.2547636
    },
    {
        "zip": "3940",
        "city": "Hechtel",
        "lng": 5.3415643775263,
        "lat": 51.11664635
    },
    {
        "zip": "3940",
        "city": "Hechtel-Eksel",
        "lng": 5.3415643775263,
        "lat": 51.11664635
    },
    {
        "zip": "3941",
        "city": "Eksel",
        "lng": 5.3440439268887,
        "lat": 51.1567865
    },
    {
        "zip": "3945",
        "city": "Ham",
        "lng": 5.1781109743218,
        "lat": 51.11417145
    },
    {
        "zip": "3945",
        "city": "Kwaadmechelen",
        "lng": 5.1325674800534,
        "lat": 51.0966275
    },
    {
        "zip": "3945",
        "city": "Oostham",
        "lng": 5.1838556043202,
        "lat": 51.10876505
    },
    {
        "zip": "3950",
        "city": "Bocholt",
        "lng": 5.5995581946268,
        "lat": 51.1924818
    },
    {
        "zip": "3950",
        "city": "Kaulille",
        "lng": 5.5172805548337,
        "lat": 51.19391715
    },
    {
        "zip": "3950",
        "city": "Reppel",
        "lng": 5.5522997799873,
        "lat": 51.1470494
    },
    {
        "zip": "3960",
        "city": "Beek",
        "lng": 5.6378178047643,
        "lat": 51.16613895
    },
    {
        "zip": "3960",
        "city": "Bree",
        "lng": 5.6229805058514,
        "lat": 51.1386239
    },
    {
        "zip": "3960",
        "city": "Gerdingen",
        "lng": 5.5648743785157,
        "lat": 51.1304481
    },
    {
        "zip": "3960",
        "city": "Opitter",
        "lng": 5.6477954812084,
        "lat": 51.1184171
    },
    {
        "zip": "3960",
        "city": "Tongerlo",
        "lng": 5.6788533966978,
        "lat": 51.1305517
    },
    {
        "zip": "3970",
        "city": "Leopoldsburg",
        "lng": 5.2638924294785,
        "lat": 51.1220584
    },
    {
        "zip": "3971",
        "city": "Heppen",
        "lng": 5.2265355962879,
        "lat": 51.11582725
    },
    {
        "zip": "3980",
        "city": "Tessenderlo",
        "lng": 5.0893974,
        "lat": 51.0681628
    },
    {
        "zip": "3990",
        "city": "Grote-Brogel",
        "lng": 5.5087098376886,
        "lat": 51.1420032
    },
    {
        "zip": "3990",
        "city": "Kleine-Brogel",
        "lng": 5.4623610101885,
        "lat": 51.1726086
    },
    {
        "zip": "3990",
        "city": "Peer",
        "lng": 5.4491572190061,
        "lat": 51.10874
    },
    {
        "zip": "3990",
        "city": "Wijchmaal",
        "lng": 5.4147469555164,
        "lat": 51.1272018
    },
    {
        "zip": "4000",
        "city": "Glain",
        "lng": 5.541864,
        "lat": 50.648205
    },
    {
        "zip": "4000",
        "city": "Liége",
        "lng": 5.5734203,
        "lat": 50.6451381
    },
    {
        "zip": "4000",
        "city": "Rocourt",
        "lng": 5.547616,
        "lat": 50.678042
    },
    {
        "zip": "4020",
        "city": "Bressoux",
        "lng": 5.6111443,
        "lat": 50.6412964
    },
    {
        "zip": "4020",
        "city": "Jupille-Sur-Meuse",
        "lng": 5.6301266,
        "lat": 50.6431909
    },
    {
        "zip": "4020",
        "city": "Liége",
        "lng": 5.5734203,
        "lat": 50.6451381
    },
    {
        "zip": "4020",
        "city": "Wandre",
        "lng": 5.6596627,
        "lat": 50.670247
    },
    {
        "zip": "4030",
        "city": "Grivegnee",
        "lng": 5.599573,
        "lat": 50.621845
    },
    {
        "zip": "4031",
        "city": "Angleur",
        "lng": 5.5954054,
        "lat": 50.6128164
    },
    {
        "zip": "4032",
        "city": "Chênée",
        "lng": 5.619261,
        "lat": 50.6111024
    },
    {
        "zip": "4040",
        "city": "Herstal",
        "lng": 5.6403906,
        "lat": 50.6701887
    },
    {
        "zip": "4041",
        "city": "Milmort",
        "lng": 5.5929155,
        "lat": 50.6904632
    },
    {
        "zip": "4041",
        "city": "Vottem",
        "lng": 5.5846274,
        "lat": 50.672653
    },
    {
        "zip": "4042",
        "city": "Liers",
        "lng": 5.563754,
        "lat": 50.6933806
    },
    {
        "zip": "4050",
        "city": "Chaudfontaine",
        "lng": 5.6469688,
        "lat": 50.5847922
    },
    {
        "zip": "4051",
        "city": "Vaux-Sous-Chévremont",
        "lng": 5.6335963,
        "lat": 50.6018717
    },
    {
        "zip": "4052",
        "city": "Beaufays",
        "lng": 5.6387759,
        "lat": 50.5589633
    },
    {
        "zip": "4053",
        "city": "Embourg",
        "lng": 5.619201,
        "lat": 50.5893639
    },
    {
        "zip": "4100",
        "city": "Boncelles",
        "lng": 5.5355698,
        "lat": 50.5742979
    },
    {
        "zip": "4100",
        "city": "Seraing",
        "lng": 5.5083375,
        "lat": 50.5966392
    },
    {
        "zip": "4101",
        "city": "Jemeppe-Sur-Meuse",
        "lng": 5.5049541,
        "lat": 50.6168289
    },
    {
        "zip": "4102",
        "city": "Ougrée",
        "lng": 5.5389943,
        "lat": 50.5992632
    },
    {
        "zip": "4120",
        "city": "Ehein",
        "lng": 5.4444346,
        "lat": 50.5445135
    },
    {
        "zip": "4120",
        "city": "Neupré",
        "lng": 5.4901079,
        "lat": 50.5431751
    },
    {
        "zip": "4120",
        "city": "Rotheux-Rimiére",
        "lng": 5.4811796,
        "lat": 50.5353385
    },
    {
        "zip": "4121",
        "city": "Neuville-En-Condroz",
        "lng": 5.4514318,
        "lat": 50.5520337
    },
    {
        "zip": "4122",
        "city": "Plainevaux",
        "lng": 5.5215011,
        "lat": 50.5446771
    },
    {
        "zip": "4130",
        "city": "Esneux",
        "lng": 5.5757727440324,
        "lat": 50.5337249
    },
    {
        "zip": "4130",
        "city": "Tilff",
        "lng": 5.594232762079,
        "lat": 50.5613241
    },
    {
        "zip": "4140",
        "city": "Dolembreux",
        "lng": 5.628000939321,
        "lat": 50.53758625
    },
    {
        "zip": "4140",
        "city": "Gomzé-Andoumont",
        "lng": 5.6835772963268,
        "lat": 50.54506305
    },
    {
        "zip": "4140",
        "city": "Rouvreux",
        "lng": 5.6642552366155,
        "lat": 50.4893408
    },
    {
        "zip": "4140",
        "city": "Sprimont",
        "lng": 5.6466570762919,
        "lat": 50.5065636
    },
    {
        "zip": "4141",
        "city": "Louveigné",
        "lng": 5.7172477995985,
        "lat": 50.5248417
    },
    {
        "zip": "4160",
        "city": "Anthisnes",
        "lng": 5.5225261,
        "lat": 50.481662
    },
    {
        "zip": "4161",
        "city": "Villers-Aux-Tours",
        "lng": 5.5122974,
        "lat": 50.4969412
    },
    {
        "zip": "4162",
        "city": "Hody",
        "lng": 5.5009881,
        "lat": 50.4869156
    },
    {
        "zip": "4163",
        "city": "Tavier",
        "lng": 5.4707571,
        "lat": 50.4960227
    },
    {
        "zip": "4170",
        "city": "Comblain-Au-Pont",
        "lng": 5.5764707268884,
        "lat": 50.474910743586
    },
    {
        "zip": "4171",
        "city": "Poulseur",
        "lng": 5.5827847,
        "lat": 50.5097627
    },
    {
        "zip": "4180",
        "city": "Comblain-Fairon",
        "lng": 5.5428656,
        "lat": 50.4454473
    },
    {
        "zip": "4180",
        "city": "Comblain-La-Tour",
        "lng": 5.5699525,
        "lat": 50.4553077
    },
    {
        "zip": "4180",
        "city": "Hamoir",
        "lng": 5.5328465,
        "lat": 50.4253958
    },
    {
        "zip": "4181",
        "city": "Filot",
        "lng": 5.5680501,
        "lat": 50.426751
    },
    {
        "zip": "4190",
        "city": "Ferriéres",
        "lng": 5.605138,
        "lat": 50.3999541
    },
    {
        "zip": "4190",
        "city": "My",
        "lng": 5.5726031,
        "lat": 50.40526
    },
    {
        "zip": "4190",
        "city": "Vieuxville",
        "lng": 5.5512446,
        "lat": 50.3955089
    },
    {
        "zip": "4190",
        "city": "Werbomont",
        "lng": 5.686798,
        "lat": 50.3800099
    },
    {
        "zip": "4190",
        "city": "Xhoris",
        "lng": 5.602069,
        "lat": 50.443983
    },
    {
        "zip": "4210",
        "city": "Burdinne",
        "lng": 5.072436,
        "lat": 50.5812607
    },
    {
        "zip": "4210",
        "city": "Hannêche",
        "lng": 5.0495231,
        "lat": 50.5795921
    },
    {
        "zip": "4210",
        "city": "Lamontzée",
        "lng": 5.0899261,
        "lat": 50.583727
    },
    {
        "zip": "4210",
        "city": "Marneffe",
        "lng": 5.1448898,
        "lat": 50.5790977
    },
    {
        "zip": "4210",
        "city": "Oteppe",
        "lng": 5.1270159,
        "lat": 50.5823574
    },
    {
        "zip": "4217",
        "city": "Héron",
        "lng": 5.0971783,
        "lat": 50.5508203
    },
    {
        "zip": "4217",
        "city": "Lavoir",
        "lng": 5.1257368,
        "lat": 50.5487123
    },
    {
        "zip": "4217",
        "city": "Waret-L'Evêque",
        "lng": 5.0671316,
        "lat": 50.5557893
    },
    {
        "zip": "4218",
        "city": "Couthuin",
        "lng": 5.1318708,
        "lat": 50.5331675
    },
    {
        "zip": "4219",
        "city": "Acosse",
        "lng": 5.0485902,
        "lat": 50.5945676
    },
    {
        "zip": "4219",
        "city": "Ambresin",
        "lng": 5.0305133,
        "lat": 50.6266779
    },
    {
        "zip": "4219",
        "city": "Meeffe",
        "lng": 5.016335,
        "lat": 50.608964
    },
    {
        "zip": "4219",
        "city": "Wasseiges",
        "lng": 5.006384,
        "lat": 50.6221762
    },
    {
        "zip": "4250",
        "city": "Boëlhe",
        "lng": 5.1679183,
        "lat": 50.6841901
    },
    {
        "zip": "4250",
        "city": "Geer",
        "lng": 5.1710529,
        "lat": 50.6684881
    },
    {
        "zip": "4250",
        "city": "Hollogne-Sur-Geer",
        "lng": 5.2045681,
        "lat": 50.6770806
    },
    {
        "zip": "4250",
        "city": "Lens-Saint-Servais",
        "lng": 5.16091,
        "lat": 50.6637114
    },
    {
        "zip": "4252",
        "city": "Omal",
        "lng": 5.1969427,
        "lat": 50.6554534
    },
    {
        "zip": "4253",
        "city": "Darion",
        "lng": 5.187047,
        "lat": 50.6649171
    },
    {
        "zip": "4254",
        "city": "Ligney",
        "lng": 5.182381,
        "lat": 50.66174
    },
    {
        "zip": "4257",
        "city": "Berloz",
        "lng": 5.2146261,
        "lat": 50.6977331
    },
    {
        "zip": "4257",
        "city": "Corswarem",
        "lng": 5.2131541,
        "lat": 50.7095698
    },
    {
        "zip": "4260",
        "city": "Avennes",
        "lng": 5.1159746,
        "lat": 50.6287517
    },
    {
        "zip": "4260",
        "city": "Braives",
        "lng": 5.1435257,
        "lat": 50.6300711
    },
    {
        "zip": "4260",
        "city": "Ciplet",
        "lng": 5.0953487,
        "lat": 50.6171393
    },
    {
        "zip": "4260",
        "city": "Fallais",
        "lng": 5.1738356,
        "lat": 50.6093088
    },
    {
        "zip": "4260",
        "city": "Fumal",
        "lng": 5.184607,
        "lat": 50.586887
    },
    {
        "zip": "4260",
        "city": "Ville-En-Hesbaye",
        "lng": 5.1157512,
        "lat": 50.6182889
    },
    {
        "zip": "4261",
        "city": "Latinne",
        "lng": 5.1632447,
        "lat": 50.6242037
    },
    {
        "zip": "4263",
        "city": "Tourinne",
        "lng": 5.1765528,
        "lat": 50.639549
    },
    {
        "zip": "4280",
        "city": "Abolens",
        "lng": 5.1498313,
        "lat": 50.6720609
    },
    {
        "zip": "4280",
        "city": "Avernas-Le-Bauduin",
        "lng": 5.0789239,
        "lat": 50.694616
    },
    {
        "zip": "4280",
        "city": "Avin",
        "lng": 5.067671,
        "lat": 50.620859
    },
    {
        "zip": "4280",
        "city": "Bertrée",
        "lng": 5.089556,
        "lat": 50.6941776
    },
    {
        "zip": "4280",
        "city": "Blehen",
        "lng": 5.1255375,
        "lat": 50.6629289
    },
    {
        "zip": "4280",
        "city": "Cras-Avernas",
        "lng": 5.122837,
        "lat": 50.6978983
    },
    {
        "zip": "4280",
        "city": "Crehen",
        "lng": 5.0621464,
        "lat": 50.6605954
    },
    {
        "zip": "4280",
        "city": "Grand-Hallet",
        "lng": 5.0340611,
        "lat": 50.6941211
    },
    {
        "zip": "4280",
        "city": "Hannut",
        "lng": 5.0779916,
        "lat": 50.6724649
    },
    {
        "zip": "4280",
        "city": "Lens-Saint-Remy",
        "lng": 5.1329047,
        "lat": 50.6534024
    },
    {
        "zip": "4280",
        "city": "Merdorp",
        "lng": 4.9987365,
        "lat": 50.6492972
    },
    {
        "zip": "4280",
        "city": "Moxhe",
        "lng": 5.0813689,
        "lat": 50.6300028
    },
    {
        "zip": "4280",
        "city": "Petit-Hallet",
        "lng": 5.0184357,
        "lat": 50.6875594
    },
    {
        "zip": "4280",
        "city": "Poucet",
        "lng": 5.1124332,
        "lat": 50.6777766
    },
    {
        "zip": "4280",
        "city": "Thisnes",
        "lng": 5.0480724,
        "lat": 50.6655625
    },
    {
        "zip": "4280",
        "city": "Trognée",
        "lng": 5.1242082,
        "lat": 50.6878033
    },
    {
        "zip": "4280",
        "city": "Villers-Le-Peuplier",
        "lng": 5.09579,
        "lat": 50.656503
    },
    {
        "zip": "4280",
        "city": "Wansin",
        "lng": 5.02018,
        "lat": 50.678627
    },
    {
        "zip": "4287",
        "city": "Lincent",
        "lng": 5.0318166,
        "lat": 50.711613
    },
    {
        "zip": "4287",
        "city": "Pellaines",
        "lng": 5.0055524,
        "lat": 50.7228078
    },
    {
        "zip": "4287",
        "city": "Racour",
        "lng": 5.0290114,
        "lat": 50.7388269
    },
    {
        "zip": "4300",
        "city": "Bettincourt",
        "lng": 5.235361,
        "lat": 50.7099793
    },
    {
        "zip": "4300",
        "city": "Bleret",
        "lng": 5.2888884,
        "lat": 50.6893285
    },
    {
        "zip": "4300",
        "city": "Bovenistier",
        "lng": 5.2843336,
        "lat": 50.6674954
    },
    {
        "zip": "4300",
        "city": "Grand-Axhe",
        "lng": 5.2243853,
        "lat": 50.6789987
    },
    {
        "zip": "4300",
        "city": "Lantremange",
        "lng": 5.2947196,
        "lat": 50.7114397
    },
    {
        "zip": "4300",
        "city": "Oleye",
        "lng": 5.2808546,
        "lat": 50.7125474
    },
    {
        "zip": "4300",
        "city": "Waremme",
        "lng": 5.2546191,
        "lat": 50.6976875
    },
    {
        "zip": "4317",
        "city": "Aineffe",
        "lng": 5.256235,
        "lat": 50.6229561
    },
    {
        "zip": "4317",
        "city": "Borlez",
        "lng": 5.245072,
        "lat": 50.632984
    },
    {
        "zip": "4317",
        "city": "Celles",
        "lng": 5.2455346,
        "lat": 50.6538847
    },
    {
        "zip": "4317",
        "city": "Faimes",
        "lng": 5.262322,
        "lat": 50.665246
    },
    {
        "zip": "4317",
        "city": "Les Waleffes",
        "lng": 5.2180365,
        "lat": 50.6402367
    },
    {
        "zip": "4317",
        "city": "Viemme",
        "lng": 5.2683121,
        "lat": 50.6482877
    },
    {
        "zip": "4340",
        "city": "Awans",
        "lng": 5.4620018,
        "lat": 50.6669712
    },
    {
        "zip": "4340",
        "city": "Fooz",
        "lng": 5.4376155,
        "lat": 50.6762489
    },
    {
        "zip": "4340",
        "city": "Othée",
        "lng": 5.4680939,
        "lat": 50.7159281
    },
    {
        "zip": "4340",
        "city": "Villers-L'Evêque",
        "lng": 5.4418773,
        "lat": 50.7028423
    },
    {
        "zip": "4342",
        "city": "Hognoul",
        "lng": 5.4556394,
        "lat": 50.6808099
    },
    {
        "zip": "4347",
        "city": "Fexhe-Le-Haut-Clocher",
        "lng": 5.3985197,
        "lat": 50.6660818
    },
    {
        "zip": "4347",
        "city": "Freloux",
        "lng": 5.4066762,
        "lat": 50.6803347
    },
    {
        "zip": "4347",
        "city": "Noville",
        "lng": 5.3827164,
        "lat": 50.6598522
    },
    {
        "zip": "4347",
        "city": "Roloux",
        "lng": 5.3960063,
        "lat": 50.6494928
    },
    {
        "zip": "4347",
        "city": "Voroux-Goreux",
        "lng": 5.4271453,
        "lat": 50.6547034
    },
    {
        "zip": "4350",
        "city": "Lamine",
        "lng": 5.333978,
        "lat": 50.689076
    },
    {
        "zip": "4350",
        "city": "Momalle",
        "lng": 5.3730111,
        "lat": 50.6852325
    },
    {
        "zip": "4350",
        "city": "Pousset",
        "lng": 5.3034495,
        "lat": 50.6956401
    },
    {
        "zip": "4350",
        "city": "Remicourt",
        "lng": 5.3267174,
        "lat": 50.6807151
    },
    {
        "zip": "4351",
        "city": "Hodeige",
        "lng": 5.3426579,
        "lat": 50.6960127
    },
    {
        "zip": "4357",
        "city": "Donceel",
        "lng": 5.3199408,
        "lat": 50.6478971
    },
    {
        "zip": "4357",
        "city": "Haneffe",
        "lng": 5.3192131,
        "lat": 50.6387144
    },
    {
        "zip": "4357",
        "city": "Jeneffe",
        "lng": 5.3559477,
        "lat": 50.6532256
    },
    {
        "zip": "4357",
        "city": "Limont",
        "lng": 5.3107335,
        "lat": 50.6600262
    },
    {
        "zip": "4360",
        "city": "Bergilers",
        "lng": 5.3277139,
        "lat": 50.7145424
    },
    {
        "zip": "4360",
        "city": "Grandville",
        "lng": 5.3379451,
        "lat": 50.7220498
    },
    {
        "zip": "4360",
        "city": "Lens-Sur-Geer",
        "lng": 5.3537922,
        "lat": 50.7214501
    },
    {
        "zip": "4360",
        "city": "Oreye",
        "lng": 5.3530675,
        "lat": 50.7291681
    },
    {
        "zip": "4360",
        "city": "Otrange",
        "lng": 5.384458,
        "lat": 50.7370788
    },
    {
        "zip": "4367",
        "city": "Crisnée",
        "lng": 5.397318,
        "lat": 50.715885
    },
    {
        "zip": "4367",
        "city": "Fize-Le-Marsal",
        "lng": 5.386298,
        "lat": 50.701353
    },
    {
        "zip": "4367",
        "city": "Kemexhe",
        "lng": 5.4060353,
        "lat": 50.6961807
    },
    {
        "zip": "4367",
        "city": "Odeur",
        "lng": 5.4158293,
        "lat": 50.707654
    },
    {
        "zip": "4367",
        "city": "Thys",
        "lng": 5.3894005,
        "lat": 50.7225262
    },
    {
        "zip": "4400",
        "city": "Awirs",
        "lng": 5.409295,
        "lat": 50.5985223
    },
    {
        "zip": "4400",
        "city": "Chokier",
        "lng": 5.4528969,
        "lat": 50.5936483
    },
    {
        "zip": "4400",
        "city": "Flémalle",
        "lng": 5.4580872,
        "lat": 50.6022808
    },
    {
        "zip": "4400",
        "city": "Flémalle-Grande",
        "lng": 5.4729319,
        "lat": 50.6155715
    },
    {
        "zip": "4400",
        "city": "Flémalle-Haute",
        "lng": 5.4474684,
        "lat": 50.6018434
    },
    {
        "zip": "4400",
        "city": "Ivoz-Ramet",
        "lng": 5.4523491,
        "lat": 50.5822766
    },
    {
        "zip": "4400",
        "city": "Mons-Lez-Liége",
        "lng": 5.4600597,
        "lat": 50.6224546
    },
    {
        "zip": "4420",
        "city": "Montegnée",
        "lng": 5.5190161,
        "lat": 50.64257
    },
    {
        "zip": "4420",
        "city": "Saint-Nicolas",
        "lng": 5.539419,
        "lat": 50.6305341
    },
    {
        "zip": "4420",
        "city": "Tilleur",
        "lng": 5.5307061,
        "lat": 50.620651
    },
    {
        "zip": "4430",
        "city": "Ans",
        "lng": 5.5190612,
        "lat": 50.6624326
    },
    {
        "zip": "4431",
        "city": "Loncin",
        "lng": 5.502732,
        "lat": 50.665153
    },
    {
        "zip": "4432",
        "city": "Alleur",
        "lng": 5.5129926,
        "lat": 50.6742549
    },
    {
        "zip": "4432",
        "city": "Xhendremael",
        "lng": 5.4805775,
        "lat": 50.7043671
    },
    {
        "zip": "4450",
        "city": "Juprelle",
        "lng": 5.5290689,
        "lat": 50.7106926
    },
    {
        "zip": "4450",
        "city": "Lantin",
        "lng": 5.5234944,
        "lat": 50.6879619
    },
    {
        "zip": "4450",
        "city": "Slins",
        "lng": 5.5652092,
        "lat": 50.7277246
    },
    {
        "zip": "4451",
        "city": "Voroux-Lez-Liers",
        "lng": 5.552484,
        "lat": 50.687631
    },
    {
        "zip": "4452",
        "city": "Paifve",
        "lng": 5.5241784,
        "lat": 50.7280141
    },
    {
        "zip": "4452",
        "city": "Wihogne",
        "lng": 5.5064112,
        "lat": 50.7277451
    },
    {
        "zip": "4453",
        "city": "Villers-Saint-Siméon",
        "lng": 5.5434045,
        "lat": 50.7097826
    },
    {
        "zip": "4458",
        "city": "Fexhe-Slins",
        "lng": 5.5714611,
        "lat": 50.7219179
    },
    {
        "zip": "4460",
        "city": "Bierset",
        "lng": 5.4512607,
        "lat": 50.6547149
    },
    {
        "zip": "4460",
        "city": "Grâce-Berleur",
        "lng": 5.501194,
        "lat": 50.6323331
    },
    {
        "zip": "4460",
        "city": "Grâce-Hollogne",
        "lng": 5.503152,
        "lat": 50.644955
    },
    {
        "zip": "4460",
        "city": "Hollogne-Aux-Pierres",
        "lng": 5.4757847,
        "lat": 50.6385937
    },
    {
        "zip": "4460",
        "city": "Horion-Hozémont",
        "lng": 5.3886713,
        "lat": 50.6149668
    },
    {
        "zip": "4460",
        "city": "Velroux",
        "lng": 5.4254194,
        "lat": 50.6436521
    },
    {
        "zip": "4470",
        "city": "Saint-Georges-Sur-Meuse",
        "lng": 5.3579041,
        "lat": 50.6000686
    },
    {
        "zip": "4480",
        "city": "Clermont-Sous-Huy",
        "lng": 5.3922,
        "lat": 50.569046
    },
    {
        "zip": "4480",
        "city": "Ehein",
        "lng": 5.4444346,
        "lat": 50.5445135
    },
    {
        "zip": "4480",
        "city": "Engis",
        "lng": 5.4043113,
        "lat": 50.582433
    },
    {
        "zip": "4480",
        "city": "Hermalle-Sous-Huy",
        "lng": 5.3622542,
        "lat": 50.5569911
    },
    {
        "zip": "4500",
        "city": "Ben-Ahin",
        "lng": 5.1773498,
        "lat": 50.5033196
    },
    {
        "zip": "4500",
        "city": "Huy",
        "lng": 5.2357453,
        "lat": 50.5215385
    },
    {
        "zip": "4500",
        "city": "Tihange",
        "lng": 5.259369,
        "lat": 50.527586
    },
    {
        "zip": "4520",
        "city": "Antheit",
        "lng": 5.2358517,
        "lat": 50.5512026
    },
    {
        "zip": "4520",
        "city": "Bas-Oha",
        "lng": 5.1880583,
        "lat": 50.522397
    },
    {
        "zip": "4520",
        "city": "Huccorgne",
        "lng": 5.1647622,
        "lat": 50.56759
    },
    {
        "zip": "4520",
        "city": "Moha",
        "lng": 5.1878867,
        "lat": 50.5471619
    },
    {
        "zip": "4520",
        "city": "Vinalmont",
        "lng": 5.2261382,
        "lat": 50.5627464
    },
    {
        "zip": "4520",
        "city": "Wanze",
        "lng": 5.2166503,
        "lat": 50.5332587
    },
    {
        "zip": "4530",
        "city": "Fize-Fontaine",
        "lng": 5.2835083,
        "lat": 50.5849842
    },
    {
        "zip": "4530",
        "city": "Vaux-Et-Borset",
        "lng": 5.23141,
        "lat": 50.6131537
    },
    {
        "zip": "4530",
        "city": "Vieux-Waleffe",
        "lng": 5.2044866,
        "lat": 50.6161817
    },
    {
        "zip": "4530",
        "city": "Villers-Le-Bouillet",
        "lng": 5.2619239,
        "lat": 50.5750242
    },
    {
        "zip": "4530",
        "city": "Warnant-Dreye",
        "lng": 5.2254442,
        "lat": 50.5951052
    },
    {
        "zip": "4537",
        "city": "Bodegnée",
        "lng": 5.3041269,
        "lat": 50.5857931
    },
    {
        "zip": "4537",
        "city": "Chapon-Seraing",
        "lng": 5.2734472,
        "lat": 50.609673
    },
    {
        "zip": "4537",
        "city": "Seraing-Le-Château",
        "lng": 5.2989318,
        "lat": 50.6216405
    },
    {
        "zip": "4537",
        "city": "Verlaine",
        "lng": 5.3184439,
        "lat": 50.6075114
    },
    {
        "zip": "4540",
        "city": "Amay",
        "lng": 5.3240992,
        "lat": 50.5497761
    },
    {
        "zip": "4540",
        "city": "Ampsin",
        "lng": 5.286736,
        "lat": 50.540238
    },
    {
        "zip": "4540",
        "city": "Flône",
        "lng": 5.3354736,
        "lat": 50.5582387
    },
    {
        "zip": "4540",
        "city": "Jehay",
        "lng": 5.3202782,
        "lat": 50.575487
    },
    {
        "zip": "4540",
        "city": "Ombret",
        "lng": 5.3371708,
        "lat": 50.5442809
    },
    {
        "zip": "4550",
        "city": "Nandrin",
        "lng": 5.4190411,
        "lat": 50.506355
    },
    {
        "zip": "4550",
        "city": "Saint-Séverin",
        "lng": 5.4130878,
        "lat": 50.5290054
    },
    {
        "zip": "4550",
        "city": "Villers-Le-Temple",
        "lng": 5.3708571,
        "lat": 50.5081151
    },
    {
        "zip": "4550",
        "city": "Yernée-Fraineux",
        "lng": 5.3819538,
        "lat": 50.5284301
    },
    {
        "zip": "4557",
        "city": "Abée",
        "lng": 5.3564571,
        "lat": 50.4735257
    },
    {
        "zip": "4557",
        "city": "Fraiture",
        "lng": 5.4196671,
        "lat": 50.478693
    },
    {
        "zip": "4557",
        "city": "Ramelot",
        "lng": 5.3286278,
        "lat": 50.4644497
    },
    {
        "zip": "4557",
        "city": "Seny",
        "lng": 5.4029395,
        "lat": 50.4595451
    },
    {
        "zip": "4557",
        "city": "Soheit-Tinlot",
        "lng": 5.3766541,
        "lat": 50.4794492
    },
    {
        "zip": "4557",
        "city": "Tinlot",
        "lng": 5.3786954,
        "lat": 50.4777419
    },
    {
        "zip": "4560",
        "city": "Bois-Et-Borsu",
        "lng": 5.3302671,
        "lat": 50.3946271
    },
    {
        "zip": "4560",
        "city": "Clavier",
        "lng": 5.3575692,
        "lat": 50.4115085
    },
    {
        "zip": "4560",
        "city": "Les Avins",
        "lng": 5.299356,
        "lat": 50.4154227
    },
    {
        "zip": "4560",
        "city": "Ocquier",
        "lng": 5.3954539,
        "lat": 50.3965212
    },
    {
        "zip": "4560",
        "city": "Pailhe",
        "lng": 5.2579288,
        "lat": 50.4229708
    },
    {
        "zip": "4560",
        "city": "Terwagne",
        "lng": 5.3491827,
        "lat": 50.4438855
    },
    {
        "zip": "4570",
        "city": "Marchin",
        "lng": 5.2263372,
        "lat": 50.4802051
    },
    {
        "zip": "4570",
        "city": "Vyle-Et-Tharoul",
        "lng": 5.2683004,
        "lat": 50.4462845
    },
    {
        "zip": "4577",
        "city": "Modave",
        "lng": 5.3017936,
        "lat": 50.4416137
    },
    {
        "zip": "4577",
        "city": "Outrelouxhe",
        "lng": 5.335294,
        "lat": 50.504232
    },
    {
        "zip": "4577",
        "city": "Strée-Lez-Huy",
        "lng": 5.3271603,
        "lat": 50.4922647
    },
    {
        "zip": "4577",
        "city": "Vierset-Barse",
        "lng": 5.2948879,
        "lat": 50.4811242
    },
    {
        "zip": "4590",
        "city": "Ellemelle",
        "lng": 5.442913,
        "lat": 50.4664238
    },
    {
        "zip": "4590",
        "city": "Ouffet",
        "lng": 5.4653273,
        "lat": 50.4377341
    },
    {
        "zip": "4590",
        "city": "Warzée",
        "lng": 5.427096,
        "lat": 50.4487876
    },
    {
        "zip": "4600",
        "city": "Lanaye",
        "lng": 5.6944381,
        "lat": 50.7806558
    },
    {
        "zip": "4600",
        "city": "Lixhe",
        "lng": 5.680109,
        "lat": 50.7550757
    },
    {
        "zip": "4600",
        "city": "Richelle",
        "lng": 5.695354,
        "lat": 50.7171434
    },
    {
        "zip": "4600",
        "city": "Visé",
        "lng": 5.695536,
        "lat": 50.7336644
    },
    {
        "zip": "4601",
        "city": "Argenteau",
        "lng": 5.686015,
        "lat": 50.6957645
    },
    {
        "zip": "4602",
        "city": "Cheratte",
        "lng": 5.670164,
        "lat": 50.682809
    },
    {
        "zip": "4606",
        "city": "Saint-André",
        "lng": 5.7536486,
        "lat": 50.6953487
    },
    {
        "zip": "4607",
        "city": "Berneau",
        "lng": 5.731253,
        "lat": 50.7423427
    },
    {
        "zip": "4607",
        "city": "Bombaye",
        "lng": 5.7429371,
        "lat": 50.730153
    },
    {
        "zip": "4607",
        "city": "Dalhem",
        "lng": 5.7229816,
        "lat": 50.7139302
    },
    {
        "zip": "4607",
        "city": "Feneur",
        "lng": 5.7138885,
        "lat": 50.7041818
    },
    {
        "zip": "4607",
        "city": "Mortroux",
        "lng": 5.7513854,
        "lat": 50.7132408
    },
    {
        "zip": "4608",
        "city": "Neufchâteau",
        "lng": 5.7764464,
        "lat": 50.7195828
    },
    {
        "zip": "4608",
        "city": "Warsage",
        "lng": 5.773045637996,
        "lat": 50.73466495
    },
    {
        "zip": "4610",
        "city": "Bellaire",
        "lng": 5.6654685,
        "lat": 50.6447087
    },
    {
        "zip": "4610",
        "city": "Beyne-Heusay",
        "lng": 5.6534141,
        "lat": 50.6220566
    },
    {
        "zip": "4610",
        "city": "Queue-Du-Bois",
        "lng": 5.6785831,
        "lat": 50.6371513
    },
    {
        "zip": "4620",
        "city": "Fléron",
        "lng": 5.6832219,
        "lat": 50.6168354
    },
    {
        "zip": "4621",
        "city": "Retinne",
        "lng": 5.6978166,
        "lat": 50.6302993
    },
    {
        "zip": "4623",
        "city": "Magnée",
        "lng": 5.6811009,
        "lat": 50.6028765
    },
    {
        "zip": "4624",
        "city": "Romsée",
        "lng": 5.6655488,
        "lat": 50.6068155
    },
    {
        "zip": "4630",
        "city": "Ayeneux",
        "lng": 5.7147473,
        "lat": 50.6087645
    },
    {
        "zip": "4630",
        "city": "Micheroux",
        "lng": 5.7355403,
        "lat": 50.6315865
    },
    {
        "zip": "4630",
        "city": "Soumagne",
        "lng": 5.739941,
        "lat": 50.6147718
    },
    {
        "zip": "4630",
        "city": "Tignée",
        "lng": 5.7042703,
        "lat": 50.6493983
    },
    {
        "zip": "4631",
        "city": "Evegnée",
        "lng": 5.7060994,
        "lat": 50.6418082
    },
    {
        "zip": "4632",
        "city": "Cérexhe-Heuseux",
        "lng": 5.7262388,
        "lat": 50.6508837
    },
    {
        "zip": "4633",
        "city": "Melen",
        "lng": 5.7370469,
        "lat": 50.6460841
    },
    {
        "zip": "4650",
        "city": "Chaineux",
        "lng": 5.8342711,
        "lat": 50.6319367
    },
    {
        "zip": "4650",
        "city": "Grand-Rechain",
        "lng": 5.8107674,
        "lat": 50.6072867
    },
    {
        "zip": "4650",
        "city": "Herve",
        "lng": 5.7935245,
        "lat": 50.6392112
    },
    {
        "zip": "4650",
        "city": "Julémont",
        "lng": 5.7718682,
        "lat": 50.6856398
    },
    {
        "zip": "4651",
        "city": "Battice",
        "lng": 5.8194813,
        "lat": 50.6482697
    },
    {
        "zip": "4652",
        "city": "Xhendelesse",
        "lng": 5.7726472,
        "lat": 50.6037018
    },
    {
        "zip": "4653",
        "city": "Bolland",
        "lng": 5.7594413,
        "lat": 50.6614055
    },
    {
        "zip": "4654",
        "city": "Charneux",
        "lng": 5.8048204,
        "lat": 50.669117
    },
    {
        "zip": "4670",
        "city": "Blégny",
        "lng": 5.7252372,
        "lat": 50.6727185
    },
    {
        "zip": "4670",
        "city": "Mortier",
        "lng": 5.7432715,
        "lat": 50.6821815
    },
    {
        "zip": "4670",
        "city": "Trembleur",
        "lng": 5.7268086,
        "lat": 50.6953295
    },
    {
        "zip": "4671",
        "city": "Barchon",
        "lng": 5.6969122,
        "lat": 50.6682189
    },
    {
        "zip": "4671",
        "city": "Housse",
        "lng": 5.693985,
        "lat": 50.6796259
    },
    {
        "zip": "4671",
        "city": "Saive",
        "lng": 5.6820303,
        "lat": 50.6525478
    },
    {
        "zip": "4672",
        "city": "Saint-Remy",
        "lng": 5.700802,
        "lat": 50.6958409
    },
    {
        "zip": "4680",
        "city": "Hermée",
        "lng": 5.6186068,
        "lat": 50.7087526
    },
    {
        "zip": "4680",
        "city": "Oupeye",
        "lng": 5.6450671,
        "lat": 50.7091889
    },
    {
        "zip": "4681",
        "city": "Hermalle-Sous-Argenteau",
        "lng": 5.6808711,
        "lat": 50.710544
    },
    {
        "zip": "4682",
        "city": "Heure-Le-Romain",
        "lng": 5.6295554,
        "lat": 50.7316969
    },
    {
        "zip": "4682",
        "city": "Houtain-Saint-Siméon",
        "lng": 5.6104199,
        "lat": 50.7391753
    },
    {
        "zip": "4683",
        "city": "Vivegnis",
        "lng": 5.6527071,
        "lat": 50.697932
    },
    {
        "zip": "4684",
        "city": "Haccourt",
        "lng": 5.6667674,
        "lat": 50.7339784
    },
    {
        "zip": "4690",
        "city": "Bassenge",
        "lng": 5.6094772,
        "lat": 50.7583596
    },
    {
        "zip": "4690",
        "city": "Boirs",
        "lng": 5.5788622,
        "lat": 50.7539251
    },
    {
        "zip": "4690",
        "city": "Eben-Emael",
        "lng": 5.6702417,
        "lat": 50.7994409
    },
    {
        "zip": "4690",
        "city": "Glons",
        "lng": 5.5398432,
        "lat": 50.7518981
    },
    {
        "zip": "4690",
        "city": "Roclenge-Sur-Geer",
        "lng": 5.59407,
        "lat": 50.756864
    },
    {
        "zip": "4690",
        "city": "Wonck",
        "lng": 5.6333621,
        "lat": 50.7677078
    },
    {
        "zip": "4700",
        "city": "Eupen",
        "lng": 6.031452,
        "lat": 50.6306134
    },
    {
        "zip": "4701",
        "city": "Kettenis",
        "lng": 6.0458005,
        "lat": 50.6461317
    },
    {
        "zip": "4710",
        "city": "Lontzen",
        "lng": 6.0073447,
        "lat": 50.6804233
    },
    {
        "zip": "4711",
        "city": "Walhorn",
        "lng": 6.0469422,
        "lat": 50.6750768
    },
    {
        "zip": "4720",
        "city": "La Calamine",
        "lng": 6.013341,
        "lat": 50.7154957
    },
    {
        "zip": "4721",
        "city": "Neu-Moresnet",
        "lng": 6.0240702,
        "lat": 50.7199902
    },
    {
        "zip": "4728",
        "city": "Hergenrath",
        "lng": 6.0316891,
        "lat": 50.7091279
    },
    {
        "zip": "4730",
        "city": "Hauset",
        "lng": 6.0708402,
        "lat": 50.7080172
    },
    {
        "zip": "4730",
        "city": "Raeren",
        "lng": 6.1108404,
        "lat": 50.6761305
    },
    {
        "zip": "4731",
        "city": "Eynatten",
        "lng": 6.081697,
        "lat": 50.6935863
    },
    {
        "zip": "4750",
        "city": "Butgenbach",
        "lng": 6.2053573,
        "lat": 50.4267259
    },
    {
        "zip": "4750",
        "city": "Elsenborn",
        "lng": 6.2213827,
        "lat": 50.4573116
    },
    {
        "zip": "4760",
        "city": "Bullange",
        "lng": 6.257827,
        "lat": 50.4075533
    },
    {
        "zip": "4760",
        "city": "Manderfeld",
        "lng": 6.3400555,
        "lat": 50.3310938
    },
    {
        "zip": "4761",
        "city": "Rocherath",
        "lng": 6.3009381,
        "lat": 50.4344078
    },
    {
        "zip": "4770",
        "city": "Ambléve",
        "lng": 6.1705559,
        "lat": 50.3543055
    },
    {
        "zip": "4770",
        "city": "Meyerode",
        "lng": 6.1884932,
        "lat": 50.3288415
    },
    {
        "zip": "4771",
        "city": "Heppenbach",
        "lng": 6.218691,
        "lat": 50.3631661
    },
    {
        "zip": "4780",
        "city": "Recht",
        "lng": 6.0431296,
        "lat": 50.3349273
    },
    {
        "zip": "4780",
        "city": "Saint-Vith",
        "lng": 6.1258953,
        "lat": 50.2803881
    },
    {
        "zip": "4782",
        "city": "Schoenberg",
        "lng": 6.2642498,
        "lat": 50.2888095
    },
    {
        "zip": "4783",
        "city": "Lommersweiler",
        "lng": 6.1651484,
        "lat": 50.2389236
    },
    {
        "zip": "4784",
        "city": "Crombach",
        "lng": 6.0680547,
        "lat": 50.2591318
    },
    {
        "zip": "4790",
        "city": "Burg-Reuland",
        "lng": 6.1216793580592,
        "lat": 50.174780874297
    },
    {
        "zip": "4790",
        "city": "Reuland",
        "lng": 6.1362567,
        "lat": 50.1954121
    },
    {
        "zip": "4791",
        "city": "Thommen",
        "lng": 6.0743425,
        "lat": 50.2190586
    },
    {
        "zip": "4800",
        "city": "Ensival",
        "lng": 5.843182,
        "lat": 50.582172
    },
    {
        "zip": "4800",
        "city": "Lambermont",
        "lng": 5.191001,
        "lat": 49.7051899
    },
    {
        "zip": "4800",
        "city": "Petit-Rechain",
        "lng": 5.8341784,
        "lat": 50.6143044
    },
    {
        "zip": "4800",
        "city": "Polleur",
        "lng": 5.873929354985,
        "lat": 50.542907
    },
    {
        "zip": "4800",
        "city": "Verviers",
        "lng": 5.8638544,
        "lat": 50.5932274
    },
    {
        "zip": "4801",
        "city": "Stembert",
        "lng": 5.8952801,
        "lat": 50.593109
    },
    {
        "zip": "4802",
        "city": "Heusy",
        "lng": 5.867539,
        "lat": 50.57804
    },
    {
        "zip": "4820",
        "city": "Dison",
        "lng": 5.854735,
        "lat": 50.611374
    },
    {
        "zip": "4821",
        "city": "Andrimont",
        "lng": 5.8901888,
        "lat": 50.4386821
    },
    {
        "zip": "4830",
        "city": "Limbourg",
        "lng": 5.9402987,
        "lat": 50.6122761
    },
    {
        "zip": "4831",
        "city": "Bilstain",
        "lng": 5.9212234,
        "lat": 50.623535
    },
    {
        "zip": "4834",
        "city": "Goé",
        "lng": 5.9562188,
        "lat": 50.6086202
    },
    {
        "zip": "4837",
        "city": "Baelen",
        "lng": 5.9715011,
        "lat": 50.6311898
    },
    {
        "zip": "4837",
        "city": "Membach",
        "lng": 5.9953656,
        "lat": 50.6193792
    },
    {
        "zip": "4840",
        "city": "Welkenraedt",
        "lng": 5.9723396,
        "lat": 50.6627086
    },
    {
        "zip": "4841",
        "city": "Henri-Chapelle",
        "lng": 5.9318018,
        "lat": 50.6768234
    },
    {
        "zip": "4845",
        "city": "Jalhay",
        "lng": 5.9647526,
        "lat": 50.5592528
    },
    {
        "zip": "4845",
        "city": "Sart-Lez-Spa",
        "lng": 5.933482,
        "lat": 50.51744
    },
    {
        "zip": "4850",
        "city": "Montzen",
        "lng": 5.9620321,
        "lat": 50.7075126
    },
    {
        "zip": "4850",
        "city": "Moresnet",
        "lng": 5.9880362,
        "lat": 50.7208739
    },
    {
        "zip": "4850",
        "city": "Plombiéres",
        "lng": 5.9590725,
        "lat": 50.7374801
    },
    {
        "zip": "4851",
        "city": "Gemmenich",
        "lng": 5.9967631,
        "lat": 50.7463908
    },
    {
        "zip": "4851",
        "city": "Sippenaeken",
        "lng": 5.9336285,
        "lat": 50.7509012
    },
    {
        "zip": "4852",
        "city": "Hombourg",
        "lng": 5.9207511,
        "lat": 50.723243
    },
    {
        "zip": "4860",
        "city": "Cornesse",
        "lng": 5.7910525,
        "lat": 50.5747936
    },
    {
        "zip": "4860",
        "city": "Pepinster",
        "lng": 5.8037101,
        "lat": 50.5675217
    },
    {
        "zip": "4860",
        "city": "Wegnez",
        "lng": 5.8175559,
        "lat": 50.5789617
    },
    {
        "zip": "4861",
        "city": "Soiron",
        "lng": 5.7906072,
        "lat": 50.5921673
    },
    {
        "zip": "4870",
        "city": "Forêt",
        "lng": 5.7002354,
        "lat": 50.583997
    },
    {
        "zip": "4870",
        "city": "Fraipont",
        "lng": 5.7227768,
        "lat": 50.5663484
    },
    {
        "zip": "4870",
        "city": "Nessonvaux",
        "lng": 5.7371309,
        "lat": 50.573671
    },
    {
        "zip": "4870",
        "city": "Trooz",
        "lng": 5.6883726,
        "lat": 50.5728102
    },
    {
        "zip": "4877",
        "city": "Olne",
        "lng": 5.7479948,
        "lat": 50.5901199
    },
    {
        "zip": "4880",
        "city": "Aubel",
        "lng": 5.8585553,
        "lat": 50.7041498
    },
    {
        "zip": "4890",
        "city": "Clermont",
        "lng": 5.883668,
        "lat": 50.6589677
    },
    {
        "zip": "4890",
        "city": "Thimister",
        "lng": 5.8650125,
        "lat": 50.6529465
    },
    {
        "zip": "4890",
        "city": "Thimister-Clermont",
        "lng": 5.8634486463407,
        "lat": 50.66298735
    },
    {
        "zip": "4900",
        "city": "Spa",
        "lng": 5.8626233,
        "lat": 50.4920838
    },
    {
        "zip": "4910",
        "city": "La Reid",
        "lng": 5.7905664,
        "lat": 50.4894593
    },
    {
        "zip": "4910",
        "city": "Polleur",
        "lng": 5.873929354985,
        "lat": 50.542907
    },
    {
        "zip": "4910",
        "city": "Theux",
        "lng": 5.8198955843753,
        "lat": 50.52586075
    },
    {
        "zip": "4920",
        "city": "Aywaille",
        "lng": 5.6742167,
        "lat": 50.4734827
    },
    {
        "zip": "4920",
        "city": "Ernonheid",
        "lng": 5.6660069,
        "lat": 50.4028521
    },
    {
        "zip": "4920",
        "city": "Harzé",
        "lng": 5.6672743,
        "lat": 50.4409138
    },
    {
        "zip": "4920",
        "city": "Louveigné",
        "lng": 5.7172477995985,
        "lat": 50.5248417
    },
    {
        "zip": "4950",
        "city": "Faymonville",
        "lng": 6.1407071,
        "lat": 50.4054037
    },
    {
        "zip": "4950",
        "city": "Robertville",
        "lng": 6.1224661,
        "lat": 50.4550294
    },
    {
        "zip": "4950",
        "city": "Sourbrodt",
        "lng": 6.1143694,
        "lat": 50.4772534
    },
    {
        "zip": "4950",
        "city": "Waimes",
        "lng": 6.1117543,
        "lat": 50.4149134
    },
    {
        "zip": "4960",
        "city": "Bevercé",
        "lng": 6.0391879,
        "lat": 50.4415348
    },
    {
        "zip": "4960",
        "city": "Malmedy",
        "lng": 6.0266001,
        "lat": 50.4239904
    },
    {
        "zip": "4970",
        "city": "Francorchamps",
        "lng": 5.952833,
        "lat": 50.4531568
    },
    {
        "zip": "4970",
        "city": "Stavelot",
        "lng": 5.9308362,
        "lat": 50.3940846
    },
    {
        "zip": "4980",
        "city": "Fosse",
        "lng": 5.8378154,
        "lat": 50.3388474
    },
    {
        "zip": "4980",
        "city": "Trois-Ponts",
        "lng": 5.8704355,
        "lat": 50.3727015
    },
    {
        "zip": "4980",
        "city": "Wanne",
        "lng": 5.9213974,
        "lat": 50.3557319
    },
    {
        "zip": "4983",
        "city": "Basse-Bodeux",
        "lng": 5.826789,
        "lat": 50.3579736
    },
    {
        "zip": "4987",
        "city": "Chevron",
        "lng": 5.7299282,
        "lat": 50.3819564
    },
    {
        "zip": "4987",
        "city": "La Gleize",
        "lng": 5.8459307,
        "lat": 50.4128545
    },
    {
        "zip": "4987",
        "city": "Lorcé",
        "lng": 5.7321495,
        "lat": 50.4145319
    },
    {
        "zip": "4987",
        "city": "Rahier",
        "lng": 5.7786187,
        "lat": 50.3860353
    },
    {
        "zip": "4987",
        "city": "Stoumont",
        "lng": 5.80836,
        "lat": 50.4069504
    },
    {
        "zip": "4990",
        "city": "Arbrefontaine",
        "lng": 5.8353999,
        "lat": 50.3012606
    },
    {
        "zip": "4990",
        "city": "Bra",
        "lng": 5.7309407,
        "lat": 50.3208964
    },
    {
        "zip": "4990",
        "city": "Lierneux",
        "lng": 5.7920929,
        "lat": 50.28574
    },
    {
        "zip": "5000",
        "city": "Beez",
        "lng": 4.9219558,
        "lat": 50.4687313
    },
    {
        "zip": "5000",
        "city": "Namur",
        "lng": 4.849314651989,
        "lat": 50.45620385
    },
    {
        "zip": "5001",
        "city": "Belgrade",
        "lng": 4.8222958522639,
        "lat": 50.46923485
    },
    {
        "zip": "5002",
        "city": "Saint-Servais",
        "lng": 4.8351266178881,
        "lat": 50.48141855
    },
    {
        "zip": "5003",
        "city": "Saint-Marc",
        "lng": 4.8469587132153,
        "lat": 50.4948666
    },
    {
        "zip": "5004",
        "city": "Bouge",
        "lng": 4.891664450549,
        "lat": 50.47887385
    },
    {
        "zip": "5020",
        "city": "Champion",
        "lng": 4.9035112119756,
        "lat": 50.49862565
    },
    {
        "zip": "5020",
        "city": "Daussoulx",
        "lng": 4.8769847282413,
        "lat": 50.51724395
    },
    {
        "zip": "5020",
        "city": "Flawinne",
        "lng": 4.8120768,
        "lat": 50.456602
    },
    {
        "zip": "5020",
        "city": "Malonne",
        "lng": 4.7966661,
        "lat": 50.4382517
    },
    {
        "zip": "5020",
        "city": "Suarlée",
        "lng": 4.7780268559488,
        "lat": 50.4883008
    },
    {
        "zip": "5020",
        "city": "Temploux",
        "lng": 4.7509248,
        "lat": 50.4829793
    },
    {
        "zip": "5020",
        "city": "Vedrin",
        "lng": 4.8742389,
        "lat": 50.5019599
    },
    {
        "zip": "5021",
        "city": "Boninne",
        "lng": 4.9291903523068,
        "lat": 50.49239555
    },
    {
        "zip": "5022",
        "city": "Cognelée",
        "lng": 4.9127293838106,
        "lat": 50.5171876
    },
    {
        "zip": "5024",
        "city": "Gelbressée",
        "lng": 4.9578287274527,
        "lat": 50.5113799
    },
    {
        "zip": "5024",
        "city": "Marche-Les-Dames",
        "lng": 4.9615056,
        "lat": 50.4864453
    },
    {
        "zip": "5030",
        "city": "Beuzet",
        "lng": 4.7481633,
        "lat": 50.5329777
    },
    {
        "zip": "5030",
        "city": "Ernage",
        "lng": 4.6739886,
        "lat": 50.5941971
    },
    {
        "zip": "5030",
        "city": "Gembloux",
        "lng": 4.6986933030313,
        "lat": 50.5621964
    },
    {
        "zip": "5030",
        "city": "Grand-Manil",
        "lng": 4.683029,
        "lat": 50.556923
    },
    {
        "zip": "5030",
        "city": "Lonzée",
        "lng": 4.7273547,
        "lat": 50.5533536
    },
    {
        "zip": "5030",
        "city": "Sauveniére",
        "lng": 4.7248074,
        "lat": 50.5810646
    },
    {
        "zip": "5031",
        "city": "Grand-Leez",
        "lng": 4.7609593378472,
        "lat": 50.57820735
    },
    {
        "zip": "5032",
        "city": "Bossiére",
        "lng": 4.6919946070362,
        "lat": 50.52340745
    },
    {
        "zip": "5032",
        "city": "Bothey",
        "lng": 4.6524505,
        "lat": 50.521822
    },
    {
        "zip": "5032",
        "city": "Corroy-Le-Château",
        "lng": 4.6546979,
        "lat": 50.5359706
    },
    {
        "zip": "5032",
        "city": "Isnes",
        "lng": 4.7406918,
        "lat": 50.5105186
    },
    {
        "zip": "5032",
        "city": "Mazy",
        "lng": 4.6745108,
        "lat": 50.5127674
    },
    {
        "zip": "5060",
        "city": "Arsimont",
        "lng": 4.6388177,
        "lat": 50.4273479
    },
    {
        "zip": "5060",
        "city": "Auvelais",
        "lng": 4.6354624,
        "lat": 50.4470193
    },
    {
        "zip": "5060",
        "city": "Falisolle",
        "lng": 4.621259,
        "lat": 50.4182456
    },
    {
        "zip": "5060",
        "city": "Keumiée",
        "lng": 4.5960062,
        "lat": 50.4655148
    },
    {
        "zip": "5060",
        "city": "Moignelée",
        "lng": 4.5885625,
        "lat": 50.4378354
    },
    {
        "zip": "5060",
        "city": "Sambreville",
        "lng": 4.6196692,
        "lat": 50.4409806
    },
    {
        "zip": "5060",
        "city": "Tamines",
        "lng": 4.607595,
        "lat": 50.4340969
    },
    {
        "zip": "5060",
        "city": "Velaine-Sur-Sambre",
        "lng": 4.60725,
        "lat": 50.4713313
    },
    {
        "zip": "5070",
        "city": "Aisemont",
        "lng": 4.6513189,
        "lat": 50.4048557
    },
    {
        "zip": "5070",
        "city": "Fosses-La-Ville",
        "lng": 4.6978935,
        "lat": 50.3959512
    },
    {
        "zip": "5070",
        "city": "Le Roux",
        "lng": 4.6237797,
        "lat": 50.3885408
    },
    {
        "zip": "5070",
        "city": "Sart-Eustache",
        "lng": 4.5998195,
        "lat": 50.3760693
    },
    {
        "zip": "5070",
        "city": "Sart-Saint-Laurent",
        "lng": 4.7411668,
        "lat": 50.4027187
    },
    {
        "zip": "5070",
        "city": "Vitrival",
        "lng": 4.6558828,
        "lat": 50.3941596
    },
    {
        "zip": "5080",
        "city": "Emines",
        "lng": 4.8279560785714,
        "lat": 50.5157255
    },
    {
        "zip": "5080",
        "city": "La Bruyére",
        "lng": 5.7266989,
        "lat": 50.4133942
    },
    {
        "zip": "5080",
        "city": "Rhisnes",
        "lng": 4.7909700280289,
        "lat": 50.5005948
    },
    {
        "zip": "5080",
        "city": "Villers-Lez-Heest",
        "lng": 4.8345691476021,
        "lat": 50.5379166
    },
    {
        "zip": "5080",
        "city": "Warisoulx",
        "lng": 4.8587823219021,
        "lat": 50.5348173
    },
    {
        "zip": "5081",
        "city": "Bovesse",
        "lng": 4.7787022193209,
        "lat": 50.51440005
    },
    {
        "zip": "5081",
        "city": "Meux",
        "lng": 4.7986774536609,
        "lat": 50.552563
    },
    {
        "zip": "5100",
        "city": "Dave",
        "lng": 4.9043004277231,
        "lat": 50.4110871
    },
    {
        "zip": "5100",
        "city": "Jambes",
        "lng": 4.8785294244785,
        "lat": 50.458883332027
    },
    {
        "zip": "5100",
        "city": "Naninne",
        "lng": 4.9316703499006,
        "lat": 50.4146082
    },
    {
        "zip": "5100",
        "city": "Wépion",
        "lng": 4.8633768,
        "lat": 50.4212957
    },
    {
        "zip": "5100",
        "city": "Wierde",
        "lng": 4.9497445929374,
        "lat": 50.4286275
    },
    {
        "zip": "5101",
        "city": "Erpent",
        "lng": 4.9094515331252,
        "lat": 50.4474046
    },
    {
        "zip": "5101",
        "city": "Lives-Sur-Meuse",
        "lng": 4.9384059263709,
        "lat": 50.47132665
    },
    {
        "zip": "5101",
        "city": "Loyers",
        "lng": 4.9409795174479,
        "lat": 50.4580989
    },
    {
        "zip": "5140",
        "city": "Boignée",
        "lng": 4.6119938,
        "lat": 50.5018413
    },
    {
        "zip": "5140",
        "city": "Ligny",
        "lng": 4.5757572,
        "lat": 50.5126287
    },
    {
        "zip": "5140",
        "city": "Sombreffe",
        "lng": 4.6025925,
        "lat": 50.5246589
    },
    {
        "zip": "5140",
        "city": "Tongrinne",
        "lng": 4.623887,
        "lat": 50.5157258
    },
    {
        "zip": "5150",
        "city": "Floreffe",
        "lng": 4.758878,
        "lat": 50.4348064
    },
    {
        "zip": "5150",
        "city": "Floriffoux",
        "lng": 4.7694348,
        "lat": 50.4515822
    },
    {
        "zip": "5150",
        "city": "Franiére",
        "lng": 4.7317891,
        "lat": 50.4389093
    },
    {
        "zip": "5150",
        "city": "Soye",
        "lng": 4.730789,
        "lat": 50.4494828
    },
    {
        "zip": "5170",
        "city": "Arbre",
        "lng": 4.820627,
        "lat": 50.365529
    },
    {
        "zip": "5170",
        "city": "Bois-De-Villers",
        "lng": 4.823377,
        "lat": 50.389973
    },
    {
        "zip": "5170",
        "city": "Lesve",
        "lng": 4.7748094,
        "lat": 50.3773499
    },
    {
        "zip": "5170",
        "city": "Lustin",
        "lng": 4.8965971,
        "lat": 50.379305
    },
    {
        "zip": "5170",
        "city": "Profondeville",
        "lng": 4.8694363,
        "lat": 50.3776473
    },
    {
        "zip": "5170",
        "city": "Riviére",
        "lng": 4.8724268,
        "lat": 50.3580729
    },
    {
        "zip": "5190",
        "city": "Balâtre",
        "lng": 4.6382422,
        "lat": 50.4983123
    },
    {
        "zip": "5190",
        "city": "Ham-Sur-Sambre",
        "lng": 4.6734915,
        "lat": 50.44449
    },
    {
        "zip": "5190",
        "city": "Jemeppe-Sur-Sambre",
        "lng": 4.6545986012407,
        "lat": 50.46299585
    },
    {
        "zip": "5190",
        "city": "Mornimont",
        "lng": 4.7043344,
        "lat": 50.4555129
    },
    {
        "zip": "5190",
        "city": "Moustier-Sur-Sambre",
        "lng": 4.6967162,
        "lat": 50.4660542
    },
    {
        "zip": "5190",
        "city": "Onoz",
        "lng": 4.6696856,
        "lat": 50.4923374
    },
    {
        "zip": "5190",
        "city": "Saint-Martin",
        "lng": 4.6472621,
        "lat": 50.5008367
    },
    {
        "zip": "5190",
        "city": "Spy",
        "lng": 4.7027047,
        "lat": 50.4811696
    },
    {
        "zip": "5300",
        "city": "Andenne",
        "lng": 5.1043923,
        "lat": 50.4900943
    },
    {
        "zip": "5300",
        "city": "Bonneville",
        "lng": 5.0338525,
        "lat": 50.4709296
    },
    {
        "zip": "5300",
        "city": "Coutisse",
        "lng": 5.1169603,
        "lat": 50.4636595
    },
    {
        "zip": "5300",
        "city": "Landenne",
        "lng": 5.0646275,
        "lat": 50.5142495
    },
    {
        "zip": "5300",
        "city": "Maizeret",
        "lng": 4.978173,
        "lat": 50.4598458
    },
    {
        "zip": "5300",
        "city": "Namêche",
        "lng": 4.9947316,
        "lat": 50.4712487
    },
    {
        "zip": "5300",
        "city": "Sclayn",
        "lng": 5.0284735,
        "lat": 50.4897139
    },
    {
        "zip": "5300",
        "city": "Seilles",
        "lng": 5.0805501,
        "lat": 50.4984249
    },
    {
        "zip": "5300",
        "city": "Thon",
        "lng": 5.0133577,
        "lat": 50.4641615
    },
    {
        "zip": "5300",
        "city": "Vezin",
        "lng": 5.0114611,
        "lat": 50.4966018
    },
    {
        "zip": "5310",
        "city": "Aische-En-Refail",
        "lng": 4.8359955,
        "lat": 50.5988682
    },
    {
        "zip": "5310",
        "city": "Bolinne",
        "lng": 4.9299565,
        "lat": 50.6003478
    },
    {
        "zip": "5310",
        "city": "Boneffe",
        "lng": 4.957465,
        "lat": 50.623868
    },
    {
        "zip": "5310",
        "city": "Branchon",
        "lng": 4.971969,
        "lat": 50.629691
    },
    {
        "zip": "5310",
        "city": "Dhuy",
        "lng": 4.8573789,
        "lat": 50.5596814
    },
    {
        "zip": "5310",
        "city": "Eghezée",
        "lng": 4.9049523,
        "lat": 50.5917416
    },
    {
        "zip": "5310",
        "city": "Hanret",
        "lng": 4.9440702,
        "lat": 50.5834955
    },
    {
        "zip": "5310",
        "city": "Leuze",
        "lng": 4.9103111,
        "lat": 50.5605615
    },
    {
        "zip": "5310",
        "city": "Liernu",
        "lng": 4.8311032,
        "lat": 50.5821124
    },
    {
        "zip": "5310",
        "city": "Longchamps",
        "lng": 4.8962185,
        "lat": 50.5772925
    },
    {
        "zip": "5310",
        "city": "Mehaigne",
        "lng": 4.8762345,
        "lat": 50.5942203
    },
    {
        "zip": "5310",
        "city": "Noville-Sur-Méhaigne",
        "lng": 4.8909225,
        "lat": 50.6082112
    },
    {
        "zip": "5310",
        "city": "Saint-Germain",
        "lng": 4.8427332,
        "lat": 50.5730646
    },
    {
        "zip": "5310",
        "city": "Taviers",
        "lng": 4.9318579,
        "lat": 50.6170592
    },
    {
        "zip": "5310",
        "city": "Upigny",
        "lng": 4.8653991,
        "lat": 50.5756144
    },
    {
        "zip": "5310",
        "city": "Waret-La-Chaussée",
        "lng": 4.9217571,
        "lat": 50.5417091
    },
    {
        "zip": "5330",
        "city": "Assesse",
        "lng": 5.0233625,
        "lat": 50.3704203
    },
    {
        "zip": "5330",
        "city": "Maillen",
        "lng": 4.9699385,
        "lat": 50.3775518
    },
    {
        "zip": "5330",
        "city": "Sart-Bernard",
        "lng": 4.9506941,
        "lat": 50.405155
    },
    {
        "zip": "5332",
        "city": "Crupet",
        "lng": 4.9581149,
        "lat": 50.3468626
    },
    {
        "zip": "5333",
        "city": "Sorinne-La-Longue",
        "lng": 5.0294375,
        "lat": 50.3896858
    },
    {
        "zip": "5334",
        "city": "Florée",
        "lng": 5.0704373,
        "lat": 50.3730757
    },
    {
        "zip": "5336",
        "city": "Courriére",
        "lng": 4.9845433,
        "lat": 50.378301
    },
    {
        "zip": "5340",
        "city": "Faulx-Les-Tombes",
        "lng": 5.0191438,
        "lat": 50.4272928
    },
    {
        "zip": "5340",
        "city": "Gesves",
        "lng": 5.0776175,
        "lat": 50.4028535
    },
    {
        "zip": "5340",
        "city": "Haltinne",
        "lng": 5.0744138,
        "lat": 50.4508568
    },
    {
        "zip": "5340",
        "city": "Mozet",
        "lng": 4.9846704,
        "lat": 50.4416402
    },
    {
        "zip": "5340",
        "city": "Sorée",
        "lng": 5.1258806,
        "lat": 50.4001817
    },
    {
        "zip": "5350",
        "city": "Evelette",
        "lng": 5.171436,
        "lat": 50.4135772
    },
    {
        "zip": "5350",
        "city": "Ohey",
        "lng": 5.1238439,
        "lat": 50.4359066
    },
    {
        "zip": "5351",
        "city": "Haillot",
        "lng": 5.1491871,
        "lat": 50.4405961
    },
    {
        "zip": "5353",
        "city": "Goesnes",
        "lng": 5.2217845,
        "lat": 50.440101
    },
    {
        "zip": "5354",
        "city": "Jallet",
        "lng": 5.1821601,
        "lat": 50.4432621
    },
    {
        "zip": "5360",
        "city": "Hamois",
        "lng": 5.1594475,
        "lat": 50.3408694
    },
    {
        "zip": "5360",
        "city": "Natoye",
        "lng": 5.0562173,
        "lat": 50.3413559
    },
    {
        "zip": "5361",
        "city": "Mohiville",
        "lng": 5.1923275,
        "lat": 50.3176664
    },
    {
        "zip": "5361",
        "city": "Scy",
        "lng": 5.2094413,
        "lat": 50.3079186
    },
    {
        "zip": "5362",
        "city": "Achet",
        "lng": 5.1751963,
        "lat": 50.3334777
    },
    {
        "zip": "5363",
        "city": "Emptinne",
        "lng": 5.1212547,
        "lat": 50.3253905
    },
    {
        "zip": "5364",
        "city": "Schaltin",
        "lng": 5.1237159,
        "lat": 50.3579006
    },
    {
        "zip": "5370",
        "city": "Barvaux-Condroz",
        "lng": 5.2607234,
        "lat": 50.3291239
    },
    {
        "zip": "5370",
        "city": "Flostoy",
        "lng": 5.1845059,
        "lat": 50.3883757
    },
    {
        "zip": "5370",
        "city": "Havelange",
        "lng": 5.23958,
        "lat": 50.3870946
    },
    {
        "zip": "5370",
        "city": "Jeneffe",
        "lng": 5.2451952,
        "lat": 50.3563281
    },
    {
        "zip": "5370",
        "city": "Porcheresse",
        "lng": 5.2408578,
        "lat": 50.3384304
    },
    {
        "zip": "5370",
        "city": "Verlée",
        "lng": 5.2795649,
        "lat": 50.3675402
    },
    {
        "zip": "5372",
        "city": "Méan",
        "lng": 5.333856,
        "lat": 50.3619241
    },
    {
        "zip": "5374",
        "city": "Maffe",
        "lng": 5.3125869,
        "lat": 50.3533462
    },
    {
        "zip": "5376",
        "city": "Miécret",
        "lng": 5.24941,
        "lat": 50.365689
    },
    {
        "zip": "5377",
        "city": "Baillonville",
        "lng": 5.338213,
        "lat": 50.288759
    },
    {
        "zip": "5377",
        "city": "Bonsin",
        "lng": 5.3788758,
        "lat": 50.3727364
    },
    {
        "zip": "5377",
        "city": "Heure",
        "lng": 5.2963125,
        "lat": 50.2945343
    },
    {
        "zip": "5377",
        "city": "Hogne",
        "lng": 5.279464,
        "lat": 50.24859
    },
    {
        "zip": "5377",
        "city": "Nettinne",
        "lng": 5.2612996,
        "lat": 50.2910362
    },
    {
        "zip": "5377",
        "city": "Noiseux",
        "lng": 5.3737372,
        "lat": 50.2976637
    },
    {
        "zip": "5377",
        "city": "Sinsin",
        "lng": 5.2623625,
        "lat": 50.275734
    },
    {
        "zip": "5377",
        "city": "Somme-Leuze",
        "lng": 5.3669021,
        "lat": 50.336585
    },
    {
        "zip": "5377",
        "city": "Waillet",
        "lng": 5.304196,
        "lat": 50.260584
    },
    {
        "zip": "5380",
        "city": "Bierwart",
        "lng": 5.0360917,
        "lat": 50.5580545
    },
    {
        "zip": "5380",
        "city": "Cortil-Wodon",
        "lng": 4.958369,
        "lat": 50.567323
    },
    {
        "zip": "5380",
        "city": "Fernelmont",
        "lng": 4.9746742,
        "lat": 50.548118
    },
    {
        "zip": "5380",
        "city": "Forville",
        "lng": 5.0006655,
        "lat": 50.574642
    },
    {
        "zip": "5380",
        "city": "Franc-Waret",
        "lng": 4.9759589,
        "lat": 50.5210841
    },
    {
        "zip": "5380",
        "city": "Hemptinne",
        "lng": 4.9869761,
        "lat": 50.6010427
    },
    {
        "zip": "5380",
        "city": "Hingeon",
        "lng": 5.008801,
        "lat": 50.5244901
    },
    {
        "zip": "5380",
        "city": "Marchovelette",
        "lng": 4.9411438,
        "lat": 50.5231886
    },
    {
        "zip": "5380",
        "city": "Noville-Les-Bois",
        "lng": 4.982708,
        "lat": 50.5555805
    },
    {
        "zip": "5380",
        "city": "Pontillas",
        "lng": 5.01707,
        "lat": 50.5500591
    },
    {
        "zip": "5380",
        "city": "Tillier",
        "lng": 4.946342,
        "lat": 50.544287
    },
    {
        "zip": "5500",
        "city": "Anseremme",
        "lng": 4.907923,
        "lat": 50.23791
    },
    {
        "zip": "5500",
        "city": "Bouvignes-Sur-Meuse",
        "lng": 4.8984129,
        "lat": 50.2725677
    },
    {
        "zip": "5500",
        "city": "Dinant",
        "lng": 4.91242,
        "lat": 50.2608166
    },
    {
        "zip": "5500",
        "city": "Dréhance",
        "lng": 4.9388148,
        "lat": 50.2368273
    },
    {
        "zip": "5500",
        "city": "Falmagne",
        "lng": 4.8971396,
        "lat": 50.2003101
    },
    {
        "zip": "5500",
        "city": "Falmignoul",
        "lng": 4.8924373,
        "lat": 50.2037856
    },
    {
        "zip": "5500",
        "city": "Furfooz",
        "lng": 4.9584822,
        "lat": 50.223335
    },
    {
        "zip": "5501",
        "city": "Lisogne",
        "lng": 4.9715412,
        "lat": 50.2814293
    },
    {
        "zip": "5502",
        "city": "Thynes",
        "lng": 4.9882689,
        "lat": 50.2798224
    },
    {
        "zip": "5503",
        "city": "Sorinnes",
        "lng": 4.982409,
        "lat": 50.260159
    },
    {
        "zip": "5504",
        "city": "Foy-Notre-Dame",
        "lng": 4.9897127,
        "lat": 50.2471757
    },
    {
        "zip": "5520",
        "city": "Anthée",
        "lng": 4.7604034,
        "lat": 50.2395304
    },
    {
        "zip": "5520",
        "city": "Onhaye",
        "lng": 4.840864,
        "lat": 50.2435657
    },
    {
        "zip": "5521",
        "city": "Serville",
        "lng": 4.7806526,
        "lat": 50.2500754
    },
    {
        "zip": "5522",
        "city": "Falaen",
        "lng": 4.793357,
        "lat": 50.2782156
    },
    {
        "zip": "5523",
        "city": "Sommiére",
        "lng": 4.8454743,
        "lat": 50.274944
    },
    {
        "zip": "5523",
        "city": "Weillen",
        "lng": 4.8245344,
        "lat": 50.2596445
    },
    {
        "zip": "5524",
        "city": "Gerin",
        "lng": 4.8158102,
        "lat": 50.2452911
    },
    {
        "zip": "5530",
        "city": "Dorinne",
        "lng": 4.97325,
        "lat": 50.3173377
    },
    {
        "zip": "5530",
        "city": "Durnal",
        "lng": 4.9857278,
        "lat": 50.3380783
    },
    {
        "zip": "5530",
        "city": "Evrehailles",
        "lng": 4.9125689,
        "lat": 50.3202787
    },
    {
        "zip": "5530",
        "city": "Godinne",
        "lng": 4.875987,
        "lat": 50.3505281
    },
    {
        "zip": "5530",
        "city": "Houx",
        "lng": 4.8978933,
        "lat": 50.3031942
    },
    {
        "zip": "5530",
        "city": "Mont",
        "lng": 5.7909538,
        "lat": 50.5341607
    },
    {
        "zip": "5530",
        "city": "Purnode",
        "lng": 4.9455473,
        "lat": 50.3142896
    },
    {
        "zip": "5530",
        "city": "Spontin",
        "lng": 5.006949,
        "lat": 50.322619
    },
    {
        "zip": "5530",
        "city": "Yvoir",
        "lng": 4.8806618,
        "lat": 50.3264425
    },
    {
        "zip": "5537",
        "city": "Anhée",
        "lng": 4.8796936,
        "lat": 50.310778
    },
    {
        "zip": "5537",
        "city": "Annevoie-Rouillon",
        "lng": 4.841866,
        "lat": 50.343103
    },
    {
        "zip": "5537",
        "city": "Bioul",
        "lng": 4.7976225,
        "lat": 50.3335584
    },
    {
        "zip": "5537",
        "city": "Denée",
        "lng": 4.7512903,
        "lat": 50.3179627
    },
    {
        "zip": "5537",
        "city": "Haut-Le-Wastia",
        "lng": 4.8417104,
        "lat": 50.3053165
    },
    {
        "zip": "5537",
        "city": "Sosoye",
        "lng": 4.7815722,
        "lat": 50.2955787
    },
    {
        "zip": "5537",
        "city": "Warnant",
        "lng": 4.836084,
        "lat": 50.3238206
    },
    {
        "zip": "5540",
        "city": "Hastiére",
        "lng": 4.8225541872005,
        "lat": 50.1973562
    },
    {
        "zip": "5540",
        "city": "Hastiére-Lavaux",
        "lng": 4.8242423,
        "lat": 50.216541
    },
    {
        "zip": "5540",
        "city": "Hermeton-Sur-Meuse",
        "lng": 4.8189189,
        "lat": 50.1967082
    },
    {
        "zip": "5540",
        "city": "Waulsort",
        "lng": 4.8632452,
        "lat": 50.2032886
    },
    {
        "zip": "5541",
        "city": "Hastiére-Par-Delà",
        "lng": 4.8294854,
        "lat": 50.2150767
    },
    {
        "zip": "5542",
        "city": "Blaimont",
        "lng": 4.8352693,
        "lat": 50.192216
    },
    {
        "zip": "5543",
        "city": "Heer",
        "lng": 4.831747,
        "lat": 50.161698
    },
    {
        "zip": "5544",
        "city": "Agimont",
        "lng": 4.7942801,
        "lat": 50.16421
    },
    {
        "zip": "5550",
        "city": "Alle",
        "lng": 4.9726784,
        "lat": 49.8421207
    },
    {
        "zip": "5550",
        "city": "Bagimont",
        "lng": 4.8751221,
        "lat": 49.8241964
    },
    {
        "zip": "5550",
        "city": "Bohan",
        "lng": 4.8860106,
        "lat": 49.864219
    },
    {
        "zip": "5550",
        "city": "Chairiére",
        "lng": 4.9489546,
        "lat": 49.8612272
    },
    {
        "zip": "5550",
        "city": "Laforet",
        "lng": 4.9294849,
        "lat": 49.8639125
    },
    {
        "zip": "5550",
        "city": "Membre",
        "lng": 4.9002694,
        "lat": 49.8655398
    },
    {
        "zip": "5550",
        "city": "Mouzaive",
        "lng": 4.9620137,
        "lat": 49.8534637
    },
    {
        "zip": "5550",
        "city": "Nafraiture",
        "lng": 4.9172157,
        "lat": 49.9106502
    },
    {
        "zip": "5550",
        "city": "Orchimont",
        "lng": 4.9274653,
        "lat": 49.8936753
    },
    {
        "zip": "5550",
        "city": "Pussemange",
        "lng": 4.8697858,
        "lat": 49.8110791
    },
    {
        "zip": "5550",
        "city": "Sugny",
        "lng": 4.9032847,
        "lat": 49.8140011
    },
    {
        "zip": "5550",
        "city": "Vresse-Sur-Semois",
        "lng": 4.9340633,
        "lat": 49.8729769
    },
    {
        "zip": "5555",
        "city": "Baillamont",
        "lng": 5.0267634,
        "lat": 49.8994469
    },
    {
        "zip": "5555",
        "city": "Bellefontaine",
        "lng": 4.973101,
        "lat": 49.9177
    },
    {
        "zip": "5555",
        "city": "Biévre",
        "lng": 5.0168496,
        "lat": 49.9411216
    },
    {
        "zip": "5555",
        "city": "Cornimont",
        "lng": 4.9979774,
        "lat": 49.8626123
    },
    {
        "zip": "5555",
        "city": "Graide",
        "lng": 5.0674675,
        "lat": 49.9514336
    },
    {
        "zip": "5555",
        "city": "Gros-Fays",
        "lng": 4.9830915,
        "lat": 49.869524
    },
    {
        "zip": "5555",
        "city": "Monceau-En-Ardenne",
        "lng": 4.9859857,
        "lat": 49.9046481
    },
    {
        "zip": "5555",
        "city": "Naomé",
        "lng": 5.0894297,
        "lat": 49.9226206
    },
    {
        "zip": "5555",
        "city": "Oizy",
        "lng": 5.0089274,
        "lat": 49.8934868
    },
    {
        "zip": "5555",
        "city": "Petit-Fays",
        "lng": 4.9675442,
        "lat": 49.9015726
    },
    {
        "zip": "5560",
        "city": "Ciergnon",
        "lng": 5.0899109,
        "lat": 50.1666464
    },
    {
        "zip": "5560",
        "city": "Finnevaux",
        "lng": 4.9452635,
        "lat": 50.1581671
    },
    {
        "zip": "5560",
        "city": "Houyet",
        "lng": 5.0051364,
        "lat": 50.1900646
    },
    {
        "zip": "5560",
        "city": "Hulsonniaux",
        "lng": 4.947825,
        "lat": 50.201583
    },
    {
        "zip": "5560",
        "city": "Mesnil-Eglise",
        "lng": 4.9616357,
        "lat": 50.1635695
    },
    {
        "zip": "5560",
        "city": "Mesnil-Saint-Blaise",
        "lng": 4.8854633,
        "lat": 50.1671763
    },
    {
        "zip": "5561",
        "city": "Celles",
        "lng": 5.0058339,
        "lat": 50.2312617
    },
    {
        "zip": "5562",
        "city": "Custinne",
        "lng": 5.0484175,
        "lat": 50.2117661
    },
    {
        "zip": "5563",
        "city": "Hour",
        "lng": 5.0372408,
        "lat": 50.1613066
    },
    {
        "zip": "5564",
        "city": "Wanlin",
        "lng": 5.061512,
        "lat": 50.1593381
    },
    {
        "zip": "5570",
        "city": "Baronville",
        "lng": 4.9468551,
        "lat": 50.1262206
    },
    {
        "zip": "5570",
        "city": "Beauraing",
        "lng": 4.9576144,
        "lat": 50.1105211
    },
    {
        "zip": "5570",
        "city": "Dion",
        "lng": 4.8883798,
        "lat": 50.1178162
    },
    {
        "zip": "5570",
        "city": "Felenne",
        "lng": 4.8490158,
        "lat": 50.0680742
    },
    {
        "zip": "5570",
        "city": "Feschaux",
        "lng": 4.910576,
        "lat": 50.15093
    },
    {
        "zip": "5570",
        "city": "Honnay",
        "lng": 5.0123245,
        "lat": 50.0666286
    },
    {
        "zip": "5570",
        "city": "Javingue",
        "lng": 4.9187569,
        "lat": 50.1018962
    },
    {
        "zip": "5570",
        "city": "Vonêche",
        "lng": 4.9800374,
        "lat": 50.0608665
    },
    {
        "zip": "5570",
        "city": "Wancennes",
        "lng": 4.961015,
        "lat": 50.0920374
    },
    {
        "zip": "5570",
        "city": "Winenne",
        "lng": 4.8930998,
        "lat": 50.0980233
    },
    {
        "zip": "5571",
        "city": "Wiesme",
        "lng": 4.9767431,
        "lat": 50.1487792
    },
    {
        "zip": "5572",
        "city": "Focant",
        "lng": 5.0408552,
        "lat": 50.1314538
    },
    {
        "zip": "5573",
        "city": "Martouzin-Neuville",
        "lng": 5.003949,
        "lat": 50.1176141
    },
    {
        "zip": "5574",
        "city": "Pondrôme",
        "lng": 5.008842,
        "lat": 50.099609
    },
    {
        "zip": "5575",
        "city": "Bourseigne-Neuve",
        "lng": 4.855026,
        "lat": 50.0250695
    },
    {
        "zip": "5575",
        "city": "Bourseigne-Vieille",
        "lng": 4.871198,
        "lat": 50.0221032
    },
    {
        "zip": "5575",
        "city": "Gedinne",
        "lng": 4.9388966,
        "lat": 49.9792667
    },
    {
        "zip": "5575",
        "city": "Houdremont",
        "lng": 4.9445943,
        "lat": 49.942041
    },
    {
        "zip": "5575",
        "city": "Louette-Saint-Denis",
        "lng": 4.9572488,
        "lat": 49.9589147
    },
    {
        "zip": "5575",
        "city": "Louette-Saint-Pierre",
        "lng": 4.9276767,
        "lat": 49.960145
    },
    {
        "zip": "5575",
        "city": "Malvoisin",
        "lng": 4.9609972,
        "lat": 50.0121497
    },
    {
        "zip": "5575",
        "city": "Patignies",
        "lng": 4.9521201,
        "lat": 49.9999798
    },
    {
        "zip": "5575",
        "city": "Rienne",
        "lng": 4.8847815,
        "lat": 49.9922547
    },
    {
        "zip": "5575",
        "city": "Sart-Custinne",
        "lng": 4.9175574,
        "lat": 50.0014538
    },
    {
        "zip": "5575",
        "city": "Vencimont",
        "lng": 4.9198406,
        "lat": 50.0323702
    },
    {
        "zip": "5575",
        "city": "Willerzie",
        "lng": 4.8475671,
        "lat": 49.9874142
    },
    {
        "zip": "5576",
        "city": "Froidfontaine",
        "lng": 5.000634,
        "lat": 50.0604028
    },
    {
        "zip": "5580",
        "city": "Ave-Et-Auffe",
        "lng": 5.1530857761777,
        "lat": 50.11195305
    },
    {
        "zip": "5580",
        "city": "Buissonville",
        "lng": 5.1958839,
        "lat": 50.2172183
    },
    {
        "zip": "5580",
        "city": "Eprave",
        "lng": 5.1713525249725,
        "lat": 50.1502721
    },
    {
        "zip": "5580",
        "city": "Han-Sur-Lesse",
        "lng": 5.1877385,
        "lat": 50.1254505
    },
    {
        "zip": "5580",
        "city": "Jemelle",
        "lng": 5.2624584,
        "lat": 50.1624278
    },
    {
        "zip": "5580",
        "city": "Lessive",
        "lng": 5.1458087816947,
        "lat": 50.13800485
    },
    {
        "zip": "5580",
        "city": "Mont-Gauthier",
        "lng": 5.120824,
        "lat": 50.2092771
    },
    {
        "zip": "5580",
        "city": "Rochefort",
        "lng": 5.2140311511356,
        "lat": 50.1725817
    },
    {
        "zip": "5580",
        "city": "Villers-Sur-Lesse",
        "lng": 5.1050287385834,
        "lat": 50.1442751
    },
    {
        "zip": "5580",
        "city": "Wavreille",
        "lng": 5.2419236738324,
        "lat": 50.11639205
    },
    {
        "zip": "5590",
        "city": "Achêne",
        "lng": 5.0458772,
        "lat": 50.2668644
    },
    {
        "zip": "5590",
        "city": "Braibant",
        "lng": 5.0629626,
        "lat": 50.3129076
    },
    {
        "zip": "5590",
        "city": "Chevetogne",
        "lng": 5.1188335,
        "lat": 50.223801
    },
    {
        "zip": "5590",
        "city": "Ciney",
        "lng": 5.0974251,
        "lat": 50.2949558
    },
    {
        "zip": "5590",
        "city": "Conneux",
        "lng": 5.0603857,
        "lat": 50.2496153
    },
    {
        "zip": "5590",
        "city": "Haversin",
        "lng": 5.208871,
        "lat": 50.2531051
    },
    {
        "zip": "5590",
        "city": "Leignon",
        "lng": 5.1102616,
        "lat": 50.2687357
    },
    {
        "zip": "5590",
        "city": "Pessoux",
        "lng": 5.1707753,
        "lat": 50.2825466
    },
    {
        "zip": "5590",
        "city": "Serinchamps",
        "lng": 5.2343634,
        "lat": 50.2319269
    },
    {
        "zip": "5590",
        "city": "Sovet",
        "lng": 5.0349178,
        "lat": 50.296303
    },
    {
        "zip": "5600",
        "city": "Fagnolle",
        "lng": 4.5703749,
        "lat": 50.1050216
    },
    {
        "zip": "5600",
        "city": "Franchimont",
        "lng": 4.6393972,
        "lat": 50.1900466
    },
    {
        "zip": "5600",
        "city": "Jamagne",
        "lng": 4.531286,
        "lat": 50.2180956
    },
    {
        "zip": "5600",
        "city": "Jamiolle",
        "lng": 4.5079027,
        "lat": 50.2122411
    },
    {
        "zip": "5600",
        "city": "Merlemont",
        "lng": 4.6072911,
        "lat": 50.1728667
    },
    {
        "zip": "5600",
        "city": "Neuville",
        "lng": 5.7242282,
        "lat": 50.3868446
    },
    {
        "zip": "5600",
        "city": "Omezée",
        "lng": 4.6995791,
        "lat": 50.1933288
    },
    {
        "zip": "5600",
        "city": "Philippeville",
        "lng": 4.5449033,
        "lat": 50.195588
    },
    {
        "zip": "5600",
        "city": "Roly",
        "lng": 4.5381501,
        "lat": 50.1356527
    },
    {
        "zip": "5600",
        "city": "Romedenne",
        "lng": 4.6979645,
        "lat": 50.1724056
    },
    {
        "zip": "5600",
        "city": "Samart",
        "lng": 4.534019,
        "lat": 50.17794
    },
    {
        "zip": "5600",
        "city": "Sart-En-Fagne",
        "lng": 4.6199447,
        "lat": 50.1579167
    },
    {
        "zip": "5600",
        "city": "Sautour",
        "lng": 4.5612425,
        "lat": 50.1694005
    },
    {
        "zip": "5600",
        "city": "Surice",
        "lng": 4.6962604,
        "lat": 50.1824646
    },
    {
        "zip": "5600",
        "city": "Villers-En-Fagne",
        "lng": 4.5846227,
        "lat": 50.1457902
    },
    {
        "zip": "5600",
        "city": "Villers-Le-Gambon",
        "lng": 4.6084845,
        "lat": 50.1904615
    },
    {
        "zip": "5600",
        "city": "Vodecée",
        "lng": 4.5920788,
        "lat": 50.1972001
    },
    {
        "zip": "5620",
        "city": "Corenne",
        "lng": 4.6792792,
        "lat": 50.2519501
    },
    {
        "zip": "5620",
        "city": "Flavion",
        "lng": 4.7134091,
        "lat": 50.2496186
    },
    {
        "zip": "5620",
        "city": "Florennes",
        "lng": 4.6030187,
        "lat": 50.2514227
    },
    {
        "zip": "5620",
        "city": "Hemptinne-Lez-Florennes",
        "lng": 4.5631279,
        "lat": 50.2282738
    },
    {
        "zip": "5620",
        "city": "Morville",
        "lng": 4.7440112,
        "lat": 50.2330003
    },
    {
        "zip": "5620",
        "city": "Rosée",
        "lng": 4.6876566,
        "lat": 50.2323028
    },
    {
        "zip": "5620",
        "city": "Saint-Aubin",
        "lng": 4.5778492,
        "lat": 50.2469253
    },
    {
        "zip": "5621",
        "city": "Hanzinelle",
        "lng": 4.5607099,
        "lat": 50.2949873
    },
    {
        "zip": "5621",
        "city": "Hanzinne",
        "lng": 4.5435524,
        "lat": 50.3084666
    },
    {
        "zip": "5621",
        "city": "Morialmé",
        "lng": 4.5634203,
        "lat": 50.2776442
    },
    {
        "zip": "5630",
        "city": "Cerfontaine",
        "lng": 4.4125932,
        "lat": 50.1687764
    },
    {
        "zip": "5630",
        "city": "Daussois",
        "lng": 4.455439,
        "lat": 50.218825
    },
    {
        "zip": "5630",
        "city": "Senzeille",
        "lng": 4.466208,
        "lat": 50.1762834
    },
    {
        "zip": "5630",
        "city": "Silenrieux",
        "lng": 4.4103561,
        "lat": 50.2251302
    },
    {
        "zip": "5630",
        "city": "Soumoy",
        "lng": 4.4366472,
        "lat": 50.1896906
    },
    {
        "zip": "5630",
        "city": "Villers-Deux-Eglises",
        "lng": 4.4827391,
        "lat": 50.189702
    },
    {
        "zip": "5640",
        "city": "Biesme",
        "lng": 4.6073303,
        "lat": 50.3346461
    },
    {
        "zip": "5640",
        "city": "Biesmerée",
        "lng": 4.6803521,
        "lat": 50.297376
    },
    {
        "zip": "5640",
        "city": "Graux",
        "lng": 4.7203188,
        "lat": 50.325597
    },
    {
        "zip": "5640",
        "city": "Mettet",
        "lng": 4.6584949,
        "lat": 50.3212094
    },
    {
        "zip": "5640",
        "city": "Oret",
        "lng": 4.615806,
        "lat": 50.3004056
    },
    {
        "zip": "5640",
        "city": "Saint-Gérard",
        "lng": 4.7395306,
        "lat": 50.3463414
    },
    {
        "zip": "5641",
        "city": "Furnaux",
        "lng": 4.7055271,
        "lat": 50.3080016
    },
    {
        "zip": "5644",
        "city": "Ermeton-Sur-Biert",
        "lng": 4.7197934,
        "lat": 50.2975439
    },
    {
        "zip": "5646",
        "city": "Stave",
        "lng": 4.6590441,
        "lat": 50.2828212
    },
    {
        "zip": "5650",
        "city": "Castillon",
        "lng": 4.3528279,
        "lat": 50.2472813
    },
    {
        "zip": "5650",
        "city": "Chastrés",
        "lng": 4.4592009,
        "lat": 50.2649096
    },
    {
        "zip": "5650",
        "city": "Clermont",
        "lng": 4.3173399,
        "lat": 50.2598853
    },
    {
        "zip": "5650",
        "city": "Fontenelle",
        "lng": 4.3827342,
        "lat": 50.2490122
    },
    {
        "zip": "5650",
        "city": "Fraire",
        "lng": 4.5081639,
        "lat": 50.2613846
    },
    {
        "zip": "5650",
        "city": "Pry",
        "lng": 4.4326804,
        "lat": 50.273814
    },
    {
        "zip": "5650",
        "city": "Vogenée",
        "lng": 4.4523592,
        "lat": 50.2398955
    },
    {
        "zip": "5650",
        "city": "Walcourt",
        "lng": 4.4347509,
        "lat": 50.2532022
    },
    {
        "zip": "5650",
        "city": "Yves-Gomezée",
        "lng": 4.4947935,
        "lat": 50.2392649
    },
    {
        "zip": "5651",
        "city": "Berzée",
        "lng": 4.4012206,
        "lat": 50.2892396
    },
    {
        "zip": "5651",
        "city": "Gourdinne",
        "lng": 4.456597,
        "lat": 50.2899588
    },
    {
        "zip": "5651",
        "city": "Laneffe",
        "lng": 4.4950207,
        "lat": 50.2778386
    },
    {
        "zip": "5651",
        "city": "Rognée",
        "lng": 4.3892146,
        "lat": 50.2691529
    },
    {
        "zip": "5651",
        "city": "Somzée",
        "lng": 4.4829777,
        "lat": 50.2951613
    },
    {
        "zip": "5651",
        "city": "Tarcienne",
        "lng": 4.4983015,
        "lat": 50.3119341
    },
    {
        "zip": "5651",
        "city": "Thy-Le-Château",
        "lng": 4.4278716,
        "lat": 50.2827004
    },
    {
        "zip": "5660",
        "city": "Aublain",
        "lng": 4.4090395,
        "lat": 50.0672297
    },
    {
        "zip": "5660",
        "city": "Boussu-En-Fagne",
        "lng": 4.4710191,
        "lat": 50.0754838
    },
    {
        "zip": "5660",
        "city": "Brûly",
        "lng": 4.5296813,
        "lat": 49.9695906
    },
    {
        "zip": "5660",
        "city": "Brûly-De-Pesche",
        "lng": 4.4602842,
        "lat": 50.0009202
    },
    {
        "zip": "5660",
        "city": "Couvin",
        "lng": 4.4955009,
        "lat": 50.0524906
    },
    {
        "zip": "5660",
        "city": "Cul-Des-Sarts",
        "lng": 4.4549981,
        "lat": 49.9615402
    },
    {
        "zip": "5660",
        "city": "Dailly",
        "lng": 4.4360525,
        "lat": 50.0577541
    },
    {
        "zip": "5660",
        "city": "Frasnes",
        "lng": 4.5105493,
        "lat": 50.0796144
    },
    {
        "zip": "5660",
        "city": "Gonrieux",
        "lng": 4.4236449,
        "lat": 50.0374018
    },
    {
        "zip": "5660",
        "city": "Mariembourg",
        "lng": 4.5233186,
        "lat": 50.0941307
    },
    {
        "zip": "5660",
        "city": "Pesche",
        "lng": 4.4584819,
        "lat": 50.0431267
    },
    {
        "zip": "5660",
        "city": "Petigny",
        "lng": 4.5324267,
        "lat": 50.0583821
    },
    {
        "zip": "5660",
        "city": "Petite-Chapelle",
        "lng": 4.505529,
        "lat": 49.949789
    },
    {
        "zip": "5660",
        "city": "Presgaux",
        "lng": 4.4206168,
        "lat": 50.0247898
    },
    {
        "zip": "5670",
        "city": "Dourbes",
        "lng": 4.59145,
        "lat": 50.091356
    },
    {
        "zip": "5670",
        "city": "Le Mesnil",
        "lng": 4.6712991,
        "lat": 50.030867
    },
    {
        "zip": "5670",
        "city": "Mazée",
        "lng": 4.6964875,
        "lat": 50.1008805
    },
    {
        "zip": "5670",
        "city": "Nismes",
        "lng": 4.5486173,
        "lat": 50.0745275
    },
    {
        "zip": "5670",
        "city": "Oignies-En-Thiérache",
        "lng": 4.6396988,
        "lat": 50.0237221
    },
    {
        "zip": "5670",
        "city": "Olloy-Sur-Viroin",
        "lng": 4.607218,
        "lat": 50.0726831
    },
    {
        "zip": "5670",
        "city": "Treignes",
        "lng": 4.6692715,
        "lat": 50.0922698
    },
    {
        "zip": "5670",
        "city": "Vierves-Sur-Viroin",
        "lng": 4.634249,
        "lat": 50.080269
    },
    {
        "zip": "5670",
        "city": "Viroinval",
        "lng": 4.6270287,
        "lat": 50.0516361
    },
    {
        "zip": "5680",
        "city": "Doische",
        "lng": 4.7458219,
        "lat": 50.1357152
    },
    {
        "zip": "5680",
        "city": "Gimnée",
        "lng": 4.7141334,
        "lat": 50.1315986
    },
    {
        "zip": "5680",
        "city": "Gochenée",
        "lng": 4.7597215,
        "lat": 50.1841874
    },
    {
        "zip": "5680",
        "city": "Matagne-La-Grande",
        "lng": 4.6236448,
        "lat": 50.1171293
    },
    {
        "zip": "5680",
        "city": "Matagne-La-Petite",
        "lng": 4.6468816,
        "lat": 50.1186008
    },
    {
        "zip": "5680",
        "city": "Niverlée",
        "lng": 4.7026857,
        "lat": 50.1177554
    },
    {
        "zip": "5680",
        "city": "Romerée",
        "lng": 4.6748777,
        "lat": 50.135223
    },
    {
        "zip": "5680",
        "city": "Soulme",
        "lng": 4.7367955,
        "lat": 50.1873423
    },
    {
        "zip": "5680",
        "city": "Vaucelles",
        "lng": 4.7424981,
        "lat": 50.1131712
    },
    {
        "zip": "5680",
        "city": "Vodelée",
        "lng": 4.7323157,
        "lat": 50.1702393
    },
    {
        "zip": "6000",
        "city": "Charleroi",
        "lng": 4.4491649999234,
        "lat": 50.41571025
    },
    {
        "zip": "6001",
        "city": "Marcinelle",
        "lng": 4.4442531,
        "lat": 50.3977245
    },
    {
        "zip": "6010",
        "city": "Couillet",
        "lng": 4.468631,
        "lat": 50.391219
    },
    {
        "zip": "6020",
        "city": "Dampremy",
        "lng": 4.4322464,
        "lat": 50.4186181
    },
    {
        "zip": "6030",
        "city": "Goutroux",
        "lng": 4.3611946147288,
        "lat": 50.41684425
    },
    {
        "zip": "6030",
        "city": "Marchienne-Au-Pont",
        "lng": 4.3960065,
        "lat": 50.4067102
    },
    {
        "zip": "6031",
        "city": "Monceau-Sur-Sambre",
        "lng": 4.382402,
        "lat": 50.4170168
    },
    {
        "zip": "6032",
        "city": "Mont-Sur-Marchienne",
        "lng": 4.4046365,
        "lat": 50.3902046
    },
    {
        "zip": "6040",
        "city": "Jumet",
        "lng": 4.4360232749854,
        "lat": 50.44466065
    },
    {
        "zip": "6041",
        "city": "Gosselies",
        "lng": 4.4301757,
        "lat": 50.4653846
    },
    {
        "zip": "6042",
        "city": "Lodelinsart",
        "lng": 4.4489421,
        "lat": 50.431853
    },
    {
        "zip": "6043",
        "city": "Ransart",
        "lng": 4.481573078145,
        "lat": 50.45615655
    },
    {
        "zip": "6044",
        "city": "Roux",
        "lng": 4.3907758274107,
        "lat": 50.4415002
    },
    {
        "zip": "6060",
        "city": "Gilly",
        "lng": 4.485649013438,
        "lat": 50.4269705
    },
    {
        "zip": "6061",
        "city": "Montignies-Sur-Sambre",
        "lng": 4.4729234863084,
        "lat": 50.4109216
    },
    {
        "zip": "6110",
        "city": "Montigny-Le-Tilleul",
        "lng": 4.3602314806535,
        "lat": 50.369072045553
    },
    {
        "zip": "6111",
        "city": "Landelies",
        "lng": 4.3496404,
        "lat": 50.377822
    },
    {
        "zip": "6120",
        "city": "Cour-Sur-Heure",
        "lng": 4.3868963,
        "lat": 50.2974701
    },
    {
        "zip": "6120",
        "city": "Ham-Sur-Heure",
        "lng": 4.3889214,
        "lat": 50.3219897
    },
    {
        "zip": "6120",
        "city": "Jamioulx",
        "lng": 4.4127635,
        "lat": 50.3528702
    },
    {
        "zip": "6120",
        "city": "Marbaix",
        "lng": 4.3713936,
        "lat": 50.3268072
    },
    {
        "zip": "6120",
        "city": "Nalinnes",
        "lng": 4.4466922,
        "lat": 50.3249486
    },
    {
        "zip": "6140",
        "city": "Fontaine-L'evêque",
        "lng": 4.3239637925238,
        "lat": 50.411224661019
    },
    {
        "zip": "6141",
        "city": "Forchies-La-Marche",
        "lng": 4.3198191,
        "lat": 50.4383794
    },
    {
        "zip": "6142",
        "city": "Leernes",
        "lng": 4.3307851,
        "lat": 50.3974398
    },
    {
        "zip": "6150",
        "city": "Anderlues",
        "lng": 4.2696102,
        "lat": 50.4079969
    },
    {
        "zip": "6180",
        "city": "Courcelles",
        "lng": 4.3763406,
        "lat": 50.4601576
    },
    {
        "zip": "6181",
        "city": "Gouy-Lez-Piéton",
        "lng": 4.3287132,
        "lat": 50.4875628
    },
    {
        "zip": "6182",
        "city": "Souvret",
        "lng": 4.350827,
        "lat": 50.450999
    },
    {
        "zip": "6183",
        "city": "Trazegnies",
        "lng": 4.3317415,
        "lat": 50.4665295
    },
    {
        "zip": "6200",
        "city": "Bouffioulx",
        "lng": 4.5151961,
        "lat": 50.390213
    },
    {
        "zip": "6200",
        "city": "Châtelet",
        "lng": 4.5243511,
        "lat": 50.4046075
    },
    {
        "zip": "6200",
        "city": "Châtelineau",
        "lng": 4.5179328,
        "lat": 50.4155938
    },
    {
        "zip": "6210",
        "city": "Frasnes-Lez-Gosselies",
        "lng": 4.4501525,
        "lat": 50.5376477
    },
    {
        "zip": "6210",
        "city": "Les Bons Villers",
        "lng": 4.4252588130793,
        "lat": 50.53495775
    },
    {
        "zip": "6210",
        "city": "Réves",
        "lng": 4.4054735658856,
        "lat": 50.543725
    },
    {
        "zip": "6210",
        "city": "Villers-Perwin",
        "lng": 4.4783773,
        "lat": 50.5262014
    },
    {
        "zip": "6210",
        "city": "Wayaux",
        "lng": 4.4723314,
        "lat": 50.4896309
    },
    {
        "zip": "6211",
        "city": "Mellet",
        "lng": 4.4782005997401,
        "lat": 50.50462075
    },
    {
        "zip": "6220",
        "city": "Fleurus",
        "lng": 4.5446545325944,
        "lat": 50.47194905
    },
    {
        "zip": "6220",
        "city": "Heppignies",
        "lng": 4.4935448,
        "lat": 50.4817059
    },
    {
        "zip": "6220",
        "city": "Lambusart",
        "lng": 4.5579198971642,
        "lat": 50.45472595
    },
    {
        "zip": "6220",
        "city": "Wangenies",
        "lng": 4.519112,
        "lat": 50.4776689
    },
    {
        "zip": "6221",
        "city": "Saint-Amand",
        "lng": 4.5319238043004,
        "lat": 50.5111679
    },
    {
        "zip": "6222",
        "city": "Brye",
        "lng": 4.561356496071,
        "lat": 50.53007005
    },
    {
        "zip": "6223",
        "city": "Wagnelée",
        "lng": 4.5273110200086,
        "lat": 50.52262195
    },
    {
        "zip": "6224",
        "city": "Wanfercée-Baulet",
        "lng": 4.5816274059151,
        "lat": 50.47584335
    },
    {
        "zip": "6230",
        "city": "Buzet",
        "lng": 4.3713097181263,
        "lat": 50.53893345
    },
    {
        "zip": "6230",
        "city": "Obaix",
        "lng": 4.3401322241149,
        "lat": 50.53439825
    },
    {
        "zip": "6230",
        "city": "Pont-à-Celles",
        "lng": 4.3622972938902,
        "lat": 50.5043242
    },
    {
        "zip": "6230",
        "city": "Thiméon",
        "lng": 4.4306784623925,
        "lat": 50.4842821
    },
    {
        "zip": "6230",
        "city": "Viesville",
        "lng": 4.4030299776693,
        "lat": 50.4906258
    },
    {
        "zip": "6238",
        "city": "Liberchies",
        "lng": 4.4243830944654,
        "lat": 50.5152429
    },
    {
        "zip": "6238",
        "city": "Luttre",
        "lng": 4.3888817117527,
        "lat": 50.51456265
    },
    {
        "zip": "6240",
        "city": "Farciennes",
        "lng": 4.553041,
        "lat": 50.4273228
    },
    {
        "zip": "6240",
        "city": "Pironchamps",
        "lng": 4.526056,
        "lat": 50.4285932
    },
    {
        "zip": "6250",
        "city": "Aiseau",
        "lng": 4.5870152,
        "lat": 50.4111232
    },
    {
        "zip": "6250",
        "city": "Aiseau-Presles",
        "lng": 4.584081,
        "lat": 50.4065228
    },
    {
        "zip": "6250",
        "city": "Pont-De-Loup",
        "lng": 4.5459892,
        "lat": 50.4173702
    },
    {
        "zip": "6250",
        "city": "Presles",
        "lng": 4.5786617,
        "lat": 50.3850088
    },
    {
        "zip": "6250",
        "city": "Roselies",
        "lng": 4.5728849,
        "lat": 50.4289142
    },
    {
        "zip": "6280",
        "city": "Acoz",
        "lng": 4.5323568,
        "lat": 50.3588065
    },
    {
        "zip": "6280",
        "city": "Gerpinnes",
        "lng": 4.5276771,
        "lat": 50.3378166
    },
    {
        "zip": "6280",
        "city": "Gougnies",
        "lng": 4.5771625,
        "lat": 50.3544437
    },
    {
        "zip": "6280",
        "city": "Joncret",
        "lng": 4.5125952,
        "lat": 50.3528064
    },
    {
        "zip": "6280",
        "city": "Loverval",
        "lng": 4.47342,
        "lat": 50.374997
    },
    {
        "zip": "6280",
        "city": "Villers-Poterie",
        "lng": 4.547498,
        "lat": 50.3513008
    },
    {
        "zip": "6440",
        "city": "Boussu-Lez-Walcourt",
        "lng": 4.3767743,
        "lat": 50.2262753
    },
    {
        "zip": "6440",
        "city": "Fourbechies",
        "lng": 4.3166667,
        "lat": 50.1666667
    },
    {
        "zip": "6440",
        "city": "Froidchapelle",
        "lng": 4.3269129,
        "lat": 50.1511597
    },
    {
        "zip": "6440",
        "city": "Vergnies",
        "lng": 4.3059105,
        "lat": 50.198614
    },
    {
        "zip": "6441",
        "city": "Erpion",
        "lng": 4.3512321,
        "lat": 50.2118893
    },
    {
        "zip": "6460",
        "city": "Bailiévre",
        "lng": 4.238617,
        "lat": 50.069276
    },
    {
        "zip": "6460",
        "city": "Chimay",
        "lng": 4.3173338,
        "lat": 50.0479266
    },
    {
        "zip": "6460",
        "city": "Robechies",
        "lng": 4.278378,
        "lat": 50.072802
    },
    {
        "zip": "6460",
        "city": "Saint-Remy",
        "lng": 4.309222,
        "lat": 50.050354
    },
    {
        "zip": "6460",
        "city": "Salles",
        "lng": 4.247403,
        "lat": 50.0560102
    },
    {
        "zip": "6460",
        "city": "Villers-La-Tour",
        "lng": 4.26287,
        "lat": 50.035369
    },
    {
        "zip": "6461",
        "city": "Virelles",
        "lng": 4.3329805,
        "lat": 50.0648008
    },
    {
        "zip": "6462",
        "city": "Vaulx-Lez-Chimay",
        "lng": 4.363579,
        "lat": 50.061113
    },
    {
        "zip": "6463",
        "city": "Lompret",
        "lng": 4.3790041,
        "lat": 50.0642146
    },
    {
        "zip": "6464",
        "city": "Baileux",
        "lng": 4.3755329,
        "lat": 50.0298544
    },
    {
        "zip": "6464",
        "city": "Bourlers",
        "lng": 4.341293,
        "lat": 50.027359
    },
    {
        "zip": "6464",
        "city": "Forges",
        "lng": 4.3211041,
        "lat": 50.022579
    },
    {
        "zip": "6464",
        "city": "L'escaillére",
        "lng": 4.431664,
        "lat": 49.947246
    },
    {
        "zip": "6464",
        "city": "Riézes",
        "lng": 4.368311,
        "lat": 49.960822
    },
    {
        "zip": "6470",
        "city": "Grandrieu",
        "lng": 4.1714631,
        "lat": 50.200517
    },
    {
        "zip": "6470",
        "city": "Montbliart",
        "lng": 4.224365,
        "lat": 50.1344457
    },
    {
        "zip": "6470",
        "city": "Rance",
        "lng": 4.2731851,
        "lat": 50.1430111
    },
    {
        "zip": "6470",
        "city": "Sautin",
        "lng": 4.226143,
        "lat": 50.163816
    },
    {
        "zip": "6470",
        "city": "Sivry",
        "lng": 4.1792797,
        "lat": 50.1678978
    },
    {
        "zip": "6470",
        "city": "Sivry-Rance",
        "lng": 4.1899982,
        "lat": 50.1640023
    },
    {
        "zip": "6500",
        "city": "Barbençon",
        "lng": 4.2859259,
        "lat": 50.2206905
    },
    {
        "zip": "6500",
        "city": "Beaumont",
        "lng": 4.2383115,
        "lat": 50.2355294
    },
    {
        "zip": "6500",
        "city": "Leugnies",
        "lng": 4.196155,
        "lat": 50.224642
    },
    {
        "zip": "6500",
        "city": "Leval-Chaudeville",
        "lng": 4.2289677,
        "lat": 50.2349363
    },
    {
        "zip": "6500",
        "city": "Renlies",
        "lng": 4.2670432,
        "lat": 50.189927
    },
    {
        "zip": "6500",
        "city": "Solre-Saint-Géry",
        "lng": 4.246304,
        "lat": 50.216245
    },
    {
        "zip": "6500",
        "city": "Thirimont",
        "lng": 4.2364418,
        "lat": 50.2608137
    },
    {
        "zip": "6511",
        "city": "Strée",
        "lng": 4.2980462,
        "lat": 50.2766332
    },
    {
        "zip": "6530",
        "city": "Leers-Et-Fosteau",
        "lng": 4.244767,
        "lat": 50.3041589
    },
    {
        "zip": "6530",
        "city": "Thuin",
        "lng": 4.2870475,
        "lat": 50.3397445
    },
    {
        "zip": "6531",
        "city": "Biesme-Sous-Thuin",
        "lng": 4.3079897,
        "lat": 50.320724
    },
    {
        "zip": "6532",
        "city": "Ragnies",
        "lng": 4.2837674,
        "lat": 50.3081977
    },
    {
        "zip": "6533",
        "city": "Biercée",
        "lng": 4.2591396,
        "lat": 50.3250862
    },
    {
        "zip": "6534",
        "city": "Gozée",
        "lng": 4.3515595,
        "lat": 50.3332279
    },
    {
        "zip": "6536",
        "city": "Donstiennes",
        "lng": 4.3119991,
        "lat": 50.2854167
    },
    {
        "zip": "6536",
        "city": "Thuillies",
        "lng": 4.329007,
        "lat": 50.2947304
    },
    {
        "zip": "6540",
        "city": "Lobbes",
        "lng": 4.2654874,
        "lat": 50.3460194
    },
    {
        "zip": "6540",
        "city": "Mont-Sainte-Geneviéve",
        "lng": 4.2377392,
        "lat": 50.3766211
    },
    {
        "zip": "6542",
        "city": "Sars-La-Buissiére",
        "lng": 4.209803,
        "lat": 50.3352549
    },
    {
        "zip": "6543",
        "city": "Bienne-Lez-Happart",
        "lng": 4.2155807,
        "lat": 50.3512087
    },
    {
        "zip": "6560",
        "city": "Bersillies-L'abbaye",
        "lng": 4.1520931,
        "lat": 50.2631919
    },
    {
        "zip": "6560",
        "city": "Erquelinnes",
        "lng": 4.1241436,
        "lat": 50.3102184
    },
    {
        "zip": "6560",
        "city": "Grand-Reng",
        "lng": 4.0705909,
        "lat": 50.3287642
    },
    {
        "zip": "6560",
        "city": "Hantes-Wihéries",
        "lng": 4.1783639,
        "lat": 50.3057605
    },
    {
        "zip": "6560",
        "city": "Montignies-Saint-Christophe",
        "lng": 4.1884975,
        "lat": 50.2815734
    },
    {
        "zip": "6560",
        "city": "Solre-Sur-Sambre",
        "lng": 4.1558267,
        "lat": 50.3067709
    },
    {
        "zip": "6567",
        "city": "Fontaine-Valmont",
        "lng": 4.2137302,
        "lat": 50.3208131
    },
    {
        "zip": "6567",
        "city": "Labuissiére",
        "lng": 4.194796,
        "lat": 50.3141612
    },
    {
        "zip": "6567",
        "city": "Merbes-Le-Château",
        "lng": 4.1644816,
        "lat": 50.3234035
    },
    {
        "zip": "6567",
        "city": "Merbes-Sainte-Marie",
        "lng": 4.1711042,
        "lat": 50.3552041
    },
    {
        "zip": "6590",
        "city": "Momignies",
        "lng": 4.1649981,
        "lat": 50.0286317
    },
    {
        "zip": "6591",
        "city": "Macon",
        "lng": 4.2096331,
        "lat": 50.052122
    },
    {
        "zip": "6592",
        "city": "Monceau-Imbrechies",
        "lng": 4.224118,
        "lat": 50.036452
    },
    {
        "zip": "6593",
        "city": "Macquenoise",
        "lng": 4.1802891,
        "lat": 49.9755399
    },
    {
        "zip": "6594",
        "city": "Beauwelz",
        "lng": 4.156972,
        "lat": 50.015365
    },
    {
        "zip": "6596",
        "city": "Forge-Philippe",
        "lng": 4.2520681,
        "lat": 49.96543
    },
    {
        "zip": "6596",
        "city": "Seloignes",
        "lng": 4.255615,
        "lat": 50.016087
    },
    {
        "zip": "6600",
        "city": "Bastogne",
        "lng": 5.7153203,
        "lat": 50.0009951
    },
    {
        "zip": "6600",
        "city": "Longvilly",
        "lng": 5.8368151,
        "lat": 50.0249524
    },
    {
        "zip": "6600",
        "city": "Noville",
        "lng": 5.7616864,
        "lat": 50.0651684
    },
    {
        "zip": "6600",
        "city": "Villers-La-Bonne-Eau",
        "lng": 5.7477792,
        "lat": 49.9345566
    },
    {
        "zip": "6600",
        "city": "Wardin",
        "lng": 5.7857948,
        "lat": 49.9902268
    },
    {
        "zip": "6630",
        "city": "Martelange",
        "lng": 5.7376366,
        "lat": 49.8313323
    },
    {
        "zip": "6637",
        "city": "Fauvillers",
        "lng": 5.665031,
        "lat": 49.8509306
    },
    {
        "zip": "6637",
        "city": "Hollange",
        "lng": 5.6881541,
        "lat": 49.9064531
    },
    {
        "zip": "6637",
        "city": "Tintange",
        "lng": 5.7518438,
        "lat": 49.878638
    },
    {
        "zip": "6640",
        "city": "Hompré",
        "lng": 5.6866728,
        "lat": 49.9437838
    },
    {
        "zip": "6640",
        "city": "Morhet",
        "lng": 5.5824265,
        "lat": 49.957603
    },
    {
        "zip": "6640",
        "city": "Nives",
        "lng": 5.602746,
        "lat": 49.9121387
    },
    {
        "zip": "6640",
        "city": "Sibret",
        "lng": 5.6352461,
        "lat": 49.968275
    },
    {
        "zip": "6640",
        "city": "Vaux-Lez-Rosiéres",
        "lng": 5.5705339,
        "lat": 49.9133632
    },
    {
        "zip": "6640",
        "city": "Vaux-Sur-Sûre",
        "lng": 5.5908285596978,
        "lat": 49.908755829225
    },
    {
        "zip": "6642",
        "city": "Juseret",
        "lng": 5.5493342,
        "lat": 49.8805193
    },
    {
        "zip": "6660",
        "city": "Houffalize",
        "lng": 5.7886928,
        "lat": 50.1323761
    },
    {
        "zip": "6660",
        "city": "Nadrin",
        "lng": 5.6807442,
        "lat": 50.1611829
    },
    {
        "zip": "6661",
        "city": "Mont",
        "lng": 5.7909538,
        "lat": 50.5341607
    },
    {
        "zip": "6661",
        "city": "Tailles",
        "lng": 5.7447558,
        "lat": 50.2272012
    },
    {
        "zip": "6662",
        "city": "Tavigny",
        "lng": 5.837773,
        "lat": 50.1081487
    },
    {
        "zip": "6663",
        "city": "Mabompré",
        "lng": 5.7384527,
        "lat": 50.098929
    },
    {
        "zip": "6666",
        "city": "Wibrin",
        "lng": 5.7156009,
        "lat": 50.1661182
    },
    {
        "zip": "6670",
        "city": "Gouvy",
        "lng": 5.9549305,
        "lat": 50.1907895
    },
    {
        "zip": "6670",
        "city": "Limerlé",
        "lng": 5.9393768987183,
        "lat": 50.1608553
    },
    {
        "zip": "6671",
        "city": "Bovigny",
        "lng": 5.9182421,
        "lat": 50.2244199
    },
    {
        "zip": "6672",
        "city": "Beho",
        "lng": 5.9972692,
        "lat": 50.220365
    },
    {
        "zip": "6673",
        "city": "Cherain",
        "lng": 5.8646609,
        "lat": 50.1806741
    },
    {
        "zip": "6674",
        "city": "Montleban",
        "lng": 5.8288862,
        "lat": 50.1876021
    },
    {
        "zip": "6680",
        "city": "Amberloup",
        "lng": 5.5272931,
        "lat": 50.0292955
    },
    {
        "zip": "6680",
        "city": "Sainte-Ode",
        "lng": 5.5245094,
        "lat": 50.0362888
    },
    {
        "zip": "6680",
        "city": "Tillet",
        "lng": 5.5297763,
        "lat": 50.0097939
    },
    {
        "zip": "6681",
        "city": "Lavacherie",
        "lng": 5.5136455,
        "lat": 50.052317
    },
    {
        "zip": "6686",
        "city": "Flamierge",
        "lng": 5.6038758,
        "lat": 50.0333519
    },
    {
        "zip": "6687",
        "city": "Bertogne",
        "lng": 5.6675577,
        "lat": 50.0842908
    },
    {
        "zip": "6688",
        "city": "Longchamps",
        "lng": 5.6892466,
        "lat": 50.0535428
    },
    {
        "zip": "6690",
        "city": "Bihain",
        "lng": 5.8082072,
        "lat": 50.2390245
    },
    {
        "zip": "6690",
        "city": "Vielsalm",
        "lng": 5.9170187,
        "lat": 50.2883685
    },
    {
        "zip": "6692",
        "city": "Petit-Thier",
        "lng": 5.9672766,
        "lat": 50.3074461
    },
    {
        "zip": "6698",
        "city": "Grand-Halleux",
        "lng": 5.9072517,
        "lat": 50.3262753
    },
    {
        "zip": "6700",
        "city": "Arlon",
        "lng": 5.811919,
        "lat": 49.6827625
    },
    {
        "zip": "6700",
        "city": "Bonnert",
        "lng": 5.8190143,
        "lat": 49.7107729
    },
    {
        "zip": "6700",
        "city": "Heinsch",
        "lng": 5.7464566,
        "lat": 49.7007526
    },
    {
        "zip": "6700",
        "city": "Toernich",
        "lng": 5.7795299,
        "lat": 49.6512359
    },
    {
        "zip": "6704",
        "city": "Guirsch",
        "lng": 5.853381,
        "lat": 49.7193575
    },
    {
        "zip": "6706",
        "city": "Autelbas",
        "lng": 5.8675666,
        "lat": 49.6499559
    },
    {
        "zip": "6717",
        "city": "Attert",
        "lng": 5.7872217,
        "lat": 49.7514982
    },
    {
        "zip": "6717",
        "city": "Nobressart",
        "lng": 5.7187077,
        "lat": 49.7399333
    },
    {
        "zip": "6717",
        "city": "Nothomb",
        "lng": 5.7861375,
        "lat": 49.7717529
    },
    {
        "zip": "6717",
        "city": "Thiaumont",
        "lng": 5.7291343,
        "lat": 49.7159959
    },
    {
        "zip": "6717",
        "city": "Tontelange",
        "lng": 5.8099377,
        "lat": 49.7266352
    },
    {
        "zip": "6720",
        "city": "Habay",
        "lng": 5.5932514325174,
        "lat": 49.73558035
    },
    {
        "zip": "6720",
        "city": "Habay-La-Neuve",
        "lng": 5.6503942,
        "lat": 49.7286061
    },
    {
        "zip": "6720",
        "city": "Hachy",
        "lng": 5.6805003,
        "lat": 49.7015507
    },
    {
        "zip": "6721",
        "city": "Anlier",
        "lng": 5.620414,
        "lat": 49.772082
    },
    {
        "zip": "6723",
        "city": "Habay-La-Vieille",
        "lng": 5.6225107,
        "lat": 49.7228424
    },
    {
        "zip": "6724",
        "city": "Houdemont",
        "lng": 5.5843337,
        "lat": 49.7189016
    },
    {
        "zip": "6724",
        "city": "Rulles",
        "lng": 5.558116,
        "lat": 49.7181041
    },
    {
        "zip": "6730",
        "city": "Bellefontaine",
        "lng": 5.4979103,
        "lat": 49.6649541
    },
    {
        "zip": "6730",
        "city": "Rossignol",
        "lng": 5.4851582,
        "lat": 49.718767
    },
    {
        "zip": "6730",
        "city": "Saint-Vincent",
        "lng": 5.475602,
        "lat": 49.6774116
    },
    {
        "zip": "6730",
        "city": "Tintigny",
        "lng": 5.5185152,
        "lat": 49.6820986
    },
    {
        "zip": "6740",
        "city": "Etalle",
        "lng": 5.5982415,
        "lat": 49.6729527
    },
    {
        "zip": "6740",
        "city": "Sainte-Marie-Sur-Semois",
        "lng": 5.5644887,
        "lat": 49.6732168
    },
    {
        "zip": "6740",
        "city": "Villers-Sur-Semois",
        "lng": 5.5614663,
        "lat": 49.6987456
    },
    {
        "zip": "6741",
        "city": "Vance",
        "lng": 5.6698138,
        "lat": 49.6701655
    },
    {
        "zip": "6742",
        "city": "Chantemelle",
        "lng": 5.6551334,
        "lat": 49.6535106
    },
    {
        "zip": "6743",
        "city": "Buzenol",
        "lng": 5.5942184,
        "lat": 49.6478969
    },
    {
        "zip": "6747",
        "city": "Châtillon",
        "lng": 5.6983633,
        "lat": 49.6255848
    },
    {
        "zip": "6747",
        "city": "Meix-Le-Tige",
        "lng": 5.7187012,
        "lat": 49.6151951
    },
    {
        "zip": "6747",
        "city": "Saint-Léger",
        "lng": 5.6569569,
        "lat": 49.6134362
    },
    {
        "zip": "6750",
        "city": "Musson",
        "lng": 5.705881,
        "lat": 49.5580794
    },
    {
        "zip": "6750",
        "city": "Mussy-La-Ville",
        "lng": 5.6617004,
        "lat": 49.5714143
    },
    {
        "zip": "6750",
        "city": "Signeulx",
        "lng": 5.6403955,
        "lat": 49.5528606
    },
    {
        "zip": "6760",
        "city": "Bleid",
        "lng": 5.6272894,
        "lat": 49.5680419
    },
    {
        "zip": "6760",
        "city": "Ethe",
        "lng": 5.5766897,
        "lat": 49.5814209
    },
    {
        "zip": "6760",
        "city": "Ruette",
        "lng": 5.5937884,
        "lat": 49.5376723
    },
    {
        "zip": "6760",
        "city": "Virton",
        "lng": 5.5329559,
        "lat": 49.5677263
    },
    {
        "zip": "6761",
        "city": "Latour",
        "lng": 5.5704642,
        "lat": 49.5581925
    },
    {
        "zip": "6762",
        "city": "Saint-Mard",
        "lng": 5.5284511,
        "lat": 49.5577562
    },
    {
        "zip": "6767",
        "city": "Dampicourt",
        "lng": 5.4979579,
        "lat": 49.5553011
    },
    {
        "zip": "6767",
        "city": "Harnoncourt",
        "lng": 5.4996797,
        "lat": 49.534288
    },
    {
        "zip": "6767",
        "city": "Lamorteau",
        "lng": 5.4795124,
        "lat": 49.5262083
    },
    {
        "zip": "6767",
        "city": "Rouvroy",
        "lng": 5.48971,
        "lat": 49.539075
    },
    {
        "zip": "6767",
        "city": "Torgny",
        "lng": 5.4735035,
        "lat": 49.5085018
    },
    {
        "zip": "6769",
        "city": "Gérouville",
        "lng": 5.4287902,
        "lat": 49.6184295
    },
    {
        "zip": "6769",
        "city": "Meix-Devant-Virton",
        "lng": 5.4816156,
        "lat": 49.6055933
    },
    {
        "zip": "6769",
        "city": "Robelmont",
        "lng": 5.5071291,
        "lat": 49.595344
    },
    {
        "zip": "6769",
        "city": "Sommethonne",
        "lng": 5.4456876,
        "lat": 49.5764582
    },
    {
        "zip": "6769",
        "city": "Villers-La-Loue",
        "lng": 5.481043,
        "lat": 49.5749479
    },
    {
        "zip": "6780",
        "city": "Hondelange",
        "lng": 5.8350151,
        "lat": 49.6351714
    },
    {
        "zip": "6780",
        "city": "Messancy",
        "lng": 5.8169286,
        "lat": 49.5969055
    },
    {
        "zip": "6780",
        "city": "Wolkrange",
        "lng": 5.7983045,
        "lat": 49.6350021
    },
    {
        "zip": "6781",
        "city": "Sélange",
        "lng": 5.848409,
        "lat": 49.6081105
    },
    {
        "zip": "6782",
        "city": "Habergy",
        "lng": 5.7608307,
        "lat": 49.6153377
    },
    {
        "zip": "6790",
        "city": "Aubange",
        "lng": 5.804352,
        "lat": 49.5675296
    },
    {
        "zip": "6791",
        "city": "Athus",
        "lng": 5.8317661,
        "lat": 49.5642065
    },
    {
        "zip": "6792",
        "city": "Halanzy",
        "lng": 5.7422467,
        "lat": 49.5590364
    },
    {
        "zip": "6792",
        "city": "Rachecourt",
        "lng": 5.7248143,
        "lat": 49.5903125
    },
    {
        "zip": "6800",
        "city": "Bras",
        "lng": 5.3877654,
        "lat": 49.9760182
    },
    {
        "zip": "6800",
        "city": "Freux",
        "lng": 5.4497307,
        "lat": 49.9708428
    },
    {
        "zip": "6800",
        "city": "Libramont-Chevigny",
        "lng": 5.3823537,
        "lat": 49.9197154
    },
    {
        "zip": "6800",
        "city": "Moircy",
        "lng": 5.468077,
        "lat": 49.9894846
    },
    {
        "zip": "6800",
        "city": "Recogne",
        "lng": 5.3607943,
        "lat": 49.9112853
    },
    {
        "zip": "6800",
        "city": "Remagne",
        "lng": 5.494626,
        "lat": 49.9764499
    },
    {
        "zip": "6800",
        "city": "Saint-Pierre",
        "lng": 5.386433,
        "lat": 49.9047868
    },
    {
        "zip": "6800",
        "city": "Sainte-Marie-Chevigny",
        "lng": 5.4578106,
        "lat": 49.9235741
    },
    {
        "zip": "6810",
        "city": "Chiny",
        "lng": 5.3394252,
        "lat": 49.738581
    },
    {
        "zip": "6810",
        "city": "Izel",
        "lng": 5.3747807,
        "lat": 49.693986
    },
    {
        "zip": "6810",
        "city": "Jamoigne",
        "lng": 5.420477,
        "lat": 49.6958089
    },
    {
        "zip": "6811",
        "city": "Les Bulles",
        "lng": 5.4263273,
        "lat": 49.7031011
    },
    {
        "zip": "6812",
        "city": "Suxy",
        "lng": 5.4005563,
        "lat": 49.7626877
    },
    {
        "zip": "6813",
        "city": "Termes",
        "lng": 5.4579753,
        "lat": 49.7071328
    },
    {
        "zip": "6820",
        "city": "Florenville",
        "lng": 5.3100518,
        "lat": 49.6993182
    },
    {
        "zip": "6820",
        "city": "Fontenoille",
        "lng": 5.234469,
        "lat": 49.71508
    },
    {
        "zip": "6820",
        "city": "Muno",
        "lng": 5.1759524,
        "lat": 49.717679
    },
    {
        "zip": "6820",
        "city": "Sainte-Cécile",
        "lng": 5.2429556,
        "lat": 49.7293745
    },
    {
        "zip": "6821",
        "city": "Lacuisine",
        "lng": 5.318262,
        "lat": 49.7170106
    },
    {
        "zip": "6823",
        "city": "Villers-Devant-Orval",
        "lng": 5.331466,
        "lat": 49.6185771
    },
    {
        "zip": "6824",
        "city": "Chassepierre",
        "lng": 5.2621817,
        "lat": 49.7076905
    },
    {
        "zip": "6830",
        "city": "Bouillon",
        "lng": 5.0672528,
        "lat": 49.7949836
    },
    {
        "zip": "6830",
        "city": "Les Hayons",
        "lng": 5.1456628,
        "lat": 49.8133379
    },
    {
        "zip": "6830",
        "city": "Poupehan",
        "lng": 5.0035785,
        "lat": 49.8115586
    },
    {
        "zip": "6830",
        "city": "Rochehaut",
        "lng": 5.0070138,
        "lat": 49.8404522
    },
    {
        "zip": "6831",
        "city": "Noirfontaine",
        "lng": 5.412251,
        "lat": 50.6175774
    },
    {
        "zip": "6832",
        "city": "Sensenruth",
        "lng": 5.0738804,
        "lat": 49.8254037
    },
    {
        "zip": "6833",
        "city": "Ucimont",
        "lng": 5.0573896,
        "lat": 49.8314004
    },
    {
        "zip": "6833",
        "city": "Vivy",
        "lng": 5.0388784,
        "lat": 49.8675973
    },
    {
        "zip": "6834",
        "city": "Bellevaux",
        "lng": 6.0129575,
        "lat": 50.3912772
    },
    {
        "zip": "6836",
        "city": "Dohan",
        "lng": 5.1418578,
        "lat": 49.7973741
    },
    {
        "zip": "6838",
        "city": "Corbion",
        "lng": 5.0065337,
        "lat": 49.7976297
    },
    {
        "zip": "6840",
        "city": "Grandvoir",
        "lng": 5.3717959,
        "lat": 49.8576034
    },
    {
        "zip": "6840",
        "city": "Grapfontaine",
        "lng": 5.4076005,
        "lat": 49.8215033
    },
    {
        "zip": "6840",
        "city": "Hamipré",
        "lng": 5.4564462,
        "lat": 49.8359615
    },
    {
        "zip": "6840",
        "city": "Longlier",
        "lng": 5.4609005,
        "lat": 49.8562222
    },
    {
        "zip": "6840",
        "city": "Neufchâteau",
        "lng": 5.4339147,
        "lat": 49.8411765
    },
    {
        "zip": "6840",
        "city": "Tournay",
        "lng": 5.3969928,
        "lat": 49.8543387
    },
    {
        "zip": "6850",
        "city": "Carlsbourg",
        "lng": 5.0830529,
        "lat": 49.8947611
    },
    {
        "zip": "6850",
        "city": "Offagne",
        "lng": 5.175013,
        "lat": 49.8855714
    },
    {
        "zip": "6850",
        "city": "Paliseul",
        "lng": 5.1349041,
        "lat": 49.9035269
    },
    {
        "zip": "6851",
        "city": "Nollevaux",
        "lng": 5.1220764,
        "lat": 49.8710591
    },
    {
        "zip": "6852",
        "city": "Maissin",
        "lng": 5.1805775,
        "lat": 49.9651106
    },
    {
        "zip": "6852",
        "city": "Opont",
        "lng": 5.1201252,
        "lat": 49.9348514
    },
    {
        "zip": "6853",
        "city": "Framont",
        "lng": 5.1601932,
        "lat": 49.9185629
    },
    {
        "zip": "6856",
        "city": "Fays-Les-Veneurs",
        "lng": 5.1607144,
        "lat": 49.8662058
    },
    {
        "zip": "6860",
        "city": "Assenois",
        "lng": 5.4625426,
        "lat": 49.8029977
    },
    {
        "zip": "6860",
        "city": "Ebly",
        "lng": 5.534876707362,
        "lat": 49.8508818
    },
    {
        "zip": "6860",
        "city": "Léglise",
        "lng": 5.5381496,
        "lat": 49.8003582
    },
    {
        "zip": "6860",
        "city": "Mellier",
        "lng": 5.5209461,
        "lat": 49.7671531
    },
    {
        "zip": "6860",
        "city": "Witry",
        "lng": 5.6138516,
        "lat": 49.8589203
    },
    {
        "zip": "6870",
        "city": "Arville",
        "lng": 5.3166577,
        "lat": 50.0323986
    },
    {
        "zip": "6870",
        "city": "Awenne",
        "lng": 5.306188,
        "lat": 50.074242
    },
    {
        "zip": "6870",
        "city": "Hatrival",
        "lng": 5.348119,
        "lat": 50.0052874
    },
    {
        "zip": "6870",
        "city": "Mirwart",
        "lng": 5.2653304,
        "lat": 50.0569945
    },
    {
        "zip": "6870",
        "city": "Saint-Hubert",
        "lng": 5.3738561,
        "lat": 50.0251428
    },
    {
        "zip": "6870",
        "city": "Vesqueville",
        "lng": 5.3968709,
        "lat": 50.0058391
    },
    {
        "zip": "6880",
        "city": "Auby-Sur-Semois",
        "lng": 5.1823728,
        "lat": 49.8157173
    },
    {
        "zip": "6880",
        "city": "Bertrix",
        "lng": 5.2529266,
        "lat": 49.8546564
    },
    {
        "zip": "6880",
        "city": "Cugnon",
        "lng": 5.2033082,
        "lat": 49.8020427
    },
    {
        "zip": "6880",
        "city": "Jehonville",
        "lng": 5.2022069,
        "lat": 49.9065515
    },
    {
        "zip": "6880",
        "city": "Orgeo",
        "lng": 5.3022804,
        "lat": 49.8343079
    },
    {
        "zip": "6887",
        "city": "Herbeumont",
        "lng": 5.2377736,
        "lat": 49.7815364
    },
    {
        "zip": "6887",
        "city": "Saint-Médard",
        "lng": 5.3277128,
        "lat": 49.8158829
    },
    {
        "zip": "6887",
        "city": "Straimont",
        "lng": 5.3811714,
        "lat": 49.7961667
    },
    {
        "zip": "6890",
        "city": "Anloy",
        "lng": 5.2215609,
        "lat": 49.9499043
    },
    {
        "zip": "6890",
        "city": "Libin",
        "lng": 5.2569017,
        "lat": 49.9809464
    },
    {
        "zip": "6890",
        "city": "Ochamps",
        "lng": 5.2781878,
        "lat": 49.9244612
    },
    {
        "zip": "6890",
        "city": "Redu",
        "lng": 5.1601828,
        "lat": 50.0077873
    },
    {
        "zip": "6890",
        "city": "Smuid",
        "lng": 5.2662483,
        "lat": 50.0188027
    },
    {
        "zip": "6890",
        "city": "Transinne",
        "lng": 5.2026639,
        "lat": 50.0004466
    },
    {
        "zip": "6890",
        "city": "Villance",
        "lng": 5.2221196,
        "lat": 49.9709626
    },
    {
        "zip": "6900",
        "city": "Aye",
        "lng": 5.2931158,
        "lat": 50.2232964
    },
    {
        "zip": "6900",
        "city": "Hargimont",
        "lng": 5.3094652,
        "lat": 50.1825868
    },
    {
        "zip": "6900",
        "city": "Humain",
        "lng": 5.2552435,
        "lat": 50.2053202
    },
    {
        "zip": "6900",
        "city": "Marche-En-Famenne",
        "lng": 5.3428796,
        "lat": 50.2240814
    },
    {
        "zip": "6900",
        "city": "On",
        "lng": 5.2862777,
        "lat": 50.1715967
    },
    {
        "zip": "6900",
        "city": "Roy",
        "lng": 5.4095929,
        "lat": 50.1879034
    },
    {
        "zip": "6900",
        "city": "Waha",
        "lng": 5.3441742,
        "lat": 50.2119666
    },
    {
        "zip": "6920",
        "city": "Sohier",
        "lng": 5.0702515,
        "lat": 50.0685885
    },
    {
        "zip": "6920",
        "city": "Wellin",
        "lng": 5.1146708,
        "lat": 50.0822967
    },
    {
        "zip": "6921",
        "city": "Chanly",
        "lng": 5.1559991,
        "lat": 50.0786533
    },
    {
        "zip": "6922",
        "city": "Halma",
        "lng": 5.1374206,
        "lat": 50.0754135
    },
    {
        "zip": "6924",
        "city": "Lomprez",
        "lng": 5.092143,
        "lat": 50.0762474
    },
    {
        "zip": "6927",
        "city": "Bure",
        "lng": 5.261162,
        "lat": 50.0885262
    },
    {
        "zip": "6927",
        "city": "Grupont",
        "lng": 5.2798077,
        "lat": 50.0923432
    },
    {
        "zip": "6927",
        "city": "Resteigne",
        "lng": 5.1778089,
        "lat": 50.0828516
    },
    {
        "zip": "6927",
        "city": "Tellin",
        "lng": 5.216837,
        "lat": 50.081019
    },
    {
        "zip": "6929",
        "city": "Daverdisse",
        "lng": 5.1184644,
        "lat": 50.0217646
    },
    {
        "zip": "6929",
        "city": "Gembes",
        "lng": 5.063233,
        "lat": 49.997198
    },
    {
        "zip": "6929",
        "city": "Haut-Fays",
        "lng": 5.016947,
        "lat": 50.0017421
    },
    {
        "zip": "6929",
        "city": "Porcheresse",
        "lng": 5.0866787,
        "lat": 49.978801
    },
    {
        "zip": "6940",
        "city": "Durbuy",
        "lng": 5.456248,
        "lat": 50.3524116
    },
    {
        "zip": "6940",
        "city": "Grandhan",
        "lng": 5.4086678,
        "lat": 50.3299795
    },
    {
        "zip": "6940",
        "city": "Septon",
        "lng": 5.423028,
        "lat": 50.3570338
    },
    {
        "zip": "6940",
        "city": "Wéris",
        "lng": 5.5307553,
        "lat": 50.3266395
    },
    {
        "zip": "6941",
        "city": "Bende",
        "lng": 5.4142387,
        "lat": 50.4176261
    },
    {
        "zip": "6941",
        "city": "Bomal-Sur-Ourthe",
        "lng": 5.5223752,
        "lat": 50.3760087
    },
    {
        "zip": "6941",
        "city": "Borlon",
        "lng": 5.4048529,
        "lat": 50.3783963
    },
    {
        "zip": "6941",
        "city": "Heyd",
        "lng": 5.562158,
        "lat": 50.346445
    },
    {
        "zip": "6941",
        "city": "Izier",
        "lng": 5.5793252,
        "lat": 50.3848936
    },
    {
        "zip": "6941",
        "city": "Tohogne",
        "lng": 5.481405,
        "lat": 50.3810361
    },
    {
        "zip": "6941",
        "city": "Villers-Sainte-Gertrude",
        "lng": 5.5764092,
        "lat": 50.3619168
    },
    {
        "zip": "6950",
        "city": "Harsin",
        "lng": 5.3475606,
        "lat": 50.173788
    },
    {
        "zip": "6950",
        "city": "Nassogne",
        "lng": 5.3455462,
        "lat": 50.1294031
    },
    {
        "zip": "6951",
        "city": "Bande",
        "lng": 5.4154822,
        "lat": 50.1665822
    },
    {
        "zip": "6952",
        "city": "Grune",
        "lng": 5.3828952,
        "lat": 50.1549918
    },
    {
        "zip": "6953",
        "city": "Ambly",
        "lng": 5.3142288,
        "lat": 50.1441815
    },
    {
        "zip": "6953",
        "city": "Forriéres",
        "lng": 5.2842239,
        "lat": 50.1324941
    },
    {
        "zip": "6953",
        "city": "Lesterny",
        "lng": 5.2799209,
        "lat": 50.1109713
    },
    {
        "zip": "6953",
        "city": "Masbourg",
        "lng": 5.3136921,
        "lat": 50.1143521
    },
    {
        "zip": "6960",
        "city": "Dochamps",
        "lng": 5.6230297,
        "lat": 50.234199
    },
    {
        "zip": "6960",
        "city": "Grandmenil",
        "lng": 5.6625445,
        "lat": 50.2922823
    },
    {
        "zip": "6960",
        "city": "Harre",
        "lng": 5.6587925,
        "lat": 50.3516772
    },
    {
        "zip": "6960",
        "city": "Malempré",
        "lng": 5.7155249,
        "lat": 50.2816159
    },
    {
        "zip": "6960",
        "city": "Manhay",
        "lng": 5.6756341,
        "lat": 50.2929635
    },
    {
        "zip": "6960",
        "city": "Odeigne",
        "lng": 5.682551,
        "lat": 50.2563686
    },
    {
        "zip": "6960",
        "city": "Vaux-Chavanne",
        "lng": 5.6968767,
        "lat": 50.3021516
    },
    {
        "zip": "6970",
        "city": "Tenneville",
        "lng": 5.5242133,
        "lat": 50.091248
    },
    {
        "zip": "6971",
        "city": "Champlon",
        "lng": 5.5034047,
        "lat": 50.1077503
    },
    {
        "zip": "6972",
        "city": "Erneuville",
        "lng": 5.5507905,
        "lat": 50.1137831
    },
    {
        "zip": "6980",
        "city": "Beausaint",
        "lng": 5.5532565,
        "lat": 50.170322
    },
    {
        "zip": "6980",
        "city": "La Roche-En-Ardenne",
        "lng": 5.5701521878995,
        "lat": 50.182916023074
    },
    {
        "zip": "6982",
        "city": "Samrée",
        "lng": 5.6394828,
        "lat": 50.209256
    },
    {
        "zip": "6983",
        "city": "Ortho",
        "lng": 5.6138065,
        "lat": 50.1249111
    },
    {
        "zip": "6984",
        "city": "Hives",
        "lng": 5.5783344,
        "lat": 50.1506704
    },
    {
        "zip": "6986",
        "city": "Halleux",
        "lng": 5.5020384,
        "lat": 50.1717133
    },
    {
        "zip": "6987",
        "city": "Beffe",
        "lng": 5.5230608,
        "lat": 50.245425
    },
    {
        "zip": "6987",
        "city": "Hodister",
        "lng": 5.4954367,
        "lat": 50.2006652
    },
    {
        "zip": "6987",
        "city": "Marcourt",
        "lng": 5.5268173,
        "lat": 50.2145396
    },
    {
        "zip": "6987",
        "city": "Rendeux",
        "lng": 5.5043243,
        "lat": 50.2330466
    },
    {
        "zip": "6990",
        "city": "Fronville",
        "lng": 5.4221284,
        "lat": 50.2943293
    },
    {
        "zip": "6990",
        "city": "Hampteau",
        "lng": 5.4729019,
        "lat": 50.2579039
    },
    {
        "zip": "6990",
        "city": "Hotton",
        "lng": 5.4463304,
        "lat": 50.2688011
    },
    {
        "zip": "6990",
        "city": "Marenne",
        "lng": 5.4165812,
        "lat": 50.2418351
    },
    {
        "zip": "6997",
        "city": "Amonines",
        "lng": 5.5571509,
        "lat": 50.2655197
    },
    {
        "zip": "6997",
        "city": "Erezée",
        "lng": 5.5597229,
        "lat": 50.2930897
    },
    {
        "zip": "6997",
        "city": "Mormont",
        "lng": 5.7189108,
        "lat": 50.1458065
    },
    {
        "zip": "6997",
        "city": "Soy",
        "lng": 5.5100929,
        "lat": 50.285577
    },
    {
        "zip": "7000",
        "city": "Mons",
        "lng": 3.951958,
        "lat": 50.4549568
    },
    {
        "zip": "7011",
        "city": "Ghlin",
        "lng": 3.903746,
        "lat": 50.4760502
    },
    {
        "zip": "7012",
        "city": "Flénu",
        "lng": 3.8878381,
        "lat": 50.4367571
    },
    {
        "zip": "7012",
        "city": "Jemappes",
        "lng": 3.8894392,
        "lat": 50.448422
    },
    {
        "zip": "7020",
        "city": "Maisiéres",
        "lng": 3.962763,
        "lat": 50.4901522
    },
    {
        "zip": "7020",
        "city": "Nimy",
        "lng": 3.9545894,
        "lat": 50.4759358
    },
    {
        "zip": "7021",
        "city": "Havre",
        "lng": 4.0453329,
        "lat": 50.4643434
    },
    {
        "zip": "7022",
        "city": "Harmignies",
        "lng": 4.0177899,
        "lat": 50.4076446
    },
    {
        "zip": "7022",
        "city": "Harveng",
        "lng": 3.986884,
        "lat": 50.3949004
    },
    {
        "zip": "7022",
        "city": "Hyon",
        "lng": 3.9619447,
        "lat": 50.4392359
    },
    {
        "zip": "7022",
        "city": "Mesvin",
        "lng": 3.960668,
        "lat": 50.4283672
    },
    {
        "zip": "7022",
        "city": "Nouvelles",
        "lng": 3.9689185,
        "lat": 50.4100798
    },
    {
        "zip": "7024",
        "city": "Ciply",
        "lng": 3.9438349,
        "lat": 50.4193042
    },
    {
        "zip": "7030",
        "city": "Saint-Symphorien",
        "lng": 4.012574,
        "lat": 50.4366253
    },
    {
        "zip": "7031",
        "city": "Villers-Saint-Ghislain",
        "lng": 4.0389071,
        "lat": 50.4312421
    },
    {
        "zip": "7032",
        "city": "Spiennes",
        "lng": 3.9868957,
        "lat": 50.4254505
    },
    {
        "zip": "7033",
        "city": "Cuesmes",
        "lng": 3.9206652,
        "lat": 50.436171
    },
    {
        "zip": "7034",
        "city": "Obourg",
        "lng": 4.0062232,
        "lat": 50.4760289
    },
    {
        "zip": "7034",
        "city": "Saint-Denis",
        "lng": 4.0181787,
        "lat": 50.4920472
    },
    {
        "zip": "7040",
        "city": "Asquillies",
        "lng": 3.9570563,
        "lat": 50.4020009
    },
    {
        "zip": "7040",
        "city": "Aulnois",
        "lng": 3.9031862,
        "lat": 50.340829
    },
    {
        "zip": "7040",
        "city": "Blaregnies",
        "lng": 3.8980429,
        "lat": 50.357777
    },
    {
        "zip": "7040",
        "city": "Bougnies",
        "lng": 3.9391701,
        "lat": 50.386751
    },
    {
        "zip": "7040",
        "city": "Genly",
        "lng": 3.9168758,
        "lat": 50.3922864
    },
    {
        "zip": "7040",
        "city": "Goegnies-Chaussée",
        "lng": 3.9442029,
        "lat": 50.3422836
    },
    {
        "zip": "7040",
        "city": "Quévy",
        "lng": 3.9439033,
        "lat": 50.3667226
    },
    {
        "zip": "7040",
        "city": "Quévy-Le-Grand",
        "lng": 3.9492347,
        "lat": 50.3609637
    },
    {
        "zip": "7040",
        "city": "Quévy-Le-Petit",
        "lng": 3.93752,
        "lat": 50.367996
    },
    {
        "zip": "7041",
        "city": "Givry",
        "lng": 4.0293435,
        "lat": 50.3795142
    },
    {
        "zip": "7041",
        "city": "Havay",
        "lng": 3.9841693,
        "lat": 50.3611541
    },
    {
        "zip": "7050",
        "city": "Erbaut",
        "lng": 3.884409,
        "lat": 50.53309
    },
    {
        "zip": "7050",
        "city": "Erbisoeul",
        "lng": 3.8961158,
        "lat": 50.5155495
    },
    {
        "zip": "7050",
        "city": "Herchies",
        "lng": 3.8577095,
        "lat": 50.528238
    },
    {
        "zip": "7050",
        "city": "Jurbise",
        "lng": 3.9113179,
        "lat": 50.5325067
    },
    {
        "zip": "7050",
        "city": "Masnuy-Saint-Jean",
        "lng": 3.9423599,
        "lat": 50.5310206
    },
    {
        "zip": "7050",
        "city": "Masnuy-Saint-Pierre",
        "lng": 3.9598418,
        "lat": 50.5369939
    },
    {
        "zip": "7060",
        "city": "Horrues",
        "lng": 4.0413076,
        "lat": 50.6093445
    },
    {
        "zip": "7060",
        "city": "Soignies",
        "lng": 4.0732968,
        "lat": 50.5775309
    },
    {
        "zip": "7061",
        "city": "Casteau",
        "lng": 4.0031319,
        "lat": 50.5130195
    },
    {
        "zip": "7061",
        "city": "Thieusies",
        "lng": 4.0489552,
        "lat": 50.5151015
    },
    {
        "zip": "7062",
        "city": "Naast",
        "lng": 4.1011662,
        "lat": 50.5536883
    },
    {
        "zip": "7063",
        "city": "Chaussée-Notre-Dame-Louvignies",
        "lng": 3.9979808,
        "lat": 50.5921606
    },
    {
        "zip": "7063",
        "city": "Neufvilles",
        "lng": 4.0029098,
        "lat": 50.5683075
    },
    {
        "zip": "7070",
        "city": "Gottignies",
        "lng": 4.0622054,
        "lat": 50.4971875
    },
    {
        "zip": "7070",
        "city": "Le Roeulx",
        "lng": 4.112245,
        "lat": 50.5034034
    },
    {
        "zip": "7070",
        "city": "Mignault",
        "lng": 4.152112,
        "lat": 50.5291801
    },
    {
        "zip": "7070",
        "city": "Thieu",
        "lng": 4.0922224,
        "lat": 50.473352
    },
    {
        "zip": "7070",
        "city": "Ville-Sur-Haine",
        "lng": 4.0655313,
        "lat": 50.4767906
    },
    {
        "zip": "7080",
        "city": "Eugies",
        "lng": 3.8883703,
        "lat": 50.3906692
    },
    {
        "zip": "7080",
        "city": "Frameries",
        "lng": 3.8905656,
        "lat": 50.4087571
    },
    {
        "zip": "7080",
        "city": "La Bouverie",
        "lng": 3.8774491,
        "lat": 50.407951
    },
    {
        "zip": "7080",
        "city": "Noirchain",
        "lng": 3.9298869,
        "lat": 50.4018707
    },
    {
        "zip": "7080",
        "city": "Sars-La-Bruyére",
        "lng": 3.8764088,
        "lat": 50.3719019
    },
    {
        "zip": "7090",
        "city": "Braine-Le-Comte",
        "lng": 4.1425138,
        "lat": 50.6123481
    },
    {
        "zip": "7090",
        "city": "Hennuyéres",
        "lng": 4.1715689,
        "lat": 50.6386981
    },
    {
        "zip": "7090",
        "city": "Henripont",
        "lng": 4.1861019,
        "lat": 50.5996119
    },
    {
        "zip": "7090",
        "city": "Petit-Roeulx-Lez-Braine",
        "lng": 4.0894781,
        "lat": 50.6240857
    },
    {
        "zip": "7090",
        "city": "Ronquiéres",
        "lng": 4.2235457,
        "lat": 50.6084328
    },
    {
        "zip": "7090",
        "city": "Steenkerque",
        "lng": 4.0698505,
        "lat": 50.6432692
    },
    {
        "zip": "7100",
        "city": "Haine-Saint-Paul",
        "lng": 4.1883094,
        "lat": 50.454942
    },
    {
        "zip": "7100",
        "city": "Haine-Saint-Pierre",
        "lng": 4.2000181,
        "lat": 50.4547557
    },
    {
        "zip": "7100",
        "city": "La Louviére",
        "lng": 4.1852682,
        "lat": 50.479487
    },
    {
        "zip": "7100",
        "city": "Saint-Vaast",
        "lng": 4.1604101,
        "lat": 50.4525018
    },
    {
        "zip": "7100",
        "city": "Triviéres",
        "lng": 4.1475857,
        "lat": 50.4517378
    },
    {
        "zip": "7110",
        "city": "Boussoit",
        "lng": 4.081995,
        "lat": 50.4588091
    },
    {
        "zip": "7110",
        "city": "Houdeng-Aimeries",
        "lng": 4.1524459,
        "lat": 50.4835958
    },
    {
        "zip": "7110",
        "city": "Houdeng-Goegnies",
        "lng": 4.1575303,
        "lat": 50.4879863
    },
    {
        "zip": "7110",
        "city": "Maurage",
        "lng": 4.0979858,
        "lat": 50.4577279
    },
    {
        "zip": "7110",
        "city": "Strépy-Bracquegnies",
        "lng": 4.1207031,
        "lat": 50.474474
    },
    {
        "zip": "7120",
        "city": "Croix-Lez-Rouveroy",
        "lng": 4.0758701,
        "lat": 50.3609408
    },
    {
        "zip": "7120",
        "city": "Estinnes",
        "lng": 4.0975758,
        "lat": 50.3976702
    },
    {
        "zip": "7120",
        "city": "Estinnes-Au-Mont",
        "lng": 4.0989347,
        "lat": 50.3952117
    },
    {
        "zip": "7120",
        "city": "Estinnes-Au-Val",
        "lng": 4.1064835,
        "lat": 50.4127908
    },
    {
        "zip": "7120",
        "city": "Fauroeulx",
        "lng": 4.1095709,
        "lat": 50.3703355
    },
    {
        "zip": "7120",
        "city": "Haulchin",
        "lng": 4.0801348,
        "lat": 50.3831523
    },
    {
        "zip": "7120",
        "city": "Peissant",
        "lng": 4.1208612,
        "lat": 50.3512136
    },
    {
        "zip": "7120",
        "city": "Rouveroy",
        "lng": 4.062845,
        "lat": 50.3555625
    },
    {
        "zip": "7120",
        "city": "Vellereille-Le-Sec",
        "lng": 4.0573258,
        "lat": 50.401796
    },
    {
        "zip": "7120",
        "city": "Vellereille-Les-Brayeux",
        "lng": 4.1507915,
        "lat": 50.3790081
    },
    {
        "zip": "7130",
        "city": "Battignies",
        "lng": 4.1685575,
        "lat": 50.4165125
    },
    {
        "zip": "7130",
        "city": "Binche",
        "lng": 4.1701677159951,
        "lat": 50.412824846085
    },
    {
        "zip": "7130",
        "city": "Bray",
        "lng": 4.1024947,
        "lat": 50.4299906
    },
    {
        "zip": "7131",
        "city": "Waudrez",
        "lng": 4.150981,
        "lat": 50.4137808
    },
    {
        "zip": "7133",
        "city": "Buvrinnes",
        "lng": 4.2055425,
        "lat": 50.3897538
    },
    {
        "zip": "7134",
        "city": "Epinois",
        "lng": 4.2093685,
        "lat": 50.4049792
    },
    {
        "zip": "7134",
        "city": "Leval-Trahegnies",
        "lng": 4.218073,
        "lat": 50.416547
    },
    {
        "zip": "7134",
        "city": "Péronnes-Lez-Binche",
        "lng": 4.1458468,
        "lat": 50.4364165
    },
    {
        "zip": "7134",
        "city": "Ressaix",
        "lng": 4.1910482,
        "lat": 50.4238109
    },
    {
        "zip": "7140",
        "city": "Morlanwelz",
        "lng": 4.2523562,
        "lat": 50.4509843
    },
    {
        "zip": "7140",
        "city": "Morlanwelz-Mariemont",
        "lng": 4.243909,
        "lat": 50.4544626
    },
    {
        "zip": "7141",
        "city": "Carniéres",
        "lng": 4.2542126,
        "lat": 50.443039
    },
    {
        "zip": "7141",
        "city": "Mont-Sainte-Aldegonde",
        "lng": 4.2340727,
        "lat": 50.4293887
    },
    {
        "zip": "7160",
        "city": "Chapelle-Lez-Herlaimont",
        "lng": 4.2804202,
        "lat": 50.4713265
    },
    {
        "zip": "7160",
        "city": "Godarville",
        "lng": 4.2865899,
        "lat": 50.4955503
    },
    {
        "zip": "7160",
        "city": "Piéton",
        "lng": 4.2984261,
        "lat": 50.4414944
    },
    {
        "zip": "7170",
        "city": "Bellecourt",
        "lng": 4.254013,
        "lat": 50.4843145
    },
    {
        "zip": "7170",
        "city": "Bois-D'haine",
        "lng": 4.2156931,
        "lat": 50.5023427
    },
    {
        "zip": "7170",
        "city": "Fayt-Lez-Manage",
        "lng": 4.2282511,
        "lat": 50.4898236
    },
    {
        "zip": "7170",
        "city": "La Hestre",
        "lng": 4.2358174,
        "lat": 50.4738724
    },
    {
        "zip": "7170",
        "city": "Manage",
        "lng": 4.2343288,
        "lat": 50.5036133
    },
    {
        "zip": "7180",
        "city": "Seneffe",
        "lng": 4.2664609240834,
        "lat": 50.52505545
    },
    {
        "zip": "7181",
        "city": "Arquennes",
        "lng": 4.287514819651,
        "lat": 50.5640405
    },
    {
        "zip": "7181",
        "city": "Familleureux",
        "lng": 4.199657690926,
        "lat": 50.52567625
    },
    {
        "zip": "7181",
        "city": "Feluy",
        "lng": 4.2430926037513,
        "lat": 50.5625639
    },
    {
        "zip": "7181",
        "city": "Petit-Roeulx-Lez-Nivelles",
        "lng": 4.3186986634815,
        "lat": 50.55876715
    },
    {
        "zip": "7190",
        "city": "Ecaussinnes",
        "lng": 4.1752492,
        "lat": 50.5696129
    },
    {
        "zip": "7190",
        "city": "Ecaussinnes-D'enghien",
        "lng": 4.1776303,
        "lat": 50.5714795
    },
    {
        "zip": "7190",
        "city": "Marche-Lez-Ecaussinnes",
        "lng": 4.1832813,
        "lat": 50.5468953
    },
    {
        "zip": "7191",
        "city": "Ecaussinnes-Lalaing",
        "lng": 4.1785518,
        "lat": 50.5703563
    },
    {
        "zip": "7300",
        "city": "Boussu",
        "lng": 3.7960902,
        "lat": 50.4331253
    },
    {
        "zip": "7301",
        "city": "Hornu",
        "lng": 3.8276077,
        "lat": 50.4337613
    },
    {
        "zip": "7320",
        "city": "Bernissart",
        "lng": 3.6504677,
        "lat": 50.475502
    },
    {
        "zip": "7321",
        "city": "Blaton",
        "lng": 3.6611754,
        "lat": 50.5011724
    },
    {
        "zip": "7321",
        "city": "Harchies",
        "lng": 3.6936,
        "lat": 50.4794445
    },
    {
        "zip": "7322",
        "city": "Pommeroeul",
        "lng": 3.7107354,
        "lat": 50.4611322
    },
    {
        "zip": "7322",
        "city": "Ville-Pommeroeul",
        "lng": 3.7304437,
        "lat": 50.471652
    },
    {
        "zip": "7330",
        "city": "Saint-Ghislain",
        "lng": 3.8214781396442,
        "lat": 50.448359502766
    },
    {
        "zip": "7331",
        "city": "Baudour",
        "lng": 3.8327577,
        "lat": 50.4814352
    },
    {
        "zip": "7332",
        "city": "Neufmaison",
        "lng": 3.7918519,
        "lat": 50.5304672
    },
    {
        "zip": "7332",
        "city": "Sirault",
        "lng": 3.7881267,
        "lat": 50.5060592
    },
    {
        "zip": "7333",
        "city": "Tertre",
        "lng": 3.8081735,
        "lat": 50.4673149
    },
    {
        "zip": "7334",
        "city": "Hautrage",
        "lng": 3.7650049,
        "lat": 50.4823864
    },
    {
        "zip": "7334",
        "city": "Villerot",
        "lng": 3.7902777,
        "lat": 50.4861629
    },
    {
        "zip": "7340",
        "city": "Colfontaine",
        "lng": 3.8513958,
        "lat": 50.4056635
    },
    {
        "zip": "7340",
        "city": "Paturages",
        "lng": 3.8629223,
        "lat": 50.4136507
    },
    {
        "zip": "7340",
        "city": "Warquignies",
        "lng": 3.8223517,
        "lat": 50.4002638
    },
    {
        "zip": "7340",
        "city": "Wasmes",
        "lng": 3.846637,
        "lat": 50.414181
    },
    {
        "zip": "7350",
        "city": "Hainin",
        "lng": 3.7661218,
        "lat": 50.4311514
    },
    {
        "zip": "7350",
        "city": "Hensies",
        "lng": 3.6847215,
        "lat": 50.4328388
    },
    {
        "zip": "7350",
        "city": "Montroeul-Sur-Haine",
        "lng": 3.703666,
        "lat": 50.436001
    },
    {
        "zip": "7350",
        "city": "Thulin",
        "lng": 3.7393229,
        "lat": 50.4292832
    },
    {
        "zip": "7370",
        "city": "Blaugies",
        "lng": 3.8051335,
        "lat": 50.3729779
    },
    {
        "zip": "7370",
        "city": "Dour",
        "lng": 3.7806897,
        "lat": 50.3978815
    },
    {
        "zip": "7370",
        "city": "Elouges",
        "lng": 3.7521047,
        "lat": 50.4036968
    },
    {
        "zip": "7370",
        "city": "Wihéries",
        "lng": 3.7521224,
        "lat": 50.3852902
    },
    {
        "zip": "7380",
        "city": "Baisieux",
        "lng": 3.6957835,
        "lat": 50.387884
    },
    {
        "zip": "7380",
        "city": "Quiévrain",
        "lng": 3.6819263,
        "lat": 50.4084818
    },
    {
        "zip": "7382",
        "city": "Audregnies",
        "lng": 3.7177788,
        "lat": 50.3830135
    },
    {
        "zip": "7387",
        "city": "Angre",
        "lng": 3.6954509,
        "lat": 50.366983
    },
    {
        "zip": "7387",
        "city": "Angreau",
        "lng": 3.6911259,
        "lat": 50.3509285
    },
    {
        "zip": "7387",
        "city": "Athis",
        "lng": 3.776059,
        "lat": 50.3647711
    },
    {
        "zip": "7387",
        "city": "Autreppe",
        "lng": 3.7317059,
        "lat": 50.3463188
    },
    {
        "zip": "7387",
        "city": "Erquennes",
        "lng": 3.7933404,
        "lat": 50.3590043
    },
    {
        "zip": "7387",
        "city": "Fayt-Le-Franc",
        "lng": 3.7724828,
        "lat": 50.3566928
    },
    {
        "zip": "7387",
        "city": "Honnelles",
        "lng": 3.731019,
        "lat": 50.352089
    },
    {
        "zip": "7387",
        "city": "Marchipont",
        "lng": 3.666982,
        "lat": 50.377885
    },
    {
        "zip": "7387",
        "city": "Montignies-Sur-Roc",
        "lng": 3.7333223,
        "lat": 50.3680079
    },
    {
        "zip": "7387",
        "city": "Onnezies",
        "lng": 3.71633,
        "lat": 50.363513
    },
    {
        "zip": "7387",
        "city": "Roisin",
        "lng": 3.6938101,
        "lat": 50.333082
    },
    {
        "zip": "7390",
        "city": "Quaregnon",
        "lng": 3.8636971,
        "lat": 50.4421804
    },
    {
        "zip": "7390",
        "city": "Wasmuel",
        "lng": 3.8466719,
        "lat": 50.445727
    },
    {
        "zip": "7500",
        "city": "Ere",
        "lng": 3.3668477,
        "lat": 50.5822217
    },
    {
        "zip": "7500",
        "city": "Saint-Maur",
        "lng": 3.3923658,
        "lat": 50.5721668
    },
    {
        "zip": "7500",
        "city": "Tournai",
        "lng": 3.3878259,
        "lat": 50.60566
    },
    {
        "zip": "7501",
        "city": "Orcq",
        "lng": 3.3492864,
        "lat": 50.6049549
    },
    {
        "zip": "7502",
        "city": "Esplechin",
        "lng": 3.3038112,
        "lat": 50.5743852
    },
    {
        "zip": "7503",
        "city": "Froyennes",
        "lng": 3.3569842,
        "lat": 50.6195489
    },
    {
        "zip": "7504",
        "city": "Froidmont",
        "lng": 3.3287795,
        "lat": 50.57792
    },
    {
        "zip": "7506",
        "city": "Willemeau",
        "lng": 3.350025,
        "lat": 50.573981
    },
    {
        "zip": "7520",
        "city": "Ramegnies-Chin",
        "lng": 3.335337,
        "lat": 50.651295
    },
    {
        "zip": "7520",
        "city": "Templeuve",
        "lng": 3.2831577,
        "lat": 50.6449056
    },
    {
        "zip": "7521",
        "city": "Chercq",
        "lng": 3.4212542,
        "lat": 50.5885516
    },
    {
        "zip": "7522",
        "city": "Blandain",
        "lng": 3.3035015,
        "lat": 50.6249665
    },
    {
        "zip": "7522",
        "city": "Hertain",
        "lng": 3.2880161,
        "lat": 50.6141111
    },
    {
        "zip": "7522",
        "city": "Lamain",
        "lng": 3.2931148,
        "lat": 50.5982618
    },
    {
        "zip": "7522",
        "city": "Marquain",
        "lng": 3.3226398,
        "lat": 50.6078843
    },
    {
        "zip": "7530",
        "city": "Gaurain-Ramecroix",
        "lng": 3.4834445,
        "lat": 50.5924891
    },
    {
        "zip": "7531",
        "city": "Havinnes",
        "lng": 3.4655917,
        "lat": 50.6156953
    },
    {
        "zip": "7532",
        "city": "Beclers",
        "lng": 3.5028761,
        "lat": 50.6217774
    },
    {
        "zip": "7533",
        "city": "Thimougies",
        "lng": 3.5102508,
        "lat": 50.6349458
    },
    {
        "zip": "7534",
        "city": "Barry",
        "lng": 3.5430623,
        "lat": 50.5875423
    },
    {
        "zip": "7534",
        "city": "Maulde",
        "lng": 3.5483951,
        "lat": 50.6169646
    },
    {
        "zip": "7536",
        "city": "Vaulx",
        "lng": 3.426172,
        "lat": 50.589889
    },
    {
        "zip": "7538",
        "city": "Vezon",
        "lng": 3.5017888,
        "lat": 50.5683976
    },
    {
        "zip": "7540",
        "city": "Kain",
        "lng": 3.3809106,
        "lat": 50.6379097
    },
    {
        "zip": "7540",
        "city": "Melles",
        "lng": 3.4818996,
        "lat": 50.646728
    },
    {
        "zip": "7540",
        "city": "Quartes",
        "lng": 3.5120068,
        "lat": 50.6501425
    },
    {
        "zip": "7540",
        "city": "Rumillies",
        "lng": 3.4372618,
        "lat": 50.6201039
    },
    {
        "zip": "7542",
        "city": "Mont-Saint-Aubert",
        "lng": 3.4000171,
        "lat": 50.6554352
    },
    {
        "zip": "7543",
        "city": "Mourcourt",
        "lng": 3.445164,
        "lat": 50.6530409
    },
    {
        "zip": "7548",
        "city": "Warchin",
        "lng": 3.4267528,
        "lat": 50.6096187
    },
    {
        "zip": "7600",
        "city": "Péruwelz",
        "lng": 3.5908864,
        "lat": 50.5097897
    },
    {
        "zip": "7601",
        "city": "Roucourt",
        "lng": 3.5870901,
        "lat": 50.529165
    },
    {
        "zip": "7602",
        "city": "Bury",
        "lng": 3.59458,
        "lat": 50.5426384
    },
    {
        "zip": "7603",
        "city": "Bon-Secours",
        "lng": 3.607483,
        "lat": 50.4976184
    },
    {
        "zip": "7604",
        "city": "Baugnies",
        "lng": 3.552247,
        "lat": 50.561586
    },
    {
        "zip": "7604",
        "city": "Braffe",
        "lng": 3.5828589,
        "lat": 50.5523518
    },
    {
        "zip": "7604",
        "city": "Brasmenil",
        "lng": 3.5472808,
        "lat": 50.5417372
    },
    {
        "zip": "7604",
        "city": "Callenelle",
        "lng": 3.5253447,
        "lat": 50.5297342
    },
    {
        "zip": "7604",
        "city": "Wasmes-Audemez-Briffoeil",
        "lng": 3.5352247,
        "lat": 50.5539524
    },
    {
        "zip": "7608",
        "city": "Wiers",
        "lng": 3.533035,
        "lat": 50.508665
    },
    {
        "zip": "7610",
        "city": "Rumes",
        "lng": 3.3039251,
        "lat": 50.5557678
    },
    {
        "zip": "7611",
        "city": "La Glanerie",
        "lng": 3.3010202,
        "lat": 50.5298416
    },
    {
        "zip": "7618",
        "city": "Taintignies",
        "lng": 3.3416855,
        "lat": 50.5502028
    },
    {
        "zip": "7620",
        "city": "Bléharies",
        "lng": 3.4155171,
        "lat": 50.5131151
    },
    {
        "zip": "7620",
        "city": "Brunehaut",
        "lng": 3.3795619,
        "lat": 50.5178063
    },
    {
        "zip": "7620",
        "city": "Guignies",
        "lng": 3.3663017,
        "lat": 50.5471385
    },
    {
        "zip": "7620",
        "city": "Hollain",
        "lng": 3.4271843,
        "lat": 50.5421847
    },
    {
        "zip": "7620",
        "city": "Jollain-Merlin",
        "lng": 3.4041047456312,
        "lat": 50.54598165
    },
    {
        "zip": "7620",
        "city": "Wez-Velvain",
        "lng": 3.388586,
        "lat": 50.5468427
    },
    {
        "zip": "7621",
        "city": "Lesdain",
        "lng": 3.3880963285681,
        "lat": 50.520291
    },
    {
        "zip": "7622",
        "city": "Laplaigne",
        "lng": 3.4418587625525,
        "lat": 50.52296155
    },
    {
        "zip": "7623",
        "city": "Rongy",
        "lng": 3.3815632027097,
        "lat": 50.50665205
    },
    {
        "zip": "7624",
        "city": "Howardries",
        "lng": 3.3541900391658,
        "lat": 50.5170287
    },
    {
        "zip": "7640",
        "city": "Antoing",
        "lng": 3.4513116,
        "lat": 50.565911
    },
    {
        "zip": "7640",
        "city": "Maubray",
        "lng": 3.5020267,
        "lat": 50.5515108
    },
    {
        "zip": "7640",
        "city": "Péronnes-Lez-Antoing",
        "lng": 3.4538057,
        "lat": 50.551862
    },
    {
        "zip": "7641",
        "city": "Bruyelle",
        "lng": 3.426992,
        "lat": 50.557403
    },
    {
        "zip": "7642",
        "city": "Calonne",
        "lng": 3.4371661,
        "lat": 50.578325
    },
    {
        "zip": "7643",
        "city": "Fontenoy",
        "lng": 3.473373,
        "lat": 50.567887
    },
    {
        "zip": "7700",
        "city": "Luingne",
        "lng": 3.2344942,
        "lat": 50.7386186
    },
    {
        "zip": "7700",
        "city": "Mouscron",
        "lng": 3.2139093,
        "lat": 50.7433351
    },
    {
        "zip": "7711",
        "city": "Dottignies",
        "lng": 3.3037301,
        "lat": 50.7273981
    },
    {
        "zip": "7712",
        "city": "Herseaux",
        "lng": 3.2342044,
        "lat": 50.7227127
    },
    {
        "zip": "7730",
        "city": "Bailleul",
        "lng": 3.3176015,
        "lat": 50.6689214
    },
    {
        "zip": "7730",
        "city": "Estaimbourg",
        "lng": 3.3180722,
        "lat": 50.6826198
    },
    {
        "zip": "7730",
        "city": "Estaimpuis",
        "lng": 3.2640184,
        "lat": 50.7059794
    },
    {
        "zip": "7730",
        "city": "Evregnies",
        "lng": 3.2875443,
        "lat": 50.7130523
    },
    {
        "zip": "7730",
        "city": "Leers-Nord",
        "lng": 3.2703911,
        "lat": 50.6875576
    },
    {
        "zip": "7730",
        "city": "Néchin",
        "lng": 3.2690175,
        "lat": 50.6669438
    },
    {
        "zip": "7730",
        "city": "Saint-Léger",
        "lng": 3.3149729,
        "lat": 50.7056533
    },
    {
        "zip": "7740",
        "city": "Pecq",
        "lng": 3.3407492,
        "lat": 50.685616
    },
    {
        "zip": "7740",
        "city": "Warcoing",
        "lng": 3.3451985,
        "lat": 50.702173
    },
    {
        "zip": "7742",
        "city": "Hérinnes-Lez-Pecq",
        "lng": 3.3623581,
        "lat": 50.7008798
    },
    {
        "zip": "7743",
        "city": "Esquelmes",
        "lng": 3.347779,
        "lat": 50.665077
    },
    {
        "zip": "7743",
        "city": "Obigies",
        "lng": 3.364281,
        "lat": 50.662055
    },
    {
        "zip": "7750",
        "city": "Amougies",
        "lng": 3.5066795,
        "lat": 50.743719
    },
    {
        "zip": "7750",
        "city": "Anseroeul",
        "lng": 3.520558,
        "lat": 50.728033
    },
    {
        "zip": "7750",
        "city": "Mont-De-L'enclus",
        "lng": 3.5110542,
        "lat": 50.7456511
    },
    {
        "zip": "7750",
        "city": "Orroir",
        "lng": 3.481663,
        "lat": 50.747596
    },
    {
        "zip": "7750",
        "city": "Russeignies",
        "lng": 3.5309528,
        "lat": 50.7447633
    },
    {
        "zip": "7760",
        "city": "Celles",
        "lng": 3.4571664,
        "lat": 50.7125435
    },
    {
        "zip": "7760",
        "city": "Escanaffles",
        "lng": 3.4486114,
        "lat": 50.7546292
    },
    {
        "zip": "7760",
        "city": "Molenbaix",
        "lng": 3.4294567,
        "lat": 50.6930043
    },
    {
        "zip": "7760",
        "city": "Popuelles",
        "lng": 3.5192597,
        "lat": 50.6621501
    },
    {
        "zip": "7760",
        "city": "Pottes",
        "lng": 3.4050325,
        "lat": 50.7314164
    },
    {
        "zip": "7760",
        "city": "Velaines",
        "lng": 3.4865934,
        "lat": 50.6685604
    },
    {
        "zip": "7780",
        "city": "Comines",
        "lng": 2.998666,
        "lat": 50.768736
    },
    {
        "zip": "7780",
        "city": "Comines-Warneton",
        "lng": 3.0019077817068,
        "lat": 50.77120012074
    },
    {
        "zip": "7781",
        "city": "Houthem",
        "lng": 2.9640553,
        "lat": 50.7868243
    },
    {
        "zip": "7782",
        "city": "Ploegsteert",
        "lng": 2.8803139,
        "lat": 50.7262204
    },
    {
        "zip": "7783",
        "city": "Bizet",
        "lng": 2.88999,
        "lat": 50.7060943
    },
    {
        "zip": "7784",
        "city": "Bas-Warneton",
        "lng": 2.9625206,
        "lat": 50.7588153
    },
    {
        "zip": "7784",
        "city": "Warneton",
        "lng": 2.9502023,
        "lat": 50.7529932
    },
    {
        "zip": "7800",
        "city": "Ath",
        "lng": 3.7926793502157,
        "lat": 50.639504499385
    },
    {
        "zip": "7800",
        "city": "Lanquesaint",
        "lng": 3.8042549,
        "lat": 50.6534242
    },
    {
        "zip": "7801",
        "city": "Irchonwelz",
        "lng": 3.7536768,
        "lat": 50.6201619
    },
    {
        "zip": "7802",
        "city": "Ormeignies",
        "lng": 3.7516336,
        "lat": 50.5947224
    },
    {
        "zip": "7803",
        "city": "Bouvignies",
        "lng": 3.7677307,
        "lat": 50.6477056
    },
    {
        "zip": "7804",
        "city": "Ostiches",
        "lng": 3.7584252,
        "lat": 50.6788509
    },
    {
        "zip": "7804",
        "city": "Rebaix",
        "lng": 3.7836911,
        "lat": 50.660198
    },
    {
        "zip": "7810",
        "city": "Maffle",
        "lng": 3.8060162,
        "lat": 50.615068
    },
    {
        "zip": "7811",
        "city": "Arbre",
        "lng": 3.8148309,
        "lat": 50.6103517
    },
    {
        "zip": "7812",
        "city": "Houtaing",
        "lng": 3.67998,
        "lat": 50.6397485
    },
    {
        "zip": "7812",
        "city": "Ligne",
        "lng": 3.7054981,
        "lat": 50.6222783
    },
    {
        "zip": "7812",
        "city": "Mainvault",
        "lng": 3.7184421,
        "lat": 50.6495829
    },
    {
        "zip": "7812",
        "city": "Moulbaix",
        "lng": 3.7156806,
        "lat": 50.600191
    },
    {
        "zip": "7812",
        "city": "Villers-Notre-Dame",
        "lng": 3.7360962,
        "lat": 50.617881
    },
    {
        "zip": "7812",
        "city": "Villers-Saint-Amand",
        "lng": 3.730712,
        "lat": 50.6226624
    },
    {
        "zip": "7822",
        "city": "Ghislenghien",
        "lng": 3.875818,
        "lat": 50.6566864
    },
    {
        "zip": "7822",
        "city": "Isières",
        "lng": 3.8181687,
        "lat": 50.6637962
    },
    {
        "zip": "7822",
        "city": "Meslin-L'Evêque",
        "lng": 3.8480962,
        "lat": 50.6485784
    },
    {
        "zip": "7823",
        "city": "Gibecq",
        "lng": 3.8878195,
        "lat": 50.637673
    },
    {
        "zip": "7830",
        "city": "Bassilly",
        "lng": 3.9360497,
        "lat": 50.6724203
    },
    {
        "zip": "7830",
        "city": "Fouleng",
        "lng": 3.9282801,
        "lat": 50.6182841
    },
    {
        "zip": "7830",
        "city": "Gondregnies",
        "lng": 3.9118667,
        "lat": 50.627067
    },
    {
        "zip": "7830",
        "city": "Graty",
        "lng": 3.9967288,
        "lat": 50.6311726
    },
    {
        "zip": "7830",
        "city": "Hellebecq",
        "lng": 3.8887882,
        "lat": 50.663165
    },
    {
        "zip": "7830",
        "city": "Hoves",
        "lng": 4.0358365,
        "lat": 50.6717098
    },
    {
        "zip": "7830",
        "city": "Silly",
        "lng": 3.9243973,
        "lat": 50.6489028
    },
    {
        "zip": "7830",
        "city": "Thoricourt",
        "lng": 3.9509699,
        "lat": 50.6101161
    },
    {
        "zip": "7850",
        "city": "Enghien",
        "lng": 4.0406912,
        "lat": 50.6927553
    },
    {
        "zip": "7850",
        "city": "Marcq",
        "lng": 4.0173479,
        "lat": 50.6903571
    },
    {
        "zip": "7850",
        "city": "Petit-Enghien",
        "lng": 4.0845939,
        "lat": 50.6899321
    },
    {
        "zip": "7860",
        "city": "Lessines",
        "lng": 3.8301131,
        "lat": 50.7123036
    },
    {
        "zip": "7861",
        "city": "Papignies",
        "lng": 3.819132,
        "lat": 50.6861533
    },
    {
        "zip": "7861",
        "city": "Wannebecq",
        "lng": 3.7998354,
        "lat": 50.6940794
    },
    {
        "zip": "7862",
        "city": "Ogy",
        "lng": 3.780225,
        "lat": 50.7208405
    },
    {
        "zip": "7863",
        "city": "Ghoy",
        "lng": 3.8094385,
        "lat": 50.7283427
    },
    {
        "zip": "7864",
        "city": "Deux-Acren",
        "lng": 3.8522036,
        "lat": 50.7307336
    },
    {
        "zip": "7866",
        "city": "Bois-De-Lessines",
        "lng": 3.8856306,
        "lat": 50.6953023
    },
    {
        "zip": "7866",
        "city": "Ollignies",
        "lng": 3.8598773,
        "lat": 50.6874157
    },
    {
        "zip": "7870",
        "city": "Bauffe",
        "lng": 3.8537045,
        "lat": 50.5701441
    },
    {
        "zip": "7870",
        "city": "Cambron-Saint-Vincent",
        "lng": 3.9162237,
        "lat": 50.5827811
    },
    {
        "zip": "7870",
        "city": "Lens",
        "lng": 3.9005461,
        "lat": 50.5570816
    },
    {
        "zip": "7870",
        "city": "Lombise",
        "lng": 3.9397913,
        "lat": 50.6007682
    },
    {
        "zip": "7870",
        "city": "Montignies-Lez-Lens",
        "lng": 3.9433795,
        "lat": 50.5643065
    },
    {
        "zip": "7880",
        "city": "Flobecq",
        "lng": 3.7378707,
        "lat": 50.7371675
    },
    {
        "zip": "7890",
        "city": "Ellezelles",
        "lng": 3.6802062,
        "lat": 50.7338701
    },
    {
        "zip": "7890",
        "city": "Lahamaide",
        "lng": 3.7246735,
        "lat": 50.69558
    },
    {
        "zip": "7890",
        "city": "Wodecq",
        "lng": 3.7444299,
        "lat": 50.7178459
    },
    {
        "zip": "7900",
        "city": "Grandmetz",
        "lng": 3.6322713,
        "lat": 50.6220544
    },
    {
        "zip": "7900",
        "city": "Leuze-En-Hainaut",
        "lng": 3.617736902638,
        "lat": 50.599042981582
    },
    {
        "zip": "7901",
        "city": "Thieulain",
        "lng": 3.6085857,
        "lat": 50.6179826
    },
    {
        "zip": "7903",
        "city": "Blicquy",
        "lng": 3.6857513,
        "lat": 50.5878005
    },
    {
        "zip": "7903",
        "city": "Chapelle-À-Oie",
        "lng": 3.6709445,
        "lat": 50.5967927
    },
    {
        "zip": "7903",
        "city": "Chapelle-À-Wattines",
        "lng": 3.6551354,
        "lat": 50.6125001
    },
    {
        "zip": "7904",
        "city": "Pipaix",
        "lng": 3.575887,
        "lat": 50.582865
    },
    {
        "zip": "7904",
        "city": "Tourpes",
        "lng": 3.6493593,
        "lat": 50.572688
    },
    {
        "zip": "7904",
        "city": "Willaupuis",
        "lng": 3.6038961,
        "lat": 50.566635
    },
    {
        "zip": "7906",
        "city": "Gallaix",
        "lng": 3.5748349,
        "lat": 50.6060715
    },
    {
        "zip": "7910",
        "city": "Anvaing",
        "lng": 3.5617225,
        "lat": 50.6817661
    },
    {
        "zip": "7910",
        "city": "Arc-Wattripont",
        "lng": 3.5489704,
        "lat": 50.7282535
    },
    {
        "zip": "7910",
        "city": "Cordes",
        "lng": 3.5342514,
        "lat": 50.688202
    },
    {
        "zip": "7910",
        "city": "Ellignies-Lez-Frasnes",
        "lng": 3.5903652,
        "lat": 50.6747768
    },
    {
        "zip": "7910",
        "city": "Forest",
        "lng": 3.5373149,
        "lat": 50.6736151
    },
    {
        "zip": "7910",
        "city": "Frasnes-Lez-Anvaing",
        "lng": 3.5455253970222,
        "lat": 50.684567271165
    },
    {
        "zip": "7910",
        "city": "Wattripont",
        "lng": 3.5489704,
        "lat": 50.7282535
    },
    {
        "zip": "7911",
        "city": "Buissenal",
        "lng": 3.6551307,
        "lat": 50.6648633
    },
    {
        "zip": "7911",
        "city": "Frasnes-Lez-Buissenal",
        "lng": 3.6194481,
        "lat": 50.667755
    },
    {
        "zip": "7911",
        "city": "Hacquegnies",
        "lng": 3.5938505,
        "lat": 50.6522435
    },
    {
        "zip": "7911",
        "city": "Herquegies",
        "lng": 3.5785557,
        "lat": 50.6349335
    },
    {
        "zip": "7911",
        "city": "Montroeul-Au-Bois",
        "lng": 3.571359,
        "lat": 50.642909
    },
    {
        "zip": "7911",
        "city": "Moustier",
        "lng": 3.619931,
        "lat": 50.6557475
    },
    {
        "zip": "7911",
        "city": "Oeudeghien",
        "lng": 3.7117971,
        "lat": 50.6780664
    },
    {
        "zip": "7912",
        "city": "Dergneau",
        "lng": 3.5666,
        "lat": 50.712579
    },
    {
        "zip": "7912",
        "city": "Saint-Sauveur",
        "lng": 3.5977459,
        "lat": 50.7061803
    },
    {
        "zip": "7940",
        "city": "Brugelette",
        "lng": 3.8546563,
        "lat": 50.5954253
    },
    {
        "zip": "7940",
        "city": "Cambron-Casteau",
        "lng": 3.8807488,
        "lat": 50.5885597
    },
    {
        "zip": "7941",
        "city": "Attre",
        "lng": 3.8410951,
        "lat": 50.6102428
    },
    {
        "zip": "7942",
        "city": "Mévergnies-Lez-Lens",
        "lng": 3.8507081,
        "lat": 50.6044689
    },
    {
        "zip": "7943",
        "city": "Gages",
        "lng": 3.8910777,
        "lat": 50.6068018
    },
    {
        "zip": "7950",
        "city": "Chiévres",
        "lng": 3.810128,
        "lat": 50.587693
    },
    {
        "zip": "7950",
        "city": "Grosage",
        "lng": 3.7571017,
        "lat": 50.5444446
    },
    {
        "zip": "7950",
        "city": "Huissignies",
        "lng": 3.7532905,
        "lat": 50.5658217
    },
    {
        "zip": "7950",
        "city": "Ladeuze",
        "lng": 3.7713023,
        "lat": 50.567984
    },
    {
        "zip": "7950",
        "city": "Tongre-Saint-Martin",
        "lng": 3.7907921,
        "lat": 50.5860413
    },
    {
        "zip": "7951",
        "city": "Tongre-Notre-Dame",
        "lng": 3.7748043,
        "lat": 50.581313
    },
    {
        "zip": "7970",
        "city": "Beloeil",
        "lng": 3.7361279,
        "lat": 50.5479197
    },
    {
        "zip": "7971",
        "city": "Basécles",
        "lng": 3.6472042,
        "lat": 50.5252131
    },
    {
        "zip": "7971",
        "city": "Ramegnies",
        "lng": 3.6351295,
        "lat": 50.544393
    },
    {
        "zip": "7971",
        "city": "Thumaide",
        "lng": 3.6304664,
        "lat": 50.5404186
    },
    {
        "zip": "7971",
        "city": "Wadelincourt",
        "lng": 3.6483678,
        "lat": 50.5385955
    },
    {
        "zip": "7972",
        "city": "Aubechies",
        "lng": 3.6769173,
        "lat": 50.573932
    },
    {
        "zip": "7972",
        "city": "Quevaucamps",
        "lng": 3.6909731,
        "lat": 50.5302519
    },
    {
        "zip": "7973",
        "city": "Grandglise",
        "lng": 3.6966742,
        "lat": 50.5044413
    },
    {
        "zip": "7973",
        "city": "Stambruges",
        "lng": 3.7202435,
        "lat": 50.508349
    },
    {
        "zip": "8000",
        "city": "Brugge",
        "lng": 3.2073611,
        "lat": 51.2147083
    },
    {
        "zip": "8000",
        "city": "Koolkerke",
        "lng": 3.2473789,
        "lat": 51.2419954
    },
    {
        "zip": "8020",
        "city": "Hertsberge",
        "lng": 3.2612349549978,
        "lat": 51.10996785
    },
    {
        "zip": "8020",
        "city": "Oostkamp",
        "lng": 3.2497137262681,
        "lat": 51.1511469
    },
    {
        "zip": "8020",
        "city": "Ruddervoorde",
        "lng": 3.2014781027698,
        "lat": 51.08464525
    },
    {
        "zip": "8020",
        "city": "Waardamme",
        "lng": 3.2222872,
        "lat": 51.1123296
    },
    {
        "zip": "8200",
        "city": "Sint-Andries",
        "lng": 3.1750054151928,
        "lat": 51.18830895
    },
    {
        "zip": "8200",
        "city": "Sint-Michiels",
        "lng": 3.2111722,
        "lat": 51.1884619
    },
    {
        "zip": "8210",
        "city": "Loppem",
        "lng": 3.1955523,
        "lat": 51.1557488
    },
    {
        "zip": "8210",
        "city": "Veldegem",
        "lng": 3.1606518,
        "lat": 51.1048944
    },
    {
        "zip": "8210",
        "city": "Zedelgem",
        "lng": 3.1404473279372,
        "lat": 51.1355326
    },
    {
        "zip": "8211",
        "city": "Aartrijke",
        "lng": 3.0913265545139,
        "lat": 51.1180002
    },
    {
        "zip": "8300",
        "city": "Knokke",
        "lng": 3.3233738606453,
        "lat": 51.34942965
    },
    {
        "zip": "8300",
        "city": "Knokke-Heist",
        "lng": 3.2702133,
        "lat": 51.3396066
    },
    {
        "zip": "8300",
        "city": "Westkapelle",
        "lng": 3.3172865697943,
        "lat": 51.3207807
    },
    {
        "zip": "8301",
        "city": "Ramskapelle",
        "lng": 3.2505004,
        "lat": 51.3126954
    },
    {
        "zip": "8310",
        "city": "Assebroek",
        "lng": 3.2570785402003,
        "lat": 51.1933202
    },
    {
        "zip": "8310",
        "city": "Sint-Kruis",
        "lng": 3.2506508,
        "lat": 51.2142018
    },
    {
        "zip": "8340",
        "city": "Damme",
        "lng": 3.2920911130762,
        "lat": 51.2553021
    },
    {
        "zip": "8340",
        "city": "Hoeke",
        "lng": 3.3302838831505,
        "lat": 51.2936953
    },
    {
        "zip": "8340",
        "city": "Lapscheure",
        "lng": 3.3523918963854,
        "lat": 51.2821424
    },
    {
        "zip": "8340",
        "city": "Moerkerke",
        "lng": 3.3558017705651,
        "lat": 51.2422333
    },
    {
        "zip": "8340",
        "city": "Oostkerke",
        "lng": 3.2884674049058,
        "lat": 51.2751719
    },
    {
        "zip": "8340",
        "city": "Sijsele",
        "lng": 3.3435315097294,
        "lat": 51.210525
    },
    {
        "zip": "8370",
        "city": "Blankenberge",
        "lng": 3.1297578035057,
        "lat": 51.31651475
    },
    {
        "zip": "8370",
        "city": "Uitkerke",
        "lng": 3.1404868099971,
        "lat": 51.2996935
    },
    {
        "zip": "8377",
        "city": "Houtave",
        "lng": 3.1071768079055,
        "lat": 51.2388018
    },
    {
        "zip": "8377",
        "city": "Meetkerke",
        "lng": 3.1469831971503,
        "lat": 51.2356311
    },
    {
        "zip": "8377",
        "city": "Nieuwmunster",
        "lng": 3.0994149719104,
        "lat": 51.275802
    },
    {
        "zip": "8377",
        "city": "Zuienkerke",
        "lng": 3.1567406094326,
        "lat": 51.2685834
    },
    {
        "zip": "8380",
        "city": "Dudzele",
        "lng": 3.2292277,
        "lat": 51.2747463
    },
    {
        "zip": "8380",
        "city": "Lissewege",
        "lng": 3.1991824156265,
        "lat": 51.31711375
    },
    {
        "zip": "8380",
        "city": "Zeebrugge",
        "lng": 3.2078842,
        "lat": 51.331382
    },
    {
        "zip": "8400",
        "city": "Oostende",
        "lng": 2.9203275,
        "lat": 51.2303177
    },
    {
        "zip": "8400",
        "city": "Stene",
        "lng": 2.9200835535007,
        "lat": 51.20214645
    },
    {
        "zip": "8400",
        "city": "Zandvoorde",
        "lng": 2.9668008654261,
        "lat": 51.20484775
    },
    {
        "zip": "8420",
        "city": "De Haan",
        "lng": 3.0049248,
        "lat": 51.2415107
    },
    {
        "zip": "8420",
        "city": "Klemskerke",
        "lng": 3.0238828,
        "lat": 51.242521
    },
    {
        "zip": "8420",
        "city": "Wenduine",
        "lng": 3.0927740709639,
        "lat": 51.3019771
    },
    {
        "zip": "8421",
        "city": "Vlissegem",
        "lng": 3.0635598193415,
        "lat": 51.2641695
    },
    {
        "zip": "8430",
        "city": "Middelkerke",
        "lng": 2.8063401225473,
        "lat": 51.18331695
    },
    {
        "zip": "8431",
        "city": "Wilskerke",
        "lng": 2.8401373,
        "lat": 51.1809498
    },
    {
        "zip": "8432",
        "city": "Leffinge",
        "lng": 2.8776766,
        "lat": 51.1746761
    },
    {
        "zip": "8433",
        "city": "Mannekensvere",
        "lng": 2.8183418,
        "lat": 51.1258003
    },
    {
        "zip": "8433",
        "city": "Schore",
        "lng": 2.8402112,
        "lat": 51.1110889
    },
    {
        "zip": "8433",
        "city": "Sint-Pieters-Kapelle",
        "lng": 2.8859081205773,
        "lat": 51.1228258
    },
    {
        "zip": "8433",
        "city": "Slijpe",
        "lng": 2.8476959,
        "lat": 51.1543955
    },
    {
        "zip": "8434",
        "city": "Lombardsijde",
        "lng": 2.7549864166944,
        "lat": 51.15402425
    },
    {
        "zip": "8434",
        "city": "Westende",
        "lng": 2.7777512824641,
        "lat": 51.15833025
    },
    {
        "zip": "8450",
        "city": "Bredene",
        "lng": 2.9725769468284,
        "lat": 51.23890495
    },
    {
        "zip": "8460",
        "city": "Ettelgem",
        "lng": 3.0384504834689,
        "lat": 51.19765475
    },
    {
        "zip": "8460",
        "city": "Oudenburg",
        "lng": 3.0061089520182,
        "lat": 51.20118315
    },
    {
        "zip": "8460",
        "city": "Roksem",
        "lng": 3.0333284671459,
        "lat": 51.1662204
    },
    {
        "zip": "8460",
        "city": "Westkerke",
        "lng": 3.0047445512126,
        "lat": 51.16355795
    },
    {
        "zip": "8470",
        "city": "Gistel",
        "lng": 2.9670810948205,
        "lat": 51.1582502
    },
    {
        "zip": "8470",
        "city": "Moere",
        "lng": 2.9565554411399,
        "lat": 51.1319588
    },
    {
        "zip": "8470",
        "city": "Snaaskerke",
        "lng": 2.9333678137411,
        "lat": 51.17625885
    },
    {
        "zip": "8470",
        "city": "Zevekote",
        "lng": 2.9016605351732,
        "lat": 51.1381923
    },
    {
        "zip": "8480",
        "city": "Bekegem",
        "lng": 3.0582322396597,
        "lat": 51.14886485
    },
    {
        "zip": "8480",
        "city": "Eernegem",
        "lng": 3.023122345129,
        "lat": 51.12819605
    },
    {
        "zip": "8480",
        "city": "Ichtegem",
        "lng": 3.0296222129927,
        "lat": 51.08742745
    },
    {
        "zip": "8490",
        "city": "Jabbeke",
        "lng": 3.0902953889402,
        "lat": 51.1846386
    },
    {
        "zip": "8490",
        "city": "Snellegem",
        "lng": 3.1229871043255,
        "lat": 51.17552695
    },
    {
        "zip": "8490",
        "city": "Stalhille",
        "lng": 3.0730014206469,
        "lat": 51.21970915
    },
    {
        "zip": "8490",
        "city": "Varsenare",
        "lng": 3.1361350320817,
        "lat": 51.19920035
    },
    {
        "zip": "8490",
        "city": "Zerkegem",
        "lng": 3.0612486455732,
        "lat": 51.1712593
    },
    {
        "zip": "8500",
        "city": "Kortrijk",
        "lng": 3.2784153903743,
        "lat": 50.81788085
    },
    {
        "zip": "8501",
        "city": "Bissegem",
        "lng": 3.2284723,
        "lat": 50.8228226
    },
    {
        "zip": "8501",
        "city": "Heule",
        "lng": 3.2382004806211,
        "lat": 50.8511736
    },
    {
        "zip": "8510",
        "city": "Bellegem",
        "lng": 3.2800137,
        "lat": 50.7781502
    },
    {
        "zip": "8510",
        "city": "Kooigem",
        "lng": 3.3261001,
        "lat": 50.739664
    },
    {
        "zip": "8510",
        "city": "Marke",
        "lng": 3.2336226,
        "lat": 50.8070452
    },
    {
        "zip": "8510",
        "city": "Rollegem",
        "lng": 3.2564730180436,
        "lat": 50.77232975
    },
    {
        "zip": "8511",
        "city": "Aalbeke",
        "lng": 3.2186046321991,
        "lat": 50.77745545
    },
    {
        "zip": "8520",
        "city": "Kuurne",
        "lng": 3.2736322150643,
        "lat": 50.86005145
    },
    {
        "zip": "8530",
        "city": "Harelbeke",
        "lng": 3.3425511,
        "lat": 50.8293249
    },
    {
        "zip": "8531",
        "city": "Bavikhove",
        "lng": 3.3126044844799,
        "lat": 50.8790473
    },
    {
        "zip": "8531",
        "city": "Hulste",
        "lng": 3.2990273,
        "lat": 50.8820656
    },
    {
        "zip": "8540",
        "city": "Deerlijk",
        "lng": 3.3622790229139,
        "lat": 50.83878495
    },
    {
        "zip": "8550",
        "city": "Zwevegem",
        "lng": 3.3419067426276,
        "lat": 50.80406995
    },
    {
        "zip": "8551",
        "city": "Heestert",
        "lng": 3.4120184,
        "lat": 50.7821543
    },
    {
        "zip": "8552",
        "city": "Moen",
        "lng": 3.3825263241918,
        "lat": 50.775415
    },
    {
        "zip": "8553",
        "city": "Otegem",
        "lng": 3.4245499365566,
        "lat": 50.80927585
    },
    {
        "zip": "8554",
        "city": "Sint-Denijs",
        "lng": 3.349745322203,
        "lat": 50.76440175
    },
    {
        "zip": "8560",
        "city": "Gullegem",
        "lng": 3.2026154878227,
        "lat": 50.84493445
    },
    {
        "zip": "8560",
        "city": "Moorsele",
        "lng": 3.1549318040629,
        "lat": 50.84259085
    },
    {
        "zip": "8560",
        "city": "Wevelgem",
        "lng": 3.1864450062666,
        "lat": 50.8121065
    },
    {
        "zip": "8570",
        "city": "Anzegem",
        "lng": 3.463734552107,
        "lat": 50.844003
    },
    {
        "zip": "8570",
        "city": "Gijzelbrechtegem",
        "lng": 3.5060602,
        "lat": 50.8279177
    },
    {
        "zip": "8570",
        "city": "Ingooigem",
        "lng": 3.4271837357678,
        "lat": 50.8297876
    },
    {
        "zip": "8570",
        "city": "Vichte",
        "lng": 3.4068379,
        "lat": 50.8353247
    },
    {
        "zip": "8572",
        "city": "Kaster",
        "lng": 3.4976090001837,
        "lat": 50.8121917
    },
    {
        "zip": "8573",
        "city": "Tiegem",
        "lng": 3.4697738543929,
        "lat": 50.8130531
    },
    {
        "zip": "8580",
        "city": "Avelgem",
        "lng": 3.4468985313677,
        "lat": 50.7754997
    },
    {
        "zip": "8581",
        "city": "Kerkhove",
        "lng": 3.5058971661987,
        "lat": 50.8008418
    },
    {
        "zip": "8581",
        "city": "Waarmaarde",
        "lng": 3.4854202,
        "lat": 50.7903789
    },
    {
        "zip": "8582",
        "city": "Outrijve",
        "lng": 3.4257331809137,
        "lat": 50.75868715
    },
    {
        "zip": "8583",
        "city": "Bossuit",
        "lng": 3.4080819304802,
        "lat": 50.74804505
    },
    {
        "zip": "8587",
        "city": "Espierres",
        "lng": 3.3465264652837,
        "lat": 50.72568025
    },
    {
        "zip": "8587",
        "city": "Espierres-Helchin",
        "lng": 3.3465264652837,
        "lat": 50.72568025
    },
    {
        "zip": "8587",
        "city": "Helchin",
        "lng": 3.3837254,
        "lat": 50.7300021
    },
    {
        "zip": "8600",
        "city": "Beerst",
        "lng": 2.873227743412,
        "lat": 51.05367055
    },
    {
        "zip": "8600",
        "city": "Diksmuide",
        "lng": 2.8647185,
        "lat": 51.0333408
    },
    {
        "zip": "8600",
        "city": "Driekapellen",
        "lng": 3.9855229,
        "lat": 50.7059709
    },
    {
        "zip": "8600",
        "city": "Esen",
        "lng": 2.8990845692587,
        "lat": 51.02078675
    },
    {
        "zip": "8600",
        "city": "Kaaskerke",
        "lng": 2.8387074,
        "lat": 51.0358094
    },
    {
        "zip": "8600",
        "city": "Keiem",
        "lng": 2.8814585998043,
        "lat": 51.08623835
    },
    {
        "zip": "8600",
        "city": "Lampernisse",
        "lng": 2.7703028703735,
        "lat": 51.0396362
    },
    {
        "zip": "8600",
        "city": "Leke",
        "lng": 2.8993179258716,
        "lat": 51.10066415
    },
    {
        "zip": "8600",
        "city": "Nieuwkapelle",
        "lng": 2.8042814087195,
        "lat": 50.9974451
    },
    {
        "zip": "8600",
        "city": "Oostkerke",
        "lng": 2.7997074366181,
        "lat": 51.04353425
    },
    {
        "zip": "8600",
        "city": "Oudekapelle",
        "lng": 2.8040816730756,
        "lat": 51.0206897
    },
    {
        "zip": "8600",
        "city": "Pervijze",
        "lng": 2.7914713556336,
        "lat": 51.08053165
    },
    {
        "zip": "8600",
        "city": "Stuivekenskerke",
        "lng": 2.823381776939,
        "lat": 51.07690075
    },
    {
        "zip": "8600",
        "city": "Vladslo",
        "lng": 2.9207800886428,
        "lat": 51.06253345
    },
    {
        "zip": "8600",
        "city": "Woumen",
        "lng": 2.8520595441482,
        "lat": 50.99494815
    },
    {
        "zip": "8610",
        "city": "Handzame",
        "lng": 2.996075151704,
        "lat": 51.0321939
    },
    {
        "zip": "8610",
        "city": "Kortemark",
        "lng": 3.0441178,
        "lat": 51.0285109
    },
    {
        "zip": "8610",
        "city": "Werken",
        "lng": 2.9637026,
        "lat": 51.0290062
    },
    {
        "zip": "8610",
        "city": "Zarren",
        "lng": 2.9597764,
        "lat": 51.0181095
    },
    {
        "zip": "8620",
        "city": "Nieuwpoort",
        "lng": 2.7283986495034,
        "lat": 51.14416005
    },
    {
        "zip": "8620",
        "city": "Ramskapelle",
        "lng": 2.7811249526825,
        "lat": 51.10665355
    },
    {
        "zip": "8620",
        "city": "Sint-Joris",
        "lng": 2.7781339,
        "lat": 51.1300222
    },
    {
        "zip": "8630",
        "city": "Avekapelle",
        "lng": 2.7457710712354,
        "lat": 51.0631103
    },
    {
        "zip": "8630",
        "city": "Booitshoeke",
        "lng": 2.7399983485035,
        "lat": 51.08832895
    },
    {
        "zip": "8630",
        "city": "Bulskamp",
        "lng": 2.6434487702288,
        "lat": 51.0444613
    },
    {
        "zip": "8630",
        "city": "De Moeren",
        "lng": 2.5953950379549,
        "lat": 51.03587905
    },
    {
        "zip": "8630",
        "city": "Eggewaartskapelle",
        "lng": 2.7298247748665,
        "lat": 51.0495264
    },
    {
        "zip": "8630",
        "city": "Houtem",
        "lng": 2.604305716244,
        "lat": 51.0069146
    },
    {
        "zip": "8630",
        "city": "Steenkerke",
        "lng": 2.6895389537109,
        "lat": 51.0527661
    },
    {
        "zip": "8630",
        "city": "Veurne",
        "lng": 2.6548858237238,
        "lat": 51.071043
    },
    {
        "zip": "8630",
        "city": "Vinkem",
        "lng": 2.6570581890086,
        "lat": 51.01364415
    },
    {
        "zip": "8630",
        "city": "Wulveringem",
        "lng": 2.6550439487441,
        "lat": 51.03030765
    },
    {
        "zip": "8630",
        "city": "Zoutenaaie",
        "lng": 2.7515311775286,
        "lat": 51.05092295
    },
    {
        "zip": "8640",
        "city": "Oostvleteren",
        "lng": 2.7492224474635,
        "lat": 50.92313355
    },
    {
        "zip": "8640",
        "city": "Vleteren",
        "lng": 2.7289867,
        "lat": 50.930143
    },
    {
        "zip": "8640",
        "city": "Westvleteren",
        "lng": 2.7205508961772,
        "lat": 50.9103823
    },
    {
        "zip": "8640",
        "city": "Woesten",
        "lng": 2.7887585311487,
        "lat": 50.9035513
    },
    {
        "zip": "8647",
        "city": "Lo",
        "lng": 2.7607800723908,
        "lat": 50.9931276
    },
    {
        "zip": "8647",
        "city": "Lo-Reninge",
        "lng": 2.7643391,
        "lat": 50.9638066
    },
    {
        "zip": "8647",
        "city": "Noordschote",
        "lng": 2.8217318560837,
        "lat": 50.9484727
    },
    {
        "zip": "8647",
        "city": "Pollinkhove",
        "lng": 2.7198585358008,
        "lat": 50.9726562
    },
    {
        "zip": "8647",
        "city": "Reninge",
        "lng": 2.7771170853989,
        "lat": 50.94228475
    },
    {
        "zip": "8650",
        "city": "Houthulst",
        "lng": 2.9438110508216,
        "lat": 50.97407785
    },
    {
        "zip": "8650",
        "city": "Klerken",
        "lng": 2.9155135952546,
        "lat": 50.99044185
    },
    {
        "zip": "8650",
        "city": "Merkem",
        "lng": 2.8736172801014,
        "lat": 50.962074
    },
    {
        "zip": "8660",
        "city": "Adinkerke",
        "lng": 2.5896028375712,
        "lat": 51.06926315
    },
    {
        "zip": "8660",
        "city": "De Panne",
        "lng": 2.5806696887141,
        "lat": 51.09437775
    },
    {
        "zip": "8670",
        "city": "Koksijde",
        "lng": 2.6353426015022,
        "lat": 51.1091101
    },
    {
        "zip": "8670",
        "city": "Oostduinkerke",
        "lng": 2.7012679966518,
        "lat": 51.12531835
    },
    {
        "zip": "8670",
        "city": "Wulpen",
        "lng": 2.7073119164927,
        "lat": 51.09779175
    },
    {
        "zip": "8680",
        "city": "Bovekerke",
        "lng": 2.9633414725432,
        "lat": 51.05621365
    },
    {
        "zip": "8680",
        "city": "Koekelare",
        "lng": 2.9682187344976,
        "lat": 51.0911765
    },
    {
        "zip": "8680",
        "city": "Zande",
        "lng": 2.9186353800579,
        "lat": 51.1174383
    },
    {
        "zip": "8690",
        "city": "Alveringem",
        "lng": 2.7225688102727,
        "lat": 51.01509585
    },
    {
        "zip": "8690",
        "city": "Hoogstade",
        "lng": 2.6903321,
        "lat": 50.9800701
    },
    {
        "zip": "8690",
        "city": "Oeren",
        "lng": 2.7051716,
        "lat": 51.0235332
    },
    {
        "zip": "8690",
        "city": "Sint-Rijkers",
        "lng": 2.6881434,
        "lat": 50.9945231
    },
    {
        "zip": "8691",
        "city": "Gijverinkhove",
        "lng": 2.6727552,
        "lat": 50.9761749
    },
    {
        "zip": "8691",
        "city": "Izenberge",
        "lng": 2.6608332486701,
        "lat": 50.9896601
    },
    {
        "zip": "8691",
        "city": "Leisele",
        "lng": 2.622401,
        "lat": 50.9844835
    },
    {
        "zip": "8691",
        "city": "Stavele",
        "lng": 2.673321,
        "lat": 50.9398411
    },
    {
        "zip": "8700",
        "city": "Aarsele",
        "lng": 3.4139884196625,
        "lat": 50.9972412
    },
    {
        "zip": "8700",
        "city": "Kanegem",
        "lng": 3.4035567083156,
        "lat": 51.021401
    },
    {
        "zip": "8700",
        "city": "Schuiferskapelle",
        "lng": 3.3283107336881,
        "lat": 51.03700665
    },
    {
        "zip": "8700",
        "city": "Tielt",
        "lng": 3.3414263284671,
        "lat": 50.99996475
    },
    {
        "zip": "8710",
        "city": "Ooigem",
        "lng": 3.3304964142362,
        "lat": 50.8949817
    },
    {
        "zip": "8710",
        "city": "Sint-Baafs-Vijve",
        "lng": 3.3862545030012,
        "lat": 50.9245338
    },
    {
        "zip": "8710",
        "city": "Wielsbeke",
        "lng": 3.3604037488967,
        "lat": 50.91140755
    },
    {
        "zip": "8720",
        "city": "Dentergem",
        "lng": 3.4022937173313,
        "lat": 50.966125
    },
    {
        "zip": "8720",
        "city": "Markegem",
        "lng": 3.394380172379,
        "lat": 50.95014795
    },
    {
        "zip": "8720",
        "city": "Oeselgem",
        "lng": 3.429680547389,
        "lat": 50.94008805
    },
    {
        "zip": "8720",
        "city": "Wakken",
        "lng": 3.4009830853574,
        "lat": 50.93483565
    },
    {
        "zip": "8730",
        "city": "Beernem",
        "lng": 3.3144732597608,
        "lat": 51.12774395
    },
    {
        "zip": "8730",
        "city": "Oedelem",
        "lng": 3.3441122702909,
        "lat": 51.166016
    },
    {
        "zip": "8730",
        "city": "Sint-Joris",
        "lng": 3.3646568393591,
        "lat": 51.125095
    },
    {
        "zip": "8740",
        "city": "Egem",
        "lng": 3.2540634837689,
        "lat": 51.0209559
    },
    {
        "zip": "8740",
        "city": "Pittem",
        "lng": 3.2622730034453,
        "lat": 50.99996475
    },
    {
        "zip": "8750",
        "city": "Wingene",
        "lng": 3.2847683081115,
        "lat": 51.06808895
    },
    {
        "zip": "8750",
        "city": "Zwevezele",
        "lng": 3.2053756921268,
        "lat": 51.0422255
    },
    {
        "zip": "8755",
        "city": "Ruiselede",
        "lng": 3.3789495803068,
        "lat": 51.062755
    },
    {
        "zip": "8760",
        "city": "Meulebeke",
        "lng": 3.2859605761212,
        "lat": 50.9484788
    },
    {
        "zip": "8770",
        "city": "Ingelmunster",
        "lng": 3.2617189739329,
        "lat": 50.92196635
    },
    {
        "zip": "8780",
        "city": "Oostrozebeke",
        "lng": 3.3468206372996,
        "lat": 50.93434535
    },
    {
        "zip": "8790",
        "city": "Waregem",
        "lng": 3.4257380400092,
        "lat": 50.87620025
    },
    {
        "zip": "8791",
        "city": "Beveren",
        "lng": 3.3427472358752,
        "lat": 50.871701
    },
    {
        "zip": "8792",
        "city": "Desselgem",
        "lng": 3.3669119563274,
        "lat": 50.88302825
    },
    {
        "zip": "8793",
        "city": "Sint-Eloois-Vijve",
        "lng": 3.4036062,
        "lat": 50.9057236
    },
    {
        "zip": "8800",
        "city": "Beveren",
        "lng": 3.14416,
        "lat": 50.9687312
    },
    {
        "zip": "8800",
        "city": "Oekene",
        "lng": 3.1627760972777,
        "lat": 50.9042588
    },
    {
        "zip": "8800",
        "city": "Roeselare",
        "lng": 3.124765,
        "lat": 50.9444948
    },
    {
        "zip": "8800",
        "city": "Rumbeke",
        "lng": 3.1223026883986,
        "lat": 50.91511475
    },
    {
        "zip": "8810",
        "city": "Lichtervelde",
        "lng": 3.1422717715338,
        "lat": 51.03033065
    },
    {
        "zip": "8820",
        "city": "Torhout",
        "lng": 3.0913072045454,
        "lat": 51.0585915
    },
    {
        "zip": "8830",
        "city": "Gits",
        "lng": 3.1103285411089,
        "lat": 50.9975916
    },
    {
        "zip": "8830",
        "city": "Hooglede",
        "lng": 3.0687215849639,
        "lat": 50.98218015
    },
    {
        "zip": "8840",
        "city": "Oostnieuwkerke",
        "lng": 3.0641586047981,
        "lat": 50.94159445
    },
    {
        "zip": "8840",
        "city": "Staden",
        "lng": 3.001340623187,
        "lat": 50.97048785
    },
    {
        "zip": "8840",
        "city": "Westrozebeke",
        "lng": 3.0095525945894,
        "lat": 50.933009
    },
    {
        "zip": "8850",
        "city": "Ardooie",
        "lng": 3.2045936674459,
        "lat": 50.97076545
    },
    {
        "zip": "8851",
        "city": "Koolskamp",
        "lng": 3.2016142835313,
        "lat": 51.0075272
    },
    {
        "zip": "8860",
        "city": "Lendelede",
        "lng": 3.2320134658174,
        "lat": 50.8850661
    },
    {
        "zip": "8870",
        "city": "Emelgem",
        "lng": 3.2212852352972,
        "lat": 50.9336597
    },
    {
        "zip": "8870",
        "city": "Izegem",
        "lng": 3.2090654210557,
        "lat": 50.91382225
    },
    {
        "zip": "8870",
        "city": "Kachtem",
        "lng": 3.1890400909612,
        "lat": 50.93988415
    },
    {
        "zip": "8880",
        "city": "Ledegem",
        "lng": 3.1176598783352,
        "lat": 50.86898195
    },
    {
        "zip": "8880",
        "city": "Rollegem-Kapelle",
        "lng": 3.1510232055719,
        "lat": 50.87005025
    },
    {
        "zip": "8880",
        "city": "Sint-Eloois-Winkel",
        "lng": 3.179116,
        "lat": 50.8765142
    },
    {
        "zip": "8890",
        "city": "Dadizele",
        "lng": 3.1056665757612,
        "lat": 50.84693275
    },
    {
        "zip": "8890",
        "city": "Moorslede",
        "lng": 3.0732075972642,
        "lat": 50.89006565
    },
    {
        "zip": "8900",
        "city": "Brielen",
        "lng": 2.8475388,
        "lat": 50.8689046
    },
    {
        "zip": "8900",
        "city": "Dikkebus",
        "lng": 2.8309734,
        "lat": 50.8192065
    },
    {
        "zip": "8900",
        "city": "Ieper",
        "lng": 2.8914774512132,
        "lat": 50.851808
    },
    {
        "zip": "8900",
        "city": "Sint-Jan",
        "lng": 2.9038085,
        "lat": 50.8646017
    },
    {
        "zip": "8902",
        "city": "Hollebeke",
        "lng": 2.9383694,
        "lat": 50.8056486
    },
    {
        "zip": "8902",
        "city": "Voormezele",
        "lng": 2.8757825,
        "lat": 50.8169949
    },
    {
        "zip": "8902",
        "city": "Zillebeke",
        "lng": 2.9224029,
        "lat": 50.8346385
    },
    {
        "zip": "8904",
        "city": "Boezinge",
        "lng": 2.8624482523982,
        "lat": 50.89329445
    },
    {
        "zip": "8904",
        "city": "Zuidschote",
        "lng": 2.8285726,
        "lat": 50.9129617
    },
    {
        "zip": "8906",
        "city": "Elverdinge",
        "lng": 2.8086072093094,
        "lat": 50.88120105
    },
    {
        "zip": "8908",
        "city": "Vlamertinge",
        "lng": 2.8146596555515,
        "lat": 50.84722495
    },
    {
        "zip": "8920",
        "city": "Bikschote",
        "lng": 2.863108835832,
        "lat": 50.92504495
    },
    {
        "zip": "8920",
        "city": "Langemark",
        "lng": 2.912173087276,
        "lat": 50.91011515
    },
    {
        "zip": "8920",
        "city": "Langemark-Poelkapelle",
        "lng": 2.9338635,
        "lat": 50.9122567
    },
    {
        "zip": "8920",
        "city": "Poelkapelle",
        "lng": 2.9632606863338,
        "lat": 50.9278167
    },
    {
        "zip": "8930",
        "city": "Lauwe",
        "lng": 3.1938978797182,
        "lat": 50.78879055
    },
    {
        "zip": "8930",
        "city": "Menen",
        "lng": 3.1156565,
        "lat": 50.7969879
    },
    {
        "zip": "8930",
        "city": "Rekkem",
        "lng": 3.1658835832872,
        "lat": 50.7743769
    },
    {
        "zip": "8940",
        "city": "Geluwe",
        "lng": 3.0689009201802,
        "lat": 50.826939
    },
    {
        "zip": "8940",
        "city": "Wervik",
        "lng": 3.0429882329964,
        "lat": 50.79981455
    },
    {
        "zip": "8950",
        "city": "Heuvelland",
        "lng": 2.8457459110391,
        "lat": 50.76764855
    },
    {
        "zip": "8950",
        "city": "Nieuwkerke",
        "lng": 2.8251932,
        "lat": 50.7457387
    },
    {
        "zip": "8951",
        "city": "Dranouter",
        "lng": 2.7832991,
        "lat": 50.7662832
    },
    {
        "zip": "8952",
        "city": "Wulvergem",
        "lng": 2.840869239241,
        "lat": 50.7675035
    },
    {
        "zip": "8953",
        "city": "Wijtschate",
        "lng": 2.9126192876216,
        "lat": 50.7832683
    },
    {
        "zip": "8954",
        "city": "Westouter",
        "lng": 2.7409610507934,
        "lat": 50.8003371
    },
    {
        "zip": "8956",
        "city": "Kemmel",
        "lng": 2.8383675286243,
        "lat": 50.7952131
    },
    {
        "zip": "8957",
        "city": "Messines",
        "lng": 2.9001214234264,
        "lat": 50.7596526
    },
    {
        "zip": "8958",
        "city": "Loker",
        "lng": 2.7797309731213,
        "lat": 50.78372855
    },
    {
        "zip": "8970",
        "city": "Poperinge",
        "lng": 2.7222592638771,
        "lat": 50.84362245
    },
    {
        "zip": "8970",
        "city": "Reningelst",
        "lng": 2.7636029,
        "lat": 50.8170498
    },
    {
        "zip": "8972",
        "city": "Krombeke",
        "lng": 2.6729063768433,
        "lat": 50.9045753
    },
    {
        "zip": "8972",
        "city": "Proven",
        "lng": 2.6542871,
        "lat": 50.8903437
    },
    {
        "zip": "8972",
        "city": "Roesbrugge-Haringe",
        "lng": 2.6262588,
        "lat": 50.9150531
    },
    {
        "zip": "8978",
        "city": "Watou",
        "lng": 2.6449117149406,
        "lat": 50.84518645
    },
    {
        "zip": "8980",
        "city": "Beselare",
        "lng": 3.0293494282427,
        "lat": 50.85048855
    },
    {
        "zip": "8980",
        "city": "Geluveld",
        "lng": 2.9962568059484,
        "lat": 50.8340552
    },
    {
        "zip": "8980",
        "city": "Passendale",
        "lng": 3.0054428297242,
        "lat": 50.89915125
    },
    {
        "zip": "8980",
        "city": "Zandvoorde",
        "lng": 2.9770015427192,
        "lat": 50.8151226
    },
    {
        "zip": "8980",
        "city": "Zonnebeke",
        "lng": 2.9874678,
        "lat": 50.8731885
    },
    {
        "zip": "9000",
        "city": "Gent",
        "lng": 3.7141549000597,
        "lat": 51.0397129
    },
    {
        "zip": "9030",
        "city": "Mariakerke",
        "lng": 3.6781210300259,
        "lat": 51.0731177
    },
    {
        "zip": "9031",
        "city": "Drongen",
        "lng": 3.6395168043236,
        "lat": 51.0528944
    },
    {
        "zip": "9032",
        "city": "Wondelgem",
        "lng": 3.7034612746567,
        "lat": 51.09349535
    },
    {
        "zip": "9040",
        "city": "Sint-Amandsberg",
        "lng": 3.7488657,
        "lat": 51.061875
    },
    {
        "zip": "9041",
        "city": "Oostakker",
        "lng": 3.7644128,
        "lat": 51.1001986
    },
    {
        "zip": "9042",
        "city": "Desteldonk",
        "lng": 3.7836111,
        "lat": 51.1221778
    },
    {
        "zip": "9042",
        "city": "Mendonk",
        "lng": 3.8221384,
        "lat": 51.1467255
    },
    {
        "zip": "9042",
        "city": "Sint-Kruis-Winkel",
        "lng": 3.8234371,
        "lat": 51.1541943
    },
    {
        "zip": "9050",
        "city": "Gentbrugge",
        "lng": 3.7588813,
        "lat": 51.0447236
    },
    {
        "zip": "9050",
        "city": "Ledeberg",
        "lng": 3.7414088,
        "lat": 51.0372053
    },
    {
        "zip": "9051",
        "city": "Afsnee",
        "lng": 3.6575415863856,
        "lat": 51.0299989
    },
    {
        "zip": "9051",
        "city": "Sint-Denijs-Westrem",
        "lng": 3.6676767,
        "lat": 51.0199405
    },
    {
        "zip": "9052",
        "city": "Zwijnaarde",
        "lng": 3.7021790930106,
        "lat": 51.00092235
    },
    {
        "zip": "9060",
        "city": "Zelzate",
        "lng": 3.8105002,
        "lat": 51.2000036
    },
    {
        "zip": "9070",
        "city": "Destelbergen",
        "lng": 3.7978887,
        "lat": 51.0556204
    },
    {
        "zip": "9070",
        "city": "Heusden",
        "lng": 3.8003347,
        "lat": 51.0287341
    },
    {
        "zip": "9080",
        "city": "Beervelde",
        "lng": 3.8792625,
        "lat": 51.0802144
    },
    {
        "zip": "9080",
        "city": "Lochristi",
        "lng": 3.8271355,
        "lat": 51.0991574
    },
    {
        "zip": "9080",
        "city": "Zaffelare",
        "lng": 3.8601634,
        "lat": 51.1323736
    },
    {
        "zip": "9080",
        "city": "Zeveneken",
        "lng": 3.9000296,
        "lat": 51.1078751
    },
    {
        "zip": "9090",
        "city": "Gontrode",
        "lng": 3.7976423,
        "lat": 50.9842505
    },
    {
        "zip": "9090",
        "city": "Melle",
        "lng": 3.7988833,
        "lat": 51.00303
    },
    {
        "zip": "9100",
        "city": "Nieuwkerken-Waas",
        "lng": 4.1780279,
        "lat": 51.1933908
    },
    {
        "zip": "9100",
        "city": "Sint-Niklaas",
        "lng": 4.149464320724,
        "lat": 51.163813060072
    },
    {
        "zip": "9111",
        "city": "Belsele",
        "lng": 4.0917750185456,
        "lat": 51.1570236
    },
    {
        "zip": "9112",
        "city": "Sinaai-Waas",
        "lng": 3.9795579,
        "lat": 51.1866966
    },
    {
        "zip": "9120",
        "city": "Haasdonk",
        "lng": 4.2383861,
        "lat": 51.1806482
    },
    {
        "zip": "9120",
        "city": "Kallo",
        "lng": 4.2588008641609,
        "lat": 51.2652143
    },
    {
        "zip": "9120",
        "city": "Melsele",
        "lng": 4.2821446,
        "lat": 51.2211394
    },
    {
        "zip": "9120",
        "city": "Vrasene",
        "lng": 4.194377,
        "lat": 51.2191283
    },
    {
        "zip": "9130",
        "city": "Doel",
        "lng": 4.2456374474582,
        "lat": 51.3181461
    },
    {
        "zip": "9130",
        "city": "Kallo",
        "lng": 4.27803,
        "lat": 51.2519515
    },
    {
        "zip": "9130",
        "city": "Kieldrecht",
        "lng": 4.1983386657846,
        "lat": 51.3038751
    },
    {
        "zip": "9130",
        "city": "Verrebroek",
        "lng": 4.1871839695525,
        "lat": 51.2540739
    },
    {
        "zip": "9140",
        "city": "Elversele",
        "lng": 4.1457568844955,
        "lat": 51.12017065
    },
    {
        "zip": "9140",
        "city": "Steendorp",
        "lng": 4.2646567358993,
        "lat": 51.1310863
    },
    {
        "zip": "9140",
        "city": "Temse",
        "lng": 4.2133505278525,
        "lat": 51.1421436
    },
    {
        "zip": "9140",
        "city": "Tielrode",
        "lng": 4.1736809973609,
        "lat": 51.12202775
    },
    {
        "zip": "9150",
        "city": "Bazel",
        "lng": 4.2873842506575,
        "lat": 51.14685855
    },
    {
        "zip": "9150",
        "city": "Kruibeke",
        "lng": 4.3091107,
        "lat": 51.1712275
    },
    {
        "zip": "9150",
        "city": "Rupelmonde",
        "lng": 4.2906315,
        "lat": 51.1283692
    },
    {
        "zip": "9160",
        "city": "Daknam",
        "lng": 3.9808547859604,
        "lat": 51.12798595
    },
    {
        "zip": "9160",
        "city": "Eksaarde",
        "lng": 3.965212,
        "lat": 51.1487608
    },
    {
        "zip": "9160",
        "city": "Lokeren",
        "lng": 3.9911114,
        "lat": 51.1042159
    },
    {
        "zip": "9170",
        "city": "De Klinge",
        "lng": 4.0898144,
        "lat": 51.2564526
    },
    {
        "zip": "9170",
        "city": "Meerdonk",
        "lng": 4.1511549,
        "lat": 51.2588943
    },
    {
        "zip": "9170",
        "city": "Sint-Gillis-Waas",
        "lng": 4.1233014,
        "lat": 51.2180687
    },
    {
        "zip": "9170",
        "city": "Sint-Pauwels",
        "lng": 4.1016628795877,
        "lat": 51.1939255
    },
    {
        "zip": "9180",
        "city": "Moerbeke-Waas",
        "lng": 3.9446436,
        "lat": 51.1742698
    },
    {
        "zip": "9185",
        "city": "Wachtebeke",
        "lng": 3.8574284,
        "lat": 51.1701927
    },
    {
        "zip": "9190",
        "city": "Kemzeke",
        "lng": 4.0771037,
        "lat": 51.2065228
    },
    {
        "zip": "9190",
        "city": "Stekene",
        "lng": 4.0240412532131,
        "lat": 51.2104744
    },
    {
        "zip": "9200",
        "city": "Appels",
        "lng": 4.0562275450955,
        "lat": 51.02983825
    },
    {
        "zip": "9200",
        "city": "Baasrode",
        "lng": 4.1444016705284,
        "lat": 51.03987315
    },
    {
        "zip": "9200",
        "city": "Dendermonde",
        "lng": 4.098112,
        "lat": 51.0312293
    },
    {
        "zip": "9200",
        "city": "Grembergen",
        "lng": 4.1025435644905,
        "lat": 51.0533487
    },
    {
        "zip": "9200",
        "city": "Mespelare",
        "lng": 4.0652617659305,
        "lat": 50.99557415
    },
    {
        "zip": "9200",
        "city": "Oudegem",
        "lng": 4.0513674371884,
        "lat": 51.0081807
    },
    {
        "zip": "9200",
        "city": "Schoonaarde",
        "lng": 4.0158906182168,
        "lat": 50.9986902
    },
    {
        "zip": "9200",
        "city": "Sint-Gillis-Dendermonde",
        "lng": 4.1276622058379,
        "lat": 51.0226035
    },
    {
        "zip": "9220",
        "city": "Hamme",
        "lng": 4.1202807810427,
        "lat": 51.0881618
    },
    {
        "zip": "9220",
        "city": "Moerzeke",
        "lng": 4.1585956393745,
        "lat": 51.06183125
    },
    {
        "zip": "9230",
        "city": "Massemen",
        "lng": 3.8735741,
        "lat": 50.9809343
    },
    {
        "zip": "9230",
        "city": "Westrem",
        "lng": 3.8614248,
        "lat": 50.9697855
    },
    {
        "zip": "9230",
        "city": "Wetteren",
        "lng": 3.8855494,
        "lat": 51.0068804
    },
    {
        "zip": "9240",
        "city": "Zele",
        "lng": 4.0385864,
        "lat": 51.0683621
    },
    {
        "zip": "9250",
        "city": "Waasmunster",
        "lng": 4.0842158,
        "lat": 51.1097147
    },
    {
        "zip": "9255",
        "city": "Buggenhout",
        "lng": 4.192804319009,
        "lat": 51.0113077
    },
    {
        "zip": "9255",
        "city": "Opdorp",
        "lng": 4.2213781726505,
        "lat": 51.0286063
    },
    {
        "zip": "9260",
        "city": "Schellebelle",
        "lng": 3.928141226004,
        "lat": 51.0102518
    },
    {
        "zip": "9260",
        "city": "Serskamp",
        "lng": 3.9309567805899,
        "lat": 50.98997155
    },
    {
        "zip": "9260",
        "city": "Wichelen",
        "lng": 3.9806438098974,
        "lat": 51.0020843
    },
    {
        "zip": "9270",
        "city": "Kalken",
        "lng": 3.918568,
        "lat": 51.0370133
    },
    {
        "zip": "9270",
        "city": "Laarne",
        "lng": 3.8504738,
        "lat": 51.029657
    },
    {
        "zip": "9280",
        "city": "Denderbelle",
        "lng": 4.0954432870762,
        "lat": 51.0011357
    },
    {
        "zip": "9280",
        "city": "Lebbeke",
        "lng": 4.1307711,
        "lat": 51.0004013
    },
    {
        "zip": "9280",
        "city": "Wieze",
        "lng": 4.0981810157403,
        "lat": 50.9786275
    },
    {
        "zip": "9290",
        "city": "Berlare",
        "lng": 4.0113470297154,
        "lat": 51.0301606
    },
    {
        "zip": "9290",
        "city": "Overmere",
        "lng": 3.9569121644559,
        "lat": 51.05073005
    },
    {
        "zip": "9290",
        "city": "Uitbergen",
        "lng": 3.9639785004366,
        "lat": 51.0278435
    },
    {
        "zip": "9300",
        "city": "Aalst",
        "lng": 4.0396424203486,
        "lat": 50.9429755
    },
    {
        "zip": "9308",
        "city": "Gijzegem",
        "lng": 4.0413684723722,
        "lat": 50.9831967
    },
    {
        "zip": "9308",
        "city": "Hofstade",
        "lng": 4.0298563154701,
        "lat": 50.96682385
    },
    {
        "zip": "9310",
        "city": "Baardegem",
        "lng": 4.1400064082098,
        "lat": 50.9559778
    },
    {
        "zip": "9310",
        "city": "Herdersem",
        "lng": 4.0687165350537,
        "lat": 50.97349005
    },
    {
        "zip": "9310",
        "city": "Meldert",
        "lng": 4.1339380209476,
        "lat": 50.93483295
    },
    {
        "zip": "9310",
        "city": "Moorsel",
        "lng": 4.1008746699882,
        "lat": 50.95235015
    },
    {
        "zip": "9320",
        "city": "Erembodegem",
        "lng": 4.0573610045016,
        "lat": 50.9160962
    },
    {
        "zip": "9320",
        "city": "Nieuwerkerken",
        "lng": 4.0060975097656,
        "lat": 50.92226365
    },
    {
        "zip": "9340",
        "city": "Impe",
        "lng": 3.9521618,
        "lat": 50.9605364
    },
    {
        "zip": "9340",
        "city": "Lede",
        "lng": 3.9775435,
        "lat": 50.9660758
    },
    {
        "zip": "9340",
        "city": "Oordegem",
        "lng": 3.90209,
        "lat": 50.9574043
    },
    {
        "zip": "9340",
        "city": "Smetlede",
        "lng": 3.9269566,
        "lat": 50.9660712
    },
    {
        "zip": "9340",
        "city": "Wanzele",
        "lng": 3.9570133,
        "lat": 50.9741595
    },
    {
        "zip": "9400",
        "city": "Appelterre-Eichem",
        "lng": 3.9673266115113,
        "lat": 50.819114
    },
    {
        "zip": "9400",
        "city": "Denderwindeke",
        "lng": 4.0241352,
        "lat": 50.7986183
    },
    {
        "zip": "9400",
        "city": "Lieferinge",
        "lng": 4.0533344,
        "lat": 50.7918522
    },
    {
        "zip": "9400",
        "city": "Nederhasselt",
        "lng": 3.9750229,
        "lat": 50.8463587
    },
    {
        "zip": "9400",
        "city": "Ninove",
        "lng": 4.0242373,
        "lat": 50.8353611
    },
    {
        "zip": "9400",
        "city": "Okegem",
        "lng": 4.0561164,
        "lat": 50.8563924
    },
    {
        "zip": "9400",
        "city": "Voorde",
        "lng": 3.9454543,
        "lat": 50.8254777
    },
    {
        "zip": "9401",
        "city": "Pollare",
        "lng": 3.9969451,
        "lat": 50.8168641
    },
    {
        "zip": "9402",
        "city": "Meerbeke",
        "lng": 4.0458501718008,
        "lat": 50.81972985
    },
    {
        "zip": "9403",
        "city": "Neigem",
        "lng": 4.0606636,
        "lat": 50.803005
    },
    {
        "zip": "9404",
        "city": "Aspelare",
        "lng": 3.9584406,
        "lat": 50.8421637
    },
    {
        "zip": "9406",
        "city": "Outer",
        "lng": 3.9976558921405,
        "lat": 50.84658595
    },
    {
        "zip": "9420",
        "city": "Aaigem",
        "lng": 3.9366909,
        "lat": 50.8894174
    },
    {
        "zip": "9420",
        "city": "Bambrugge",
        "lng": 3.4154479,
        "lat": 51.0545566
    },
    {
        "zip": "9420",
        "city": "Burst",
        "lng": 3.9203695,
        "lat": 50.9136525
    },
    {
        "zip": "9420",
        "city": "Erondegem",
        "lng": 3.9555369,
        "lat": 50.9407226
    },
    {
        "zip": "9420",
        "city": "Erpe",
        "lng": 3.976602,
        "lat": 50.9333485
    },
    {
        "zip": "9420",
        "city": "Erpe-Mere",
        "lng": 3.9459888,
        "lat": 50.9226391
    },
    {
        "zip": "9420",
        "city": "Mere",
        "lng": 3.9710267,
        "lat": 50.923005
    },
    {
        "zip": "9420",
        "city": "Ottergem",
        "lng": 3.9468882,
        "lat": 50.9341483
    },
    {
        "zip": "9450",
        "city": "Denderhoutem",
        "lng": 4.0059338596505,
        "lat": 50.878059
    },
    {
        "zip": "9450",
        "city": "Haaltert",
        "lng": 4.0057717,
        "lat": 50.9022532
    },
    {
        "zip": "9450",
        "city": "Heldergem",
        "lng": 3.9548012,
        "lat": 50.8816742
    },
    {
        "zip": "9451",
        "city": "Kerksken",
        "lng": 3.9792346670559,
        "lat": 50.88457295
    },
    {
        "zip": "9470",
        "city": "Denderleeuw",
        "lng": 4.0665468327116,
        "lat": 50.8843903
    },
    {
        "zip": "9472",
        "city": "Iddergem",
        "lng": 4.0440509206633,
        "lat": 50.8746795
    },
    {
        "zip": "9473",
        "city": "Welle",
        "lng": 4.0448599,
        "lat": 50.9005405
    },
    {
        "zip": "9500",
        "city": "Geraardsbergen",
        "lng": 3.879789,
        "lat": 50.7689045
    },
    {
        "zip": "9500",
        "city": "Goeferdinge",
        "lng": 3.8392513,
        "lat": 50.7654043
    },
    {
        "zip": "9500",
        "city": "Moerbeke",
        "lng": 3.9446436,
        "lat": 51.1742698
    },
    {
        "zip": "9500",
        "city": "Nederboelare",
        "lng": 3.8718226231289,
        "lat": 50.78113635
    },
    {
        "zip": "9500",
        "city": "Onkerzele",
        "lng": 3.9040294677949,
        "lat": 50.77876845
    },
    {
        "zip": "9500",
        "city": "Ophasselt",
        "lng": 3.897035,
        "lat": 50.8218582
    },
    {
        "zip": "9500",
        "city": "Overboelare",
        "lng": 3.8625157,
        "lat": 50.7622936
    },
    {
        "zip": "9500",
        "city": "Viane",
        "lng": 3.923884698906,
        "lat": 50.7386591
    },
    {
        "zip": "9500",
        "city": "Zarlardinge",
        "lng": 3.8252714980661,
        "lat": 50.76432255
    },
    {
        "zip": "9506",
        "city": "Grimminge",
        "lng": 3.9525416,
        "lat": 50.7945316
    },
    {
        "zip": "9506",
        "city": "Idegem",
        "lng": 3.9295308016087,
        "lat": 50.8043243
    },
    {
        "zip": "9506",
        "city": "Nieuwenhove",
        "lng": 3.9891935,
        "lat": 50.7875051
    },
    {
        "zip": "9506",
        "city": "Schendelbeke",
        "lng": 3.8989486,
        "lat": 50.7968671
    },
    {
        "zip": "9506",
        "city": "Smeerebbe-Vloerzegem",
        "lng": 3.9259210427614,
        "lat": 50.81624535
    },
    {
        "zip": "9506",
        "city": "Waarbeke",
        "lng": 3.968228,
        "lat": 50.7779309
    },
    {
        "zip": "9506",
        "city": "Zandbergen",
        "lng": 3.9673318,
        "lat": 50.8013287
    },
    {
        "zip": "9520",
        "city": "Bavegem",
        "lng": 3.8680104604272,
        "lat": 50.9449691
    },
    {
        "zip": "9520",
        "city": "Oombergen",
        "lng": 3.8381185,
        "lat": 50.8988376
    },
    {
        "zip": "9520",
        "city": "Sint-Lievens-Houtem",
        "lng": 3.8521321366835,
        "lat": 50.91972825
    },
    {
        "zip": "9520",
        "city": "Vlierzele",
        "lng": 3.9009360317784,
        "lat": 50.935088
    },
    {
        "zip": "9520",
        "city": "Zonnegem",
        "lng": 3.9163011372068,
        "lat": 50.93017155
    },
    {
        "zip": "9521",
        "city": "Letterhoutem",
        "lng": 3.8812597,
        "lat": 50.92696
    },
    {
        "zip": "9550",
        "city": "Herzele",
        "lng": 3.8873953834097,
        "lat": 50.88830225
    },
    {
        "zip": "9550",
        "city": "Hillegem",
        "lng": 3.853568,
        "lat": 50.895806
    },
    {
        "zip": "9550",
        "city": "Sint-Antelinks",
        "lng": 3.9249438,
        "lat": 50.8495126
    },
    {
        "zip": "9550",
        "city": "Sint-Lievens-Esse",
        "lng": 3.8871129,
        "lat": 50.8553127
    },
    {
        "zip": "9550",
        "city": "Steenhuize-Wijnhuize",
        "lng": 3.8903745,
        "lat": 50.8411146
    },
    {
        "zip": "9550",
        "city": "Woubrechtegem",
        "lng": 3.9158325,
        "lat": 50.8726761
    },
    {
        "zip": "9551",
        "city": "Ressegem",
        "lng": 3.9109808,
        "lat": 50.8920791
    },
    {
        "zip": "9552",
        "city": "Borsbeke",
        "lng": 3.8945771,
        "lat": 50.9047179
    },
    {
        "zip": "9570",
        "city": "Deftinge",
        "lng": 3.8409061,
        "lat": 50.7863579
    },
    {
        "zip": "9570",
        "city": "Lierde",
        "lng": 3.8249605,
        "lat": 50.815045
    },
    {
        "zip": "9570",
        "city": "Sint-Maria-Lierde",
        "lng": 3.8370299582538,
        "lat": 50.8217365
    },
    {
        "zip": "9571",
        "city": "Hemelveerdegem",
        "lng": 3.8638783508569,
        "lat": 50.8088369
    },
    {
        "zip": "9572",
        "city": "Sint-Martens-Lierde",
        "lng": 3.8157846440419,
        "lat": 50.80452055
    },
    {
        "zip": "9600",
        "city": "Renaix",
        "lng": 3.6020465,
        "lat": 50.7476192
    },
    {
        "zip": "9620",
        "city": "Elene",
        "lng": 3.8078492,
        "lat": 50.8907864
    },
    {
        "zip": "9620",
        "city": "Erwetegem",
        "lng": 3.8142328,
        "lat": 50.8558332
    },
    {
        "zip": "9620",
        "city": "Godveerdegem",
        "lng": 3.8204374,
        "lat": 50.8594123
    },
    {
        "zip": "9620",
        "city": "Grotenberge",
        "lng": 3.8333978,
        "lat": 50.872305
    },
    {
        "zip": "9620",
        "city": "Leeuwergem",
        "lng": 3.829278,
        "lat": 50.8880639
    },
    {
        "zip": "9620",
        "city": "Oombergen",
        "lng": 3.8381185,
        "lat": 50.8988376
    },
    {
        "zip": "9620",
        "city": "Sint-Goriks-Oudenhove",
        "lng": 3.7863829,
        "lat": 50.8522592
    },
    {
        "zip": "9620",
        "city": "Sint-Maria-Oudenhove",
        "lng": 3.7998568349562,
        "lat": 50.82512665
    },
    {
        "zip": "9620",
        "city": "Strijpen",
        "lng": 3.7893247,
        "lat": 50.8690354
    },
    {
        "zip": "9620",
        "city": "Velzeke-Ruddershove",
        "lng": 3.7825766,
        "lat": 50.8824839
    },
    {
        "zip": "9620",
        "city": "Zottegem",
        "lng": 3.8155460176798,
        "lat": 50.86909165
    },
    {
        "zip": "9630",
        "city": "Beerlegem",
        "lng": 3.719796314005,
        "lat": 50.90267645
    },
    {
        "zip": "9630",
        "city": "Dikkele",
        "lng": 3.7418503,
        "lat": 50.9034583
    },
    {
        "zip": "9630",
        "city": "Hundelgem",
        "lng": 3.7552865,
        "lat": 50.8891513
    },
    {
        "zip": "9630",
        "city": "Meilegem",
        "lng": 3.6992879,
        "lat": 50.9051876
    },
    {
        "zip": "9630",
        "city": "Munkzwalm",
        "lng": 3.7327378,
        "lat": 50.8776841
    },
    {
        "zip": "9630",
        "city": "Paulatem",
        "lng": 3.7148522,
        "lat": 50.8963806
    },
    {
        "zip": "9630",
        "city": "Roborst",
        "lng": 3.7535689,
        "lat": 50.8636242
    },
    {
        "zip": "9630",
        "city": "Rozebeke",
        "lng": 3.7533417,
        "lat": 50.8488955
    },
    {
        "zip": "9630",
        "city": "Sint-Blasius-Boekel",
        "lng": 3.7206404,
        "lat": 50.8515679
    },
    {
        "zip": "9630",
        "city": "Sint-Denijs-Boekel",
        "lng": 3.7130873,
        "lat": 50.8660879
    },
    {
        "zip": "9630",
        "city": "Sint-Maria-Latem",
        "lng": 3.7059521,
        "lat": 50.890215
    },
    {
        "zip": "9630",
        "city": "Zwalm",
        "lng": 3.719334,
        "lat": 50.8793307
    },
    {
        "zip": "9636",
        "city": "Nederzwalm-Hermelgem",
        "lng": 3.6879894099711,
        "lat": 50.8867594
    },
    {
        "zip": "9660",
        "city": "Brakel",
        "lng": 3.762778,
        "lat": 50.7979739
    },
    {
        "zip": "9660",
        "city": "Elst",
        "lng": 5.5556351,
        "lat": 50.7685711
    },
    {
        "zip": "9660",
        "city": "Everbeek",
        "lng": 3.7859967948024,
        "lat": 50.7685909
    },
    {
        "zip": "9660",
        "city": "Michelbeke",
        "lng": 3.7638335,
        "lat": 50.834125
    },
    {
        "zip": "9660",
        "city": "Nederbrakel",
        "lng": 3.7617767441185,
        "lat": 50.8052186
    },
    {
        "zip": "9660",
        "city": "Opbrakel",
        "lng": 3.7463234,
        "lat": 50.7921614
    },
    {
        "zip": "9660",
        "city": "Sint-Maria-Oudenhove",
        "lng": 3.7998568349562,
        "lat": 50.82512665
    },
    {
        "zip": "9660",
        "city": "Zegelsem",
        "lng": 3.7170799912553,
        "lat": 50.8135755
    },
    {
        "zip": "9661",
        "city": "Parike",
        "lng": 3.799316,
        "lat": 50.7849168
    },
    {
        "zip": "9667",
        "city": "Horebeke",
        "lng": 3.6920346,
        "lat": 50.8348628
    },
    {
        "zip": "9667",
        "city": "Sint-Kornelis-Horebeke",
        "lng": 3.6991826,
        "lat": 50.8356339
    },
    {
        "zip": "9667",
        "city": "Sint-Maria-Horebeke",
        "lng": 3.6875097,
        "lat": 50.8383442
    },
    {
        "zip": "9680",
        "city": "Etikhove",
        "lng": 3.625998,
        "lat": 50.8129246
    },
    {
        "zip": "9680",
        "city": "Maarke-Kerkem",
        "lng": 3.6489314,
        "lat": 50.807217
    },
    {
        "zip": "9680",
        "city": "Maarkedal",
        "lng": 3.642779,
        "lat": 50.7973072
    },
    {
        "zip": "9681",
        "city": "Nukerke",
        "lng": 3.5954667,
        "lat": 50.7965606
    },
    {
        "zip": "9688",
        "city": "Schorisse",
        "lng": 3.6782376,
        "lat": 50.8061799
    },
    {
        "zip": "9690",
        "city": "Berchem",
        "lng": 3.5082669,
        "lat": 50.7903918
    },
    {
        "zip": "9690",
        "city": "Kluisbergen",
        "lng": 3.5212498,
        "lat": 50.7782986
    },
    {
        "zip": "9690",
        "city": "Kwaremont",
        "lng": 3.5230939,
        "lat": 50.7750908
    },
    {
        "zip": "9690",
        "city": "Ruien",
        "lng": 3.4860074,
        "lat": 50.7739701
    },
    {
        "zip": "9690",
        "city": "Zulzeke",
        "lng": 3.569982,
        "lat": 50.7912081
    },
    {
        "zip": "9700",
        "city": "Bevere",
        "lng": 3.5955691,
        "lat": 50.8513634
    },
    {
        "zip": "9700",
        "city": "Edelare",
        "lng": 3.6281646,
        "lat": 50.8316945
    },
    {
        "zip": "9700",
        "city": "Eine",
        "lng": 3.6226347,
        "lat": 50.8695429
    },
    {
        "zip": "9700",
        "city": "Ename",
        "lng": 3.6317612,
        "lat": 50.8553126
    },
    {
        "zip": "9700",
        "city": "Heurne",
        "lng": 3.6405818,
        "lat": 50.8878782
    },
    {
        "zip": "9700",
        "city": "Leupegem",
        "lng": 3.6086057,
        "lat": 50.8336184
    },
    {
        "zip": "9700",
        "city": "Mater",
        "lng": 3.6636488,
        "lat": 50.8428971
    },
    {
        "zip": "9700",
        "city": "Melden",
        "lng": 3.5655354,
        "lat": 50.8155547
    },
    {
        "zip": "9700",
        "city": "Mullem",
        "lng": 3.6039655,
        "lat": 50.8964155
    },
    {
        "zip": "9700",
        "city": "Nederename",
        "lng": 3.6322868,
        "lat": 50.8678376
    },
    {
        "zip": "9700",
        "city": "Ooike",
        "lng": 3.5514004,
        "lat": 50.8711812
    },
    {
        "zip": "9700",
        "city": "Oudenaarde",
        "lng": 3.604782,
        "lat": 50.8442604
    },
    {
        "zip": "9700",
        "city": "Volkegem",
        "lng": 3.6356493,
        "lat": 50.8408663
    },
    {
        "zip": "9700",
        "city": "Welden",
        "lng": 3.6536587,
        "lat": 50.8791033
    },
    {
        "zip": "9750",
        "city": "Huise",
        "lng": 3.5907777,
        "lat": 50.8988246
    },
    {
        "zip": "9750",
        "city": "Ouwegem",
        "lng": 3.5978246,
        "lat": 50.9121208
    },
    {
        "zip": "9750",
        "city": "Zingem",
        "lng": 3.653793,
        "lat": 50.9039326
    },
    {
        "zip": "9770",
        "city": "Kruishoutem",
        "lng": 3.5404462768494,
        "lat": 50.9200896
    },
    {
        "zip": "9771",
        "city": "Nokere",
        "lng": 3.5108474,
        "lat": 50.8853618
    },
    {
        "zip": "9772",
        "city": "Wannegem-Lede",
        "lng": 3.5605794173077,
        "lat": 50.8902386
    },
    {
        "zip": "9790",
        "city": "Elsegem",
        "lng": 3.5366137,
        "lat": 50.8247164
    },
    {
        "zip": "9790",
        "city": "Moregem",
        "lng": 3.5623575,
        "lat": 50.8503548
    },
    {
        "zip": "9790",
        "city": "Ooike",
        "lng": 3.5514004,
        "lat": 50.8711812
    },
    {
        "zip": "9790",
        "city": "Petegem-Aan-De-Schelde",
        "lng": 3.5581136,
        "lat": 50.8330877
    },
    {
        "zip": "9790",
        "city": "Wortegem",
        "lng": 3.5101135,
        "lat": 50.8533808
    },
    {
        "zip": "9790",
        "city": "Wortegem-Petegem",
        "lng": 3.5329831121506,
        "lat": 50.845989
    },
    {
        "zip": "9800",
        "city": "Astene",
        "lng": 3.5628186,
        "lat": 50.9845547
    },
    {
        "zip": "9800",
        "city": "Bachte-Maria-Leerne",
        "lng": 3.5624453,
        "lat": 51.0038237
    },
    {
        "zip": "9800",
        "city": "Deinze",
        "lng": 3.5274017,
        "lat": 50.9840526
    },
    {
        "zip": "9800",
        "city": "Gottem",
        "lng": 3.4640122,
        "lat": 50.9646883
    },
    {
        "zip": "9800",
        "city": "Grammene",
        "lng": 3.468333,
        "lat": 50.9764412
    },
    {
        "zip": "9800",
        "city": "Meigem",
        "lng": 3.5413767,
        "lat": 51.0175334
    },
    {
        "zip": "9800",
        "city": "Petegem-Aan-De-Leie",
        "lng": 3.5324987,
        "lat": 50.969075
    },
    {
        "zip": "9800",
        "city": "Sint-Martens-Leerne",
        "lng": 3.5837978,
        "lat": 51.0134905
    },
    {
        "zip": "9800",
        "city": "Vinkt",
        "lng": 3.4808321,
        "lat": 51.0078663
    },
    {
        "zip": "9800",
        "city": "Wontergem",
        "lng": 3.4443004,
        "lat": 50.9786839
    },
    {
        "zip": "9800",
        "city": "Zeveren",
        "lng": 3.5031988,
        "lat": 50.9967515
    },
    {
        "zip": "9810",
        "city": "Eke",
        "lng": 3.6418029,
        "lat": 50.9569377
    },
    {
        "zip": "9810",
        "city": "Nazareth",
        "lng": 3.5963218,
        "lat": 50.959802
    },
    {
        "zip": "9820",
        "city": "Bottelare",
        "lng": 3.7552702,
        "lat": 50.9635195
    },
    {
        "zip": "9820",
        "city": "Lemberge",
        "lng": 3.7726469,
        "lat": 50.9779856
    },
    {
        "zip": "9820",
        "city": "Melsen",
        "lng": 3.6928827,
        "lat": 50.9579283
    },
    {
        "zip": "9820",
        "city": "Merelbeke",
        "lng": 3.7459119,
        "lat": 50.9942924
    },
    {
        "zip": "9820",
        "city": "Munte",
        "lng": 3.7445458,
        "lat": 50.9452452
    },
    {
        "zip": "9820",
        "city": "Schelderode",
        "lng": 3.7131906,
        "lat": 50.9698809
    },
    {
        "zip": "9830",
        "city": "Sint-Martens-Latem",
        "lng": 3.6306291238782,
        "lat": 51.0134462
    },
    {
        "zip": "9831",
        "city": "Deurle",
        "lng": 3.6111658724762,
        "lat": 51.0000909
    },
    {
        "zip": "9840",
        "city": "De Pinte",
        "lng": 3.6495958,
        "lat": 50.9924205
    },
    {
        "zip": "9840",
        "city": "Zevergem",
        "lng": 3.6943144,
        "lat": 50.9786614
    },
    {
        "zip": "9850",
        "city": "Hansbeke",
        "lng": 3.5345703,
        "lat": 51.0747776
    },
    {
        "zip": "9850",
        "city": "Landegem",
        "lng": 3.5888091437976,
        "lat": 51.0546369
    },
    {
        "zip": "9850",
        "city": "Merendree",
        "lng": 3.5715236988666,
        "lat": 51.0829292
    },
    {
        "zip": "9850",
        "city": "Nevele",
        "lng": 3.5491068,
        "lat": 51.0330372
    },
    {
        "zip": "9850",
        "city": "Poesele",
        "lng": 3.5117304815871,
        "lat": 51.03238865
    },
    {
        "zip": "9850",
        "city": "Vosselare",
        "lng": 3.5661276338188,
        "lat": 51.0289033
    },
    {
        "zip": "9860",
        "city": "Balegem",
        "lng": 3.7896135,
        "lat": 50.9252028
    },
    {
        "zip": "9860",
        "city": "Gijzenzele",
        "lng": 3.8164475,
        "lat": 50.971061
    },
    {
        "zip": "9860",
        "city": "Landskouter",
        "lng": 3.7928259,
        "lat": 50.9694937
    },
    {
        "zip": "9860",
        "city": "Moortsele",
        "lng": 3.7800022,
        "lat": 50.9591122
    },
    {
        "zip": "9860",
        "city": "Oosterzele",
        "lng": 3.8032713,
        "lat": 50.9456388
    },
    {
        "zip": "9860",
        "city": "Scheldewindeke",
        "lng": 3.78971,
        "lat": 50.9392571
    },
    {
        "zip": "9870",
        "city": "Machelen",
        "lng": 3.4858873,
        "lat": 50.9591856
    },
    {
        "zip": "9870",
        "city": "Olsene",
        "lng": 3.4651619,
        "lat": 50.9357958
    },
    {
        "zip": "9870",
        "city": "Zulte",
        "lng": 3.4485632,
        "lat": 50.9206638
    },
    {
        "zip": "9880",
        "city": "Aalter",
        "lng": 3.4176803282422,
        "lat": 51.088195
    },
    {
        "zip": "9880",
        "city": "Lotenhulle",
        "lng": 3.4596784,
        "lat": 51.0497022
    },
    {
        "zip": "9880",
        "city": "Poeke",
        "lng": 3.4455642,
        "lat": 51.0414698
    },
    {
        "zip": "9881",
        "city": "Bellem",
        "lng": 3.4994525656039,
        "lat": 51.0902776
    },
    {
        "zip": "9890",
        "city": "Baaigem",
        "lng": 3.721942,
        "lat": 50.9309503
    },
    {
        "zip": "9890",
        "city": "Dikkelvenne",
        "lng": 3.6894495,
        "lat": 50.9176673
    },
    {
        "zip": "9890",
        "city": "Gavere",
        "lng": 3.6614192,
        "lat": 50.9292335
    },
    {
        "zip": "9890",
        "city": "Vurste",
        "lng": 3.6845236,
        "lat": 50.9459212
    },
    {
        "zip": "9900",
        "city": "Eeklo",
        "lng": 3.5665965,
        "lat": 51.1844827
    },
    {
        "zip": "9910",
        "city": "Knesselare",
        "lng": 3.412339,
        "lat": 51.1392692
    },
    {
        "zip": "9910",
        "city": "Ursel",
        "lng": 3.4841052,
        "lat": 51.1285879
    },
    {
        "zip": "9920",
        "city": "Lovendegem",
        "lng": 3.6076129,
        "lat": 51.0956807
    },
    {
        "zip": "9921",
        "city": "Vinderhoute",
        "lng": 3.6412401,
        "lat": 51.0876396
    },
    {
        "zip": "9930",
        "city": "Zomergem",
        "lng": 3.5642436785112,
        "lat": 51.1195778
    },
    {
        "zip": "9931",
        "city": "Oostwinkel",
        "lng": 3.5253176,
        "lat": 51.150867
    },
    {
        "zip": "9932",
        "city": "Ronsele",
        "lng": 3.5532444,
        "lat": 51.1303816
    },
    {
        "zip": "9940",
        "city": "Ertvelde",
        "lng": 3.7471789,
        "lat": 51.1793505
    },
    {
        "zip": "9940",
        "city": "Evergem",
        "lng": 3.7078565,
        "lat": 51.1087891
    },
    {
        "zip": "9940",
        "city": "Kluizen",
        "lng": 3.732612,
        "lat": 51.1547125
    },
    {
        "zip": "9940",
        "city": "Sleidinge",
        "lng": 3.6789045,
        "lat": 51.131929
    },
    {
        "zip": "9950",
        "city": "Waarschoot",
        "lng": 3.6061712,
        "lat": 51.1515304
    },
    {
        "zip": "9960",
        "city": "Assenede",
        "lng": 3.7568076657628,
        "lat": 51.23004155
    },
    {
        "zip": "9961",
        "city": "Boekhoute",
        "lng": 3.7059614986083,
        "lat": 51.2509311
    },
    {
        "zip": "9968",
        "city": "Bassevelde",
        "lng": 3.6789952,
        "lat": 51.2311917
    },
    {
        "zip": "9968",
        "city": "Oosteeklo",
        "lng": 3.687828,
        "lat": 51.1929943
    },
    {
        "zip": "9970",
        "city": "Kaprijke",
        "lng": 3.6219251372375,
        "lat": 51.228952
    },
    {
        "zip": "9971",
        "city": "Lembeke",
        "lng": 3.6346312134542,
        "lat": 51.19424
    },
    {
        "zip": "9980",
        "city": "Sint-Laureins",
        "lng": 3.5374820679851,
        "lat": 51.2330483
    },
    {
        "zip": "9981",
        "city": "Sint-Margriete",
        "lng": 3.5315005565828,
        "lat": 51.26767195
    },
    {
        "zip": "9982",
        "city": "Sint-Jan-In-Eremo",
        "lng": 3.580094,
        "lat": 51.2682182
    },
    {
        "zip": "9988",
        "city": "Waterland-Oudeman",
        "lng": 3.5905485,
        "lat": 51.2891181
    },
    {
        "zip": "9988",
        "city": "Watervliet",
        "lng": 3.626982177111,
        "lat": 51.2776499
    },
    {
        "zip": "9990",
        "city": "Maldegem",
        "lng": 3.4154538,
        "lat": 51.2263133
    },
    {
        "zip": "9991",
        "city": "Adegem",
        "lng": 3.4858471,
        "lat": 51.2035291
    },
    {
        "zip": "9992",
        "city": "Middelburg",
        "lng": 3.4071425562584,
        "lat": 51.25583235
    }
]

