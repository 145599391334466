// Chakra imports
import {
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Image,
  Input,
  Select,
  SimpleGrid,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
// Custom  account components
import { BrandedButton } from 'components/buttons/BrandedButton';
//Assets
import banner from 'assets/img/backgrounds/Nft5.png';
import Card from 'components/card/Card';
import { brandAtom, languageAtom, userState } from 'data/atoms';
import { GetUsersInfo } from 'data/selectors';
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { BsCameraFill } from 'react-icons/bs';
import { HiOutlinePlusSm } from 'react-icons/hi';
import { MdArrowDropDown } from 'react-icons/md';
import { NavLink, useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { BrandName, ILang, IUser, IUserInfo } from 'shared/types';
import { api } from '../../../utils/repository';
import { pick } from 'lodash';
import { useNotification } from '../../../shared/hooks';
import { ERROR_MESSAGE } from '../../../shared/constants';

export default function SettingsAccount() {
  const history = useHistory();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone(); //Dropzone

  const user = useRecoilValue<IUser>(GetUsersInfo);
  const { errorNotification, successNotification } = useNotification();

  const languages: ILang[] = useRecoilValue(languageAtom);
  const currentBrand = useRecoilValue(brandAtom);
  const currentRole = user.brands.find( brand => brand.brand.name === currentBrand )?.role?.name

  const [isLoading, setIsLoading] = useState('');
  const [isError, setIsError] = useState('');

  const [userInfo, setUserInfo] = useRecoilState<IUserInfo>(userState);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const iconBackground = 'secondaryGray.400';
  const headerColor = useColorModeValue('navy.800', 'black');

  useEffect(() => {
    setUserInfo(pick(user, ['firstName', 'lastName', 'email', 'lang']));
  }, [])

  const reset = () => {
    acceptedFiles[0] = undefined;
    setIsLoading('');
    setIsError('');
    setUserInfo({} as IUserInfo);
  }

  const editUserHandler = () => {
    api.updateMyAccountData(userInfo).then(() => {
      successNotification('my account info is updated!')
      history.push('/admin/dashboard');
    }).catch(() => {
      errorNotification(ERROR_MESSAGE);
    })
  };

  const cancel = () => {
    reset();
    history.push('/admin/dashboard');
  };

  const renderAvatar = () => {
    return (
      <>
        <Avatar
          mx="auto"
          src={user?.avatar?.path}
          h="87px"
          w="87px"
          mt="-43px"
          mb="15px"
        />
        <Flex {...getRootProps()} justifyContent="center">
          <Input
            {...getInputProps()}
            variant="main"
            type="file"
            size="222"
            accept="image/apng, image/avif, image/gif, image/jpeg, image/png, image/svg+xml, image/webp"
          />
          <Icon
            padding="3px"
            borderRadius="40px"
            bg={iconBackground}
            as={BsCameraFill}
            w="27px"
            h="27px"
            color={textColor}
            position="absolute"
            mt="-10"
            ml="14"
          />
        </Flex>
      </>
    );
  }

  return (
    <>
      <Flex direction="column" zIndex="201" pos="fixed">
        <Text fontSize="33px" lineHeight="63px" color={headerColor} zIndex="1" fontWeight="700">
          My account
        </Text>
      </Flex>
      <Box pt={{ base: '180px', md: '130px', xl: '130px' }}>
        {isLoading}
        <Flex justifyContent="flex-end" mb="20px">
          <BrandedButton onClick={editUserHandler}>
            <Icon
              as={HiOutlinePlusSm}
              w="22px"
              h="22px"
              color="brandColor.300"
              mr="2"
            />
            <Text color="brandColor.300" as="span">
              SAVE CHANGES
            </Text>
          </BrandedButton>
          <Button m="1" type="submit" onClick={cancel}>
            <Text color="brandColor.300" as="span">
              CANCEL
            </Text>
          </Button>
        </Flex>
        <SimpleGrid
          mb="20px"
          mt="10"
          columns={{ sm: 1, md: 1, lg: 2 }}
          spacing={{ base: '20px', xl: '20px' }}
        >
          <Flex direction="column">
            <Card alignItems="center" w="100%" pb="20px">
              <Image
                src={banner}
                borderRadius="16px"
                maxH="120px"
                position="relative"
              />
              <Text color="red" align="center">
                {isError}
              </Text>
              <Text
                fontSize="2xl"
                textColor={textColorPrimary}
                fontWeight="700"
              >
                {userInfo.firstName} {userInfo.lastName}
              </Text>
              <Text fontWeight="600">{currentRole}</Text>
            </Card>
          </Flex>
          <Flex direction="column">
            <Card
              flexDirection="column"
              py="20px"
              pb="40px"
              alignSelf="center"
              w="100%"
              bgPosition="10%"
            >
              <FormControl pt="20px" isRequired>
                <FormLabel mt="10px">First Name</FormLabel>
                <Input
                  mb="20px"
                  me="30px"
                  id="first_name"
                  defaultValue={userInfo.firstName}
                  onChange={(e) =>
                    setUserInfo({
                      ...userInfo,
                      firstName: e.target.value
                    })
                  }
                />
                <FormLabel mt="10px">Last Name</FormLabel>
                <Input
                  mb="20px"
                  me="30px"
                  id="last_name"
                  value={userInfo.lastName}
                  onChange={(e) =>
                    setUserInfo({
                      ...userInfo,
                      lastName: e.target.value
                    })
                  }
                />
                <FormLabel mt="10px">Email</FormLabel>
                <Input
                  mb="20px"
                  me="30px"
                  id="email"
                  value={userInfo.email}
                  onChange={(e) =>
                    setUserInfo({
                      ...userInfo,
                      email: e.target.value
                    })
                  }
                />
                <FormLabel ms="4px"> Language</FormLabel>
                <Select
                  icon={<MdArrowDropDown />}
                  borderRadius={16}
                  mb="5px"
                  value={userInfo.lang}
                  onChange={(e) =>
                    setUserInfo({
                      ...userInfo,
                      lang: e.target.value
                    })
                  }
                >
                  {languages.map((lang, key) => (
                    <option key={key} value={lang.name}>
                      {lang.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Card>
          </Flex>
        </SimpleGrid>
      </Box>
    </>
  );
}
