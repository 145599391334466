import { isEmpty, isNil, omit } from 'lodash';
import moment from 'moment';
import { mappingCityPostalCode } from 'zipCodes';

import {
  ActionTypeType,
  BrandName,
  CityZipPair,
  FilterStatus,
  IActionType,
  IActionTypeDTOForSave,
  IActionTypeLangState,
  IActionTypeState,
  IActivation,
  IAddon,
  IAddonDTOSave,
  IAddonState,
  IAddonType,
  IBrand,
  ICategory, Iconfig,
  IImage,
  ILang,
  INewActivationResponse,
  IUser,
  Lang
} from 'shared/types';
import { nullTimestamp } from '../views/admin/overview/create-activation/constants';

export const getZipCodeSuggestion = (inputValue: string, count: number = 5) => {

  return mappingCityPostalCode
    .filter((pair: CityZipPair) => pair.zip.startsWith(inputValue))
    .map(pair => (
      {
        code: pair.zip,
        city: pair.city
      }
    ))
    .slice(0, count > 1 ? count - 1 : 4)
}

export const getCitySuggestion = (inputValue: string, count: number = 5) => {


  return mappingCityPostalCode
    .filter((pair: CityZipPair) => pair.city.toLowerCase().startsWith(inputValue.toLowerCase()))
    .map(pair => (
      {
        code: pair.zip,
        city: pair.city
      }
    ))
    .slice(0, count > 1 ? count - 1 : 4)
}

export const getRoles = (user: IUser): [string | undefined, string | undefined] => {
  const roles = user.brands.map(b => ({ id: b.role.id, name: b.brand.name }))
  const telenetRoleId = roles.find(role => role.name === BrandName.Telenet)?.id;
  const baseRoleId = roles.find(role => role.name === BrandName.Base)?.id;

  return [telenetRoleId, baseRoleId];
}

export const getRoleId = (user: IUser, brandName: BrandName): string => {
  if (isNil(user) || isNil(user.brands)) {
    return '';
  }

  const currentBrand = user.brands.find(brand => brand.brand.name === brandName)

  return currentBrand?.role?.id || '';
}

export const getBrandId = (allBrands: IBrand[] = [], brandName: BrandName): string => {
  return allBrands.find((item) => item.name === brandName)?.id;
}

export const getDefaultStateAddon = (defaultAddonType: IAddonType, langs: ILang[], addon?: IAddon): IAddonState => {

  const defaultStateKeys: Array<{ key: string; value: string }> = langs.map(lang => {
    const translation = addon?.translations?.find(t => t.lang === lang.name) || { name: '', lang: lang.name }

    return { key: lang.name, value: translation.name }
  });

  let defaultState = {} as { [x: string]: string };

  defaultStateKeys.forEach(i => {
    defaultState[i.key] = i.value;
  });

  return addon
    ? {
      ...addon,
      names: defaultState
    }
    : {
      names: defaultState,
      id: '',
      addonType:  defaultAddonType,
      image: {} as IImage,
      translations: []
    }
}

export const debug = (msg: any, ...options: any[]) => {
  if (!window.location.host.includes('localhost')) return
  console.log(msg, ...options)
}

export const addonsDateString = (deliveryAddonsOnActionDate: boolean, actionDate: string, addonDeliveryDate: string) => {
  return deliveryAddonsOnActionDate
    ? actionDate
      ? moment(actionDate).format('DD/MM/YYYY')
      : 'dd/mm/yyyy'
    : addonDeliveryDate
      ? moment(addonDeliveryDate).format('DD/MM/YYYY')
      : 'dd/mm/yyyy'
}

export const formatDateToLocaleDateString = (str: string) => str ? (new Date(str)).toLocaleDateString() : 'Incorrect date'

export const formatDateToDateWithWeekDay = (str: string) => str ? (new Date(str)).toDateString() : 'Incorrect date'

export const timeFormat = (str: string) => {
  return (new Date(str)).toUTCString() === nullTimestamp
    ? 'no time'
    : new Date(str).toLocaleTimeString()
}

export const statusDone = (timestamp: string): string => {
  return (new Date(timestamp)).getTime() > (new Date()).getTime() ? "done" : ""
}

export const transformActivationDTOtoActivation = (input: INewActivationResponse): IActivation => {

  const activation: IActivation = {
    ...omit(input, ['actionType', 'id', 'requestedBy', 'shop']) as INewActivationResponse,
    requestedById: input.requestedBy.id,
    addons: input.addons.map((it) => it.actionTypeAddon.addon.id),
    actionTypeId: input.actionType.id
  }

  return activation
}

export const getCurrentRole = (user: IUser, brandName: BrandName): { brandName: string; role: string } => {
  const brand = user.brands?.find(b => b.brand.name === brandName) || user.brands[0];

  if (isEmpty(brand)) {
    return { brandName: '', role: '' }
  }

  return { brandName: brand.brand.name, role: brand.role.name.toLowerCase() };
}

export const getDefaultActionTypeState = (langs: ILang[], defaultBrand: IBrand, actionTypeNl?: IActionType, actionTypeFr?: IActionType): IActionTypeState => {
  const defaultStateKeys: Array<{ key: string; value: IActionTypeLangState }> = langs.map(lang => {
    let value = {
      name: '',
      infoDoc: {} as IImage,
      extraInfo: '',
    };

    if (!isNil(actionTypeNl)) {
      value = {
        name: (lang.name === Lang.nl ? actionTypeNl.name : actionTypeFr.name) || '',
        infoDoc: (lang.name === Lang.nl ? actionTypeNl.infoDoc : actionTypeFr.infoDoc) || {} as IImage,
        extraInfo: (lang.name === Lang.nl ? actionTypeNl.extraInfo : actionTypeFr.extraInfo) || '',
      }
    }

    return { key: lang.name, value }
  });

  const addons = actionTypeNl?.addons ? actionTypeNl.addons.map(addon => ({ ...addon.addon })) : []
  const visibility = addons.filter(addon => addon?.addonType?.name === ActionTypeType.Visibility);
  const others = addons.filter(addon => addon?.addonType?.name === ActionTypeType.Required);
  let defaultState: IActionTypeState = {
    transition: {} as { [lang: string]: IActionTypeLangState; },
    brand: actionTypeNl?.brand || defaultBrand,
    active: Boolean(actionTypeNl?.active),
    category: actionTypeNl?.category || {} as ICategory,
    addons: {
      visibility,
      others,
    },
  }

  defaultStateKeys.forEach(i => {
    defaultState.transition[i.key] = i.value;
  });

  return defaultState;
}

export const searchAddonsByName = (addons: IAddon[], lang: Lang, search: string): IAddon[] => {
  return addons.filter((addon) => {
    const name = addon.translations.find(t => t.lang === lang)?.name || '';

    return name.match(search);
  });
}

export const getQueryParams = (query: string): { duplicate: boolean } => {
  const validQuery = query.replace('?', '');
  const params = validQuery.split('&');
  let duplicate = false;

  params.forEach((param: string) => {
    const [key, value] = param.split('=');

    if (key === 'duplicate') {
      duplicate = Boolean(value);
    }

  })

  return { duplicate };
}

export const getActionTypeDataForSave = (state: IActionTypeState, lang: Lang, isUpdate?: boolean): IActionTypeDTOForSave => {
  const translate = state.transition[lang];
  const addons = [...state.addons.others, ...state.addons.visibility].map(addon => (addon.id));

  if (isUpdate) {
    return {
      infoDocId: translate.infoDoc.id,
      infoDocPath: translate.infoDoc.path,
      name: translate.name,
      extraInfo: translate.extraInfo,
    }
  }

  return {
    brandId: state.brand.id,
    infoDocId: translate.infoDoc.id,
    infoDocPath: translate.infoDoc.path,
    categoryId: state.category.id,
    addons,
    name: translate.name,
    extraInfo: translate.extraInfo,
    active: state.active,
  }
}

export const getAddonDTO = (state: IAddonState, langs: ILang[]): { data: IAddonDTOSave, errors: string[] } => {
  const errors: string[] = [];
  const data: IAddonDTOSave = {
    imageId: state.image.id,
    addonTypeId: state.addonType.id,
    translations: [],
  }

  langs.forEach(l => {
    if (!isEmpty(state.names[l.name])) {
      data.translations.push({ lang: l.name, name: state.names[l.name] });
    } else {
      errors.push(`need add name for ${l.name} language`);
    }
  });

  return {
    data, errors
  }
}

export const getApproveColor = (approved: boolean, status?: FilterStatus) => {
  switch (status) {
    case(FilterStatus.CANCELLED): return '#A3AED0'
    default: {
      return approved ? '#01B574' : '#FFC421'
    }
  }
}

export const getCalendarBg = (status: FilterStatus) => {
  switch (status) {
    case(FilterStatus.DONE):      return '#C9FBD5'
    case(FilterStatus.CANCELLED): return '#F6F7FB'
    case(FilterStatus.PENDING):   return '#FFF6DA'
    case(FilterStatus.ACTIVE):    return '#E9E3FF'
  }
}

export const getDetailStatuses = ({ firstStatus, shopStatus, dateStatus, adminStatus, overviewStatus }: Iconfig) => {

}

export const getDomainForFile = () => {
  const domain = window.location.origin;
  const host = window.location.host;

  if (host.includes('localhost')) {
    return process.env.REACT_APP_FILE_LINK
  }

  return `${domain}/@/file-storage/`
}
