import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button
} from '@chakra-ui/react';
import { FC, useRef } from 'react';
import { isEmpty } from 'lodash';
import { useConfirm } from 'shared/hooks';

export const ConfirmPopup: FC<{}> = () => {
  const cancelRef = useRef();
  const { state, onClose } = useConfirm();

  return (
    <AlertDialog
      isOpen={state.isOpen}
      leastDestructiveRef={cancelRef}
      onClose={state.cancel}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            {state.title}
          </AlertDialogHeader>

          <AlertDialogBody>
            {state.description}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={state.cancel}>Cancel</Button>
            <Button colorScheme='red' onClick={state.confirmPopup} ml={3}>
              {isEmpty(state.confirmButton) ? 'Confirm' : state.confirmButton}
            </Button>
          </AlertDialogFooter>

        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
