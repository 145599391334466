import { Icon } from '@chakra-ui/react';
import {
  MdContacts,
  MdHome,
  MdOutlineFormatListBulleted,
  MdSettings
} from 'react-icons/md';

// Admin Imports
import DashboardsDefault from 'views/admin/dashboard';

//Overview Imports
import OverviewActivationDetails from 'views/admin/overview/activation-details/new-detail-page';

import ActivationsOverview from 'views/admin/overview/activation-overview';
import Calendar from 'views/admin/overview/calendar';

//Settings account and contacts
import SettingsAccount from 'views/admin/account';
import SettingsContacts from 'views/admin/contacts';

//Settings users
import SettingsUsers from 'views/admin/settings/users';
import {
  SettingsAddUser,
} from 'views/admin/settings/users/new-user';

//Settings actions
import SettingsActionTypes from 'views/admin/settings/action-types';
import SettingsActionTypesAddOns from 'views/admin/settings/add-ons';
import { CreateActionType } from 'views/admin/settings/create';

// Auth Imports
import SignInDefault from 'views/auth/signIn/SignInDefault';
import SignUpDefault from 'views/auth/signUp/SignUpDefault';
import { IRoute } from './layouts/types';
import { SetPassword } from './views/auth/setPassword';
import ForgotPassword from './views/auth/forgotPassword/ForgotPasswordDefault';
import { ActivationPage } from './views/admin/overview/create-activation/activation-page';
import { withRouter } from 'react-router-dom';

export const routes: IRoute[] = [
  // --- Dashboards ---
  {
    name: 'Dashboard',
    layout: '/admin',
    path: '/dashboard',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: DashboardsDefault,
    secondary: true
  },

  //Overview
  {
    name: 'Overview',
    path: '/overview',
    icon: (
      <Icon
        width="20px"
        height="20px"
        as={MdOutlineFormatListBulleted}
        color="inherit"
      />
    ),
    collapse: true,
    items: [
      {
        name: 'Activations',
        layout: '/admin',
        path: '/overview/view_activations_list',
        component: withRouter(ActivationsOverview),
        secondary: true
      },
      {
        name: 'Calendar',
        layout: '/admin',
        path: '/overview/calendar',
        component: Calendar,
        secondary: true
      },
      {
        name: '',
        layout: '/admin',
        path: '/overview/edit_activation/:activationId',
        component: ActivationPage,
        secondary: true
      },
      {
        name: '',
        layout: '/admin',
        path: '/overview/create_activation/:activationId',
        component: ActivationPage,
        secondary: true
      },
      {
        name: '',
        layout: '/admin',
        path: '/overview/create_activation',
        component: ActivationPage,
        secondary: true
      },
      {
        name: '',
        layout: '/admin',
        path: '/overview/view_activation_details/:activationId',
        component: OverviewActivationDetails,
        secondary: true
      }
    ]
  },

  // --- Settings ---
  {
    name: 'Settings',
    path: '/settings',
    icon: <Icon as={MdSettings} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: 'Action Types',
        layout: '/admin',
        path: '/settings/view_action_types_list',
        component: SettingsActionTypes,
        secondary: true
      },
      {
        name: 'Add-ons',
        layout: '/admin',
        path: '/settings/view_addons_list',
        component: SettingsActionTypesAddOns,
        secondary: true
      },
      {
        name: 'Users',
        path: '/settings/users',
        collapse: true,
        items: [
          {
            name: 'Users',
            layout: '/admin',
            path: '/settings/view_users_list',
            component: SettingsUsers,
            secondary: true
          },
          {
            name: 'Add New User',
            layout: '/admin',
            path: '/settings/create_user',
            component: SettingsAddUser,
            secondary: true
          },
        ]
      },
      {
        name: '',
        layout: '/admin',
        path: '/settings/edit_user/:id',
        component: SettingsAddUser,
        secondary: true
      },
      {
        name: '',
        layout: '/admin',
        path: '/settings/create_action_type',
        component: CreateActionType,
        secondary: true
      },
      {
        name: '',
        layout: '/admin',
        path: '/settings/edit_action_type/:id',
        component: CreateActionType,
        secondary: true
      },
      {
        name: '',
        layout: '/admin',
        path: '/view_my_account',
        component: SettingsAccount,
        secondary: true
      },
      // --- Sign In ---
      {
        name: '',
        layout: '/auth',
        path: '/sign-in/default',
        component: SignInDefault,
        secondary: true
      },
      // --- Sign Up ---
      {
        name: '',
        layout: '/auth',
        path: '/sign-up/default',
        component: SignUpDefault,
        secondary: true
      },
      {
        name: '',
        layout: '/auth',
        path: '/set-password/:token',
        component: SetPassword,
        secondary: true
      },
      {
        name: '',
        layout: '/auth',
        path: '/lost-my-access',
        component: ForgotPassword,
        secondary: true
      }
    ]
  },
  {
    name: 'Contact & help',
    layout: '/admin',
    path: '/contacts',
    component: SettingsContacts,
    icon: <Icon as={MdContacts} width="20px" height="20px" color="inherit" />,
    secondary: true
  },
];

export const manager = [
  // --- Dashboards ---
  {
    name: 'Dashboard',
    layout: '/manager',
    path: '/dashboard',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: DashboardsDefault,
    secondary: true
  },

  //Overview
  {
    name: 'Overview',
    path: '/overview',
    icon: (
      <Icon
        width="20px"
        height="20px"
        as={MdOutlineFormatListBulleted}
        color="inherit"
      />
    ),
    collapse: true,
    items: [
      {
        name: 'Activations',
        layout: '/manager',
        path: '/overview/view_activations_list',
        component: withRouter(ActivationsOverview),
        secondary: true
      },
      {
        name: 'Calendar',
        layout: '/manager',
        path: '/overview/calendar',
        component: Calendar,
        secondary: true
      },
      {
        name: '',
        layout: '/manager',
        path: '/overview/create_activation/:activationId',
        component: ActivationPage,
        secondary: true
      },
      {
        name: '',
        layout: '/manager',
        path: '/overview/create_activation',
        component: ActivationPage,
        secondary: true
      },
      {
        name: '',
        layout: '/manager',
        path: '/overview/edit_activation/:activationId',
        component: ActivationPage,
        secondary: true
      },
      {
        name: '',
        layout: '/manager',
        path: '/overview/view_activation_details/:activationId',
        component: OverviewActivationDetails,
        secondary: true
      }
    ]
  },

  // --- Settings ---
  {
    name: 'Settings',
    path: '/settings',
    icon: <Icon as={MdSettings} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: 'Action Types',
        layout: '/manager',
        path: '/settings/view_action_types_list',
        component: SettingsActionTypes,
        secondary: true
      },
      {
        name: 'Add-ons',
        layout: '/manager',
        path: '/settings/view_addons_list',
        component: SettingsActionTypesAddOns,
        secondary: true
      },

      {
        name: '',
        layout: '/manager',
        path: '/settings/create_action_type',
        component: CreateActionType,
        secondary: true
      },
      {
        name: '',
        layout: '/manager',
        path: '/settings/edit_action_type/:id',
        component: CreateActionType,
        secondary: true
      },
      {
        name: '',
        layout: '/manager',
        path: '/view_my_account',
        component: SettingsAccount,
        secondary: true
      },
      // --- Sign In ---
      {
        name: '',
        layout: '/auth',
        path: '/sign-in/default',
        component: SignInDefault,
        secondary: true
      },
      // --- Sign Up ---
      {
        name: '',
        layout: '/auth',
        path: '/sign-up/default',
        component: SignUpDefault,
        secondary: true
      }
    ]
  },
  {
    name: 'Contact & help',
    layout: '/manager',
    path: '/contacts',
    component: SettingsContacts,
    icon: <Icon as={MdContacts} width="20px" height="20px" color="inherit" />,
    secondary: true
  }
];

export const requester = [
  // --- Dashboards ---
  {
    name: 'Dashboard',
    layout: '/requester',
    path: '/dashboard',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: DashboardsDefault,
    secondary: true
  },

  //Overview
  {
    name: 'Overview',
    path: '/overview',
    icon: (
      <Icon
        width="20px"
        height="20px"
        as={MdOutlineFormatListBulleted}
        color="inherit"
      />
    ),
    collapse: true,
    items: [
      {
        name: 'Activations',
        layout: '/requester',
        path: '/overview/view_activations_list',
        component: withRouter(ActivationsOverview),
        secondary: true
      },
      {
        name: 'Calendar',
        layout: '/requester',
        path: '/overview/calendar',
        component: Calendar,
        secondary: true
      },
      {
        name: '',
        layout: '/requester',
        path: '/overview/create_activation/:activationId',
        component: ActivationPage,
        secondary: true
      },
      {
        name: '',
        layout: '/requester',
        path: '/overview/create_activation',
        component: ActivationPage,
        secondary: true
      },
      {
        name: '',
        layout: '/requester',
        path: '/overview/edit_activation/:activationId',
        component: ActivationPage,
        secondary: true
      },
      {
        name: '',
        layout: '/requester',
        path: '/overview/view_activation_details/:activationId',
        component: OverviewActivationDetails,
        secondary: true
      }
    ]
  },

  // --- Settings ---
  {
    name: 'Settings',
    path: '/settings',
    icon: <Icon as={MdSettings} width="20px" height="20px" color="inherit" />,
    collapse: true,
    hide: true,
    items: [
      {
        name: '',
        layout: '/requester',
        path: '/settings/view_action_types_list',
        component: SettingsActionTypes,
        secondary: true
      },

      {
        name: '',
        layout: '/requester',
        path: '/settings/create_action_type',
        component: CreateActionType,
        secondary: true
      },
      {
        name: '',
        layout: '/requester',
        path: '/settings/edit_action_type/:id',
        component: CreateActionType,
        secondary: true
      },
      {
        name: '',
        layout: '/requester',
        path: '/view_my_account',
        component: SettingsAccount,
        secondary: true
      },
      // --- Sign In ---
      {
        name: '',
        layout: '/auth',
        path: '/sign-in/default',
        component: SignInDefault,
        secondary: true
      },
      // --- Sign Up ---
      {
        name: '',
        layout: '/auth',
        path: '/sign-up/default',
        component: SignUpDefault,
        secondary: true
      }
    ]
  },
  {
    name: 'Contact & help',
    layout: '/requester',
    path: '/contacts',
    component: SettingsContacts,
    icon: <Icon as={MdContacts} width="20px" height="20px" color="inherit" />,
    secondary: true
  },
];
