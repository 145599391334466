import { useMemo } from 'react';
import {
  Badge,
  Button,
  Flex,
  Icon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue
} from '@chakra-ui/react';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { currentRoleSelector, FilterActionType } from 'data/selectors';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable
} from 'react-table';
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from 'recoil';
import { ActivationMenu } from 'components/menu/ActivationMenu';
import { IAddon, Lang } from 'shared/types';
import { useHistory } from 'react-router-dom';
import { api } from 'utils/repository';
import { AxiosError } from 'axios';
import { useConfirm, useNotification } from 'shared/hooks';
import { ERROR_MESSAGE } from 'shared/constants';
import { getDomainForFile } from '../../../../../shared/utils';

function TableActionTypeOverview(props: any) {
  const { columnsData, tableData } = props;
  const role = useRecoilValue(currentRoleSelector);
  const history = useHistory();
  const { successNotification, errorNotification } = useNotification();
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const { onConfirm } = useConfirm();
  const refreshList = useRecoilRefresher_UNSTABLE(FilterActionType);

  const tableInstance = useTable(
    {
      columns,
      data
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    gotoPage,
    pageCount,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
    state
  } = tableInstance;

  const handleEdit = (id: string) => {
    history.push(`/${role}/settings/edit_action_type/${id}`);
  }

  const handleDuplicate = (id: string) => {
    history.push(`/${role}/settings/edit_action_type/${id}?duplicate=true`);
  }

  const handleRemove = (id: string) => {
    onConfirm('Warning', 'Are you sure?', 'Delete').then(() => {
      api.deleteActionType(Lang.fr, id)
        .then(() => {
          refreshList();
          successNotification('Action type is deleted');
        })
        .catch((error: AxiosError<{ message: string }>) => {
          errorNotification(error.response.data.message || ERROR_MESSAGE)
        })
    })

  }

  const createPages = (count: number) => {
    let arrPageCount = [];

    for (let i = 1; i <= count; i++) {
      arrPageCount.push(i);
    }

    return arrPageCount;
  };

  const { pageIndex, pageSize } = state;
  // Chakra Color Mode

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  return (
    <>
      <Flex
        direction="column"
        w="100%"
        overflowX={{ sm: 'scroll', lg: 'hidden' }}
      >
        <Flex
          align={{ sm: 'flex-start', lg: 'flex-start' }}
          justify={{ sm: 'flex-start', lg: 'flex-start' }}
          w="100%"
          px="22px"
          mb="36px"
        >
          <SearchBar
            onChange={(e: any) => setGlobalFilter(e.target.value)}
            h="44px"
            w={{ lg: '390px' }}
            borderRadius="16px"
          />
        </Flex>
        <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="10px"
                    key={index}
                    borderColor={borderColor}
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: '10px', lg: '12px' }}
                      color="gray.400"
                    >
                      {column.render('Header')}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = null;


                    if (cell.column.Header === 'NAME') {
                      data = (
                        <Flex align="center">
                          <Text
                            color={textColor}
                            fontSize="md"
                            fontWeight="500"
                          >
                            {cell.value}
                          </Text>
                        </Flex>
                      );
                    } else if (cell.column.Header === 'EXTRA INFO') {
                      data = (
                        <Text color={textColor} fontSize="md" fontWeight="500">
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === 'ADD-ONS') {
                      let otherAddonsCounter = 0;
                      let visAddonsCounter = 0;
                      cell.value.forEach((val: { addon: IAddon}) => {
                        if (val.addon.addonType.name === 'Others') {
                          otherAddonsCounter++;
                        } else {
                          visAddonsCounter++;
                        }
                      })
                      data = (
                        <Flex align="center" direction="column">
                          <Text
                            color={textColor}
                            fontSize="md"
                            fontWeight="medium"
                            whiteSpace={'nowrap'}
                          >
                            {`${visAddonsCounter} visibility`}
                          </Text>
                        </Flex>
                      );
                    } else if (cell.column.Header === 'INFO DOC') {
                      data = (
                        <Badge
                          bg={'red.500'}
                          color={'white'}
                          fontSize="md"
                          fontWeight="500"
                          cursor="pointer"
                        >
                          <a
                            rel={'noreferrer'}
                            target={'_blank'}
                            href={
                              `${getDomainForFile()}` +
                              cell.value.path
                            }
                          >
                            pdf file
                          </a>
                        </Badge>
                      );
                    } else if (cell.column.Header === '') {
                      data = (
                        <ActivationMenu
                          onEdit={() => handleEdit(cell.value)}
                          onDuplicate={() => handleDuplicate(cell.value)}
                          onRemove={() => handleRemove(cell.value)}
                        />
                      );
                    }
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        borderColor={borderColor}
                      >
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <Flex
          direction={{ sm: 'column', md: 'row' }}
          justify="space-between"
          align="center"
          w="100%"
          px={{ md: '22px' }}
        >
          <Text
            fontSize="sm"
            color="gray.500"
            fontWeight="normal"
            mb={{ sm: '24px', md: '0px' }}
          >
            Showing {pageSize * pageIndex + 1} to{' '}
            {pageSize * (pageIndex + 1) <= tableData.length
              ? pageSize * (pageIndex + 1)
              : tableData.length}{' '}
            of {tableData.length} entries
          </Text>
          <Stack direction="row" alignSelf="flex-end" spacing="4px" ms="auto">
            <Button
              variant="no-effects"
              onClick={() => previousPage()}
              transition="all .5s ease"
              w="40px"
              h="40px"
              borderRadius="50%"
              bg="transparent"
              border="1px solid"
              borderColor={useColorModeValue('gray.200', 'white')}
              display={
                pageSize === 5 ? 'none' : canPreviousPage ? 'flex' : 'none'
              }
              bgColor="brandColor.400"
              _hover={{ bg: 'brandColor.100' }}
            >
              <Icon
                as={MdChevronLeft}
                w="16px"
                h="16px"
                color="brandColor.900"
              />
            </Button>
            {pageSize === 5 ? (
              <NumberInput
                max={pageCount - 1}
                min={1}
                w="75px"
                mx="6px"
                defaultValue="1"
                onChange={(e) => gotoPage(Number(e))}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper onClick={() => nextPage()} />
                  <NumberDecrementStepper onClick={() => previousPage()} />
                </NumberInputStepper>
              </NumberInput>
            ) : (
              createPages(pageCount).map((pageNumber, index) => {
                return (
                  <Button
                    variant="no-effects"
                    transition="all .5s ease"
                    onClick={() => gotoPage(pageNumber - 1)}
                    w="40px"
                    h="40px"
                    borderRadius="50%"
                    bg={
                      pageNumber === pageIndex + 1
                        ? 'brandColor.400'
                        : 'transparent'
                    }
                    border={
                      pageNumber === pageIndex + 1
                        ? 'none'
                        : '1px solid lightgray'
                    }
                    _hover={
                      pageNumber === pageIndex + 1
                        ? {
                            opacity: '0.7'
                          }
                        : {
                            bg: 'brandColor.900'
                          }
                    }
                    key={index}
                  >
                    <Text
                      fontSize="sm"
                      color={
                        pageNumber === pageIndex + 1 ? '#fff' : 'brandColor.100'
                      }
                    >
                      {pageNumber}
                    </Text>
                  </Button>
                );
              })
            )}
            <Button
              variant="no-effects"
              onClick={() => nextPage()}
              transition="all .5s ease"
              w="40px"
              h="40px"
              borderRadius="50%"
              bgColor="transparent"
              border="1px solid"
              borderColor={useColorModeValue('gray.200', 'white')}
              display={pageSize === 5 ? 'none' : canNextPage ? 'flex' : 'none'}
              _hover={{ bg: 'brandColor.100' }}
            >
              <Icon
                as={MdChevronRight}
                w="16px"
                h="16px"
                color="brandColor.900"
              />
            </Button>
          </Stack>
        </Flex>
      </Flex>
    </>
  );
}

export default TableActionTypeOverview;
