import { useState } from 'react';
//Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Input,
  Select,
  Tab,
  TabList,
  Tabs,
  Text,
  useColorModeValue
} from '@chakra-ui/react';

// Custom components
import { SearchBar } from 'components/search/Search';
import { HSeparator } from 'components/separator/Separator';
// Custom imports
import { BrandedButton } from 'components/buttons/BrandedButton';
// Assets
import { DownloadIcon } from '@chakra-ui/icons';
import { MdCalendarToday, MdFormatListBulleted } from 'react-icons/md';
import { useRecoilValue } from 'recoil';
import { currentRoleSelector, GetActionType, GetAllUsers, GetCounters } from 'data/selectors';
import { FilterStatus, IActionType, IUser } from 'shared/types';
import { Link } from 'react-router-dom';

interface ICounters {
  all: number;
  pending: number;
  active: number;
  cancelled: number;
  done: number;
}
type Props = {
  setStatus?: (status: FilterStatus) => void;
  setSelectedAction?: (selectedAction: string) => void;
  setDateFilter?: (date: string) => void;
  setUserFilter?: (user: string) => void;
  setSearchQuery?: (searchQuery: string) => void;
  status?: FilterStatus;
  selectedAction?: string;
  dateFilter?: string;
  userFilter?: string;
  searchQuery?: string;
  hiddenFilters?: boolean;
}

function Filter({
  setStatus = (status: FilterStatus) => console.log('status', status),
  setSelectedAction,
  setDateFilter,
  setUserFilter,
  status = FilterStatus.ALL,
  selectedAction,
  dateFilter,
  userFilter,
  searchQuery,
  setSearchQuery,
  hiddenFilters,
}: Props) {
  // let [status, setTabState] = useState('all');
  const role = useRecoilValue(currentRoleSelector);
  const actionTypes = useRecoilValue<IActionType[]>(GetActionType);
  const users = useRecoilValue<IUser[]>(GetAllUsers);
  const counters = useRecoilValue<ICounters>(GetCounters);

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const buttonBg = useColorModeValue('transparent', 'navy.800');
  const underlineColor = useColorModeValue('brandColor.400', 'brandColor.400');
  const activeButton = useColorModeValue(
    { bg: 'gray.200' },
    { bg: 'whiteAlpha.200' }
  );
  const paleGray = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');
  const borderColor = useColorModeValue(
    'secondaryGray.100',
    'whiteAlpha.100'
  )
  return (
    <>
      {/*<Flex align="center" w="100%" justifyContent="flex-end">*/}
      {/*  <BrandedButton disabled={true}>*/}
      {/*    <DownloadIcon w="14px" h="14px" mr={3} color="brandColor.300" />*/}
      {/*    <Text color="brandColor.300" as="span">*/}
      {/*      Import In Bulk*/}
      {/*    </Text>*/}
      {/*  </BrandedButton>*/}
      {/*</Flex>*/}

      <Tabs variant="soft-rounded" colorScheme="brandTabs">
        <Tabs variant="soft-rounded" colorScheme="brandTabs">
          <TabList mx="0" overflowX={{ sm: 'scroll', lg: 'unset' }} mt={10}>
            <Tab
              pb="0px"
              flexDirection="column"
              onClick={function () {
                setStatus(FilterStatus.ALL);
              }}
              me="50px"
              bg="unset"
              _selected={{
                bg: 'none'
              }}
              _focus={{ border: 'none' }}
              minW="max-content"
            >
              <Flex align="center">
                <Text color={textColor} fontSize="lg" fontWeight="500" me="12px">
                  All
                </Text>
                <Text color="secondaryGray.600" fontSize="md" fontWeight="400">
                  {counters.all}
                </Text>
              </Flex>
              <Box
                height="4px"
                w="100%"
                transition="0.1s linear"
                bg={status === FilterStatus.ALL ? underlineColor : 'transparent'}
                mt="15px"
                borderRadius="30px"
              />
            </Tab>
            <Tab
              onClick={function () {
                setStatus(FilterStatus.ACTIVE);
              }}
              pb="0px"
              me="50px"
              bg="unset"
              _selected={{
                bg: 'none'
              }}
              _focus={{ border: 'none' }}
              minW="max-content"
              flexDirection="column"
            >
              <Flex align="center">
                <Text color={textColor} fontSize="lg" fontWeight="500" me="12px">
                  Active
                </Text>
                <Text color="secondaryGray.600" fontSize="md" fontWeight="400">
                  {counters.active}
                </Text>
              </Flex>
              <Box
                height="4px"
                w="100%"
                transition="0.1s linear"
                bg={status === FilterStatus.ACTIVE ? underlineColor : 'transparent'}
                mt="15px"
                borderRadius="30px"
              />
            </Tab>
            <Tab
              pb="0px"
              flexDirection="column"
              onClick={function () {
                setStatus(FilterStatus.PENDING);
              }}
              me="50px"
              bg="unset"
              _selected={{
                bg: 'none'
              }}
              _focus={{ border: 'none' }}
              minW="max-content"
            >
              <Flex align="center">
                <Text
                  color={textColor}
                  fontSize="lg"
                  fontWeight="500"
                  me="12px"
                >
                  Pending
                </Text>
                <Text color="secondaryGray.600" fontSize="md" fontWeight="400">
                  {counters.pending}
                </Text>
              </Flex>
              <Box
                height="4px"
                w="100%"
                transition="0.1s linear"
                bg={status === FilterStatus.PENDING ? underlineColor : 'transparent'}
                mt="15px"
                borderRadius="30px"
              />
            </Tab>
            <Tab
              pb="0px"
              flexDirection="column"
              onClick={function () {
                setStatus(FilterStatus.CANCELLED);
              }}
              me="50px"
              bg="unset"
              _selected={{
                bg: 'none'
              }}
              _focus={{ border: 'none' }}
              minW="max-content"
            >
              <Flex align="center">
                <Text
                  color={textColor}
                  fontSize="lg"
                  fontWeight="500"
                  me="12px"
                >
                  Cancelled
                </Text>
                <Text color="secondaryGray.600" fontSize="md" fontWeight="400">
                  {counters.cancelled}
                </Text>
              </Flex>
              <Box
                height="4px"
                w="100%"
                transition="0.1s linear"
                bg={status === FilterStatus.CANCELLED ? underlineColor : 'transparent'}
                mt="15px"
                borderRadius="30px"
              />
            </Tab>
            <Tab
              pb="0px"
              flexDirection="column"
              onClick={function () {
                setStatus(FilterStatus.DONE);
              }}
              me="50px"
              bg="unset"
              _selected={{
                bg: 'none'
              }}
              _focus={{ border: 'none' }}
              minW="max-content"
            >
              <Flex align="center">
                <Text
                  color={textColor}
                  fontSize="lg"
                  fontWeight="500"
                  me="12px"
                >
                  Done
                </Text>
                <Text color="secondaryGray.600" fontSize="md" fontWeight="400">
                  {counters.done}
                </Text>
              </Flex>
              <Box
                height="4px"
                w="100%"
                transition="0.1s linear"
                bg={status === FilterStatus.DONE ? underlineColor : 'transparent'}
                mt="15px"
                borderRadius="30px"
              />
            </Tab>
          </TabList>
        </Tabs>
        <HSeparator mb="30px" bg={paleGray} mt="0px" />
        {!hiddenFilters && <Flex w="100%" mb={10}>
          <SearchBar
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}          />
          <Select
            fontSize="sm"
            // id="edit_product"
            placeholder='Choose action type'
            variant="main"
            h="44px"
            maxH="44px"
            me="20px"
            value={selectedAction}
            onChange={(e) => {
              setSelectedAction(e.target.value)
            }}
          >
            {actionTypes?.map(actionTypes => (
              <option
                key={actionTypes.id}
                value={actionTypes.name}
              >
                {actionTypes.name}
              </option>
            )
            )}
          </Select>
          <Input
            fontSize="sm"
            variant="main"
            h="44px"
            maxH="44px"
            me="20px"
            type={'date'}
            value={dateFilter}
            onChange={(e) => setDateFilter(e.target.value)}
          />
          <Select
            fontSize="sm"
            variant="main"
            h="44px"
            maxH="44px"
            me="20px"
            placeholder='Choose user'
            value={userFilter}
            onChange={(e) => {
              setUserFilter(e.target.value)
            }}
          >
            {users?.map(user => (
              <option
                key={user.id}
                value={user.id}
              >
                {user.firstName} {user.lastName}
              </option>
            )
            )}
          </Select>
          <Link to={`/${role}/overview/calendar`}>
            <Button
              me="20px"
              bg={buttonBg}
              border="1px solid"
              color="secondaryGray.600"
              borderColor={borderColor}
              borderRadius="16px"
              _placeholder={{ color: 'secondaryGray.600' }}
              _active={activeButton}
              _focus={activeButton}
            >
              <Icon color={textColor} as={MdCalendarToday} />
            </Button>
          </Link>
          <Link to={`/${role}/overview/view_activations_list`}>
            <Button
              bg={buttonBg}
              border="1px solid"
              color="secondaryGray.600"
              borderColor={borderColor}
              borderRadius="16px"
              _placeholder={{ color: 'secondaryGray.600' }}
              _active={activeButton}
              _focus={activeButton}
            >
              <Icon color={textColor} as={MdFormatListBulleted} />
            </Button>
          </Link>
        </Flex>}
      </Tabs>
    </>
  );
}

export default Filter;
