import { FC } from 'react';
import { Text, useColorModeValue } from '@chakra-ui/react';

export const TitleStep: FC<{
  title: string;
  description?: string;
}> = ({ title, description }) => {
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  return (
    <>
      <Text color={textColor} fontSize='2xl' fontWeight='700' lineHeight='100%' mb={description ? '2' : '20px'}>
        {title}
      </Text>
      {description && (
        <Text color={'secondaryGray.600'} fontSize='lg' mb='20px'>
          {description}
        </Text>
      )}
    </>
  )
}
