import {
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import { HSeparator } from 'components/separator/Separator';
import { isNil } from 'lodash';
import { HiDotsVertical } from 'react-icons/hi';
import { useState } from 'react';

type ThemeColors = {
  textColor?: string;
  iconColor?: string;
  hoverColor?: string;
  paleGray?: string;
}
type Props = {
  onEdit?(): void;
  onRemove?(): void;
  onDuplicate?(): void;
  onView?(): void;
  onCancel?(): void;
  themeColors?: ThemeColors;
  renderInPortal?: boolean;
}

export const ActivationMenu = ({
  onEdit,
  onRemove,
  onDuplicate,
  onView,
  onCancel,
  themeColors,
  renderInPortal,
}: Props) => {
  const defaultThemeColors = {
    textColor: useColorModeValue('navy.700', 'navy.700'),
    iconColor: useColorModeValue('navy.700', 'white'),
    hoverColor: useColorModeValue('white', 'gray.200'),
    paleGray: useColorModeValue('secondaryGray.400', 'secondaryGray.400'),
  }
  const {
    textColor,
    iconColor,
    hoverColor,
    paleGray,
  } = themeColors ?? defaultThemeColors

  const renderMenuList = () => (
    <MenuList
      p="0px"
      mt="10px"
      borderRadius="20px"
      border="none"
      bg="gray.50"
    >
      <Flex flexDirection="column" p="10px">
        {!isNil(onView) && (<MenuItem
          borderRadius="15px"
          cursor="pointer"
          fontSize="md"
          _hover={{ bg: hoverColor }}
          _focus={{ bg: 'none' }}
          fontWeight="700"
          color={textColor}
          ps="20px"
          pb="10px"
          w="100%"
          onClick={onView}
        >
          View
        </MenuItem>)}
        {!isNil(onEdit) && (<MenuItem
          borderRadius="15px"
          cursor="pointer"
          fontSize="md"
          _hover={{ bg: hoverColor }}
          _focus={{ bg: 'none' }}
          fontWeight="700"
          color={textColor}
          ps="20px"
          pb="10px"
          w="100%"
          onClick={onEdit}
        >
          Edit
        </MenuItem>)}
        {!isNil(onDuplicate) && (<MenuItem
          borderRadius="15px"
          cursor="pointer"
          fontSize="md"
          _hover={{ bg: hoverColor }}
          _focus={{ bg: 'none' }}
          fontWeight="700"
          color={textColor}
          ps="20px"
          pb="10px"
          w="100%"
          onClick={onDuplicate}
        >
          Duplicate
        </MenuItem>)}
        <HSeparator bg={paleGray} />
        {!isNil(onRemove) && (<MenuItem
          borderRadius="15px"
          cursor="pointer"
          _hover={{ bg: hoverColor }}
          _focus={{ bg: 'none' }}
          px="35px"
          fontSize="md"
          fontWeight="700"
          color={textColor}
          ps="20px"
          pb="10px"
          w="100%"
          onClick={onRemove}
        >
          <Text>Delete</Text>
        </MenuItem>)}
        {!isNil(onCancel) && (<MenuItem
          borderRadius="15px"
          cursor="pointer"
          _hover={{ bg: hoverColor }}
          _focus={{ bg: 'none' }}
          px="35px"
          fontSize="md"
          fontWeight="700"
          color={textColor}
          ps="20px"
          pb="10px"
          w="100%"
          onClick={onCancel}
        >
          <Text>Cancel</Text>
        </MenuItem>)}
      </Flex>
    </MenuList>
  )

  return (
    <Menu>
      <MenuButton p="0px" ml="7">
        <Icon
          cursor="pointer"
          as={HiDotsVertical}
          color={iconColor}
          w="auto"
          h="25px"
        />
      </MenuButton>
      {renderInPortal
        ? (<Portal>
          {renderMenuList()}
        </Portal>)
        : renderMenuList()}
    </Menu>
  );
};
