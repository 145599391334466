import { Icon, Text, Flex, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';

import { useRecoilState } from 'recoil';
import { activationStatusDetail } from 'data/atoms';
import OrderStep from 'components/dataDisplay/OrderStep';
import { Step } from 'shared/types';

export default function ActivationStepStatus(props: { [x: string]: any }) {
	const { ...rest } = props;
  const [stepDetail, setStepDetail] = useRecoilState(activationStatusDetail);
	// Chakra Color Mode
	const textColor = useColorModeValue('secondaryGray.900', 'white');

  const setActiveStep = (currentStep: Step) => {
    const updatedStatus = stepDetail.map(step => {
      if (step.step === currentStep) {
        return {
          ...step,
          active: true,
        }
      } else {
        return {
          ...step,
          active: false,
        }
      }
    })
    setStepDetail(updatedStatus);
  }

	return (
		<Card flexDirection='column' w='100%' pt='30px' pb='20px' {...rest}>
			<Text color={textColor} fontSize='2xl' fontWeight='700' lineHeight='100%' mb='40px'>
				Activation details overview
			</Text>
			<Flex position='relative' direction='column'>
        {stepDetail.map(step => (
          <OrderStep
            key={step.name}
            step={step}
            onEdit={() => setActiveStep(step.step)}
          />
        ))}
			</Flex>
		</Card>
	);
}
