export enum Lang {
  nl = 'nl',
  fr = 'fr',
}

export enum Step {
  activation = 1,
  shop = 2,
  date = 3,
  administration = 4,
  overview = 5,
}

export enum RoleStatus {
  pending = 'pending',
  approved = 'approved',
  declined = 'declined'
}

export enum ApproveStatus {
  PENDING = 'Pending',
  APPROVED = 'Approved',
  CANCELLED = 'Cancelled'
}

export enum BrandName {
  Base = 'Base',
  Telenet = 'Telenet',
}

export enum ActionTypeType {
  Required = 'Options (required)',
  Visibility = 'Visibility',
}

export enum BoxShadowEnum {
  base =  "base",
  outline =  "outline",
  darkLg =  "dark-lg",
  sm =  "sm",
  md =  "md",
  lg =  "lg",
  xl =  "xl",
  xxl =  "2xl",
  xs =  "xs",
  inner =  "inner",
}
