import { FC } from "react";
import { Flex, Spinner } from '@chakra-ui/react';

export const Preloader: FC<{ show: boolean }> = (props) => {
  if (!props.show) {
    return null;
  }

  return (
    <Flex position={'fixed'} width={'100vw'} height={'100vh'} top={0} left={0} alignItems={'center'} justifyContent={'center'} zIndex={'1000'} >
      <Spinner
        thickness='4px'
        speed='0.65s'
        emptyColor='gray.200'
        color='brandColor.400'
        size='xl'
      />
    </Flex>
  );
}
