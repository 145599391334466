/*eslint-disable*/

import { Flex, List, ListItem, Text, useColorModeValue } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import moment from 'moment/moment';
import { useRecoilValue } from 'recoil';
import { currentRoleSelector } from '../../data/selectors';

export default function Footer() {
	const textColor = useColorModeValue('gray.400', 'white');
  const role = useRecoilValue(currentRoleSelector);

	return (
		<Flex
			zIndex='3'
			flexDirection={{
				base: 'column',
				xl: 'row'
			}}
			alignItems={{
				base: 'center',
				xl: 'start'
			}}
			justifyContent='space-between'
			px={{ base: '30px', md: '50px' }}
			pb='30px'>
			<Text
				color={textColor}
				textAlign={{
					base: 'center',
					xl: 'start'
				}}
				mb={{ base: '20px', xl: '0px' }}>
				{' '}
				&copy; {moment().format('YYYY')}
				<Text as='span' fontWeight='500' ms='4px'>
          Planingtool. All Rights Reserved
				</Text>
			</Text>
			<List display='flex'>
				<ListItem
					me={{
						base: '20px',
						md: '44px'
					}}>
          <Link to={`/${role}/contacts`}>
            <Text fontWeight='500' color={textColor}>
              Contact & help
            </Text>
          </Link>
				</ListItem>

			</List>
		</Flex>
	);
}
