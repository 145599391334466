import { useColorModeValue } from '@chakra-ui/react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  brandSelector,
  currentRoleSelector,
  GetUsersInfo
} from 'data/selectors';
import { IBrand, IUser } from 'shared/types';
import { useHistory } from 'react-router-dom';
import { forceUpdateActivationList } from 'data/atoms';


import { Flex } from "@chakra-ui/react";
import { ActivationMenu } from "components/menu/ActivationMenu";
import { api } from "utils/repository";
import { useConfirm, useNotification } from 'shared/hooks';
import { EventContentArg } from '@fullcalendar/react';

const EventContent = (({ info }: { info: EventContentArg }) => {
    const role = useRecoilValue(currentRoleSelector);
    const history = useHistory();
  const [, refreshTableData] = useRecoilState(forceUpdateActivationList);
    const { errorNotification, successNotification } = useNotification();
    const { onConfirm } = useConfirm()
    const brand = useRecoilValue<IBrand>(brandSelector);
  const user = useRecoilValue<IUser>(GetUsersInfo);
    const themeColors = {
        textColor: useColorModeValue('navy.700', 'navy.700'),
        iconColor: useColorModeValue('white', 'white'),
        hoverColor: useColorModeValue('white', 'gray.200'),
        paleGray: useColorModeValue('secondaryGray.400', 'secondaryGray.400'),
    }

    const { activation } = info?.event?.extendedProps || { activation: undefined }
    if (!activation) {
        return <></>
    }
    const { id } = activation
    return (
        <Flex justifyContent="space-between" alignItems={'center'} flexGrow={1} zIndex={6}>
            <div style={({zIndex: 5, paddingLeft: '15px' })}>{info.event.title}</div>
            <ActivationMenu
                renderInPortal={true}
                themeColors={themeColors}
                onEdit={() => {
                    history.push(`/${role}/overview/edit_activation/${id}`);
                }}
                onView={() => {
                    history.push(`/${role}/overview/view_activation_details/${id}`);
                }}
                onDuplicate={() => {
                    history.push(`/${role}/overview/create_activation/${id}`);
                }}
                onRemove={() => {
                  api.deleteActivation(user.lang, brand.id, id)
                    .then(() => {
                      refreshTableData(n => n + 1)
                      successNotification('Activation deleted')
                    })
                    .catch(() => {
                      errorNotification('Error while activation deleting')
                    })
                }}
                onCancel={() => {
                    onConfirm(
                        'Confirm cancel activation',
                        `Are you sure you want to cancel the activation ${activation?.actionType?.name}?`,
                        'Yes',
                    )
                        .then(() => {
                            api.cancelActivation(user.lang, brand.id, id)
                                .then(() => {
                                    refreshTableData(n => n + 1)
                                    successNotification('Activation canceled')
                                })
                                .catch(() => {
                                    errorNotification('Error while activation canceling')
                                    // refreshTableData(n => n + 1)
                                })
                        })
                        .catch()

                }}
            />
        </Flex>

    );
})

export default EventContent;
