import {
  Box,
  SimpleGrid,
  Text,

} from '@chakra-ui/react';
import {
  activationAtom,
  activationStatusDetail,
  currentActivationCategory,
  selectedActionAddOns
} from 'data/atoms';
import { currentRoleSelector, GetActiveActionType } from 'data/selectors';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { IActionType, IActivation } from 'shared/types';

import CardItem from './CardItem';

export default function ActivationType({ categoryId }: { categoryId: string }) {
  const history = useHistory();
  const role = useRecoilValue(currentRoleSelector);

  const allActionTypes = useRecoilValue(GetActiveActionType);
  const neededActionTypes = allActionTypes.filter(type => type.category.id === categoryId);
  const [, setActivationState] = useRecoilState(activationAtom);
  const [, setSelectedActionAddOns] = useRecoilState(selectedActionAddOns);

  const chooseActionType = (data: IActionType) => {
    setActivationState((state: IActivation) =>  ({ ...state, actionTypeId: data.id }) );
    setSelectedActionAddOns(data.addons);
    history.push(`/${role}/overview/create_activation`);
  };

  return (
    <>
      <Text color='secondaryGray.600' fontSize="md" fontWeight={'300'} mb={'20px'}>
        Action type
      </Text>
      <Box gridArea='1 / 1 / 2 / 2'>
        <SimpleGrid columns={{ base: 2, md: 3, '2xl': 3 }} gap='20px' mb='20px'>
          {neededActionTypes?.map((ActionType, index) => (
            <CardItem
              key={index}
              description={ActionType.extraInfo}
              title={ActionType.name}
              onClick={() => chooseActionType(ActionType)}
            />
          ))}
        </SimpleGrid>
      </Box>
    </>
  );
}
