import { FC, ReactNode } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalContent,
  ModalFooter
} from '@chakra-ui/react';

export interface IModalPopupProps {
  isOpen: boolean;
  title: ReactNode;
  footer?: ReactNode;
  onClose(): void;
}

export const ModalPopup: FC<IModalPopupProps> = ({ children, isOpen, onClose, title, footer }) => {
  return (
    <Modal
      blockScrollOnMount={false}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {children}
        </ModalBody>
        {footer && (
          <ModalFooter justifyContent="flex-start">
            {footer}
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
}
