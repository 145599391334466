// Chakra imports
import {
  Box,
  Flex,
  Tab,
  TabList,
  Tabs,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
// Create action type components
import { HSeparator } from 'components/separator/Separator';
import { sortUserState, userSorting } from 'data/atoms';
import { SortUsers } from 'data/selectors';
// Create action type components
import { useRecoilState, useRecoilValue } from 'recoil';

export const Header = () => {
  let [tabState, setTabState] = useRecoilState(userSorting); //filtering atom

  const sortUsers = useRecoilValue(SortUsers); //Sorted Users
  const [, sortbyStatus] = useRecoilState(sortUserState); //sets chosen status

  function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const paleGray = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');
  return (
    <Tabs variant="soft-rounded" colorScheme="brandTabs">
      <TabList mx="0" overflowX={{ sm: 'scroll', lg: 'unset' }} mt={10}>
        {sortUsers.map((value, key) => {
          return (
            <Tab
              key={key}
              onClick={function () {
                setTabState(value[0]);
                sortbyStatus(value[0]);
              }}
              pb="0px"
              me="50px"
              bg="unset"
              _selected={{
                bg: 'none'
              }}
              _focus={{ border: 'none' }}
              minW="max-content"
              flexDirection="column"
            >
              <Flex align="center">
                <Text
                  color={textColor}
                  fontSize="lg"
                  fontWeight="500"
                  me="12px"
                >
                  {capitalizeFirstLetter(value[0] || "")}
                </Text>
                <Text color="secondaryGray.600" fontSize="md" fontWeight="400">
                  {value[1]?.length}
                </Text>
              </Flex>
              <Box
                height="4px"
                w="100%"
                transition="0.1s linear"
                bg={tabState === value[0] ? 'brandColor.400' : 'transparent'}
                mt="15px"
                borderRadius="30px"
              />
            </Tab>
          );
        })}
      </TabList>

      <HSeparator mb="30px" bg={paleGray} mt="0px" />
    </Tabs>
  );
};
