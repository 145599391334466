import {
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Image,
  Input,
  Select,
  SimpleGrid,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import { BsCameraFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { FC, ComponentType, useEffect, useState } from "react";
import { HiOutlinePlusSm } from 'react-icons/hi';
import { MdArrowDropDown } from 'react-icons/md';
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from 'recoil';
import { isEmpty, omit } from 'lodash';
import { useDropzone } from 'react-dropzone';
import {RouteComponentProps, withRouter} from "react-router-dom";

import banner from 'assets/img/backgrounds/Nft5.png';
import { languageAtom } from 'data/atoms';
import { brandsListSelector, RoleSelector, SortUsers } from 'data/selectors';
import { IBrand, ILang, IRole, IUser, IUserDTOForSave, RoleStatus } from 'shared/types';
import { api } from 'utils/repository';
import { getBrandId, getDomainForFile, getRoles } from 'shared/utils';
import { BrandName } from 'shared/types';
import { BrandedButton } from "components/buttons/BrandedButton";
import Card from "components/card/Card";
import { AxiosError } from 'axios';
import { ERROR_MESSAGE } from 'shared/constants';
import { useNotification } from 'shared/hooks';

const _SettingsAddUser: FC<RouteComponentProps<{ id?: string }>> = (props) => {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.600";
  const iconBackground = "secondaryGray.400";

  const [isLoading, setIsLoading] = useState("");
  const [oldId, setId] = useState<string>("");
  const [isError, setIsError] = useState("");
  const [baseRoleId, setBaseRoleID] = useState("");
  const [telenetRoleID, setTelenetRoleID] = useState("");
  const [userInfo, setUserInfo] = useState({
    lang: "",
    email: "",
    avatarId: "",
    firstName: "",
    lastName: ""
  });
  const [image, setImage] = useState(undefined);
  const history = useHistory();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const allBrands: IBrand[] = useRecoilValue(brandsListSelector);
  const selectRole: IRole[] = useRecoilValue(RoleSelector);
  const languages: ILang[] = useRecoilValue(languageAtom);

  const telenetId = getBrandId(allBrands, BrandName.Telenet);
  const baseId = getBrandId(allBrands, BrandName.Base);
  const { errorNotification, successNotification } = useNotification();
  const refreshUsers = useRecoilRefresher_UNSTABLE(SortUsers);

  useEffect(() => {
    const id = props.match.params?.id;
    if (id) {
      api.getUserInfo(id).then((user: IUser) => {
        if (user && user.id) {
          setId(id);
          const [telenetRoleId, baseRoleId] = getRoles(user);
          setBaseRoleID(baseRoleId);
          setTelenetRoleID(telenetRoleId);
          user.avatar?.path && setImage(`${getDomainForFile()}${user.avatar?.path}`);

          setUserInfo({
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            lang: user.lang,
            avatarId: user.avatar?.id || "",
          })
        }

      })
    }
  }, [])

  useEffect(() => {
    if (acceptedFiles[0] && isEmpty(userInfo.avatarId)) {
      const formdata = new FormData();
      formdata.append("file", acceptedFiles[0], acceptedFiles[0].name);

      api.uploadFile(formdata).then((response) => {
        const avatar = response.data;

        setUserInfo({
          ...userInfo,
          avatarId: avatar.id,
        })
      });
      const img = window.URL.createObjectURL(acceptedFiles[0]);
      setImage(img);
    }
    else if (
      userInfo.firstName === "" ||
      userInfo.lastName === "" ||
      userInfo.lang === "" ||
      userInfo.email === ""
    ) {
      setIsError("Please fill in required information")
    }
    else {
      setIsError("");
    }
  }, [acceptedFiles, userInfo]);

  const accountType = selectRole.find(
    (item) => item.id === telenetRoleID
  )?.name;

  const getDataForSave = (withStatus?: boolean): IUserDTOForSave => {
    const avatar = isEmpty(userInfo.avatarId) ? {} : { avatarId: userInfo.avatarId }
    const data = {
      ...omit(userInfo, 'avatarId'),
      ...avatar,
      roles: [] as IUserDTOForSave['roles'],
    }
    let status = {};

    if (withStatus) {
      status = { status: RoleStatus.approved }
    }

    if (telenetId && telenetRoleID) {
      data.roles.push({ brandId: telenetId, roleId: telenetRoleID, ...status })
    }

    if (baseId && baseRoleId) {
      data.roles.push({ brandId: baseId, roleId: baseRoleId, ...status })
    }

    return data;
  }


  const createUser = () => {
    if (isError) {
      return;
    }

    const data  = getDataForSave();

    api.createUser(data)
      .then(() => {
        setIsLoading("...Please wait");
        successNotification('User is created');
        refreshUsers();
        reset();
        history.push("/admin/settings/view_users_list");
      })
      .catch((error: AxiosError<{ message: string }>) => {
        errorNotification(error.response.data.message || ERROR_MESSAGE);
      });
  };

  const saveChanges = () => {
    if (isError) {
      return;
    }

    const data  = getDataForSave(true);

    api.updateUser(oldId, data)
      .then(() => {
        setIsLoading("...Please wait");
        successNotification('User is updated');
        refreshUsers();
        reset();
        history.push("/admin/settings/view_users_list");
      })
      .catch((error: AxiosError<{ message: string }>) => {
        errorNotification(error.response.data.message || ERROR_MESSAGE);
      });
  }

  const reset = () => {
    acceptedFiles[0] = undefined;
    setIsLoading("");
    setIsError("");
  }

  const renderAvatar = () => {
    return (
      <>
        <Avatar
          mx="auto"
          src={image}
          h="87px"
          w="87px"
          mt="-43px"
          mb="15px"
        />
        <Flex {...getRootProps()} justifyContent="center">
          <input
            {...getInputProps()}
            type="file"
            accept="image/png, image/avif, image/gif, image/jpeg, image/png, image/svg+xml, image/webp"
          />
          <Icon
            padding="3px"
            borderRadius="40px"
            bg={iconBackground}
            as={BsCameraFill}
            w="27px"
            h="27px"
            color={textColor}
            position="absolute"
            mt="-10"
            ml="14"
          />
        </Flex>
      </>
    );
  }

  const cancel = () => {
    history.push("/admin/settings/view_users_list");
  };

  return (
    <Box pt={{ base: "180px", md: "130px", xl: "130px" }}>
      {isLoading}
      <Flex justifyContent="flex-end" mb="20px">
        <BrandedButton onClick={isEmpty(oldId) ? createUser : saveChanges}>
          <Icon
            as={HiOutlinePlusSm}
            w="22px"
            h="22px"
            color="brandColor.300"
            mr="2"
          />
          {isEmpty(oldId)
            ? (<Text color="brandColor.300" as="span">SEND INVITE</Text>)
            : (<Text color="brandColor.300" as="span">SAVE CHANGES</Text>)}
        </BrandedButton>
        <Button m="1" onClick={cancel}>
          <Text color="brandColor.300" as="span">
            CANCEL
          </Text>
        </Button>
      </Flex>
      <SimpleGrid
        mt="10"
        mb="20px"
        columns={{ sm: 1, md: 1, lg: 2 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <Flex direction="column">
          <Card alignItems="center" w="100%" pb="20px">
            <Image
              src={banner}
              borderRadius="16px"
              maxH="120px"
              position="relative"
              mb={'15px'}
            />
            {/*{renderAvatar()}*/}
            <Text color="red" align="center">
              {isError}
            </Text>
            <Text fontSize="2xl" textColor={textColorPrimary} fontWeight="700">
              {userInfo?.firstName} {userInfo?.lastName}
            </Text>

          </Card>
          <Card mt="15px" w="100%" pb="2">
            <FormControl pt="20px" isRequired>
              <Text color={textColor} fontSize="lg" fontWeight="700" mb="20px">
                Use role environment permissions
              </Text>

              <Flex justifyContent="space-between">
                <Flex direction="column" mb="14px" w="45%">
                  <FormLabel ms="4px">Telenet</FormLabel>
                  <Select
                    borderRadius={16}
                    mb="5px"
                    placeholder="No access"
                    value={telenetRoleID}
                    onChange={({ target: { value } }) => {
                      setTelenetRoleID(value);
                    }}
                  >
                    {selectRole.map((role, key) => (
                      <option key={key} value={role.id}>
                        {role.name}
                      </option>
                    ))}
                  </Select>
                </Flex>
                <Flex direction="column" w="45%">
                  <FormLabel ms="4px">Base</FormLabel>
                  <Select
                    icon={<MdArrowDropDown />}
                    borderRadius={16}
                    mb="5px"
                    value={baseRoleId}
                    placeholder="No access"
                    onChange={({ target: { value } }) => {
                      setBaseRoleID(value);
                    }}
                  >
                    {selectRole.map((role, key) => (
                    <option key={key} value={role.id}>
                      {role.name}
                    </option>
                  ))}
                  </Select>
                </Flex>
              </Flex>
            </FormControl>
          </Card>
        </Flex>
        <Flex direction="column">
          <Card
            flexDirection="column"
            py="20px"
            pb="40px"
            alignSelf="center"
            w="100%"
            bgPosition="10%"
          >
            <FormControl pt="20px" isRequired>
              <FormLabel mt="10px">First Name</FormLabel>
              <Input
                mb="20px"
                me="30px"
                id="first_name"
                value={userInfo.firstName}
                onChange={(e) =>
                  setUserInfo({
                    ...userInfo,
                    firstName: e.target.value
                  })
                }
              />
              <FormLabel mt="10px">Last Name</FormLabel>
              <Input
                mb="20px"
                me="30px"
                id="last_name"
                value={userInfo.lastName}
                onChange={(e) =>
                  setUserInfo({
                    ...userInfo,
                    lastName: e.target.value
                  })
                }
              />
              <FormLabel mt="10px">Email</FormLabel>
              <Input
                mb="20px"
                me="30px"
                id="email"
                value={userInfo.email}
                onChange={(e) =>
                  setUserInfo({
                    ...userInfo,
                    email: e.target.value
                  })
                }
              />
              <FormLabel ms="4px"> Language</FormLabel>
              <Select
                icon={<MdArrowDropDown />}
                borderRadius={16}
                mb="5px"
                placeholder="Select language"
                value={userInfo.lang}
                onChange={(e) =>
                  setUserInfo({
                    ...userInfo,
                    lang: e.target.value
                  })
                }
              >
                {languages.map((lang, key) => (
                  <option key={key} value={lang.name}>
                    {lang.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Card>
        </Flex>
      </SimpleGrid>
    </Box>
  );
}

export const SettingsAddUser = withRouter(_SettingsAddUser) as ComponentType<{}>;
