import HttpClient from './httpClient';
import {
  FilterStatus,
  IActionType,
  IActionTypeDTOForSave,
  IActivation,
  IAddonDTOSave, ICategory,
  IEditSchedule,
  ILoginDTO,
  INewActivationResponse,
  IOTPLoginDTO,
  IOTPValidateDTO,
  IRegDTO,
  ISetPasswordDTO,
  IUserDTOForSave,
  IUserInfo,
  ShopManagerSuggestion,
  ShopQueryResult,
  ShopSuggestion
} from 'shared/types';
import moment from 'moment/moment';
import { isNil } from 'lodash';
import { AxiosResponse } from 'axios';

const configJson = {
  headers: {
    Accept: 'application/json',
    'Access-Control-Allow-Credentials': 'true',
    'Content-Type': 'application/json; charset=utf-8',
    'Access-Control-Allow-Origin': '*'
  }
}

export const responseProcessing = (response: AxiosResponse) => {
  if (isNil(response.status)) {
    return Promise.reject(response);
  }

  return response?.data;
}

class Repository {
  api = {
    /** AUTH */
    AUTH_BASE: () => '@/backend/auth',
    REQUEST_ACCOUNT: () => `${this.api.AUTH_BASE()}/request-account`,
    LOGIN: () => `${this.api.AUTH_BASE()}/login`,
    RESET_PASSWORD: () => `${this.api.AUTH_BASE()}/reset-password`,
    SET_PASSWORD: () => `${this.api.AUTH_BASE()}/set-password`,

    /** OTP */
    OTP_LOGIN: () => `${this.api.AUTH_BASE()}/otp-login`,
    OTP_GENERATE_SECRET: () => `${this.api.AUTH_BASE()}/generate-otp-secret`,
    OTP_VALIDATE: () => `${this.api.AUTH_BASE()}/validate-otp`,
    OTP_SET_SECRET: () => `${this.api.AUTH_BASE()}/set-otp-secret`,

    /** file */
    FILE: () => '@/backend/file/upload',
    FILE_DUPLICATE: () => '@/backend/file/duplicate',

    /** user */
    USER_BASE: () => `@/backend/user`,
    CREATE_USER: () => `${this.api.USER_BASE()}/new`,
    USER: (id: string) => `${this.api.USER_BASE()}/${id}`,
    USER_LIST: () => `${this.api.USER_BASE()}/list`,

    /** addon */
    ADDON_BASE: (brandId: string) => `@/backend/${brandId}/addon`,
    ADDON_NEW: (brandId: string) => `${this.api.ADDON_BASE(brandId)}/new`,
    ADDON: (brandId: string, id: string) => `${this.api.ADDON_BASE(brandId)}/${id}`,
    ADDON_LIST: (brandId: string) => `${this.api.ADDON_BASE(brandId)}/list`,

    /** addon-type */
    ADDON_TYPE_LIST: () => `@/backend/addon-type/list`,

    /** activation-type */
    ACTIVATION_TYPE: (brandId: string) => `@/backend/${brandId}/activation-type/list`,

    /** brand */
    BRAND_LIST: () => '@/backend/brand/list',

    /** action-type */
    ACTION_BASE: (lang: string) => `@/backend/${lang}/action-type`,
    ACTION_NEW: (lang: string) => `${this.api.ACTION_BASE(lang)}/new`,
    ACTION: (lang: string, id: string) => `${this.api.ACTION_BASE(lang)}/${id}`,
    ACTION_LIST: (lang: string) => `${this.api.ACTION_BASE(lang)}/list`,

    /** activation */
    ACTIVATION_BASE: (brandId: string, lang: string) => `@/backend/${lang}/${brandId}/activation`,
    ACTIVATION_NEW: (brandId: string, lang: string) => `${this.api.ACTIVATION_BASE(brandId, lang)}/new`,
    ACTIVATION: (brandId: string, id: string, lang: string) => `${this.api.ACTIVATION_BASE(brandId, lang)}/${id}`,
    ACTIVATION_LIST: (brandId: string, lang: string) => `${this.api.ACTIVATION_BASE(brandId, lang)}/list`,

    /** my account */
    MY_ACCOUNT: () => '@/backend/my-account',

    /** notification */
    NOTIFICATION: (brandId: string) => `@/backend/${brandId}/notification/list`,

    /** role */
    ROLE_LIST: () => '@/backend/role/list',

    /** Shop */
    SHOP_LIST: (search: string, page: number, perPage: number) =>
      `@/backend/shop/list?search=${search}&page=${page}&per_page=${perPage}`,

    /** Shop */
    MANAGER_LIST: (search: string, page: number, perPage: number) =>
      `@/backend/shop-manager/list?search=${search}&page=${page}&per_page=${perPage}`,
  }

  /** AUTH */
  login(data: ILoginDTO): Promise<{ token: string }> {
    return HttpClient.post<{ token: string }>(this.api.LOGIN(), data).then(responseProcessing);
  }

  resetPassword(data: { email: string }) {
    return HttpClient.post(this.api.RESET_PASSWORD(), data).then(responseProcessing);
  }

  setPassword(data: ISetPasswordDTO) {
    return HttpClient.post(this.api.SET_PASSWORD(), data).then(responseProcessing);
  }

  registration(data: IRegDTO) {
    return HttpClient.post(this.api.REQUEST_ACCOUNT(), data).then(responseProcessing);
  }

  /** otp */
  otpLogin(data: IOTPLoginDTO): Promise<{ token: string }> {
    return HttpClient.post<{ token: string }>(this.api.OTP_LOGIN(), data).then(responseProcessing);
  }

  generateOTPSecret() {
    return HttpClient.get<{ secret: string }>(this.api.OTP_GENERATE_SECRET()).then(responseProcessing);
  }

  validateOTP(data: IOTPValidateDTO) {
    return HttpClient.post<{ isValid: boolean }>(this.api.OTP_VALIDATE(), data).then(responseProcessing);
  }

  saveOtpSecret(data: IOTPValidateDTO) {
    return HttpClient.post(this.api.OTP_SET_SECRET(), data).then(responseProcessing);
  }

  /** file */
  uploadFile(data: FormData) {
    return HttpClient.post(this.api.FILE(), data);
  }

  fileDuplicate(data: { path: string }) {
    return HttpClient.post(this.api.FILE_DUPLICATE(), data).then(responseProcessing);
  }

  /** users methods */
  getListUsers() {
    return HttpClient.get(this.api.USER_LIST(), configJson).then((response) => {
      return response?.data?.results || []
    });
  }

  createUser(data: object) {
    return HttpClient.post(this.api.CREATE_USER(), data).then(responseProcessing);
  }

  getUserInfo(id: string) {
    return HttpClient.get(this.api.USER(id)).then(responseProcessing);
  }

  updateUser(id: string, data: IUserDTOForSave) {
    return HttpClient.patch(this.api.USER(id), data).then(responseProcessing);
  }

  deleteUser(id: string) {
    return HttpClient.delete(this.api.USER(id)).then(responseProcessing);
  }

  /** addon */
  getAddons(brandId: string) {
    return HttpClient.get(this.api.ADDON_LIST(brandId)).then(response => response?.data?.results || []);
  }

  getAddon(brandId: string, id: string) {
    return HttpClient.get(this.api.ADDON(brandId, id)).then(response => response?.data?.results);
  }

  updateAddon(brandId: string, id: string, data: IAddonDTOSave) {
    return HttpClient.patch(this.api.ADDON(brandId, id), data).then(responseProcessing);
  }

  createAddon(brandId: string, data: IAddonDTOSave) {
    return HttpClient.post(this.api.ADDON_NEW(brandId), data).then(responseProcessing);
  }

  deleteAddon(brandId: string, id: string) {
    return HttpClient.delete(this.api.ADDON(brandId, id)).then(responseProcessing);
  }
  /** addon-type */
  getAddonTypes() {
    return HttpClient.get(this.api.ADDON_TYPE_LIST()).then(response => response?.data?.results || []);
  }


  /** activation-type */
  getActivationTypes(brandId: string): Promise<ICategory[]> {
    return HttpClient.get(this.api.ACTIVATION_TYPE(brandId)).then(response => response?.data?.results || []);
  }

  /** brands */
  getBrandsList() {
    return HttpClient.get(this.api.BRAND_LIST()).then(response => response?.data?.results || []);
  }

  /** action-type */
  getActionTypeList(lang: string = 'nl'): Promise<IActionType[]> {
    return HttpClient.get(this.api.ACTION_LIST(lang))
      .then(response => response?.data?.results || [])
  }

  createActionType(lang: string, data: IActionTypeDTOForSave): Promise<IActionType> {
    return HttpClient.post(this.api.ACTION_NEW(lang), data).then(responseProcessing);
  }

  getActionType(lang: string, id: string): Promise<IActionType> {
    return HttpClient.get(this.api.ACTION(lang, id)).then(responseProcessing);
  }

  updateActionType(lang: string, id: string, data: IActionTypeDTOForSave) {
    return HttpClient.patch(this.api.ACTION(lang, id), data).then(responseProcessing);
  }

  deleteActionType(lang: string, id: string) {
    return HttpClient.delete(this.api.ACTION(lang, id)).then(responseProcessing);
  }
  /** activation */
  createActivation(lang: string, brandId: string, data: IActivation): Promise<INewActivationResponse> {
    return HttpClient.post(this.api.ACTIVATION_NEW(brandId, lang), data).then(responseProcessing);
  }

  updateActivation(lang: string, brandId: string, data: IActivation, id: string): Promise<INewActivationResponse> {
    return HttpClient.patch(this.api.ACTIVATION(brandId, id, lang), data)
      .then(responseProcessing);
  }


  updateActivationActionDate(lang: string, brandId: string, actionDate: Date, id: string, needChangeStatus?: boolean): Promise<INewActivationResponse> {
    const data: { actionDate: string; status?: FilterStatus } = { actionDate: moment(actionDate).format('YYYY-MM-DD') };

    if (needChangeStatus) {
      data.status = FilterStatus.PENDING;
    }

    return HttpClient.patch(this.api.ACTIVATION(brandId, id, lang), data)
      .then(responseProcessing);
  }

  updateActivationSchedule(lang: string, brandId: string, timestamps: IEditSchedule, id: string): Promise<INewActivationResponse> {
    return HttpClient.patch(this.api.ACTIVATION(brandId, id, lang), { ...timestamps })
      .then(responseProcessing);
  }

  updateActivationApprovals(lang: string, brandId: string, approvals: any, id: string): Promise<INewActivationResponse> {
    return HttpClient.patch(this.api.ACTIVATION(brandId, id, lang), {
      ...approvals,
      approved: approvals?.approvedByManager && approvals?.approvedByButik
    })
      .then(responseProcessing);
  }

  updateActivationSetup(lang: string, brandId: string, setup: any, id: string): Promise<INewActivationResponse> {
    return HttpClient.patch(this.api.ACTIVATION(brandId, id, lang), { ...setup })
      .then(responseProcessing);
  }

  cancelActivation(lang: string, brandId: string, id: string): Promise<INewActivationResponse> {
    return HttpClient.patch(this.api.ACTIVATION(brandId, id, lang), { status: 'cancelled' })
      .then(responseProcessing);
  }

  deleteActivation(lang: string, brandId: string, id: string): Promise<INewActivationResponse> {
    return HttpClient.delete(this.api.ACTIVATION(brandId, id, lang))
      .then(responseProcessing);
  }

  getActivation(lang: string, brandId: string, id: string): Promise<INewActivationResponse> {
    return HttpClient.get(this.api.ACTIVATION(brandId, id, lang))
      .then(responseProcessing);
  }

  listActivations(lang: string, brandId: string): Promise<INewActivationResponse[]> {
    return HttpClient.get(this.api.ACTIVATION_LIST(brandId, lang))
      .then((response: AxiosResponse) => {
        if (isNil(response.status)) {
          return Promise.reject(response);
        }

        return response?.data.results;
      });
  }

  /** my account */
  getMyAccountData(): Promise<IUserInfo> {
    return HttpClient.get(this.api.MY_ACCOUNT()).then(responseProcessing);
  }

  updateMyAccountData(data: IUserInfo): Promise<IUserInfo> {
    return HttpClient.patch(this.api.MY_ACCOUNT(), data).then(responseProcessing);
  }

  /** notification */

  /** role */
  getRoleList() {
    return HttpClient.get(this.api.ROLE_LIST()).then(response => response?.data?.results || []);
  }

  /** Shops */
  searchShops(search: string, page: number = 1, perPage: number = 20): Promise<ShopQueryResult<ShopSuggestion>> {
    return HttpClient.get(this.api.SHOP_LIST(search, page, perPage))
      .then(responseProcessing);
  }
  /** managers */
  searchManagers(search: string, page: number = 1, perPage: number = 20): Promise<ShopQueryResult<ShopManagerSuggestion>> {
    return HttpClient.get(this.api.MANAGER_LIST(search, page, perPage))
      .then(responseProcessing);
  }
}

// @ts-ignore
export const api = new Repository();
