import { Step, IStepStatus, IActivation } from 'shared/types';

export const defaultStepStatuses: IStepStatus[] = [
  {
    step: Step.activation,
    name: 'Action type',
    completed: false,
    active: true,
  },
  {
    step: Step.shop,
    name: 'Shop details',
    completed: false,
    active: false,
  },
  {
    step: Step.date,
    name: 'Activation date',
    completed: false,
    active: false,
  },
  {
    step: Step.administration,
    name: 'Administration details',
    completed: false,
    active: false,
  },
  {
    step: Step.overview,
    name: 'Overview',
    completed: false,
    active: false,
  },
];
export const stepStatusesForEditedActivation: IStepStatus[] = [
  {
    step: Step.activation,
    name: 'Action type',
    completed: true,
    active: true,
  },
  {
    step: Step.shop,
    name: 'Shop details',
    completed: true,
    active: false,
  },
  {
    step: Step.date,
    name: 'Activation date',
    completed: true,
    active: false,
  },
  {
    step: Step.administration,
    name: 'Administration details',
    completed: true,
    active: false,
  },
  {
    step: Step.overview,
    name: 'Overview',
    completed: true,
    active: false,
  },
];
export const stepStatusesForDuplicatedActivation: IStepStatus[] = [
  {
    step: Step.activation,
    name: 'Action type',
    completed: true,
    active: false,
  },
  {
    step: Step.shop,
    name: 'Shop details',
    completed: true,
    active: true,
  },
  {
    step: Step.date,
    name: 'Activation date',
    completed: true,
    active: false,
  },
  {
    step: Step.administration,
    name: 'Administration details',
    completed: true,
    active: false,
  },
  {
    step: Step.overview,
    name: 'Overview',
    completed: true,
    active: false,
  },
];

export const nullTimestamp = (new Date(null)).toUTCString()

export const initialActivationData: IActivation = {
  actionTypeId: null,
  addons: [],
  requestedById: null,
  wantExtraAddons: false,
  wantGadgedRemarks: false,
  gadgedRemarks: "",
  shopId: null,
  shopName: "",
  shopping: "",
  shopStreet: "",
  shopNumber: null,
  shopBus: "",
  shopPostalCode: "",
  shopCity: "",
  shopLatitude: 0,
  shopLongtitude: 0,
  requestedAt: null,
  lastMinuteAction: false,
  actionDate: null,
  deliveryAddonsOnActionDate: true,
  addonDeliveryDate: null,
  shopManagerEmail: null,
  shopManagerPhone: null,
  shopManagerName: null,
  shopGeneralEmail: null,
  extraRemarks: "",
  arrivalTime: nullTimestamp,
  startTime: nullTimestamp,
  endTime: nullTimestamp,
  departureTime: nullTimestamp,
  setupExtra1: "",
  setupExtra2: "",
  setupAccessibility: "",
  setupOtherRemarks: "",
  approved: false,
  approvedByManager: false,
  approvedByButik: false,
  setupHostesses: 2
};

