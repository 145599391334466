export const columnsApprovedOverview: { Header: string; accessor: string; }[] = [
  {
    Header: 'ROLE',
    accessor: 'brands'
  },
  {
    Header: 'USER',
    accessor: 'firstName'
  },
  {
    Header: 'EMAIL',
    accessor: 'email'
  },
  {
    Header: 'LANGUAGE',
    accessor: 'lang'
  },
  {
    Header: 'DATE',
    accessor: 'createdAt'
  },
  {
    Header: '',
    accessor: 'action'
  }
];
