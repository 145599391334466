import { FC } from 'react';
import { Badge, Flex, Image, Text, useColorModeValue } from '@chakra-ui/react';

import Card from 'components/card/Card';
import { ActivationMenu } from 'components/menu/ActivationMenu';
import { IAddon } from 'shared/types';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AddonAction, AddonModal } from 'data/atoms';
import { Action } from './AddonEditModal';
import { getDomainForFile } from '../../../../../shared/utils';
import { GetUsersInfo } from '../../../../../data/selectors';

interface IProps {
  addon: IAddon;
  openModal?: () => void;
  disableActivationMenu?: boolean;
}

export const AddOnCards: FC<IProps> = ( { addon, openModal, disableActivationMenu = false }: IProps) => {
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorBid = useColorModeValue('brand.500', 'white');
  const [, setAction] = useRecoilState(AddonAction);
  const [, setAddon] = useRecoilState(AddonModal);
  const { lang } = useRecoilValue(GetUsersInfo);
  const name = addon.translations.find(i => i.lang === lang)?.name;
  const openModalEdit = () => {
    setAction(Action.edit);
    setAddon(addon);
    openModal();
  }

  const openModalDuplicate = () => {
    setAction(Action.duplicate);
    setAddon(addon);
    openModal();
  }

  const openModalDelete = () => {
    setAction(Action.delete);
    setAddon(addon);
    openModal();
  }

  return (
    <Card p="20px" border={textColorBid} borderColor={textColorBid}>
      <Flex direction="column" justify="center">
        <Flex direction="row" justifyContent="space-between">
          <Badge
            colorScheme={'green'}
            color={'green.500'}
            fontSize="xs"
            fontWeight="500"
            h="25px"
          >
            {addon?.addonType?.name}
          </Badge>
          {!disableActivationMenu && <ActivationMenu
            onEdit={openModalEdit}
            onDuplicate={openModalDuplicate}
            onRemove={openModalDelete}
          />}
        </Flex>
        <Flex flexDirection="column" justify="space-between" mt="10px">
          <Image
            src={`${getDomainForFile()}${addon?.image?.path}`}
            alt={addon?.translations?.[0]?.name}
            objectFit={'cover'}
            maxH="200px"
            minH={{ base: '120px', sm: '150px', md: '180px', xl: '200px' }}
            w="100%"
            h="100%"
            borderRadius="20px"
          />
          <Text color={textColor} fontSize="2xl" fontWeight="bold" padding={2}>
            {name}
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
};
