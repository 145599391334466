import React, { FC, useState } from 'react';
import Card from 'components/card/Card';
import { Button, Flex, Heading, Icon, Text } from '@chakra-ui/react';
import IconBox from 'components/icons/IconBox';
import { MdCheckCircle } from 'react-icons/md';

export const CategoryItem: FC<{
  title: string;
  description: string;
  active: boolean;
  maxLength?: number;
  linkPDF?: string;
  onClick(): void;
}> = ({ title, description, active, maxLength, linkPDF, onClick}) => {
  const showButtonReadMore: boolean = maxLength && description.length >= maxLength;
  const [showAll, setShowAll] = useState(false);
  const hideBigText = showButtonReadMore && !showAll;
  const buttonText = showAll ? 'Hide more' : 'Read more'

  return (
    <Card
      onClick={onClick}
      cursor={'pointer'}
      _hover={{ boxShadow: 'md' }}
      transition={'boxShadow 0.3s ease-out'}
      border={'2px solid'}
      borderColor={active ? 'green.500' : 'transparent'}
      position={'relative'}
    >
      {active && (<IconBox
        h='20px'
        w='20px'
        icon={<Icon as={MdCheckCircle} color={'green.500'} h='20px' w='20px' />}
        position={'absolute'}
        top={'14px'}
        right={'14px'}
      />)}
      <Flex direction={'column'}>
        <Heading as="h3" fontSize={'20px'} fontWeight={'600'} mb={'8px'}>
          {title}
        </Heading>
        <Text
          fontSize={'16px'}
          color={'secondaryGray.600'}
          lineHeight={'26px'}
          whiteSpace="pre-line"
          maxHeight={hideBigText ? '78px': 'auto'}
          wordBreak={'break-word'}
          overflow={'hidden'}
          textOverflow={'ellipsis'}
        >
          {description}
        </Text>
        {(maxLength && description.length >= maxLength || linkPDF) && (
          <Flex justifyContent={'space-between'} mt={'15px'}>
            {maxLength && description.length >= maxLength && (<Button
              alignItems='center'
              me='10px'
              justifyContent='center'
              _hover={{ bg: 'brandColor.100' }}
              p='4px 15px'
              minW='unset'
              fontWeight={'400'}
              fontSize={'14px'}
              h='32px'
              lineHeight='100%'
              borderRadius='10px'
              bg={'#F6F8FD'}
              color={'#A3AED0'}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setShowAll(!showAll)
              }}
            >{buttonText}</Button>)}
            {linkPDF && (<Button as={'a'}
                    alignItems='center'
                    me='10px'
                    justifyContent='center'
                    bg='brandColor.400'
                    color={'white'}
                    _hover={{ bg: 'brandColor.100' }}
                    p='4px 15px'
                    minW='unset'
                    fontWeight={'400'}
                    fontSize={'14px'}
                    h='32px'
                    lineHeight='100%'
                    borderRadius='10px'
                    rel="noreferrer"
                    target='_blank'
                    href={linkPDF}
            >
              View PDF
            </Button>)}
          </Flex>
        )}
      </Flex>
    </Card>
  );
}
