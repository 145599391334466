// chakra imports
import {
  Box,
  Flex,
  Icon,
  Stack,
  useColorModeValue,
  VStack
} from '@chakra-ui/react';

// Assets
import { AiFillInfoCircle, AiOutlineLogout } from 'react-icons/ai';

//   Custom components
import Brand from 'components/sidebar/components/Brand';
import SidebarLinks from 'components/sidebar/components/Links';
import { NavLink } from 'react-router-dom';

// FUNCTIONS

function SidebarContent(props: any) {
  const { routes } = props;
  const mainText = useColorModeValue('navy.700', 'white');
  // SIDEBAR
  return (
    <Flex
      direction="column"
      minH="100%"
      height="max-content"
      pt="25px"
      borderRadius="30px"
    >
      <Brand />
      <Stack direction="column" mb="auto" mt="8px">
        <Box ps="20px" pe={{ md: '16px', '2xl': '1px' }}>
          <SidebarLinks routes={routes} />
        </Box>
      </Stack>

      <VStack
        mt="75px"
        mb="56px"
        justifyContent="center"
        alignItems="center"
        gap={5}
      >
        {/*<Icon as={AiFillInfoCircle} h="30px" w="30px" color={mainText} />*/}
        <NavLink to="/auth/sign-in">
          <Icon as={AiOutlineLogout} h="30px" w="30px" color={mainText} />
        </NavLink>
      </VStack>
    </Flex>
  );
}

export default SidebarContent;
