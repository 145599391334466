import { Box, Portal, useDisclosure } from '@chakra-ui/react';
import Footer from 'components/footer/FooterAdmin';
import Navbar from 'components/navbar/NavbarAdmin';
import Sidebar from 'components/sidebar/Sidebar';
import { SidebarContext } from 'contexts/SidebarContext';
import { currentRoleSelector } from 'data/selectors';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {routes} from 'routes';
import {getActiveNavbar, getActiveRoute, getRoute, getRouteForRole} from "../utils";


export default function DashboardAdmin(props: { [x: string]: any }) {
  const { ...rest } = props;
  const role = useRecoilValue(currentRoleSelector);
  // states and functions
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);

  document.documentElement.dir = 'ltr';
  const { onOpen } = useDisclosure();
  return (
    <Box sx={({display: 'flex'})} overflow="hidden" height="100vh">
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar
        }}
      >
        <Sidebar routes={routes} {...rest} />
        <Box
          float="right"
          minH="100vh"
          height="100%"
          overflow="auto"
          position="relative"
          boxSizing="border-box"
          maxH="100vh"
          w={{ base: '100%', xl: '100%' }}
          maxWidth={{ base: '100%', xl: 'calc( 100% - 290px )' }}
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
          >
          <Portal>
            <Box>
              <Navbar
                onOpen={onOpen}
                brandText={getActiveRoute(routes)}
                secondary={getActiveNavbar(routes)}
                logoText={'Horizon UI Dashboard PRO'}
                fixed={fixed}
                {...rest}
                />
            </Box>
          </Portal>

          {getRoute(role) ? (
            <Box
              display={'grid'}
              mx="auto"
              p={{ base: '20px' }}
              pe="20px"
              w="100%"
              pt="30px"
            >
              <Switch>
                {getRouteForRole(routes, role)}
                <Redirect from="/" to={`/${role}/dashboard`} />
              </Switch>
            </Box>
          ) : null}
          <Box>
            <Footer />
          </Box>
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}
