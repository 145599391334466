export const columnsDataUsersOverview = [
  {
    Header: 'NAME',
    accessor: 'name'
  },
  {
    Header: 'EXTRA INFO',
    accessor: 'extraInfo'
  },
  {
    Header: 'ADD-ONS',
    accessor: 'addons'
  },
  {
    Header: 'INFO DOC',
    accessor: 'infoDoc'
  },
  {
    Header: '',
    accessor: 'id'
  }
];
