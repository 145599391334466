import {ReactNode, useMemo, useState} from 'react';
import {
  Badge, Button, Flex, Icon, NumberDecrementStepper, NumberIncrementStepper,
  NumberInput, NumberInputField, NumberInputStepper, Stack, Table, Tbody, Td,
  Text, Th, Thead, Tr, useColorModeValue, useDisclosure
} from '@chakra-ui/react';
import moment from 'moment';
import {
  Row,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable
} from 'react-table';
import { useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValue } from 'recoil';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { selectedUserID } from 'data/atoms';
import {IUser} from 'shared/types';
import { currentRoleSelector, SortUsers } from 'data/selectors';
import { api } from 'utils/repository';
import { ApprovePopup } from './approvePopup';
import { useConfirm, useNotification } from 'shared/hooks';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';
import { ActivationMenu } from 'components/menu/ActivationMenu';
import { ERROR_MESSAGE } from 'shared/constants';

function SettingOverviewTable(props: any) {
  const [, selectedUserId] = useRecoilState(selectedUserID); //sets chosen status

  const { columnsData, tableData } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable<IUser>(
    {
      columns,
      data
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    gotoPage,
    pageCount,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
    state
  } = tableInstance;

  const createPages = (count: number) => {
    let arrPageCount = [];

    for (let i = 1; i <= count; i++) {
      arrPageCount.push(i);
    }

    return arrPageCount;
  };

  const { pageIndex, pageSize } = state;
  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const refreshUsers = useRecoilRefresher_UNSTABLE(SortUsers);
  const role = useRecoilValue(currentRoleSelector);
  const history = useHistory();
  const { errorNotification, successNotification } = useNotification()
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onConfirm } = useConfirm();
  const [modalProps, setModalProps] = useState({
    user: {} as IUser
  })

  const handleApprove = (user: IUser) => {
    setModalProps({ user });
    onOpen();
  }

  const handleEdit = (id: string) => {
    history.push(`/${role}/settings/edit_user/${id}`);
  }

  const handleDelete = (id: string) => {
    onConfirm('Warning', 'Are you sure?', 'Delete').then(() => {
      api.deleteUser(id)
        .then(() => {
          refreshUsers();
          successNotification('User is deleted');
        })
        .catch((error: AxiosError<{ message: string}>) => {
          errorNotification(error.response.data.message || ERROR_MESSAGE);
        });
    })

  }

  const handleDeny = (id: string) => {
    api.deleteUser(id).then(response => {
      successNotification(`User hard deleted`)
      refreshUsers();
    }).catch((error: AxiosError<{ message: string }>) => {
      errorNotification(error.response.data.message || ERROR_MESSAGE);
    })
  }

  const renderCols: (row: Row<IUser>) => ReactNode = (row) => {
    return row.cells.map((cell, index) => {
      let data = null;

      if (cell.column.Header === 'ROLE') {
        data = (
          <Flex align="center">
            <Badge
              id={cell.value}
              colorScheme="green"
              color="green.500"
              fontSize="xs"
              fontWeight="500"
              onClick={() => selectedUserId(cell.row?.original?.id)}
            >
              {cell.value[0]?.role?.name}
            </Badge>
          </Flex>
        );
      }
      else if (cell.column.Header === 'USER') {
        data = (
          <Flex align="center">
            <Text
              color={textColor}
              fontSize="md"
              fontWeight="500"
            >
              {`${row.original.firstName} ${row.original.lastName}`}
            </Text>
          </Flex>
        );
      }
      else if (cell.column.Header === 'EMAIL') {
        data = (
          <Text color={textColor} fontSize="md" fontWeight="500">
            {cell.value}
          </Text>
        );
      }
      else if (cell.column.Header === 'LANGUAGE') {
        data = (
          <Text color={textColor} fontSize="md" fontWeight="500">
            {cell.value}
          </Text>
        );
      }
      else if (cell.column.Header === 'DATE') {
        data = (
          <Text color={textColor} fontSize="md" fontWeight="500">
            {moment(cell.value).format('MMM  Do YYYY')}
          </Text>
        );
      }
      else if (cell.column.Header === '') {
        data = (
          <ActivationMenu
            onEdit={() => handleEdit(row?.original?.id)}
            onRemove={() => handleDelete(row?.original?.id)}
          />
        );
      }
      else if (cell.column.Header === 'ACTION REQUIRED') {
        data = (
          <Flex>
            <Badge
              id={cell.value}
              colorScheme={
                cell.value[0].status === 'approve'
                  ? 'red'
                  : 'green'
              }
              color={
                cell.value[0].status === 'approve'
                  ? 'red.500'
                  : 'green.500'
              }
              fontSize="xs"
              fontWeight="500"
              cursor="pointer"
              onClick={() => handleApprove(cell.row.original)}
            >
              Approve
            </Badge>
            <Badge
              id={cell.value}
              fontSize="xs"
              fontWeight="500"
              ms="3"
              //border="1px solid"
              bg={'pink.100'}
              color={'red'}
              cursor="pointer"
              onClick={() => handleDeny(cell.row.original.id)}
            >
              Deny
            </Badge>
          </Flex>
        );
      }

      return (
        <Td
          {...cell.getCellProps()}
          key={index}
          fontSize={{ sm: '14px' }}
          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
          borderColor={borderColor}
        >
          {data}
        </Td>
      );
    });
  }

  return (
    <>
      <ApprovePopup {...modalProps} isOpen={isOpen} onClose={onClose} />
      <Flex
        direction="column"
        w="100%"
        overflowX={{ sm: 'scroll', lg: 'hidden' }}
      >
        <Flex
          align={{ sm: 'flex-start', lg: 'flex-start' }}
          justify={{ sm: 'flex-start', lg: 'flex-start' }}
          w="100%"
          px="22px"
          mb="36px"
        >
          <SearchBar
            onChange={(e: any) => setGlobalFilter(e.target.value)}
            h="44px"
            w={{ lg: '390px' }}
            borderRadius="16px"
          />
        </Flex>
        <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="10px"
                    key={index}
                    borderColor={borderColor}
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: '10px', lg: '12px' }}
                      color="gray.400"
                    >
                      {column.render('Header')}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {renderCols(row as Row<IUser>)}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <Flex
          direction={{ sm: 'column', md: 'row' }}
          justify="space-between"
          align="center"
          w="100%"
          px={{ md: '22px' }}
        >
          <Text
            fontSize="sm"
            color="gray.500"
            fontWeight="normal"
            mb={{ sm: '24px', md: '0px' }}
          >
            Showing {pageSize * pageIndex + 1} to{' '}
            {pageSize * (pageIndex + 1) <= tableData.length
              ? pageSize * (pageIndex + 1)
              : tableData.length}{' '}
            of {tableData.length} entries
          </Text>
          <Stack direction="row" alignSelf="flex-end" spacing="4px" ms="auto">
            <Button
              variant="no-effects"
              onClick={() => previousPage()}
              transition="all .5s ease"
              w="40px"
              h="40px"
              borderRadius="50%"
              bg="transparent"
              border="1px solid"
              borderColor={useColorModeValue('gray.200', 'white')}
              display={
                pageSize === 5 ? 'none' : canPreviousPage ? 'flex' : 'none'
              }
              bgColor="brandColor.400"
              _hover={{ bg: 'brandColor.100' }}
            >
              <Icon
                as={MdChevronLeft}
                w="16px"
                h="16px"
                color="brandColor.900"
              />
            </Button>
            {pageSize === 5 ? (
              <NumberInput
                max={pageCount - 1}
                min={1}
                w="75px"
                mx="6px"
                defaultValue="1"
                onChange={(e) => gotoPage(Number(e))}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper onClick={() => nextPage()} />
                  <NumberDecrementStepper onClick={() => previousPage()} />
                </NumberInputStepper>
              </NumberInput>
            ) : (
              createPages(pageCount).map((pageNumber, index) => {
                return (
                  <Button
                    variant="no-effects"
                    transition="all .5s ease"
                    onClick={() => gotoPage(pageNumber - 1)}
                    w="40px"
                    h="40px"
                    borderRadius="50%"
                    bg={
                      pageNumber === pageIndex + 1
                        ? 'brandColor.400'
                        : 'transparent'
                    }
                    border={
                      pageNumber === pageIndex + 1
                        ? 'none'
                        : '1px solid lightgray'
                    }
                    _hover={
                      pageNumber === pageIndex + 1
                        ? {
                            opacity: '0.7'
                          }
                        : {
                            bg: 'brandColor.900'
                          }
                    }
                    key={index}
                  >
                    <Text
                      fontSize="sm"
                      color={
                        pageNumber === pageIndex + 1 ? '#fff' : 'brandColor.100'
                      }
                    >
                      {pageNumber}
                    </Text>
                  </Button>
                );
              })
            )}
            <Button
              variant="no-effects"
              onClick={() => nextPage()}
              transition="all .5s ease"
              w="40px"
              h="40px"
              borderRadius="50%"
              bgColor="transparent"
              border="1px solid"
              borderColor={useColorModeValue('gray.200', 'white')}
              display={pageSize === 5 ? 'none' : canNextPage ? 'flex' : 'none'}
              _hover={{ bg: 'brandColor.100' }}
            >
              <Icon
                as={MdChevronRight}
                w="16px"
                h="16px"
                color="brandColor.900"
              />
            </Button>
          </Stack>
        </Flex>
      </Flex>
    </>
  );
}

export default SettingOverviewTable;
