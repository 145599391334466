import { Box, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import { FC } from 'react';
import { MdCheckCircle, MdEdit, MdTimer } from 'react-icons/md';

import { IStepStatus, Step } from 'shared/types';
import IconBox from '../icons/IconBox';

const OrderStep: FC<{ step: IStepStatus, onEdit: () => void }> = ({ step, onEdit }) => {
	const { name, active, completed } = step;
  const icon = completed
    ? MdCheckCircle
    : MdTimer;

  const iconColor = completed
    ? 'green.500'
    : active
      ? 'brand.500'
      : 'secondaryGray.600';
	const textColor = useColorModeValue('secondaryGray.900', 'white');
  const statusText = completed
    ? 'Complete'
    : 'Not complete';

  const completedBoxShadow = '0px 18px 40px rgba(112, 144, 176, 0.12)';
  const boxShadowIcon = 'inset 0px 18px 22px rgba(112, 144, 176, 0.1)';
  const completeBg = useColorModeValue('white', 'linear-gradient(180deg, #1F2A4F 0%, #18224D 50.63%, #111C44 100%)');

	return (
		<Flex
      w='100%'
      zIndex='2'
      mb={step.step !== Step.overview && '80px'}
      boxShadow={active ? completedBoxShadow : 'none'}
      borderRadius={'16px'}
      alignItems={'center'}
      p={'12px'}
      position={'relative'}
    >
      {step.step !== Step.overview && (
        <Box
          position={'absolute'}
          borderLeft={completed ? '2px dashed #01B574' : '2px dashed #a3aed0'}
          top={'100%'}
          left={'44px'}
          width={'1px'}
          height={'80px'}
          zIndex={'-1'}
        />)}
      <IconBox
        h='66px'
        w='66px'

        bg={completeBg}
        boxShadow={completed ? completedBoxShadow : boxShadowIcon}
        icon={<Icon as={icon} color={iconColor} h='32px' w='32px' />}
      />
			<Flex direction={'column'} ml='20px' mr='auto'>
				<Text color={textColor} fontSize='md' me='6px' fontWeight='500'>
					{name}
				</Text>
				<Text color='secondaryGray.600' fontSize='md' fontWeight='400'>
					{statusText}
				</Text>
			</Flex>
      {completed && !active && (
        <IconBox
          h='40px'
          w='40px'
          onClick={onEdit}
          icon={<Icon as={MdEdit} color={'secondaryGray.600'} h='18px' w='18px' />}
        />
      )}
    </Flex>
	);
}

export default OrderStep;
