import React, { FC, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {ArrowForwardIcon, ArrowBackIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid,
  Stack,
  Switch,
  Text
} from '@chakra-ui/react';
import moment from 'moment/moment';
import { isEmpty } from 'lodash';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import {
  AsyncDropDownOption,
  IActionType,
  IActivation,
  IBrand,
  ICategory,
  INewActivationResponse,
  IUser,
  ShopManagerSuggestion,
  ShopQueryResult,
  ShopSuggestion,
  Step
} from 'shared/types';
import {
  activationAtom, activationsDuplicatedAtom,
  currentActivationCategory,
  currentVisibilityAddon,
  selectedActionAddOns
} from 'data/atoms';
import Card from 'components/card/Card';
import {
  brandSelector,
  currentRoleSelector,
  GetActiveActionType,
  GetActivationType,
  GetUsersInfo
} from 'data/selectors';
import { useNotification, useStatusDetail } from 'shared/hooks';
import InputField from 'components/fields/InputField';
import {
  getCitySuggestion,
  getDomainForFile,
  getZipCodeSuggestion,
  transformActivationDTOtoActivation
} from 'shared/utils';
import { SelectField } from 'components/fields/SelectField';
import { api } from 'utils/repository';
import { DateField } from 'components/fields/DateField';
import TextField from 'components/fields/TextField';

import { stepStatusesForDuplicatedActivation, stepStatusesForEditedActivation } from './constants';
import { CategoryItem } from './components/categoryItem';
import { TitleStep } from './components/titleStep';
import { MdArrowDropDown } from 'react-icons/md';

export const ActivationLayout = () => {
  const history = useHistory();
  const location = useLocation<{ actionDate: string }>();
  const { errorNotification, successNotification } = useNotification();
  const role = useRecoilValue(currentRoleSelector);
  const categories = useRecoilValue(GetActivationType);
  const allActionTypes = useRecoilValue<IActionType[]>(GetActiveActionType);
  const user = useRecoilValue<IUser>(GetUsersInfo);
  const brand = useRecoilValue<IBrand>(brandSelector);
  const activationCategories: ICategory[] = useRecoilValue(GetActivationType);

  // доп поля для первого шага
  const [visibilityAddon, setVisibilityAddon] = useRecoilState(currentVisibilityAddon);
  const [extraRemark, setExtraRemark] = useState('');
  const [toggleExtraAddon, setToggleExtraAddon] = useState<boolean>(false);
  const [toggleExtraRemark, setToggleExtraRemark] = useState<boolean>(false);
  const [initialDate, setInitialDate] = useState('');

  const [duplicates, setDuplicates] = useRecoilState(activationsDuplicatedAtom);
  const [activationState, setActivationState] = useRecoilState(activationAtom);
  const {
    isValid,
    handlePrev,
    handleNext,
    currentStep,
    setStepDetail
  } = useStatusDetail(activationState);
  const { activationId } = useParams<{ activationId: string }>()
  const [currentActivation, setCurrentActivation] = useRecoilState<ICategory>(currentActivationCategory);
  const [allAddons, setSelectedActionAddOns] = useRecoilState(selectedActionAddOns);

  const visibilityAddons = allAddons.filter((elem) => elem?.addon?.addonType?.name === 'Visibility');

  useEffect(() => {
    if (activationId) {
      api.getActivation(user?.lang, brand?.id, activationId)
        .then(resp => {
          delete resp.status
          setInitialDate(resp.actionDate);
          setActivationState(transformActivationDTOtoActivation(resp))
          setCurrentActivation(resp.actionType.category)
          setStepDetail(stepStatusesForEditedActivation)
          setToggleExtraRemark(resp.wantGadgedRemarks);
          setToggleExtraAddon(resp.wantExtraAddons);
          setSelectedActionAddOns(resp.actionType.addons)
          setExtraRemark(resp.gadgedRemarks || '');
          setVisibilityAddon(resp.addons[0]?.actionTypeAddon);
        })
        .catch(() => errorNotification('Some problems while requiring activation data'))
    }
    else if (location?.state?.actionDate) {
      setActivationState(state => ({
        ...state,
        actionDate: moment(location?.state?.actionDate).format('YYYY-MM-DD')
      }))
    }
  }, [activationId, location?.state]);

  const isCreating = window.location.href.includes('create_activation')

  const chooseActionType = (data: IActionType) => {
    setActivationState((state: IActivation) =>  ({ ...state, actionTypeId: data.id }) );
    setSelectedActionAddOns(data.addons)
  };

  const finalSubmit = () => {
    const requestedById = user.id
    const requestedAt = (new Date()).toUTCString()

    if (isCreating) {
      api.createActivation(user.lang, brand.id, {
        ...activationState,
        requestedAt,
        requestedById,
        approvedByButik: false,
        approvedByManager: false,
        approved: false,
        wantGadgedRemarks: toggleExtraRemark,
        gadgedRemarks: toggleExtraRemark ? extraRemark : '',
        wantExtraAddons: toggleExtraAddon,
        addons: toggleExtraAddon ? [visibilityAddon?.addon.id].filter(Boolean) : [],
        actionDate: activationState.actionDate,
        addonDeliveryDate: activationState.deliveryAddonsOnActionDate
          ? activationState.actionDate
          : activationState.addonDeliveryDate,
      })
        .then(res => {
          successNotification('New activation created')
          if (isEmpty(duplicates)) {
            history.push(`/${role}/overview/view_activation_details/${res.id}`);
          } else {
            setDuplicates([...duplicates, res.id])
            history.push(`/${role}/overview/view_activations_list?duplicate=true`);
          }
        })
        .catch(() => {
          errorNotification('Some errors while creating activation. Check all required attributes is filled')
        })
    } else {
      const approve = activationState.activationDate === initialDate;
      api.updateActivation(
        user.lang,
        brand.id,
        {
          ...activationState,
          approvedByButik: approve,
          approved: approve,
          wantGadgedRemarks: toggleExtraRemark,
          gadgedRemarks: toggleExtraRemark ? extraRemark : '',
          wantExtraAddons: toggleExtraAddon,
          addons: toggleExtraAddon ? [visibilityAddon?.addon.id].filter(Boolean) : [],
          actionDate: activationState.actionDate.split('T')[0],
        },
        activationId
      )
        .then((res: INewActivationResponse) => {
          successNotification('Activation updated')
          history.push(`/${role}/overview/view_activation_details/${res?.id}`);
        })
        .catch(err => {
          console.log('Activation err', err);

          errorNotification('Some errors while creating activation. Check all required attributes is filled')
        })
    }

  }

  const confirmAndDupicate = () => {
    const requestedById = user.id
    const requestedAt = (new Date()).toUTCString()

    api.createActivation(user.lang, brand.id, {
      ...activationState,
      requestedAt,
      requestedById,
      approvedByButik: false,
      approvedByManager: false,
      approved: false,
      wantGadgedRemarks: toggleExtraRemark,
      gadgedRemarks: toggleExtraRemark ? extraRemark : '',
      wantExtraAddons: toggleExtraAddon,
      addons: toggleExtraAddon ? [visibilityAddon?.addon.id].filter(Boolean) : [],
      actionDate: activationState.actionDate,
      addonDeliveryDate: activationState.deliveryAddonsOnActionDate
        ? activationState.actionDate
        : activationState.addonDeliveryDate,
    })
      .then(res => {
        successNotification('New activation created')
        setStepDetail(stepStatusesForDuplicatedActivation);
        setDuplicates([...duplicates, res.id])
      })
      .catch(() => {
        errorNotification('Some errors while creating activation. Check all required attributes is filled')
      })
  }

  const renderActiveStep = () => {
    return (
      <Box
        color={'secondaryGray.600'}
        fontSize={'2xl'}
        position={'absolute'}
        top={'29px'}
        right={'24px'}
      >
        Step <Text as={'span'}>{`${currentStep.step}/5`}</Text>
      </Box>
    );
  }
  const renderFirstStep = () => {
    if (currentStep.step !== Step.activation) {
      return null;
    }

    return (
      <>
        {!isEmpty(allActionTypes) && (<div>
          {categories.map(category => {
            return (
              <>
                <TitleStep
                  title={`Request ${category.name.toLowerCase()}`}
                  // description={'Please select your action type'}
                />
                <Box gridArea='1 / 1 / 2 / 2' mb={'24px'}>
                  <SimpleGrid columns={{ base: 2, md: 2, '2xl': 3 }} gap='20px' mb='20px'>
                    {allActionTypes?.filter(type => type.category.id === category.id).map((type, index) => (
                      <CategoryItem
                        key={index}
                        description={type.extraInfo}
                        title={type.name}
                        active={type.id === activationState.actionTypeId}
                        maxLength={120}
                        onClick={() => chooseActionType(type)}
                        linkPDF={type?.infoDoc?.path ? `${getDomainForFile()}${type.infoDoc.path}` : undefined}
                      />
                    ))}
                  </SimpleGrid>
                </Box>
              </>
            );
          })}

          {activationState.actionTypeId && (<div>
            <FormControl display='flex' alignItems='center' mb={'13px'}>
              <Switch
                id='extra-add-ons'
                colorScheme='brandScheme'
                isChecked={toggleExtraAddon}
                onChange={() => setToggleExtraAddon(!toggleExtraAddon) }
              />
              <FormLabel
                htmlFor='extra-add-ons'
                ml='17px'
                mb={'0'}
                fontSize={24}
                fontWeight={700}
                color={'secondaryGray.900'}
              >
                Do you want to add visibility items?
              </FormLabel>
            </FormControl>
            {toggleExtraAddon && (
              <Select
                icon={<MdArrowDropDown />}
                borderRadius={16}
                mb="18px"
                placeholder={'Select extra add-on'}
                value={visibilityAddon?.addon?.id}
                onChange={(val) => {
                  setVisibilityAddon(visibilityAddons.find(elem => elem?.addon?.id === val.target.value))
                }}
              >
                {allAddons.map((addon) => (
                  <option key={addon?.addon?.id} value={addon?.addon?.id}>
                    {addon?.addon?.translations?.[0]?.name}
                  </option>
                ))}
              </Select>
            )}
            {/*<FormControl display='flex' alignItems='center' mb={'13px'}>*/}
            {/*  <Switch id='extra-remarks' colorScheme='brandScheme' isChecked={toggleExtraRemark} onChange={() => setToggleExtraRemark(!toggleExtraRemark) } />*/}
            {/*  <FormLabel htmlFor='extra-remarks' ml='17px' mb={'0'}>*/}
            {/*    Do you have other extra remarks about the gadgets?*/}
            {/*  </FormLabel>*/}
            {/*</FormControl>*/}
            {toggleExtraRemark && (
              <TextField
                onChange={(e: any) => setExtraRemark(e.target.value)}
                minH="150px"
                placeholder="Write anything here you wish to specify..."
                value={extraRemark}
              />
            )}
          </div>)}

        </div>)}
      </>
    );
  }
  const renderShopStep = () => {
    if (currentStep.step !== Step.shop) {
      return null;
    }

    return (
      <>
        <TitleStep
          title={'Shop details'}
          description={'Please fill out the information about your shop.'}
        />
        <SimpleGrid columns={{ base: 2, md: 2 }} gap="20px">
          <SelectField
            id={'shopName'}
            label={'Shop name'}
            placeholder={'eg. TLN Vilvoorde'}
            value={({
              value: activationState?.shopName,
              label: activationState?.shopName,
              color: 'black',
              isFixed: true,
              data: {}
            })}
            loadOption={(
              inputValue: string,
              callback: (options: AsyncDropDownOption[]) => void
            ) => {
              api.searchShops(inputValue)
                .then((response: ShopQueryResult<ShopSuggestion>) => {
                  const { results } = response
                  const currentInput: AsyncDropDownOption = {
                    value: inputValue,
                    label: inputValue,
                    color: 'black',
                    isFixed: true,
                    data: undefined,
                  }
                  const codesOptions: AsyncDropDownOption[] = [
                    currentInput,
                    ...results
                      .map((shop) => ({
                        value: shop.name,
                        label: `${shop.name}, ${shop.city}`,
                        color: 'black',
                        isFixed: true,
                        data: shop,
                      }))
                  ]
                  callback(codesOptions)
                })
            }}
            onChange={(option: AsyncDropDownOption) => {
              const { data }: { data: ShopSuggestion } = option
              let newShopData: any = { shopName: option.value }
              if (data) {
                newShopData = {
                  ...newShopData,
                  shopId: data.id,
                  shopping: data.shopping,
                  shopStreet: data.street,
                  shopBus: data.bus,
                  shopPostalCode: data.postalCode,
                  shopCity: data.city,
                  shopNumber: data.number,
                  // shopLatitude: data.latitude,
                  // shopLongitude: data.longitude,
                }
              }
              else {
                newShopData.shopId = undefined
              }
              setActivationState(state => ({
                ...state,
                ...newShopData,
              }))
            }}
          />
          <InputField
            mb="0px"
            id="building"
            placeholder="eg. Building 202"
            label="Building / Shopping(optional)"
            value={activationState?.shopping || ''}
            onChange={(e: any) => {

              setActivationState(state => ({
                ...state,
                shopping: e.target.value
              }))
            }
            }
          />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
          <InputField
            mb="0px"
            id="street"
            placeholder="eg. Maurit Duchehof"
            label="Street"
            value={activationState?.shopStreet || ''}
            onChange={(e: any) =>
              setActivationState(state => ({
                ...state,
                shopStreet: e.target.value
              }))
            }
          />
          <SimpleGrid
            columns={{ base: 2, md: 2 }}
            gap="20px"
          >
            <InputField
              mb="17px"
              id="number"
              placeholder="eg. 453"
              label="Number"
              value={activationState?.shopNumber || ''}
              onChange={(e: any) =>
                setActivationState(state => ({
                  ...state,
                  shopNumber: parseInt(e.target.value),
                }))
              }
            />
            <InputField
              mb="0px"
              id="bus"
              placeholder="eg. A2"
              label="Bus (optional)"
              value={activationState?.shopBus || ''}
              onChange={(e: any) =>
                setActivationState(state => ({
                  ...state,
                  shopBus: e.target.value
                }))
              }
            />
          </SimpleGrid>
        </SimpleGrid>
        <SimpleGrid columns={{ base: 2, md: 2 }} gap="20px">
          <SelectField
            id={'postal-code'}
            label={'Postal Code'}
            placeholder={'eg. 10000'}
            value={({
              value: activationState?.shopPostalCode,
              label: activationState?.shopPostalCode,
              color: 'black',
              isFixed: true,
              data: {}
            })}
            loadOption={(
              inputValue: string,
              callback: (options: AsyncDropDownOption[]) => void
            ) => {
              const codesOptions: AsyncDropDownOption[] = getZipCodeSuggestion(inputValue)
                .map((pair) => ({
                  value: pair.code,
                  label: `${pair.code}, ${pair.city}`,
                  color: 'black',
                  isFixed: true,
                  data: pair,
                }));
              setTimeout(() => {
                callback(codesOptions);
              }, 10);
            }}
            onChange={(option: AsyncDropDownOption) =>
              setActivationState(state => ({
                ...state,
                shopPostalCode: option.data.code,
                shopCity: option.data.city
              }))}
          />
          <SelectField
            id={'City'}
            label={'City'}
            placeholder={'Select a city'}
            value={({
              value: activationState?.shopCity,
              label: activationState?.shopCity,
              color: 'black',
              isFixed: true,
              data: {}
            })}
            loadOption={(
              inputValue: string,
              callback: (options: AsyncDropDownOption[]) => void
            ) => {
              const codesOptions: AsyncDropDownOption[] = getCitySuggestion(inputValue)
                .map((pair) => ({
                  value: pair.code,
                  label: `${pair.code}, ${pair.city}`,
                  color: 'black',
                  isFixed: true,
                  data: pair,
                }));
              setTimeout(() => {
                callback(codesOptions);
              }, 10);
            }
            }
            onChange={(option: AsyncDropDownOption) =>
              setActivationState(state => ({
                ...state,
                shopPostalCode: option.data.code,
                shopCity: option.data.city
              }))}
          />
        </SimpleGrid>
      </>
    );
  }
  const renderSwitchExtraAddons = () => {
    if (!toggleExtraAddon) {
      return null;
    }

    return (
      <>
        <FormLabel fontSize="14px" fontWeight="bold">
          Can your add-ons be delivered on the day of the
          action?
        </FormLabel>
        <RadioGroup
          defaultValue="true"
          value={String(activationState?.deliveryAddonsOnActionDate)}
          mb={2}
          onChange={(val) => {
            setActivationState(state => ({
              ...state,
              deliveryAddonsOnActionDate: val === 'true',
            }))
          }}
        >
          <Stack>
            <Radio value={'true'} colorScheme="orange">
              Yes
            </Radio>
            <Text>{`Selected add-on: Add-on X`}</Text>
            <Radio value={'false'} colorScheme="orange">
              No, then please select date
            </Radio>
            {``}
          </Stack>
        </RadioGroup>
        <DateField
          placeholder={'DD/MM/YYYY'}
          id={'date'}
          onChange={(e: any) => {
            setActivationState(state => ({
              ...state,
              addonDeliveryDate: moment(
                e.target.value
              ).format()
            }))
          }}
          value={moment(activationState?.addonDeliveryDate).format('YYYY-MM-DD')}
          disabled={activationState?.deliveryAddonsOnActionDate}
        />
      </>
    );
  }
  const renderDateStep = () => {
    if (currentStep.step !== Step.date) {
      return null;
    }

    return (
      <>
        <TitleStep
          title={'Activation date'}
          description={'When do you want the activation to take place?'}
        />
        <DateField
          id={'date1'}
          placeholder={'DD/MM/YYYY'}
          label={'Date'}
          value={moment(activationState?.actionDate).format('YYYY-MM-DD')}
          onChange={(e) => {
            setActivationState(state => ({
              ...state,
              actionDate: moment(e.target.value).format('YYYY-MM-DD')
            }))
          }}
        />
        <Text
          width={'100%'}
          bg={'#F4F7FE'}
          color={'#A3AED0'}
          p={'14px 22px'}
          borderRadius={'14px'}
          mb={'12px'}
        >
          <Text as={'span'} fontWeight={'bold'}>Note:</Text> If the action is requested less than 2 weeks after today, it is seen as a last minute
        </Text>
        {renderSwitchExtraAddons()}
        <Text
          width={'100%'}
          bg={'#F4F7FE'}
          color={'#A3AED0'}
          p={'14px 22px'}
          borderRadius={'14px'}
          mb={'12px'}
        >
          <Text as={'span'} fontWeight={'bold'}>Note:</Text> No cancellation and edits allowed 72 hours before In emergency cases, please contact us.
        </Text>
      </>
    );
  }
  const renderAdminDetailStep = () => {
    if (currentStep.step !== Step.administration) {
      return null;
    }

    return (
      <>
        <TitleStep
          title={'Administration details'}
          description={'Please fill in administration details'}
        />
        <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
          <SelectField
            id={'email'}
            label={'Shop general email'}
            placeholder={'eg. hello@telenet.com'}
            value={({
              value: activationState?.shopGeneralEmail,
              label: activationState?.shopGeneralEmail,
              color: 'black',
              isFixed: true,
              data: {}
            })}
            loadOption={(
              inputValue: string,
              callback: (options: AsyncDropDownOption[]) => void
            ) => {
              api.searchManagers(inputValue)
                .then((response: ShopQueryResult<ShopManagerSuggestion>) => {
                  const { results } = response
                  const currentInput: AsyncDropDownOption = {
                    value: inputValue,
                    label: inputValue,
                    color: 'black',
                    isFixed: true,
                    data: undefined,
                  }
                  const codesOptions: AsyncDropDownOption[] = [
                    currentInput,
                    ...results
                      .map((shop) => ({
                        value: shop.generalEmail,
                        label: `${shop.generalEmail}, ${shop.name}`,
                        color: 'black',
                        isFixed: true,
                        data: shop,
                      }))
                  ]
                  callback(codesOptions)
                })
            }}
            onChange={(option: AsyncDropDownOption) => {
              const { data }: { data: ShopManagerSuggestion } = option
              let newShopManagerData: any = { shopGeneralEmail: option.value }

              if (data) {
                newShopManagerData = {
                  ...newShopManagerData,
                  shopManagerId: data.id,
                  shopManagerEmail: data.email,
                  shopGeneralEmail: data.generalEmail,
                  shopManagerName: data.name,
                  shopManagerPhone: data.phone,
                }
              }
              else {
                newShopManagerData.shopManagerId = undefined
              }
              setActivationState(state => ({
                ...state,
                ...newShopManagerData,
              }))
            }}
          />
          <InputField
            mb="0px"
            id="m-email"
            placeholder="eg. katrien@telenet.be"
            label="Shopmanager email"
            value={activationState?.shopManagerEmail || ''}
            onChange={(e: any) =>
              setActivationState(state => ({
                ...state,
                shopManagerEmail: e.target.value
              }))
            }
          />
          <InputField
            mb="0px"
            id="name"
            placeholder="eg. Katrien Potters"
            label="Shopmanager name"
            value={activationState?.shopManagerName || ''}
            onChange={(e: any) =>
              setActivationState(state => ({
                ...state,
                shopManagerName: e.target.value
              }))
            }
          />
          <InputField
            mb="0px"
            id="phone"
            placeholder="eg. 015 85 53 32"
            label="Shopmanager phone"
            value={activationState?.shopManagerPhone || ''}
            onChange={(e: any) =>
              setActivationState(state => ({
                ...state,
                shopManagerPhone: e.target.value
              }))
            }
          />
        </SimpleGrid>
      </>
    );
  }
  const renderOverviewStep = () => {
    if (currentStep.step !== Step.overview) {
      return null;
    }

    return (
      <>
        <TitleStep
          title={'Overview'}
          description={'Please check and confirm your action details on the right side overview and edit where needed.'}
        />
        <TextField
          onChange={(e: any) => {

            setActivationState(state => ({
              ...state,
              extraRemarks: e.target.value
            }))
          }}
          minH="150px"
          id="bio"
          placeholder="Write anything here you wish to specify..."
          label="Extra remarks?"
          value={activationState?.extraRemarks}
        />
      </>
    );
  }

  return (
    <Card justifySelf='center' m='10px' my='0px' p='24px' position={'relative'} pb={'95px'}>
      {renderActiveStep()}
      {renderFirstStep()}
      {renderShopStep()}
      {renderDateStep()}
      {renderAdminDetailStep()}
      {renderOverviewStep()}
      <Flex
        justifyContent={currentStep.step !== Step.activation ? 'space-between' : 'flex-end'}
        position={'absolute'}
        width={'100%'}
        bottom={'0'}
        left={'0'}
        p={'26px'}
      >
        {currentStep.step !== Step.activation && (
          <Button
            leftIcon={<ArrowBackIcon />}
            borderColor='secondaryGray.600'
            color='secondaryGray.600'
            variant='outline'
            size='lg'
            onClick={handlePrev}
          >
            Prev
          </Button>
        )}
        {currentStep.step !== Step.overview && (
          <Button
            rightIcon={<ArrowForwardIcon />}
            bg={!isValid ? 'secondaryGray.600' : 'brandColor.400'}
            disabled={!isValid}
            colorScheme='brandColor.400'
            variant='outline'
            color={'white'}
            size='lg'
            onClick={handleNext}
          >
            Next
          </Button>
        )}
        {currentStep.step === Step.overview && (
          <Flex gap={'20px'}>
            {isCreating && (<Button
              color={'brandColor.400'}
              borderColor={'brandColor.400'}
              variant='outline'
              size='lg'
              onClick={confirmAndDupicate}
            >
              Confirm & duplicate for another shop/date
            </Button>)}
            <Button
              bg={'brandColor.400'}
              variant='solid'
              color={'white'}
              size='lg'
              onClick={finalSubmit}
            >
              {isCreating ? 'Final confirm' : 'Update'}
            </Button>
          </Flex>
        )}
      </Flex>
    </Card>
  );
}
