import { RouteComponentProps, withRouter, NavLink, useHistory } from 'react-router-dom';
import { ComponentType, FC, useEffect, useRef, useState } from 'react';
import DefaultAuth from '../../../layouts/auth/variants/Default';
import illustration from '../../../assets/img/auth/auth_bg.jpg';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text
} from '@chakra-ui/react';
import QRCode from "react-qr-code";
import { useColors, useNotification } from 'shared/hooks';
import { api } from 'utils/repository';
import { AxiosError } from 'axios';
import { ERROR_MESSAGE } from 'shared/constants';
import jwt_decode from 'jwt-decode';

const _SetPassword: FC<RouteComponentProps<{ token?: string }>> = (props) => {
  const token = props.match.params?.token;
  const user: { email: string } = jwt_decode(token);
  const {
    textColor,
    textColorSecondary,
    textColorDetails,
    textColorBrand,
  } = useColors();
  const [password, setPassword] = useState('');
  const [otpSecret, setOtpSecret] = useState('G4VHQD2YMBBFMC2F');
  const inputRef = useRef<HTMLInputElement>(null);
  const history = useHistory();
  const { successNotification, errorNotification } = useNotification();

  useEffect(() => {
    api.generateOTPSecret().then(({ secret }) => {
      setOtpSecret(secret);
    });
  }, []);

  const handleSetPassword = () => {
    api.validateOTP({
      secret: otpSecret,
      token: password,
    })
      .then(({ isValid }) => {
        if (isValid) {
          successNotification('code is valid');

          return api.saveOtpSecret({
            secret: otpSecret,
            token,
          });
        }

        errorNotification('code is not valid, try again.');
        setPassword('');
        inputRef?.current?.focus();
    }).then(() => {
      successNotification('secret key is saved');
      history.push('/auth/sign-in/default');
    }).catch((e: AxiosError<{ message: string }>) => {
      errorNotification(e?.response?.data?.message || ERROR_MESSAGE);
    })
  }

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: '100%', md: 'max-content' }}
        w="100%"
        mx={{ base: 'auto', lg: '0px' }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: '30px', md: '60px' }}
        px={{ base: '25px', md: '0px' }}
        mt={{ base: '40px', md: '14vh' }}
        flexDirection="column"
      >
        <Flex me="auto" justifyContent={'center'} alignItems={'center'} flexDirection={'column'} width={'100%'}>
          <Heading color={textColor} fontSize="36px" mb="10px">
            Scan the QR-code
          </Heading>
          <Heading color={textColor} fontSize="36px" mb="10px">
            with Authenticator app
          </Heading>
          <Text
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            {`or enter this key manually`}
          </Text>
          <Text
            mb="9px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            {`in the Authenticator app: ${otpSecret}`}
          </Text>
          <Box
            height={'auto'}
            margin={'0 auto 16px'}
            width={'100%'}
            maxWidth={222}
            border={'20px solid white'}
            boxShadow={'0px 18px 40px rgba(112, 144, 176, 0.12)'}
            borderRadius={16}
          >
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={`otpauth://totp/planningtool (${user?.email})?secret=${otpSecret}`}
              viewBox={`0 0 256 256`}
            />
          </Box>
        </Flex>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: '100%', md: '420px' }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: 'auto', lg: 'unset' }}
          me="auto"
          mb={{ base: '20px', md: 'auto' }}
        >
          <FormControl isRequired>
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              display="flex"
            >
              Verification code
            </FormLabel>
              <Input
                id="code"
                ref={inputRef}
                required
                fontSize="sm"
                placeholder="Enter verification code"
                mb="24px"
                size="lg"
                type={'number'}
                value={password}
                variant="auth"
                onChange={(e) => setPassword(e.target.value)}
              />

            <Button
              onClick={handleSetPassword}
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
            >
              Validate code
            </Button>
          </FormControl>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColorDetails} fontWeight="400" fontSize="14px">
              {"Already registered?"}
              <NavLink to="/auth/sign-in/default">
                <Text
                  color={textColorBrand}
                  as="span"
                  ms="5px"
                  fontWeight="500"
                >
                  Sign in
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

// @ts-ignore
export const SetPassword = withRouter(_SetPassword) as ComponentType<{}>;
