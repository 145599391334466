import axios, { AxiosRequestConfig } from 'axios';

// Create instance
const httpClient = axios.create();
// Set the AUTH token for any request
httpClient.interceptors.request.use((config: AxiosRequestConfig) => {
    const token = localStorage.getItem('user');
    let headers = {}

    if (token) {
        headers = { Authorization: `Bearer ${token}` }
    }

    return  { ...config, headers: { ...config.headers, ...headers } } as AxiosRequestConfig;
});

httpClient.interceptors.response.use((response) => response, (error) => {
    if (error.request.status === 401) {
      window.location.href = '/';
      localStorage.clear();
      sessionStorage.clear();
    }
    return error;
});

export default httpClient;
