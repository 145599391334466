import { Box, Flex, Icon, SimpleGrid, Text, useDisclosure } from '@chakra-ui/react';
import { HiOutlinePlusSm } from 'react-icons/hi';
import { useRecoilState, useRecoilValue } from 'recoil';

import { FilterAddOns } from 'data/selectors';
import { AddonAction, AddonModal, confirmPopup } from 'data/atoms';
import { BrandedButton } from 'components/buttons/BrandedButton';
import { IAddon } from 'shared/types';

import { AddOnCards } from './components/AddOnCards';
import { Header } from './components/Header';
import { Action, AddonEditModal } from './components/AddonEditModal';

const SettingsActionTypesAddOns = () => {
  const addOns: IAddon[] = useRecoilValue(FilterAddOns);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [, setAction] = useRecoilState(AddonAction);
  const [, setAddon] = useRecoilState(AddonModal);

  const openModal = (action: Action, addon?: IAddon) => {
    setAction(action);
    setAddon(addon);
    onOpen();
  }

  return (
    <Box pt={{ base: '180px', md: '130px', xl: '130px' }}>
      <AddonEditModal
        isOpen={isOpen}
        onClose={onClose}
      />
      <Flex align="center" w="100%" justifyContent="flex-end">
        <BrandedButton onClick={() => openModal(Action.create)}>
          <Icon
            as={HiOutlinePlusSm}
            w="22px"
            h="22px"
            mr="2"
            color="brandColor.300"
          />
          <Text color="brandColor.300" as="span">
            ADD ADD-ONS
          </Text>
        </BrandedButton>
      </Flex>
      <Header />

      <SimpleGrid columns={{ base: 2, md: 3, xl: 4 }} gap="20px" mb="20px">
        {addOns.map((addon) => {
          return (
            <AddOnCards
              key={addon.id}
              addon={addon}
              openModal={onOpen}
            />
          );
        })}
      </SimpleGrid>
    </Box>
  );
};

export default SettingsActionTypesAddOns;
