import { Flex, HStack, Image, Select, Text } from '@chakra-ui/react';
import base from 'assets/svg/base_logo.svg';
import telenet from 'assets/svg/telenet_logo.svg';
import { HSeparator } from 'components/separator/Separator';
import { brandAtom } from 'data/atoms';
import { currentRoleSelector, userBrands } from 'data/selectors';

import { NavLink } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { BrandName, IBrand } from 'shared/types';

export function SidebarBrand() {
  const [brandName, setBrandName] = useRecoilState(brandAtom);
  const role = useRecoilValue(currentRoleSelector);
  const allBrands: IBrand[] = useRecoilValue(userBrands);

  return (
    <>
      <Flex direction="column">
        <Flex py={2} my={2}>
          <NavLink to={`/${role}`}>
            <Text
              mx={2}
              fontSize="2xl"
              fontWeight="bold"
              verticalAlign="center"
              textAlign="center"
            >
              BUTIK Activation Tool
            </Text>
          </NavLink>
        </Flex>
        <HSeparator />
      </Flex>
      <Flex direction="column" my={2} padding={2}>
        <Text fontSize="xs" mb="1">
          Current environment
        </Text>
        <HStack gap={1}>
          {brandName === 'Base' ? (
            <Image src={base} w="75px" alt="base" />
          ) : (
            <Image src={telenet} w="35px" alt="telenet" />
          )}
          <Select
            variant="unstyled"
            value={brandName}
            onChange={({ target: { value } }) => {
              setBrandName(value as BrandName);
              localStorage.setItem('brand', value);
            }}
            color="brandColor.400"
            fontWeight="700"
          >
            {allBrands.map((brand, key) => (
              <option key={key} value={brand.name}>
                {brand.name}
              </option>
            ))}
          </Select>
        </HStack>
      </Flex>
      <HSeparator />
    </>
  );
}

export default SidebarBrand;
