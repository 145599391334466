import { FC, useState } from 'react';
import {
  Button,
  Flex, Icon, Input, List, ListIcon,
  ListItem, Select, Text, useColorModeValue
} from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { RiDeleteBin6Line } from 'react-icons/ri';

import Card from 'components/card/Card';
import { IAddon, Lang } from 'shared/types';
import { searchAddonsByName } from 'shared/utils';
import { HiOutlinePlusSm } from 'react-icons/hi';

export const AddOns: FC<{
  label: string;
  lang: Lang;
  currentAddonsList: IAddon[]; // список всех возможных аддонов конкретного типа
  addedAddonsList: IAddon[]; // список всех аддонов в этом типе
  onChange(addedAddons: IAddon[]): void;
}> = (props) => {
  const { label, currentAddonsList, addedAddonsList, onChange, lang } = props;
  const ids = addedAddonsList.map(a => (a.id))
  const neededAddons = currentAddonsList.filter((addon) => !ids.includes(addon.id));
  const bgShadow = useColorModeValue('14px 17px 40px 4px rgba(112, 144, 176, 0.08)', 'unset');
  const [variant, setVariant] = useState<IAddon>(null);


  const addAddon = () => {
    if (variant?.id) {
      onChange([...addedAddonsList, variant]);
      setVariant(null);
    }
  }

  const deleteAddon = (addonId: string) => {
    setVariant(null);
    onChange(addedAddonsList.filter((addon) => addon.id !== addonId));
  }

  return (
    <Card flexDirection="column" minW="200px" w="100%">
      <Flex direction="column">
        <Text
          color="brandColor.900"
          fontSize="xl"
          fontWeight="700"
          lineHeight="100%"
          mb="3"
        >
          {label}
        </Text>
        <Button
          textAlign="center"
          h="40px"
          pl="5"
          pr="5"
          mb={'10px'}
          fontSize="sm"
          fontWeight="500"
          borderRadius="50px"
          bgColor="brandColor.400"
          color="brandColor.300"
          _hover={{ bg: 'brand.100' }}
          onClick={addAddon}
        >
          <Icon as={HiOutlinePlusSm} w="12px" h="12px" color="brandColor.300" />
          ADD-ON
        </Button>
        <Select
          borderRadius={'16px'}
          mb={'10px'}
          value={variant?.id}
          placeholder={'select add-on...'}
          onChange={(e) => {
            const addon = neededAddons.find(a => a.id === e.target.value);
            setVariant(addon);
          }}
        >
          {neededAddons.map((addon) => (
            <option value={addon.id} key={addon.id + 'select'}>
              {addon.translations?.find(t => t.lang === lang)?.name}
            </option>
          ))}
        </Select>
        <Flex direction="column">
          <List spacing={2}>
            {addedAddonsList.map((addon) => {
              return (
                <ListItem key={addon.id + 'delete'}>
                  <ListIcon
                    as={RiDeleteBin6Line}
                    color="red.500"
                    w="20px"
                    h="20px"
                    onClick={() => deleteAddon(addon.id)}
                  />
                  {addon.translations?.find(t => t.lang === lang)?.name}
                </ListItem>
              );
            })}
          </List>
        </Flex>
      </Flex>
    </Card>
  );
}
