// Chakra imports
import { Box, Flex, Grid, Text, useColorModeValue } from '@chakra-ui/react';
// Contacts components
import Contact from './components/Contact';
//Assets
import Camille from 'assets/img/avatars/Camille_De_Bruyn.jpg';
import Paulien from 'assets/img/avatars/paulien.png';
import Suys from 'assets/img/avatars/Véronique_Suys.jpg';
import avatar4 from 'assets/img/avatars/avatar5.png';
import Jesse from 'assets/img/avatars/Jesse_De_Backer.png';

export default function SettingsContacts() {
  // Chakra Color Mode
  const textColor = useColorModeValue('navy.800', 'black');

  return (
    <>
      <Box pt={{ base: '180px', md: '130px', xl: '130px' }}>
        <Flex direction="column" width="stretch" mt="50">
          <Text
            color={textColor}
            fontSize={{ base: '3xl', md: '36px' }}
            mb="10"
            fontWeight="bold"
          >
            In case of questions you can contact
          </Text>

          <Flex justifyContent="center" alignItems="center">
            <Contact
              avatar={Suys}
              avatar2={Paulien}
              cardName="BUTIK"
              cardInfo="For questions concerning the execution"
              name="Véronique Suys"
              email="veronique.suys@butikagency.eu"
              tel="+32 494 86 30 10"
              name2="Paulien Vandersmissen"
              email2="paulien.vandersmissen@butikagency.eu"
              tel2="+32 484 31 10 14"
              details="For problems during the action, please call the hotline number:"
              detailsTel="+32 470 70 80 90"
            />
          </Flex>
        </Flex>
      </Box>
    </>
  );
}
