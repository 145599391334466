export const columnsRequestersOverview = [
  {
    Header: 'USER',
    accessor: 'firstName'
  },
  {
    Header: 'EMAIL',
    accessor: 'email'
  },
  {
    Header: 'LANGUAGE',
    accessor: 'lang'
  },
  {
    Header: 'DATE',
    accessor: 'createdAt'
  },
  {
    Header: 'ACTION REQUIRED',
    accessor: 'id'
  }
];
