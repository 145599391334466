// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  useColorModeValue
} from '@chakra-ui/react';

// Custom components
import DefaultAuth from 'layouts/auth/variants/Default';

// Assets
import illustration from '../../../assets/img/auth/auth_bg.jpg';
import { NavLink, useHistory } from 'react-router-dom';
import { useColors, useNotification } from 'shared/hooks';
import { FC, useState } from 'react';
import { api } from 'utils/repository';
import { AxiosError } from 'axios';
import { ERROR_MESSAGE } from 'shared/constants';

const ForgotPassword: FC<{}> = () => {
  const {
    textColor,
    textColorSecondary,
    textColorDetails,
    textColorBrand,
  } = useColors();
  const [email, setEmail] = useState('');
  const { successNotification, errorNotification } = useNotification();
  const history = useHistory();

  const handleUpdateSecret = () => {
    api.resetPassword({ email })
      .then(() => {
        successNotification('Link was successfully sent to your email');
        history.push('/auth/sign-in/default');
      })
      .catch((error: AxiosError<{ message: string }>) => {
        errorNotification(error.response.data.message || ERROR_MESSAGE)
      });
  }

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        w="100%"
        maxW="max-content"
        mx={{ base: 'auto', lg: '0px' }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: '30px', md: '60px', lg: '100px', xl: '60px' }}
        px={{ base: '25px', md: '0px' }}
        mt={{ base: '40px', lg: '16vh', xl: '22vh' }}
        flexDirection="column"
      >
        <Box me="auto" mb="34px">
          <Heading
            color={textColor}
            fontSize={{ base: '3xl', md: '36px' }}
            mb="16px"
          >
            Lost my access
          </Heading>
          <Text
            color={textColorSecondary}
            fontSize="md"
            w={{ base: '100%', lg: '456px' }}
            maxW="100%"
          >
            {
              "Enter your email address."
            }
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: '100%', lg: '456px' }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: 'auto', lg: 'unset' }}
          me="auto"
          mb={{ base: '20px', md: 'auto' }}
          align="start"
        >
          <FormControl isRequired>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Email
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              type="email"
              placeholder="mail@simmmple.com"
              mb="24px"
              size="lg"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              onClick={handleUpdateSecret}
            >
              Send new secret key
            </Button>
          </FormControl>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColorDetails} fontWeight="400" fontSize="14px">
              {"Already registered?"}
              <NavLink to="/auth/sign-in/default">
                <Text
                  color={textColorBrand}
                  as="span"
                  ms="5px"
                  fontWeight="500"
                >
                  Sign in
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default ForgotPassword;
