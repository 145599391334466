// Chakra imports
import { Box, Center, CircularProgress, Flex, Text } from '@chakra-ui/react';

import Card from 'components/card/Card';

import { FC, useState, useEffect } from 'react';
import { FilterStatus } from 'shared/types';
import Filter from '../components/Filter';
import TableActivationsOverview from './components/TableActivationsOverview';
import { RouteComponentProps } from 'react-router-dom';
import { getQueryParams } from 'shared/utils';
import { isNil } from 'lodash';
import { useRecoilState } from 'recoil';
import { activationsDuplicatedAtom } from 'data/atoms';

const ActivationsOverview: FC<RouteComponentProps> = (props) => {
  const [status, setStatus] = useState(FilterStatus.ALL)
  const [selectedAction, setSelectedAction] = useState('')
  const [, setDuplicates] = useRecoilState(activationsDuplicatedAtom);
  const [dateFilter, setDateFilter] = useState('')
  const [userFilter, setUserFilter] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const { isLoading, error }
    : { isLoading: boolean, error: any }
    = { isLoading: false, error: undefined }

  const { search } = props.location;
  const { duplicate } = getQueryParams(search);

  useEffect(() => {
    if (duplicate) {
      return () => {
        setDuplicates([]);
      }
    }
  }, [])

  if (error) {
    return (<Text color="red" align="center">{error}</Text>);
  }

  return Boolean(window.location) === true ? (
    <Box pt={{ base: '100px', md: '80px', xl: '80px' }}>
      {!duplicate && (<Filter
        status={status}
        setStatus={setStatus}
        selectedAction={selectedAction}
        setSelectedAction={setSelectedAction}
        dateFilter={dateFilter}
        setDateFilter={setDateFilter}
        userFilter={userFilter}
        setUserFilter={setUserFilter}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />)}
      <Flex direction="column">
        <Card px="0px">
          {isLoading && <Center><CircularProgress isIndeterminate color="green.300" /></Center>}
          {!isLoading
            && <TableActivationsOverview
              status={status}
              selectedAction={selectedAction}
              dateFilter={dateFilter}
              userFilter={userFilter}
              searchQuery={searchQuery}
              duplicate={duplicate}
            />}
        </Card>
      </Flex>
    </Box>
  ) : (
    <Center>{`No activations data`}</Center>
  );
}

export default ActivationsOverview;
