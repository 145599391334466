import { FC, useState } from 'react';
import { isEmpty, omit } from 'lodash';
import { AxiosError } from 'axios';
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  Select, Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import { MdArrowDropDown, MdOutlineCloudUpload } from 'react-icons/md';
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from 'recoil';

import { BrandedButton } from 'components/buttons/BrandedButton';
import { AddonAction, AddonModal, languageAtom } from 'data/atoms';
import { api } from 'utils/repository';
import { ModalPopup } from 'components/modal/modal';
import { brandSelector, GetAddOns, GetAddOnTypes } from 'data/selectors';
import { useConfirm, useNotification, useUpdateState } from 'shared/hooks';
import {
  IAddonDTOSave,
  IAddonState,
  IAddonType,
  IImage,
  ILang,
  Lang,
  LangNames
} from 'shared/types';
import { ERROR_MESSAGE } from '../../../../../shared/constants';
import { getAddonDTO, getDomainForFile } from '../../../../../shared/utils';

export enum Action {
  create,
  edit,
  duplicate,
  delete,
}

export interface IAddonEditModalProps {
  isOpen: boolean;
  onClose(): void;
}

export const AddonEditModal: FC<IAddonEditModalProps> = ({ isOpen, onClose }) => {
  const languages: ILang[] = useRecoilValue(languageAtom);
  const currentBrand = useRecoilValue(brandSelector);
  const addOnTypes: IAddonType[] = useRecoilValue(GetAddOnTypes);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const brand = useColorModeValue('brand.400', 'brand.400');
  const bg = useColorModeValue('gray.100', 'navy.700');
  const borderColor = useColorModeValue('gray.300', 'whiteAlpha.100');
  const addon = useRecoilValue(AddonModal);
  const action = useRecoilValue(AddonAction);
  const { errorNotification, successNotification } = useNotification();
  const refreshAddons = useRecoilRefresher_UNSTABLE(GetAddOns);
  const { onConfirm } = useConfirm();
  const defaultAddonType = addOnTypes.find(type => type.name === 'Visibility');
  const {
    modalState,
    setModalState,
    imageUrl,
    getRootProps,
    getInputProps,
    fileIsUpdated,
  } = useUpdateState(addon, languages, isOpen, defaultAddonType);

  const showErrorMessage = (error: AxiosError<{ message: string }>) => {
    errorNotification(error?.response?.data?.message || ERROR_MESSAGE);
  }

  const handleAction = async (data: IAddonDTOSave) => {
    /** EDIT */
    if (action === Action.edit) {
      api.updateAddon(currentBrand.id, addon.id, fileIsUpdated ? data : omit(data, 'imageId'))
        .then(() => {
          refreshAddons();
          onClose();
        })
        .catch(showErrorMessage)
    }
    /** DELETE */
    else if (action === Action.delete) {
      api.deleteAddon(currentBrand.id, addon.id)
        .then(() => {
          refreshAddons();
          successNotification('Addon is deleted')
          onClose();
        })
        .catch(showErrorMessage)
    }
    /** DUPLICATE */
    else if (action === Action.duplicate) {
      try {
        const image: IImage = await api.fileDuplicate({ path: imageUrl })
        await api.createAddon(currentBrand.id,{ ...omit(data, 'imageId'), imageId: image.id })
        refreshAddons();
        successNotification('Addon is duplicated')
        onClose();
      } catch (e: any) {
        showErrorMessage(e);
      }
    }
    /** CREATE */
    else {
      api.createAddon(currentBrand.id, data)
        .then(() => {
          refreshAddons();
          successNotification('Addon is created')
          onClose();
        })
        .catch(showErrorMessage)
    }
  }

  const handleSubmit = () => {
    const { data, errors } = getAddonDTO(modalState, languages);

    if (action !== Action.delete && !isEmpty(errors)) {
      if (isEmpty(data.addonTypeId) && isEmpty(data.imageId)) {
        return errorNotification('You must fill required fields');
      } else if (isEmpty(data.translations)) {
        return errorNotification('You must fill in the name in all languages');
      }
      else {
        const otherLang = data.translations[0].lang === Lang.nl ? Lang.fr : Lang.nl;
        const name = data.translations[0].name;

        return onConfirm(
          'Warning',
          `You only filled in the ${LangNames[data.translations[0].lang]} content. Would you like us to duplicate everything to ${LangNames[otherLang]}`
        ).then(() => {
          const newState: IAddonState = {
            ...modalState,
            names: {
              ...modalState.names,
              [otherLang]: name,
            }
          }

          setModalState(newState);
          const newData = getAddonDTO(newState, languages);
          handleAction(newData.data);

        })

      }

    }

    handleAction(data);
  }

  const title = (prefix?: string) => {
    if (action === Action.edit) return `Edit ${prefix}`;

    if (action === Action.delete) return `Delete ${prefix}`;

    if (action === Action.duplicate) return `Duplicate ${prefix}`;

    return `Create an ${prefix}`;
  }

  const footer = () => (
    <BrandedButton onClick={handleSubmit}>
      <Text color="brandColor.300" as="span">
        {action === Action.delete ? 'Delete' : 'Save Changes'}
      </Text>
    </BrandedButton>
  )

  const getProps = action !== Action.delete ? getRootProps({ className: 'dropzone' }) : {}

  const renderPanel = (lang: ILang) => {
    return (
      <TabPanel>
        <FormControl flexDirection="column">
          <Flex direction="column" mb="14px">
            <FormLabel mt="10px">Name</FormLabel>
            <Input
              borderRadius="15px"
              disabled={action === Action.delete}
              placeholder="Name"
              value={modalState.names[lang.name]}
              onChange={(e) =>
                setModalState({
                  ...modalState,
                  names: {
                    ...modalState.names,
                    [lang.name]: e.target.value,
                  },
                })
              }
            />
          </Flex>
          <Flex direction="column" mb="14px">
            <FormLabel ms="4px">Addon-type</FormLabel>
            <Select
              icon={<MdArrowDropDown />}
              borderRadius={16}
              mb="5px"
              disabled={action === Action.delete}
              value={modalState.addonType.id}
              onChange={(e) => {
                setModalState({
                  ...modalState,
                  addonType: {
                    id: e.target.value,
                    name: addOnTypes.find(t => t.id === e.target.value).name,
                  },
                })
              }}
            >
              {addOnTypes.map((brand, key) => (
                <option key={key} value={brand.id} data-name={brand.name}>
                  {brand.name}
                </option>
              ))}
            </Select>
          </Flex>
          <Flex direction="column">
            <FormLabel>Image</FormLabel>
            <Flex
              align="center"
              justify="center"
              position="relative"
              overflow="hidden"
              bg={bg}
              border="1px dashed"
              borderColor={borderColor}
              borderRadius="16px"
              w="100%"
              maxW="100%"
              h={{ base: '208px', '3xl': '300px' }}
              cursor={action !== Action.delete ? "pointer" : "default"}
              { ...getProps }
            >
              {action !== Action.delete && (<input
                {...getInputProps()}
                type="file"
                accept="image/apng, image/avif, image/gif, image/jpeg, image/png, image/svg+xml, image/webp"
              />)}
              <Flex
                textAlign="center"
                justifyContent="center"
                padding="5px"

              >
                {imageUrl ? (
                  <Box
                    width="100%"
                    height="100%"
                    position="absolute"
                    top="0"
                    left="0"
                    backgroundRepeat="no-repeat"
                    backgroundSize="cover"
                    backgroundImage={`${getDomainForFile()}${imageUrl}`}
                  />
                ) : (
                  <Box maxW="100%">
                    <Icon
                      as={MdOutlineCloudUpload}
                      w="80px"
                      h="80px"
                      color={textColor}
                    />
                    <Text
                      mb="12px"
                      fontSize="lg"
                      w="100%"
                      maxW="100%"
                      fontWeight="700"
                      color={textColor}
                      whiteSpace="pre-wrap"
                    >
                      Drop your images here, or{' '}
                      <Text
                        as="span"
                        fontSize="lg"
                        fontWeight="700"
                        color={brand}
                      >
                        click to browse
                      </Text>
                    </Text>
                    <Text
                      fontSize="sm"
                      fontWeight="500"
                      color="secondaryGray.500"
                      whiteSpace="pre-wrap"
                    >
                      1600 x 1200 (4:3) recommended. PNG, JPG and GIF files
                      are allowed
                    </Text>
                  </Box>
                )}
              </Flex>
            </Flex>
          </Flex>
        </FormControl>
      </TabPanel>
    );
  }

  return (
    <ModalPopup
      title={title(' add-on')}
      isOpen={isOpen}
      onClose={onClose}
      footer={footer()}
    >
      <Tabs>
        <TabList>
          {languages.map(lang => (<Tab name={lang.name} key={lang.langId}>{lang.name}</Tab>))}
        </TabList>

        <TabPanels>
          {languages.map(renderPanel)}
        </TabPanels>
      </Tabs>
    </ModalPopup>
  );
}
