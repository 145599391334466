import React, { useEffect } from "react";
import FullCalendar, { CustomContentGenerator, EventChangeArg, EventClickArg, EventContentArg, ToolbarInput } from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction"; // needed for dayClick

type Props = {
	calendarData: {
		title: string;
		borderColor: string;
		start: string;
		end: string;
		backgroundColor: string;
		className: string;
	}[];
	initialDate: string;
	dateClick?: (info: DateClickArg) => void
	eventChange?: (event: EventChangeArg) => void
	eventClick?: (event: EventClickArg) => void
	eventContent?: CustomContentGenerator<EventContentArg>
	headerToolbar?: false | ToolbarInput
}
function EventCalendar({
	calendarData,
	initialDate,
	dateClick = (info) => console.log('DateClickArg', info),
	eventChange = (info) => console.log('EventChangeArg', info),
	eventClick = (info) => console.log('EventChangeArg', info),
	eventContent,
	headerToolbar = {
		start: 'title', // will normally be on the left. if RTL, will be on the right
		center: '',
		end: 'today prev,next' // will normally be on the right. if RTL, will be on the left
	}
}: Props) {

	return (
		<FullCalendar
			headerToolbar={headerToolbar}
			titleFormat={({ year: 'numeric', month: 'long' })}
			plugins={[dayGridPlugin, interactionPlugin]}
			initialView="dayGridMonth"
			contentHeight="600px"
			events={calendarData}
			eventTimeFormat={({
				hour: '2-digit',
				minute: '2-digit',
				meridiem: false
			})}
			initialDate={initialDate}
			editable={true}
			height="100%"
			// eventChange={eventChange}
			dateClick={dateClick}
			eventClick={eventClick}
			eventContent={eventContent}
			eventDrop={eventChange}
			eventDurationEditable={false}
		/>
	);
}

export default EventCalendar;
