import { useRecoilState } from 'recoil';
import { activationStatusDetail, confirmPopup } from 'data/atoms';
import { useCallback, useEffect, useState } from 'react';
import { IActivation, IAddon, IAddonDTOSave, IAddonState, IAddonType, ILang, Step } from './types';
import { getDefaultStateAddon } from './utils';
import { useDropzone } from 'react-dropzone';
import { api } from '../utils/repository';
import { isEmpty, isNil, noop } from 'lodash';
import { useColorModeValue, useToast } from '@chakra-ui/react';

export const useConfirm = () => {
  const [state, setConfirm] = useRecoilState(confirmPopup);

  const confirm = (title: string, description: string, confirmButton?: string) => new Promise<void>((resolve, reject) => {
    setConfirm({
      isOpen: true,
      title,
      description,
      confirmButton: confirmButton || '',
      confirmPopup: () => {
        close();
        resolve && resolve();
      },
      cancel: () => {
        close();
        reject && reject();
      }
    });
  });

  const close = () => {
    setConfirm({
      isOpen: false,
      title: '',
      description: '',
      confirmButton: '',
      confirmPopup: noop,
      cancel: noop,
    });
  }

  return {
    onClose: close,
    onConfirm: confirm,
    state,
  }
}

export const useUpdateState = (addon: IAddon, languages: ILang[], isOpen: boolean, defaultAddonType: IAddonType) => {
  const [modalState, setModalState] = useState<IAddonState>(getDefaultStateAddon(defaultAddonType, languages, addon));
  const [data, setData] = useState<{ data: IAddonDTOSave, errors: string[] }>({ data: {
      imageId: modalState.image.id,
      addonTypeId: modalState.addonType.id,
      translations: [],
    }, errors: [] });
  const [imageUrl, setImage] = useState<string | undefined>(addon?.image?.path);
  const [myFiles, setMyFiles] = useState([]);

  const onDrop = useCallback(acceptedFiles => {
    setMyFiles(acceptedFiles)
  }, [myFiles]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });


  useEffect(() => {
    setModalState(getDefaultStateAddon(defaultAddonType, languages, isOpen ? addon : undefined));
    setImage(isOpen ? addon?.image?.path : undefined)
    setMyFiles([]);
  }, [isOpen])

  useEffect(() => {
    if (myFiles[0]) {
      const formdata = new FormData();
      formdata.append("file", myFiles[0], myFiles[0].name);

      api.uploadFile(formdata).then((response) => {
        const image = response.data;

        setImage(image.path);
        setModalState({
          ...modalState,
          image: {
            id: image.id,
            path: image.path,
          },
        })
      });

    }
  }, [myFiles]);

  useEffect(() => {
    const errors: string[] = [];
    const data: IAddonDTOSave = {
      imageId: modalState.image.id,
      addonTypeId: modalState.addonType.id,
      translations: [],
    }

    languages.forEach(l => {
      if (!isEmpty(modalState.names[l.name])) {
        data.translations.push({ lang: l.name, name: modalState.names[l.name] });
      } else {
        errors.push(`need add name for ${l.name} language`);
      }
    });

    setData({
      data, errors
    })

  }, [modalState]);

  return {
    modalState,
    setModalState,
    imageUrl,
    setImage,
    myFiles,
    getRootProps,
    getInputProps,
    data: data.data,
    errors: data.errors,
    fileIsUpdated: !isEmpty(myFiles)
  }
}

export const useNotification = () => {
  const toast = useToast();

  const errorNotification = (text: string) => {
    toast({
      title: 'Error',
      description: text,
      status: 'error',
      duration: 3000,
      isClosable: true,
    })
  }

  const successNotification = (text: string) => {
    toast({
      title: 'Success',
      description: text,
      status: 'success',
      duration: 3000,
      isClosable: true,
    })
  }

  return {
    errorNotification,
    successNotification,
  }
}

export const useColors = () => {
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.400';
  const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600');
  const textColorBrand = useColorModeValue('brand.500', 'white');

  return {
    textColor,
    textColorSecondary,
    textColorDetails,
    textColorBrand,
  }
}

export const useStatusDetail = (props: IActivation) => {
  const [stepDetail, setStepDetail] = useRecoilState(activationStatusDetail);
  const currentStep = stepDetail.find(step => step.active);
  const handlePrev = () => {
    const changesDetail = stepDetail.map(step => {
      if (step.step === currentStep.step - 1) {
        return {
          ...step,
          active: true,
        }
      }

      return {
        ...step,
        active: false,
      }
    })

    setStepDetail(changesDetail);
  }
  const handleNext = () => {
    const changesDetail = stepDetail.map(step => {
      if (step.step === currentStep.step) {
        return {
          ...step,
          active: false,
          completed: true,
        }
      }

      if (step.step === currentStep.step + 1) {
        return {
          ...step,
          active: true,
        }
      }

      return {
        ...step,
        active: false,
      }
    })

    setStepDetail(changesDetail);
  }

  const validate = () => {
    if (currentStep.step === Step.activation) {
      return !isNil(props.actionTypeId)
    }

    if (currentStep.step === Step.shop) {
      return !isEmpty(props.shopName)
        && !isEmpty(props.shopPostalCode)
        && !isEmpty(props.shopCity)
        && !isEmpty(props.shopStreet)
        && props.shopNumber
    }

    if (currentStep.step === Step.date) {
      const isValidActionDate = !isEmpty(props.actionDate) && props.actionDate !== 'Invalid date'
      const isValidAddonDeliveryDate = !isEmpty(props.addonDeliveryDate) && props.addonDeliveryDate !== 'Invalid date'
      return isValidActionDate && (props.deliveryAddonsOnActionDate || (!props.deliveryAddonsOnActionDate && isValidAddonDeliveryDate))
    }

    if (currentStep.step === Step.administration) {
      const EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;

      return !isEmpty(props.shopManagerEmail)
        && EMAIL_REGEXP.test(props.shopManagerEmail)
        && !isEmpty(props.shopManagerName)
        && !isEmpty(props.shopGeneralEmail)
        && EMAIL_REGEXP.test(props.shopGeneralEmail)
    }

    return true;
  }

  return {
    stepDetail,
    currentStep,
    setStepDetail,
    handlePrev,
    handleNext,
    isValid: validate(),
  }
}
