import {
  Avatar,
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorMode,
  useColorModeValue,
  VStack
} from '@chakra-ui/react';
import { HiOutlinePlusSm } from 'react-icons/hi';
import { IoMdMoon, IoMdSunny } from 'react-icons/io';
import { MdNotificationsNone } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import { BrandedButton } from 'components/buttons/BrandedButton';
import { SidebarResponsive } from 'components/sidebar/Sidebar';
import { VSeparator } from 'components/separator/Separator';
import {
  activationAtom,
  activationStatusDetail,
  authAtom,
  brandAtom,
  currentActivationCategory
} from 'data/atoms';
import { currentRoleSelector, GetUsersInfo } from 'data/selectors';
import { routes } from 'routes';
import {
  defaultStepStatuses,
  initialActivationData
} from 'views/admin/overview/create-activation/constants';
import { IActivation, ICategory } from '../../shared/types';

export default function NavbarLinksAdmin(props: any) {
  const history = useHistory();
  const { secondary } = props;

  const setAuth = useResetRecoilState(authAtom);
  const { colorMode, toggleColorMode } = useColorMode();
  const user = useRecoilValue(GetUsersInfo);
  const [, setActivation] = useRecoilState(activationAtom);
  const [, setCurrentActivation] = useRecoilState<ICategory>(currentActivationCategory);
  const [, setStepDetail] = useRecoilState(activationStatusDetail);
  const selectedBrand = useRecoilValue(brandAtom);
  const role = useRecoilValue(currentRoleSelector);

  function logout() {
    // remove user from local storage, set auth state to null and redirect to login page
    localStorage.clear();
    sessionStorage.clear();
    setAuth();
    history.push('/auth/sign-in/default');
  }
  const navbarIcon = useColorModeValue('gray.400', 'white');
  let menuBg = useColorModeValue('white', 'navy.800');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorBrand = useColorModeValue('brand.700', 'brand.400');
  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');

  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
  );

  return (
    <Flex
      w={{ sm: '100%', md: 'auto' }}
      alignItems="center"
      flexDirection="row"
      bg={menuBg}
      gap={3}
      flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
      p="10px"
      borderRadius="30px"
      boxShadow={shadow}
    >
      <SidebarResponsive routes={routes} />
      <BrandedButton
        onClick={() => {
          const url = `/${role}/overview/create_activation`
          setActivation(initialActivationData);
          setCurrentActivation({} as ICategory);
          setStepDetail(defaultStepStatuses);
          history.push(url);
        }}
      >
        {<Icon
          color={'white'}
          as={HiOutlinePlusSm}
          w="22px"
          h="22px"
          mr={3}
        />}
        <Text color={'white'} as="span" fontSize={'md'}>
          Request Activation
        </Text>
      </BrandedButton>

      <Button
        variant="no-hover"
        bg="transparent"
        p="0px"
        minW="unset"
        minH="unset"
        h="18px"
        w="max-content"
        onClick={toggleColorMode}
      >
        <Icon
          me="10px"
          h="18px"
          w="18px"
          color={navbarIcon}
          as={colorMode === 'light' ? IoMdMoon : IoMdSunny}
        />
      </Button>

      <VStack lineHeight={0.5} minW={'100px'}>
        <Text fontSize={{ base: 'xs', md: 'md' }} fontWeight={{ base: 'bold' }}>
          {user.firstName} {user.lastName}
        </Text>
      </VStack>

      <Menu>
        <MenuButton p="0px">
          <Avatar
            _hover={{ cursor: 'pointer' }}
            color="white"
            src={user.avatar?.path}
            bg={selectedBrand === 'Base' ? "#009793" : "#FFC421"}
            size="sm"
            w="40px"
            h="40px"
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="0px"
          mt="10px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
        >
          <Flex w="100%" mb="0px">
            <Text
              ps="20px"
              pt="16px"
              pb="10px"
              w="100%"
              borderBottom="1px solid"
              borderColor={borderColor}
              fontSize="sm"
              fontWeight="700"
              color={textColor}
            >
              👋&nbsp; Hey, {user.firstName}
            </Text>
          </Flex>
          <Flex flexDirection="column" p="10px">
            <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              borderRadius="8px"
              px="14px"
              onClick={() => {
                history.push(`/${role}/view_my_account`);
              }}
            >
              <Text fontSize="sm">My Account</Text>{' '}
            </MenuItem>
            <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              borderRadius="8px"
              px="14px"
              onClick={() => {
                history.push(`/${role}/contacts`);
              }}
            >
              <Text fontSize="sm">Contact & help</Text>{' '}
            </MenuItem>
            <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              color="red.400"
              borderRadius="8px"
              px="14px"
              onClick={logout}
            >
              <Text fontSize="sm">Log out</Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
      <VSeparator width="2px" height="2rem" />
      <Menu>
        <MenuButton display="flex" alignItems="center" p="0px">
          <Icon
            mt="6px"
            as={MdNotificationsNone}
            color={navbarIcon}
            w="22px"
            h="22px"
            me="10px"
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="20px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
          mt="22px"
          me={{ base: '30px', md: 'unset' }}
          minW={{ base: 'unset', md: '400px', xl: '450px' }}
          maxW={{ base: '360px', md: 'unset' }}
        >
          <Flex justify="space-between" w="100%" mb="20px">
            <Text fontSize="md" fontWeight="600" color={textColor}>
              Notifications
            </Text>
            <Text
              fontSize="sm"
              fontWeight="500"
              color={textColorBrand}
              ms="auto"
              cursor="pointer"
            >
              Mark all read
            </Text>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}
