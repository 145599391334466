import { mode } from '@chakra-ui/theme-tools';

export const telenetStyles = {
  colors: {
    brandColor: {
      100: '#ffb61b',
      200: '#e5e3e3',
      300: '#291d18',
      400: '#ffc421',
      500: '#dddbda',
      600: '#555555',
      700: '#707EAE',
      800: '#707EAE',
      900: '#291d18'
    },
    brand: {
      100: '#ffb61b',
      200: '#ffc421',
      300: '#ffc421',
      400: '#ffc421',
      500: '#ffc421',
      600: '#555555',
      700: '#291d18',
      800: '#2e3644',
      900: '#000000'
    },
    brandScheme: {
      100: '#ffb61b',
      200: '#ffc421',
      300: '#ffc421',
      400: '#ffc421',
      500: '#ffc421',
      600: '#555555',
      700: '#291d18',
      800: '#2e3644',
      900: '#000000'
    },
    brandTabs: {
      100: '#ffb61b',
      200: '#ffc421',
      300: '#ffc421',
      400: '#ffc421',
      500: '#ffc421',
      600: '#555555',
      700: '#291d18',
      800: '#2e3644',
      900: '#000000'
    },
    secondaryGray: {
      100: '#E0E5F2',
      200: '#E1E9F8',
      300: '#F4F7FE',
      400: '#E9EDF7',
      500: '#8F9BBA',
      600: '#A3AED0',
      700: '#707EAE',
      800: '#707EAE',
      900: '#000000'
    },
    red: {
      100: '#FEEFEE',
      500: '#EE5D50',
      600: '#E31A1A'
    },
    blue: {
      50: '#EFF4FB',
      500: '#3965FF'
    },
    orange: {
      100: '#FFF6DA',
      500: '#ffc421'
    },
    green: {
      100: '#E6FAF5',
      500: '#01B574'
    },
    navy: {
      50: '#ffffff',
      100: '#f8f8f8',
      200: '#f2f2f2',
      300: '#eeeded',
      400: '#e5e3e3',
      500: '#999391',
      600: '#766f6b',
      700: '#696969',
      800: '#656565',
      900: '#000000'
    },
    gray: {
      100: '#FAFCFE'
    }
  },
  styles: {
    global: (props: any) => ({
      body: {
        overflowX: 'hidden',
        bg: mode('#fdfeff', 'navy.900')(props),
        fontFamily: 'DM Sans',
        letterSpacing: '-0.5px'
      },
      input: {
        color: 'gray.700'
      },
      html: {
        fontFamily: 'DM Sans'
      }
    })
  }
};
export const baseStyles = {
  colors: {
    brandColor: {
      100: '#009793',
      200: '#111111',
      300: '#ffffff',
      400: '#009793',
      500: '#7d7d7d',
      600: '#555555',
      700: '#707EAE',
      800: '#707EAE',
      900: '#E0E5F2'
    },
    brand: {
      100: '#60b0b2',
      200: '#009793',
      300: '#009793',
      400: '#60b0b2',
      500: '#009793',
      600: '#3860be',
      700: '#111111',
      800: '#2e3644',
      900: '#000000'
    },
    brandScheme: {
      100: '#ffffff',
      200: '#60b0b2',
      300: '#60b0b2',
      400: '#60b0b2',
      500: '#009793',
      600: '#3860be',
      700: '#111111',
      800: '#2e3644',
      900: '#111111'
    },
    brandTabs: {
      100: '#60b0b2',
      200: '#009793',
      300: '#009793',
      400: '#009793',
      500: '#009793',
      600: '#3860be',
      700: '#111111',
      800: '#2e3644',
      900: '#111111'
    },
    secondaryGray: {
      100: '#E0E5F2',
      200: '#E1E9F8',
      300: '#F4F7FE',
      400: '#E9EDF7',
      500: '#8F9BBA',
      600: '#A3AED0',
      700: '#707EAE',
      800: '#707EAE',
      900: '#1B2559'
    },
    red: {
      100: '#FEEFEE',
      500: '#EE5D50',
      600: '#E31A1A'
    },
    blue: {
      50: '#EFF4FB',
      500: '#3965FF'
    },
    orange: {
      100: '#FFF6DA',
      500: '#009793'
    },
    green: {
      100: '#E6FAF5',
      500: '#01B574'
    },
    navy: {
      50: '#ffffff',
      100: '#f8f8f8',
      200: '#f2f2f2',
      300: '#eeeded',
      400: '#e5e3e3',
      500: '#999391',
      600: '#766f6b',
      700: '#696969',
      800: '#656565',
      900: '#000000'
    },
    gray: {
      100: '#FAFCFE'
    }
  },
  styles: {
    global: (props: any) => ({
      body: {
        overflowX: 'hidden',
        bg: mode('#fdfeff', 'navy.900')(props),
        fontFamily: 'DM Sans',
        letterSpacing: '-0.5px'
      },
      input: {
        color: 'gray.700'
      },
      html: {
        fontFamily: 'DM Sans'
      }
    })
  }
};
