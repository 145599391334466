import moment from 'moment/moment';
import {
  Button,
  Flex,
  Icon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack, Switch,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue
} from '@chakra-ui/react';

import { ActivationMenu } from 'components/menu/ActivationMenu';
import {
  brandSelector,
  currentRoleSelector,
  GetActivationsDuplicatedList,
  GetActivationsList, GetUsersInfo
} from 'data/selectors';
import { forceUpdateActivationList } from 'data/atoms';
import React, { useEffect, useMemo } from 'react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable
} from 'react-table';
import { useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValue } from 'recoil';
import { useNotification } from 'shared/hooks';
import { ApproveStatus, FilterStatus, IBrand, IUser } from 'shared/types';
import { api } from 'utils/repository';


const columnsDataActivationOverview = [
  { Header: 'ID', accessor: 'id' as const },
  { Header: 'MAIN STATUS', accessor: 'status' as const },
  { Header: 'START DATE', accessor: 'actionDate' as const, filter: 'actionDateFilter' },
  { Header: 'ACTIVATION', accessor: 'actionType' as const },
  { Header: 'SHOP ADDRESS', accessor: 'shopStreet' as const },
  { Header: 'REQUESTER', accessor: 'requestedBy' as const, filter: 'userFilter' },
  { Header: 'APPROVAL BY MANAGER', accessor: 'approvedByManager' as const },
  { Header: 'APPROVAL BY BUTIK', accessor: 'approvedByButik' as const },
  { Header: '', accessor: 'actionMenu' as const },
]

type Props = {
  status?: FilterStatus;
  selectedAction?: string;
  dateFilter?: string;
  userFilter?: string;
  searchQuery?: string;
  duplicate?: boolean;
}

function TableActivationsOverview({
  status = FilterStatus.ALL,
  selectedAction,
  dateFilter,
  userFilter,
  searchQuery,
  duplicate,
}: Props) {
  const all = useRecoilValue(GetActivationsList)
  const duplicates = useRecoilValue(GetActivationsDuplicatedList)
  const list = duplicate ? duplicates : all;
  const [, refreshTableData] = useRecoilState(forceUpdateActivationList);
  const filterTypes = useMemo(
    () => ({
      actionDateFilter: (rows: any[], columnIds: any[], filterValue: any) => {
        return rows.filter(row => new Date(row.original.actionDate).getTime() <= new Date(filterValue).getTime())
      },
      userFilter: (rows: any[], columnIds: any[], filterValue: any) => {
        return rows.filter(row => row.original.requestedBy.id === filterValue)
      },

      text: (rows: any[], columnIds: any[], filterValue: any) => {
        return rows.filter(row => {
          const rowValue = row.actionType
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )
  // (rows: Array<Row>, columnIds: Array<ColumnId: String>, filterValue) => Rows[]

  const history = useHistory();
  const brand = useRecoilValue<IBrand>(brandSelector);
  const user = useRecoilValue<IUser>(GetUsersInfo);
  const role = useRecoilValue(currentRoleSelector);
  const isAdmin = role !== 'requester';
  const { successNotification, errorNotification } = useNotification()
  const columns = useMemo(() => columnsDataActivationOverview, [])

  const data = useMemo(() => list
    .map((elem, i ) => ({
      id: i + 1,
      actionDate: moment(elem?.actionDate).format('YYYY-MM-DD'),
      actionType: elem?.actionType.name,
      shopStreet: elem?.shopStreet,
      requestedBy: elem?.requestedBy,
      actionMenu: elem?.id,
      lastMinuteAction: elem?.lastMinuteAction,
      status: String(elem?.status),
      approvedByManager: elem?.approvedByManager,
      approvedByButik: elem?.approvedByButik,
    })) || [],
    [list])
  const tableInstance = useTable(
    {
      columns,
      data,
      filterTypes,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    gotoPage,
    pageCount,
    setAllFilters,
    setGlobalFilter,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    toggleHideColumn,
    state,
  } = tableInstance;


  useEffect(() => {
    refreshTableData(n => n + 1)
  }, [])
  useEffect(() => {
    if (searchQuery.length > 2 || !searchQuery) {
      setGlobalFilter(searchQuery)
    }
  }, [searchQuery])
  useEffect(() => {


    const filters = [
      { id: 'status', value: status.toString() },
      { id: 'actionType', value: selectedAction },
    ]
    if (dateFilter) {
      filters.push( { id: 'actionDate', value: dateFilter })
    }
    if (userFilter) {
      filters.push( { id: 'requestedBy', value: userFilter },)
    }
    setAllFilters(filters)
  }, [status, selectedAction, dateFilter, userFilter])
  // useEffect(() => setFilter('status', selectedAction), [selectedAction])

  useEffect(() => {
    toggleHideColumn('approvedByManager', !isAdmin)
    toggleHideColumn('approvedByButik', !isAdmin)
  }, [isAdmin])
  const createPages = (count: number) => {
    let arrPageCount = [];

    for (let i = 1; i <= count; i++) {
      arrPageCount.push(i);
    }

    return arrPageCount;
  };

  const { pageIndex, pageSize } = state;
  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  const changeApproveByButik = (newValue: boolean, id: string) => {
    const activation = list.find(el => el.id === id);
    const saveApprovals = {
      approvedByButik: newValue,
      approvedByManager: activation.approvedByManager,
    };
    api.updateActivationApprovals(user.lang, brand.id, saveApprovals, id)
      .then(resp => {
        refreshTableData(n => n + 1);
        successNotification('Approvals updated');
      })
      .catch(() => {
        errorNotification('Error while updating approvals');
      });
  }
  const changeApproveByManager = (newValue: boolean, id: string) => {
    const activation = list.find(el => el.id === id);
    const saveApprovals = {
      approvedByButik: activation.approvedByButik,
      approvedByManager: newValue,
    };
    api.updateActivationApprovals(user.lang, brand.id, saveApprovals, id)
      .then(resp => {
        refreshTableData(n => n + 1);
        successNotification('Approvals updated');
      })
      .catch(() => {
        errorNotification('Error while updating approvals');
      });
  }

  return (
    <>
      <Flex
        direction="column"
        w="100%"
        overflowX={{ sm: 'scroll', lg: 'hidden' }}
      >
        <Flex
          align={{ sm: 'flex-end', lg: 'flex-end' }}
          justify={{ sm: 'flex-end', lg: 'flex-end' }}
          w="100%"
          px="22px"
          mb="36px"
        ></Flex>
        <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="10px"
                    key={index}
                    borderColor={borderColor}
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: '10px', lg: '12px' }}
                      color="gray.400"
                    >
                      {column.render('Header')}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = null;
                    if (cell.column.Header === 'ID') {
                      data = (
                        <Text color={textColor} fontSize="md" fontWeight="500">
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === 'ACTIVATION') {
                      data = (
                        <>
                          <Text color={textColor} fontSize="md" fontWeight="500">
                            {cell.value}
                          </Text>
                          {cell?.row?.original?.lastMinuteAction && (<Text color={textColor} fontSize="md" fontWeight="500">
                            (last minute)
                          </Text>)}
                        </>
                      );
                    } else if (cell.column.Header === 'START DATE') {
                      data = (
                        <Text color={textColor} fontSize="md" fontWeight="500">
                          {cell.value && moment(cell.value).format('DD.MM.YYYY')}
                        </Text>
                      );
                    } else if (cell.column.Header === 'SHOP ADDRESS') {
                      data = (
                        <Text color={textColor} fontSize="md" fontWeight="500">
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === 'REQUESTER') {
                      data = (
                        <Text color={textColor} fontSize="md" fontWeight="500">
                          {cell.value.firstName + ' ' + cell.value.lastName}
                        </Text>
                      );
                    } else if (cell.column.Header === 'APPROVAL BY MANAGER') {
                      data = (
                        <Switch
                          isChecked={cell.value}
                          id={'approvedByManager' + index}
                          variant='main'
                          colorScheme="green"
                          color="green.500"
                          size='md'
                          onChange={(e) => changeApproveByManager(e.target.checked, row.original.actionMenu)}
                        />
                      );
                    } else if (cell.column.Header === 'APPROVAL BY BUTIK') {
                      data = (
                        <Switch
                          isChecked={cell.value}
                          id={'approvedByButik' + index}
                          variant='main'
                          colorScheme="green"
                          color="green.500"
                          size='md'
                          onChange={(e) => changeApproveByButik(e.target.checked, row.original.actionMenu)}
                        />
                      );
                    } else if (cell.column.Header === 'MAIN STATUS') {
                      data = (
                        <Flex justifyContent="space-between">
                          <Text
                            color={textColor}
                            fontSize="md"
                            fontWeight="500"
                          >
                            {cell.value.toUpperCase()}
                          </Text>
                        </Flex>
                      );
                    } else if (cell.column.Header === '') {
                      data = (
                        <Flex justifyContent="space-between">
                          <ActivationMenu
                            onEdit={() => {

                              history.push(`/${role}/overview/edit_activation/${cell.value}`);
                            }}
                            onView={() => {

                              history.push(`/${role}/overview/view_activation_details/${cell.value}`);
                            }}
                            onDuplicate={() => {

                              history.push(`/${role}/overview/create_activation/${cell.value}`);
                            }}
                            onRemove={() => {
                              api.deleteActivation(user.lang, brand.id, cell.value)
                                .then(() => {
                                  refreshTableData(n => n + 1)
                                  successNotification('Activation deleted')
                                })
                                .catch(() => {
                                  errorNotification('Error while activation deleting')
                                  refreshTableData(n => n + 1)
                                })
                            }}
                            onCancel={() => {
                              api.cancelActivation(user.lang, brand.id, cell.value)
                                .then(() => {
                                  refreshTableData(n => n + 1)
                                  successNotification('Activation canceled')
                                })
                                .catch(() => {
                                  errorNotification('Error while activation canceling')
                                  refreshTableData(n => n + 1)
                                })
                            }}
                          />
                        </Flex>
                      );
                    }

                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        borderColor={borderColor}
                      >
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <Flex
          direction={{ sm: 'column', md: 'row' }}
          justify="space-between"
          align="center"
          w="100%"
          px={{ md: '22px' }}
        >
          <Text
            fontSize="sm"
            color="gray.500"
            fontWeight="normal"
            mb={{ sm: '24px', md: '0px' }}
          >
            Showing {pageSize * pageIndex + 1} to{' '}
            {pageSize * (pageIndex + 1) <= data?.length
              ? pageSize * (pageIndex + 1)
              : data?.length}{' '}
            of {data?.length} entries
          </Text>
          <Stack direction="row" alignSelf="flex-end" spacing="4px" ms="auto">
            <Button
              variant="no-effects"
              onClick={() => previousPage()}
              transition="all .5s ease"
              w="40px"
              h="40px"
              borderRadius="50%"
              bg="transparent"
              border="1px solid"
              borderColor={useColorModeValue('gray.200', 'white')}
              display={
                pageSize === 5 ? 'none' : canPreviousPage ? 'flex' : 'none'
              }
              bgColor="brandColor.400"
              _hover={{ bg: 'brandColor.100' }}
            >
              <Icon
                as={MdChevronLeft}
                w="16px"
                h="16px"
                color="brandColor.900"
              />
            </Button>
            {pageSize === 5 ? (
              <NumberInput
                max={pageCount - 1}
                min={1}
                w="75px"
                mx="6px"
                defaultValue="1"
                onChange={(e) => gotoPage(Number(e))}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper onClick={() => nextPage()} />
                  <NumberDecrementStepper onClick={() => previousPage()} />
                </NumberInputStepper>
              </NumberInput>
            ) : (
              createPages(pageCount).map((pageNumber, index) => {
                return (
                  <Button
                    variant="no-effects"
                    transition="all .5s ease"
                    onClick={() => gotoPage(pageNumber - 1)}
                    w="40px"
                    h="40px"
                    borderRadius="50%"
                    bg={
                      pageNumber === pageIndex + 1
                        ? 'brandColor.400'
                        : 'transparent'
                    }
                    border={
                      pageNumber === pageIndex + 1
                        ? 'none'
                        : '1px solid lightgray'
                    }
                    _hover={
                      pageNumber === pageIndex + 1
                        ? {
                          opacity: '0.7'
                        }
                        : {
                          bg: 'brandColor.900'
                        }
                    }
                    key={index}
                  >
                    <Text
                      fontSize="sm"
                      color={
                        pageNumber === pageIndex + 1 ? '#fff' : 'brandColor.100'
                      }
                    >
                      {pageNumber}
                    </Text>
                  </Button>
                );
              })
            )}
            <Button
              type="button"
              variant="no-effects"
              onClick={() => nextPage()}
              transition="all .5s ease"
              w="40px"
              h="40px"
              borderRadius="50%"
              bgColor="transparent"
              border="1px solid"
              borderColor={useColorModeValue('gray.200', 'white')}
              display={pageSize === 5 ? 'none' : canNextPage ? 'flex' : 'none'}
              _hover={{ bg: 'brandColor.100' }}
            >
              <Icon
                as={MdChevronRight}
                w="16px"
                h="16px"
                color="brandColor.900"
              />
            </Button>
          </Stack>
        </Flex>
      </Flex>
    </>
  );
}

export default TableActivationsOverview;
