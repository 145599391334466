import { FC, useEffect, useState } from 'react';
import {
  Alert,
  AlertIcon,
  Badge,
  Flex,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text, useToast
} from '@chakra-ui/react';
import { MdArrowDropDown } from 'react-icons/md';
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from 'recoil';
import { isEmpty } from 'lodash';

import { BrandedButton } from 'components/buttons/BrandedButton';
import { IBrand, IRole, IUser, IUserDTOForSave, RoleStatus } from 'shared/types';
import { brandsListSelector, RoleSelector, SortUsers } from 'data/selectors';
import { BrandName } from 'shared/types';
import { getBrandId, getRoleId } from 'shared/utils';
import { api } from 'utils/repository';
import { ModalPopup } from 'components/modal/modal';
import { useNotification } from '../../../../../../shared/hooks';
import { ERROR_MESSAGE } from '../../../../../../shared/constants';

interface IProps {
  user: IUser,
  isOpen: boolean;
  onClose(): void;
}

export const ApprovePopup: FC<IProps> = ({ user, isOpen, onClose }) => {
  const selectRole: IRole[] = useRecoilValue(RoleSelector);
  const [modalState, setModalState] = useState({
    telenetRoleId: '',
    baseRoleId: '',
  });
  const telenetRoleId = getRoleId(user, BrandName.Telenet);
  const baseRoleId = getRoleId(user, BrandName.Base);
  const allBrands: IBrand[] = useRecoilValue(brandsListSelector);
  const telenetId = getBrandId(allBrands, BrandName.Telenet);
  const baseId = getBrandId(allBrands, BrandName.Base);
  const refreshUsers = useRecoilRefresher_UNSTABLE(SortUsers);
  const { successNotification, errorNotification } = useNotification();

  useEffect(() => {
    setModalState({
      telenetRoleId,
      baseRoleId,
    })
    setError(null);
  }, [user]);

  const [error, setError] = useState<string | null>(null);

  const handleApprove = () => {
    if (isEmpty(modalState.telenetRoleId) && isEmpty(modalState.baseRoleId)) {
      setError('You need to add a role to any brand');
      return;
    }

    const userDto: IUserDTOForSave = {
      lang: user.lang,
      avatarId: user.avatar?.id || null,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      roles: [],
    }

    if (modalState.telenetRoleId !== '') {
      userDto.roles.push({
        brandId: telenetId,
        roleId: modalState.telenetRoleId,
        status: RoleStatus.approved,
      })
    }

    if (modalState.baseRoleId !== '') {
      userDto.roles.push({
        brandId: baseId,
        roleId: modalState.baseRoleId,
        status: RoleStatus.approved,
      })
    }

    api.updateUser(user.id, userDto).then(() => {
      refreshUsers();
      onClose();
      successNotification('User approved')
    }).catch((error) => {
      console.log(error);
      errorNotification(ERROR_MESSAGE);
    });
  }

  const title = () => (
    <>
      <Text fontSize="large" fontWeight="600" mb="10px">You are about to approve</Text>
      <Text fontSize="x-large">{`${user.firstName} ${user.lastName}`}</Text>
    </>
  );

  const footer = () => (
    <BrandedButton onClick={handleApprove}>
      Confirm
    </BrandedButton>
  )

  return (
    <>
      <ModalPopup isOpen={isOpen} onClose={onClose} title={title()} footer={footer()}>
        {error && (<Alert status='error' mb="10px">
          <AlertIcon />
          {error}
        </Alert>)}
        <Flex direction="column" mb="14px">
          <FormLabel ms="4px">Please set the user role for <Badge fontSize='medium'>Telenet</Badge></FormLabel>
          <Select
            icon={<MdArrowDropDown />}
            borderRadius={16}
            mb="5px"
            placeholder="No access"
            value={modalState.telenetRoleId}
            onChange={({ target: { value } }) => {
              setModalState({
                telenetRoleId: value,
                baseRoleId: modalState.baseRoleId,
              });
            }}
          >
            {selectRole.map((role, key) => (
              <option key={key} value={role.id}>
                {role.name}
              </option>
            ))}
          </Select>
        </Flex>
        <Flex direction="column">
          <FormLabel ms="4px">Please set the user role for <Badge fontSize='medium'>Base</Badge></FormLabel>
          <Select
            icon={<MdArrowDropDown />}
            borderRadius={16}
            mb="5px"
            value={modalState.baseRoleId}
            placeholder="No access"
            onChange={({ target: { value } }) => {
              setModalState({
                telenetRoleId: modalState.telenetRoleId,
                baseRoleId: value,
              });
            }}
          >
            {selectRole.map((role, key) => (
              <option key={key} value={role.id}>
                {role.name}
              </option>
            ))}
          </Select>
        </Flex>
      </ModalPopup>
    </>
  );
}
