import React, { FC } from "react";
import { Flex, FormLabel, useColorModeValue } from '@chakra-ui/react';
import { AsyncDropDownOption } from 'shared/types';
import { AsyncSelect } from 'chakra-react-select';

export const SelectField: FC<{
  id: string;
  label: string;
  placeholder: string;
  value: any;
  loadOption(inputValue: string, callback: (options: AsyncDropDownOption[]) => void): void;
  onChange(option: AsyncDropDownOption): void;
  [x: string]: any;
}> = (props) => {
  const { id, label, value, placeholder, type, mb, onChange, loadOption, ...rest } = props;
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  return (
    <Flex direction="column" mb={mb ? mb : "30px"}>
      <FormLabel
        display="flex"
        ms="10px"
        htmlFor={id}
        fontSize="sm"
        color={textColorPrimary}
        fontWeight="bold"
        _hover={{ cursor: "pointer" }}>
        {label}
      </FormLabel>
      <AsyncSelect
        aria-labelledby="aria-label"
        useBasicStyles
        focusBorderColor="secondaryGray.600"
        value={value}
        chakraStyles={{
          dropdownIndicator: (provided, state) => {
            return {
              ...provided,
              display: 'none',
            };
          },
          control: (provided, state) => {
            return {
              ...provided,
              borderRadius: '16px',
              lineHeight: '36px'
            };
          }
        }}
        id={id}
        placeholder="1800"
        onChange={onChange}
        loadOptions={loadOption} />
    </Flex>
  );
}
