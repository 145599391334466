import React, { FC } from "react";
import { Flex, FormLabel, Input, useColorModeValue } from '@chakra-ui/react';
import { AsyncDropDownOption } from 'shared/types';

export const DateField: FC<{
  id: string;
  label?: string;
  placeholder: string;
  value: any;
  disabled?: boolean;
  onChange(e: any): void;
  [x: string]: any;
}> = (props) => {
  const { id, label, value, disabled, placeholder, type, mb, onChange, loadOption, ...rest } = props;
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  return (
    <Flex direction="column" mb={mb ? mb : "15px"}>
      {label && (<FormLabel
        display="flex"
        ms="10px"
        htmlFor={id}
        fontSize="sm"
        color={textColorPrimary}
        fontWeight="bold"
        _hover={{ cursor: "pointer" }}>
        {label}
      </FormLabel>)}
      <Input
        id={id}
        focusBorderColor="secondaryGray.600"
        placeholder={placeholder}
        size="md"
        disabled={disabled}
        type="date"
        value={value}
        onChange={onChange}
      />
    </Flex>
  );
}
