import React, { FC } from 'react';
import { Box, Flex, Grid, Text, useColorModeValue } from '@chakra-ui/react';
import ActivationStepStatus from './components/ActivationStepStatus';
import { ActivationLayout } from './activation';

export const ActivationPage: FC<{ }> = () => {
  const mainText = useColorModeValue('navy.700', 'white');

  return (
    <>
      <Flex direction="column" zIndex="201" pos="fixed" ml={4}>
        <Text fontSize="33px" lineHeight="50px" zIndex="1" fontWeight="700">
          Request Activation
        </Text>
      </Flex>
      <Box>
        <Grid
          mb='20px'
          pr={'20px'}
          templateColumns={{ base: '2fr 1fr', lg: '2fr 1fr' }}
          alignItems={'start'}
          pt={{ base: '130px', md: '80px', xl: '80px' }}
        >
          <ActivationLayout />
          <ActivationStepStatus
            ml={{ base: '0px', lg: '20px' }}
            mt={{ base: '20px', lg: '0px' }}
            zIndex='0'
          />
        </Grid>
      </Box>
    </>
  );
}
