import React, {useContext} from "react";
import {Redirect, Route, RouteProps} from "react-router-dom";
import { useRecoilValue } from 'recoil';
import { authAtom } from './data/atoms';

interface PrivateRouteProps extends RouteProps {
    component: any;
}

export const PrivateRoute = (props: PrivateRouteProps) => {
    const {component: Component, ...rest} = props;
    const user = useRecoilValue(authAtom);
    return (
        <Route
            {...rest}
            render={(routeProps) =>
                user ? (
                    <Component {...routeProps} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/auth',
                            state: {from: routeProps.location}
                        }}
                    />
                )
            }
        />
    );
};
