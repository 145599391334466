import { atom } from 'recoil';
import { v1 } from 'uuid';
import { Action } from '../views/admin/settings/add-ons/components/AddonEditModal';
import {
  BrandName,
  IAddon,
  ICategory,
  INewActivationResponse,
  IUserInfo,
  Lang
} from 'shared/types';
import {
  defaultStepStatuses,
  initialActivationData
} from '../views/admin/overview/create-activation/constants';

/** login - logout */
export const authAtom = atom({
  key: `auth/${v1()}`,
  default: localStorage.getItem('user')
});

/** Create-user */
export const userSorting = atom({
  key: `userSorting/${v1()}`,
  default: 'approved'
});

/** does sorting by brands[0].status & used in FilterUsers */
export const sortUserState = atom({
  key: `sortUserState/${v1()}`,
  default: 'approved'
});

/** used in sorting users displays selected userId */
export const selectedUserID = atom({
  key: `selectedUserID/${v1()}`,
  default: ''
});

export const userState = atom({
  key: `userState/${v1()}`,
  default: {} as IUserInfo
});

export const baseRoleId = atom({
  key: `baseRoleId/${v1()}`,
  default: ''
});

export const telenetRoleId = atom({
  key: `telenetRoleId/${v1()}`,
  default: ''
});

/** Brands */
export const languageAtom = atom({
  key: `languageAtom/${v1()}`,
  default: [
    { langId: '3fa85f64-5717-4562-b3fc-2c963f66afa6', name: Lang.nl },
    { langId: '3fa45g24-5717-63f6-b3fc-3fa85f64-571', name: Lang.fr }
  ]
});

export const brandAtom = atom({
  key: `brandAtom/${v1()}`,
  default: BrandName.Telenet
});

export const actionTypeSorting = atom({
  key: `actionTypeSorting/${v1()}`,
  default: 'All'
});

export const actionTypeSortState = atom({
  key: `actionTypeSortState/${v1()}`,
  default: ''
});

export const chosenActionType = atom({
  key: `chosenActionType/${v1()}`,
  default: {}
});

/** returns selected Add-on ID & used in ManipulationStateField */
export const manipulateActionTypeID = atom({
  key: `manipulateActionTypeID/${v1()}`,
  default: ''
});

 /**                   Add-ons */
/** does sorting by addonType.name & used in FilterAddOns */
export const addOnSorting = atom({
  key: `addOnSorting/${v1()}`,
  default: 'All'
});

/** does sorting by addonType.name & used in FilterAddOns */
export const sortState = atom({
  key: `sortState/${v1()}`,
  default: ''
});

export const addOnPopup = atom({
  key: `addOnPopup/${v1()}`,
  default: false
});

export const selectedActionAddOns = atom({
  key: `selectedActionAddOns/${v1()}`,
  default: []
});

export const currentActivationCategory = atom({
  key: `currentActivationCategory/${v1()}`,
  default: {} as ICategory
});

export const currentVisibilityAddon = atom({
  key: `currentVisibilityAddon/${v1()}`,
  default: {
    addon: {} as IAddon
  }
});

export const currentOtherAddon = atom({
  key: `currentOtherAddon/${v1()}`,
  default: {
    addon: {} as IAddon
  }
});

export const AddonAction = atom({
  key: `addonAction/${v1()}`,
  default: Action.create,
});

export const AddonModal = atom({
  key: `addonModal/${v1()}`,
  default: undefined,
})
export const responseActivation = atom({
  key: `responseActivation/${v1()}`,
  default: {} as INewActivationResponse
});

export const confirmPopup = atom({
  key: `confirmPopup/${v1()}`,
  default: {
    isOpen: false,
    title: '',
    confirmButton: '',
    description: '',
    confirmPopup: () => {},
    cancel: () => {},
  }
})

// atoms for create activation
export const activationStatusDetail = atom({
  key: `activationStatusDetail/${v1()}`,
  default: defaultStepStatuses,
})

export const activationAtom = atom({
  key: `activationAtom/${v1()}`,
  default: initialActivationData
});

export const activationsDuplicatedAtom = atom({
  key: `activationsDuplicatedAtom/${v1()}`,
  default: [],
});

export const forceUpdateActivationList = atom({
  key: `forceTodoUpdateLis/${v1()}`,
  default: 0,
});
