import { BrandName, Lang, RoleStatus } from './enums';

export interface IRole {
  id: string;
  name: string;
  permissions?: string[];
}

export interface IImage {
  id: string;
  path: string;
  fileUpdated?: boolean;
}


export interface IBrand {
  name: BrandName;
  id: string;
}

export interface IBrandDTO {
  brand: IBrand;
  role: IRole;
  status: RoleStatus;
}

export interface ILang {
  langId: string;
  name: Lang;
}

export interface IUser extends  IUserInfo {
  id: string;
  createdAt: string;
  brands: Array<{
    status: string;
    id: string;
    brand: {
      id: string;
      name: string;
    }
    role: {
      id: string;
      permissions: string[];
      name: string;
    }
  }>;
  avatar: IImage;
}

export interface IAddonType {
  name: string;
  id: string;
}


export interface IUserInfo {
  lang: string;
  email: string;
  firstName: string;
  lastName: string;
}

export interface IActivation {
  shopId?: string;
  shopName: string;
  shopStreet: string;
  shopCity: string;
  shopNumber: number;
  shopBus: string;
  shopPostalCode: string;
  actionDate: string;
  addonDeliveryDate: string;
  activationDate?: string;
  shopManagerId?: string;
  shopManagerEmail: string;
  shopManagerPhone: string;
  shopManagerName: string;
  shopGeneralEmail: string;
  actionTypeId: string;
  addons: string[];
  wantExtraAddons: boolean;
  wantGadgedRemarks: boolean;
  gadgedRemarks: string;
  shopping: string;
  shopLatitude?: number;
  shopLongtitude?: number;
  requestedAt: string;
  requestedById: string;
  lastMinuteAction: boolean;
  deliveryAddonsOnActionDate: boolean;
  extraRemarks: string;
  approved: boolean;
  approvedByManager: boolean;
  approvedByButik: boolean;
  arrivalTime: string;
  startTime: string;
  endTime: string;
  departureTime: string;
  setupHostesses: number;
  setupExtra1: string;
  setupExtra2: string;
  setupAccessibility: string;
  setupOtherRemarks: string;
}


export interface IValue {
  activation: boolean;
  shop: boolean;
  date: boolean;
  administration: boolean;
  overview: boolean;
}

export interface IAddon {
  id: string;
  image: IImage,
  addonType: IAddonType;
  translations: ITranslation[];
}

export interface IAddonDTOSave {
  imageId?: string;
  addonTypeId: string;
  translations: ITranslation[];
}

export interface IRegDTO {
  lang: string;
  email: string;
  brands: Array<{
    brandId: string;
  }>
  firstName: string;
  lastName: string;
}

export interface ILoginDTO {
  email: string;
  password: string;
}

export interface IOTPLoginDTO {
  email: string;
  token: string;
}

export interface IOTPValidateDTO {
  secret: string;
  token: string;
}

export interface ISetPasswordDTO {
  token: string;
  password: string;
}

export interface ICategory {
  id: string;
  name: string;
  description: string;
}

export type CityZipPair = {
  city: string,
  zip: string,
}

export type AsyncDropDownOption = {
  value: string,
  label: string,
  color: string,
  isFixed: boolean,
  data: any
}

export interface IUserDTOForSave {
  lang: string;
  avatarId?: string;
  email: string;
  firstName: string;
  lastName: string;
  roles: Array<{
    brandId: string;
    roleId: string;
    status?: RoleStatus;
  }>
}

export interface IAddonState extends IAddon {
  names: { [x: string]: string };
}

// Interfaces for response JSON /activation/new
export interface IImage {
  id: string;
  path: string;
}

export interface IBrand {
  id: string;
  name: BrandName;
}

export interface ITranslation {
  name: string;
  lang: Lang;
  id?: string;
}

export interface IAddonResponse {
  actionTypeAddon: {
    addon: IAddon;
  };
}

export interface ICategory {
  id: string;
  name: string;
  description: string;
}

export interface IActionTypeResponse {
  id: string;
  infoDoc: {
    id: string;
    path: string;
  };
  image: IImage;
  category: ICategory;
  brand: IBrand;
  addons: IAddonResponse[];
  name: string;
  extraInfo: string;
  active: boolean;
}

export interface IBrandsInfo {
  status: string;
  brand: IBrand;
  role: {
    id: string;
    name: string;
    permissions: string[];
  };
}

export interface IRequestedBy {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  createdAt: string;
  lang: string;
  avatar: {
    id: string;
    path: string;
  };
  brands: IBrandsInfo[];
}

export interface INewActivationResponse {
  id: string;
  wantExtraAddons: boolean;
  wantGadgedRemarks: boolean;
  gadgedRemarks: string;
  shopName: string;
  shopping: string;
  shopStreet: string;
  shopNumber: number;
  shopBus: string;
  shopPostalCode: string;
  shopCity: string;
  shopLatitude: number;
  shopLongtitude: number;
  requestedAt: string;
  lastMinuteAction: boolean;
  actionDate: string;
  deliveryAddonsOnActionDate: boolean;
  addonDeliveryDate: string;
  shopManagerEmail: string;
  shopManagerPhone: string;
  shopManagerName: string;
  shopGeneralEmail: string;
  extraRemarks: string;
  status: FilterStatus;
  approved: boolean;
  approvedByManager: boolean;
  approvedByButik: boolean;
  arrivalTime: string;
  startTime: string;
  endTime: string;
  departureTime: string;
  setupHostesses: number;
  setupExtra1: string;
  setupExtra2: string;
  setupAccessibility: string;
  setupOtherRemarks: string;
  addons: {
    actionTypeAddon: {
      addon: IAddon;
    };
  }[];
  actionType: IActionTypeResponse;
  requestedBy: IRequestedBy;
}

export const LangNames = {
  [Lang.nl]: 'Dutch',
  [Lang.fr]: 'French',
}

export type IEditSchedule = {
  arrivalTime: string;
  startTime: string;
  endTime: string;
  departureTime: string;
}

export type IActivationListResponse = {
  more: boolean,
  results: INewActivationResponse[],
  page: number;
  perPage: number;
  total: number;
  totalPages: number;
}

export enum FilterStatus {
  ALL = '',
  PENDING = 'pending',
  ACTIVE = 'active',
  CANCELLED = 'cancelled',
  DONE = 'done',
}

export interface CalendarData {
    id: string,                 // activation id
    status: FilterStatus,             // activation status
    title: string,              // "TLN - Hercules",
    borderColor: string,        // "transparent",
    start: string,              // "2022-09-27",
    end: string,                // "2022-09-29",
    backgroundColor: string,    // "#7551FF",
    className: string,          // "info"
}

export interface ShopSuggestion {
  id: string,
  name: string,
  shopping: string,
  street: string,
  bus: string,
  postalCode: string,
  city: string,
  number: number,
  latitude: number,
  longitude: number,
}

export interface ShopManagerSuggestion {
  id: string,
  name: string,
  phone: string,
  generalEmail: string,
  email: string,
}

export interface ShopQueryResult<TDATA> {
    page: number,
    perPage: number,
    total: number,
    totalPages: number,
    more: boolean,
    results: TDATA[]
}

export interface Iconfig {
  firstStatus: string;
  shopStatus: string;
  dateStatus: string;
  adminStatus: string;
  overviewStatus: string;
}
