import jwt_decode from 'jwt-decode';
import { selector } from 'recoil';
import { v1 } from 'uuid';
import {
  actionTypeSorting,
  actionTypeSortState,
  activationsDuplicatedAtom,
  addOnSorting,
  authAtom,
  brandAtom,
  sortState,
  forceUpdateActivationList,
  userSorting
} from './atoms';
import { groupBy } from './config';
import { IActionType, IBrand, IUser } from 'shared/types';
import { api } from 'utils/repository';
import { isEmpty } from 'lodash';
import { getCurrentRole } from '../shared/utils';

export const brandSelector = selector({
  key: `BrandSelector/${v1()}`,
  get: async ({ get }) => {
    const list = get(brandsListSelector);
    const currentBrandName = get(brandAtom);

    return (list || []).find((brand: IBrand) => brand.name === currentBrandName) || {}
  }
});

export const brandsListSelector = selector({
  key: `BrandsListSelector/${v1()}`,
  get: async () => {
    return await api.getBrandsList();
  }
});

export const userBrands = selector({
  key: `BrandsListSelector/${v1()}`,
  get: async ({ get }) => {
    const token = get(authAtom);
    const user: IUser = jwt_decode(token);

    return (user?.brands || []).map(item => item.brand) as IBrand[];
  }
});

export const currentRoleSelector = selector({
  key: `currentRoleSelector/${v1()}`,
  get: async ({ get }) => {
    const user = get(GetUsersInfo);
    const brandName = get(brandAtom);

    return getCurrentRole(user, brandName).role;
  }
})

export const RoleSelector = selector({
  key: `RoleSelector/${v1()}`,
  get: async () => {
    return await api.getRoleList();
  }
});

export const GetAddOnTypes = selector({
  key: `GetAddOnTypes/${v1()}`,
  get: async () => {
    return await api.getAddonTypes();
  }
});

export const GetAllUsers = selector({
  key: `GetAllUsers/${v1()}`,
  get: async ({ get }) => {
    const userList = await api.getListUsers();

    return userList
  }
});

export const GetUsersInfo = selector({
  key: `GetUsersInfo/${v1()}`,
  get: async ({ get }) => {
    const auth = get(authAtom);
    const user: IUser = auth ? jwt_decode(auth) : {} as IUser;
    const accountData = await api.getMyAccountData();

    return { ...user, ...accountData };
  }
});

export const SortUsers = selector({
  key: `SortUsers/${v1()}`,
  get: ({ get }) => {
    const users = get(GetAllUsers);
    const grouped = groupBy(users, (users) => users.brands[0]?.status || 'no access');

    return Array.from(grouped).flatMap((group) => [group]);
  }
});

export const FilterUsers = selector({
  key: `FilterUsers/${v1()}`,
  get: ({ get }) => {
    const filter = get(userSorting);
    const list = get(GetAllUsers);

    return list.filter((item: IUser) => item.brands[0]?.status === filter || (isEmpty(item.brands) && filter === 'no access'));
  }
});

export const GetAddOns = selector({
  key: `GetAddOns/${v1()}`,
  get: ({ get }) => {
    const currentBrand = get(brandSelector);

    return api.getAddons(currentBrand.id)
  }
});

export const SortAddOns = selector({
  key: `SortAddOns/${v1()}`,
  get: ({ get }) => {
    const addOns = get(GetAddOns);
    const grouped = groupBy(addOns, (addOns) => addOns.addonType.name);

    return Array.from(grouped).flatMap((group) => [group]);
  }
});

export const FilterAddOns = selector({
  key: `FilterAddOns/${v1()}`,
  get: ({ get }) => {
    const filter = get(addOnSorting);
    const list = get(GetAddOns);
    const name = get(sortState);

    switch (filter) {
      case name:
        return list.filter((item: any) => item.addonType.name === name);
      default:
        return list.filter((item: any) => item.addonType.name);
    }
  }
});

export const GetActivationType = selector({
  key: `ActivationType/${v1()}`,
  get: async ({ get }) => {
    const currentBrand = get(brandSelector);

    return await api.getActivationTypes(currentBrand.id);
  }
});

export const GetActivationsList = selector({
  key: `GetActivationsList/${v1()}`,
  get: async ({ get }) => {
    get(forceUpdateActivationList)
    const currentBrand = get(brandSelector);
    const user = get(GetUsersInfo);
    return api.listActivations(user.lang, currentBrand.id);
  }
});

export const GetActionType = selector({
  key: `ActionType/${v1()}`,
  get: async ({get}) => {
    const userInfo = get(GetUsersInfo);
    const list = await api.getActionTypeList(userInfo?.lang || 'nl');
    const currentBrand = get(brandAtom);

    return list.filter((actionType: IActionType) => actionType.brand.name === currentBrand);
  }
});

export const GetActiveActionType = selector({
  key: `ActiveActionType/${v1()}`,
  get: ({ get }) => {
    const AllActionTypes = get(GetActionType);
    const allCategories = get(GetActivationType)

    return AllActionTypes.filter((item: IActionType) => item.active);
  }
});

export const GetCounters = selector({
  key: `ActionType/${v1()}`,
  get: async ({ get }) => {
    const list = get(GetActivationsList)
    const all = list.length
    return {
      all,
      pending: list.filter(e => e.status === 'pending').length,
      active: list.filter(e => e.status === 'active').length,
      cancelled: list.filter(e => e.status === 'cancelled').length,
      done: list.filter(e => e.status === 'done').length,
    }
  }
});

// TODO удалить
export const SortActionType = selector({
  key: `SortActionType/${v1()}`,
  get: async ({ get }) => {
    const actionType = get(GetActionType);
    const grouped = groupBy(
      actionType,
      (actionType) => actionType.category.name
    );

    return Array.from(grouped).flatMap((group) => [group]);
  }
});

export const FilterActionType = selector({
  key: `FilterActionType/${v1()}`,
  get: async ({ get }) => {
    const filter = get(actionTypeSorting);
    const list = get(GetActionType);
    const name = get(actionTypeSortState);
    switch (filter) {
      case name:
        return list.filter((item: any) => item.category.name === name);
      default:
        return list.filter((item: any) => item.category.name);
    }
  }
});


export const GetActivationsDuplicatedList = selector({
  key: `GetActivationsList/${v1()}`,
  get: async ({ get }) => {
    const allActivations = get(GetActivationsList);
    const duplicates = get(activationsDuplicatedAtom);

    return allActivations.filter(item => duplicates.includes(item.id));
  }
});
