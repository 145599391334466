import {
  Box,
  Flex,
  Text,
  useColorModeValue,
  Badge, Button, Icon, SimpleGrid,
  Image, FormControl, FormLabel, Select, Tooltip, Switch
} from '@chakra-ui/react';
import MockImage from 'assets/img/backgrounds/image.png';
import { BrandedButton } from 'components/buttons/BrandedButton';
import {
  brandSelector,
  currentRoleSelector,
  GetActivationType, GetUsersInfo
} from 'data/selectors';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { responseActivation, currentActivationCategory } from 'data/atoms';
import { getApproveColor, getDomainForFile } from 'shared/utils';
import React, { useEffect, useState } from 'react';
import { api } from 'utils/repository';
import { ApproveStatus, FilterStatus, IBrand, ICategory, IUser } from 'shared/types';
import { useNotification } from 'shared/hooks';
import Card from 'components/card/Card';
import Details from 'assets/img/backgrounds/header_background.png';
import { HSeparator } from 'components/separator/Separator';
import { MdEdit } from 'react-icons/md';
import moment from 'moment';
import { get, isEmpty, isNil } from 'lodash';
import { ModalPopup } from 'components/modal/modal';
import InputField from 'components/fields/InputField';
import TextField from '../../../../components/fields/TextField';


const OverviewActivationDetails = () => {
  const role = useRecoilValue(currentRoleSelector);
  const brand = useRecoilValue<IBrand>(brandSelector);
  const user = useRecoilValue<IUser>(GetUsersInfo);
  const { errorNotification, successNotification } = useNotification();
  const [activation, setActivation] = useRecoilState(responseActivation);
  const [openModal, setOpenModal] = useState(false);
  const [approvals, setApprovals] = useState({
    approvedByButik: activation?.approvedByButik,
    approvedByManager: activation?.approvedByManager
  });

  const [openModalSetup, setOpenModalSetup] = useState(false);
  const [setupState, setSetupState] = useState({
    setupHostesses: activation?.setupHostesses,
    setupExtra1: activation?.setupExtra1,
    setupExtra2: activation?.setupExtra2,
    setupAccessibility: activation?.setupAccessibility,
    setupOtherRemarks: activation?.setupOtherRemarks
  });

  useEffect(() => setApprovals({
    approvedByButik: activation?.approvedByButik,
    approvedByManager: activation?.approvedByManager
  }), [activation?.approvedByButik, activation?.approvedByManager]);


  // const activation = useRecoilValue(GetActivationById('53b6ea34-38bd-4cb3-9581-1a57c852c4dd'))?.activation;
  const [currentActivation, setCurrentActivation] = useRecoilState(currentActivationCategory);
  const allActivations = useRecoilValue(GetActivationType);
  const isLate = new Date().getTime()
    + 72 * 60 * 60 * 1000 > new Date(activation?.actionDate || null).getTime();
  const isRequester = role === 'requester';
  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const paleGray = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');

  const { activationId } = useParams<{ activationId: string }>();

  useEffect(() => {
    if (activationId) {
      const newActivationType = allActivations.find(act => act.id);
      if (newActivationType) {
        setCurrentActivation(newActivationType);
      }
      api.getActivation(user.lang, brand?.id, activationId)
        .then(resp => setActivation(resp));
    }
  }, [activationId]);

  const updateApproval = (changedApprovals: object) => {
    const saveApprovals = { ...approvals, ...changedApprovals };
    setActivation(state => ({ ...state, ...saveApprovals }));
    api.updateActivationApprovals(user.lang, brand.id, saveApprovals, activation.id)
      .then(resp => {
        setActivation(resp);
        setOpenModal(false);
        successNotification('Approvals updated');
      })
      .catch(() => {
        errorNotification('Error while updating approvals');
      });
  }

  const changeApprovalByButik = (e: any) => {
    console.log(e.target.checked);
    setApprovals(state => ({ ...state, approvedByButik: e.target.checked }));
    updateApproval({ approvedByButik: e.target.checked });

  }

  const changeApprovalByManager = (e: any) => {
    console.log(e.target.checked);
    setApprovals(state => ({ ...state, approvedByManager: e.target.checked }));
    updateApproval({ approvedByManager: e.target.checked });
  }


  const renderHeader = () => {
    return (
      <>
        <Card
          backgroundImage={Details}
          maxH={'298px'}
          backgroundRepeat='no-repeat'
          bgSize='cover'
          bgPosition='10%'
          p={{ base: '20px', md: '60px' }}
          pt={{ base: '40px', md: '75px' }}
          pb='140px'>
          <Flex justifyContent={'space-between'}>
            <Flex>
              <Badge
                w='max-content'
                textTransform={'initial'}
                mb='10px'
                fontSize='sm'
                fontWeight={'400'}
                bg='#FFFFFF'
                color='secondaryGray.900'
                border=' 1px solid transparent'
              >
                Requested
                by {activation?.requestedBy?.firstName || 'Empty name'} at {moment(activation?.requestedAt).format('DD.MM.YYYY')}
              </Badge>
              {activation?.lastMinuteAction && (
                <Badge
                  w='max-content'
                  textTransform={'initial'}
                  mb='10px'
                  ml={'5px'}
                  fontSize='sm'
                  fontWeight={'400'}
                  bg={'transparent'}
                  border=' 1px solid red'
                  color='red'
                >Last minute</Badge>
              )}
            </Flex>
            <Flex>
              {isLate && isRequester ? (
                <Tooltip shouldWrapChildren
                         label={'No cancel and edit allowed 72 hour before activation. In emergency cases contact us.'}
                         aria-label='A tooltip'>
                  <Button
                    ms='auto'
                    me='10px'
                    alignItems='center'
                    justifyContent='center'
                    bg={'secondaryGray.900'}
                    p='4px 15px'
                    minW='unset'
                    h='32px'
                    fontWeight={'400'}
                    fontSize={'14px'}
                    lineHeight='100%'
                    borderRadius='10px'
                    color={'white'}
                    disabled={true}
                  >
                    Cancel
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  ms='auto'
                  me='10px'
                  alignItems='center'
                  justifyContent='center'
                  bg={'secondaryGray.900'}
                  p='4px 15px'
                  minW='unset'
                  h='32px'
                  fontWeight={'400'}
                  fontSize={'14px'}
                  lineHeight='100%'
                  borderRadius='10px'
                  color={'white'}
                  onClick={() => {
                    api.cancelActivation(user.lang, brand?.id, activationId)
                      .then(() => {
                        successNotification('Activation canceled');
                      })
                      .catch(() => {
                        errorNotification('Error while activation canceling');
                      });
                  }}
                >
                  Cancel
                </Button>
              )}
              <Button as={'a'}
                      alignItems='center'
                      me='10px'
                      justifyContent='center'
                      bg='brandColor.400'
                      color={'white'}
                      _hover={{ bg: 'brandColor.100' }}
                      p='4px 15px'
                      minW='unset'
                      fontWeight={'400'}
                      fontSize={'14px'}
                      h='32px'
                      lineHeight='100%'
                      borderRadius='10px'
                      rel='noreferrer'
                      target='_blank'
                      href={activation?.actionType?.infoDoc?.path
                        ? `${getDomainForFile()}${activation?.actionType?.infoDoc?.path}`
                        : '/'
                      }
              >
                View PDF
              </Button>
              {isLate && isRequester ? (
                <Tooltip shouldWrapChildren
                         label={'No cancel and edit allowed 72 hour before activation. In emergency cases contact us.'}
                         aria-label='A tooltip'>
                  <Button
                    alignItems='center'
                    justifyContent='center'
                    bg='brandColor.400'
                    _hover={{ bg: 'brandColor.100' }}
                    p='7px'
                    minW='unset'
                    h='32px'
                    lineHeight='100%'
                    borderRadius='10px'
                    disabled={true}
                  >
                    <Icon as={MdEdit} color='white' w='18px' h='18px' />
                  </Button>
                </Tooltip>
              ) : (
                <NavLink to={`/${role}/overview/edit_activation/${activationId}`}>
                  <Button
                    alignItems='center'
                    justifyContent='center'
                    bg='brandColor.400'
                    _hover={{ bg: 'brandColor.100' }}
                    p='7px'
                    minW='unset'
                    h='32px'
                    lineHeight='100%'
                    borderRadius='10px'
                  >
                    <Icon as={MdEdit} color='white' w='18px' h='18px' />
                  </Button>
                </NavLink>
              )}
            </Flex>
          </Flex>
          <Flex mb={{ base: '0px', md: '50px' }} direction={{ base: 'column', md: 'row' }}>
            <Flex direction='column' color='secondaryGray.900' h='100%' w='100%'
                  mb={{ base: '20px', md: '0px' }}>
              <Text
                mt={{ base: '10px', md: '0px' }}
                fontSize={{ base: '2xl', md: '32px', lg: '44px', xl: '44px' }}
                fontWeight='bold'>
                {activation?.actionType?.name}
              </Text>
            </Flex>
          </Flex>
        </Card>
        <Flex direction='column' p={{ base: '10px', md: '60px 60px 0' }}>
          <Card p='30px' mt='-100px'>
            <Flex direction={{ base: 'column', md: 'row' }}>
              <Flex direction='column' me='auto' mb={{ base: '30px', md: '0px' }}>
                <Text w='max-content' mb='14px' fontSize='md' color='secondaryGray.600'
                      fontWeight='400'>
                  Activation date
                </Text>
                <Text color={textColor} fontSize='xl' fontWeight='700'>
                  {moment(activation?.actionDate).format('DD.MM.YYYY')}
                </Text>
              </Flex>
              {activation?.addonDeliveryDate && (
                <Flex direction='column' me='auto' mb={{ base: '30px', md: '0px' }} ml={'28px'}>
                  <Text w='max-content' mb='14px' fontSize='md' color='secondaryGray.600'
                        fontWeight='400'>
                    Add-ons delivery date
                  </Text>
                  <Text color={textColor} fontSize='xl' fontWeight='700'>
                    {moment(activation?.addonDeliveryDate).format('DD.MM.YYYY')}
                  </Text>
                </Flex>
              )}
              <Flex direction='column' flexGrow={2} ml={'28px'}>
                <Text w='max-content' mb='14px' fontSize='md' color='secondaryGray.600'
                      fontWeight='400'>
                  Shop info
                </Text>
                <Text color={textColor} fontSize='xl' fontWeight='700'>
                  {(activation?.shopName)
                    ? (
                      <a
                        href={`https://maps.google.com/?q=${activation?.shopStreet} ${activation?.shopNumber}, ${activation?.shopPostalCode} ${activation?.shopCity}`}
                        target={'_blank'}
                        rel='noreferrer'
                      >
                        <Text>
                          {activation.shopStreet} {activation.shopNumber}{activation.shopBus ? `/${activation.shopBus}`:''}, {activation.shopPostalCode} {activation.shopCity}
                        </Text>
                        <Text>
                          {activation.shopName}{activation.shopping ? `, ${activation.shopping}` : ''}
                        </Text>

                      </a>
                    )
                    : <>No info</>
                  }
                </Text>
              </Flex>
            </Flex>
          </Card>
        </Flex>
      </>
    );
  };

  const renderContacts = () => {
    return (
      <>
        <Text w='max-content' mb='14px' fontSize='md' px='30px' color='secondaryGray.600'
              fontWeight='400'>
          Contact details
        </Text>
        <Flex direction={{ base: 'column', md: 'row' }} px='30px' mb={'28px'}>
          <Flex direction='column' me='auto' mb={{ base: '15px', md: '0px' }}>
            <Text color={textColor} fontSize='md'>
              Email: {activation.shopManagerEmail}
            </Text>
            <Text color={textColor} fontSize='md'>
              Phone: {activation.shopManagerPhone}
            </Text>
          </Flex>
          <Flex direction='column' me='auto' mb={{ base: '15px', md: '0px' }}>
            <Text color={textColor} fontSize='md'>
              Shop Manager: {activation.shopManagerName}
            </Text>
            <Text color={textColor} fontSize='md'>
              Shop email: {activation.shopGeneralEmail}
            </Text>
          </Flex>
        </Flex>
      </>
    );
  };

  const renderExtraRemarks = () => {
    if (isEmpty(activation.extraRemarks)) {
      return null;
    }

    return (
      <>
        <Text w='max-content' mb='14px' fontSize='md' px='30px' color='secondaryGray.600'
              fontWeight='400'>
          Extra remarks
        </Text>
        <Text
          w='max-content'
          mb='14px'
          fontSize='md'
          px='30px'
          color='secondaryGray.900'
          fontWeight='400'
          whiteSpace={'pre-wrap'}
          maxWidth={'100%'}
        >
          {activation.extraRemarks}
        </Text>
      </>
    );
  };

  const renderAddons = () => {
    if (isNil(activation.addons) || isEmpty(activation.addons)) {
      return null;
    }

    return (
      <Flex direction={'column'} px='30px'>
        <Text fontSize='2xl' lineHeight='50px' zIndex='1' fontWeight='700'>
          Add-ons
        </Text>
        <SimpleGrid columns={{ base: 1, md: 2, '2xl': 2 }} gap='20px' mb='20px'>
          {activation.addons.map(a => {
            const name = get(a, 'actionTypeAddon.addon.translations[0].name', '');
            const image = get(a, 'actionTypeAddon.addon.image.path', '');
            return (
              <Card key={a.actionTypeAddon.addon.id} p={'22px'}
                    boxShadow={'0px 18px 40px rgba(112, 144, 176, 0.12)'}>
                <Flex my='auto' h='100%' align={{ base: 'center', xl: 'center' }}
                      justify={{ base: 'center', xl: 'start' }}>
                  <Image
                    boxSize='66px'
                    borderRadius={'12px'}
                    objectFit='cover'
                    src={image ? `${getDomainForFile()}${image}` : MockImage}
                  />
                  <Flex direction={'column'} flexGrow={2} ml={'18px'} mb={'2px'}>
                    <Text fontSize='md' zIndex='1' fontWeight={'600'}>
                      {name}
                    </Text>
                    <Text fontSize='md' color={'green.400'}>
                      Visibility
                    </Text>
                  </Flex>

                </Flex>

              </Card>
            );
          })}
        </SimpleGrid>
      </Flex>
    );
  };

  const renderModalApproval = () => {
    return (
      <ModalPopup
        onClose={() => setOpenModal(false)}
        isOpen={openModal}
        title={'Edit approvals'}
        footer={<BrandedButton onClick={() => updateApproval({})} >OK</BrandedButton>}
      >
        <>
          <FormControl>
            <FormLabel>Approval by Butik</FormLabel>
            <Select
              value={approvals.approvedByButik ? 'Yes' : 'No'}
              onChange={(e) => {
                setApprovals(state => ({ ...state, approvedByButik: e.target.value === 'Yes' }));
              }}
            >
              <option>No</option>
              <option>Yes</option>
            </Select>
            <FormLabel>Approval by Manager </FormLabel>
            <Select
              value={approvals.approvedByManager ? 'Yes' : 'No'}
              onChange={(e) => {
                setApprovals(state => ({ ...state, approvedByManager: e.target.value === 'Yes' }));
              }}
            >
              <option>No</option>
              <option>Yes</option>
            </Select>
          </FormControl>
        </>
      </ModalPopup>
    );
  };

  const renderModalSetup = () => {
    return (
      <ModalPopup
        onClose={() => setOpenModalSetup(false)}
        isOpen={openModalSetup}
        title={'Edit schedule'}
        footer={<>
          <BrandedButton
            onClick={() => {
              setActivation(state => ({ ...state, ...setupState }));
              api.updateActivationSetup(user.lang, brand.id, setupState, activation.id)
                .then(resp => {
                  setActivation(resp);
                  setOpenModalSetup(false);
                  successNotification('Setup updated');
                })
                .catch(() => {
                  errorNotification('Error while updating schedule');
                });
            }}
          >
            OK
          </BrandedButton>
        </>}
      >
        <>
          <InputField
            mb='15px'
            type={'number'}
            placeholder='Type number hostesses'
            label='Setup hostesses'
            value={setupState?.setupHostesses || ''}
            onChange={(e: any) =>
              setSetupState(state => ({ ...state, setupHostesses: parseInt(e.target.value) }))
            }
          />
          <TextField
            onChange={(e: any) =>
              setSetupState(state => ({ ...state, setupOtherRemarks: e.target.value }))
            }
            minH="150px"
            id="bio"
            placeholder="Type other remarks"
            label="Setup other remarks"
            value={setupState?.setupOtherRemarks || ''}
          />
        </>
      </ModalPopup>
    );
  };

  const renderSetup = () => {
    return (
      <Card p={'0'} boxShadow={'0px 18px 40px rgba(112, 144, 176, 0.12)'} mb={'54px'}>
        <Flex direction='row' p={'22px'} justifyContent={'space-between'}>
          <Text fontSize='xl' lineHeight='50px' zIndex='1' fontWeight='700'>
            Setup
          </Text>
          {isLate && isRequester ? (
            <Tooltip shouldWrapChildren
                     label={'No cancel and edit allowed 72 hour before activation. In emergency cases contact us.'}
                     aria-label='A tooltip'>
              <Button
                alignItems='center'
                justifyContent='center'
                bg='secondaryGray.100'
                _hover={{ bg: 'secondaryGray.400' }}
                p='7px'
                w='32px'
                h='32px'
                minW='unset'
                lineHeight='100%'
                borderRadius='10px'
                disabled={true}
              >
                <Icon as={MdEdit} color='secondaryGray.600' w='18px' h='18px' />
              </Button>
            </Tooltip>
          ) : (
            <Button
              alignItems='center'
              justifyContent='center'
              bg='secondaryGray.100'
              _hover={{ bg: 'secondaryGray.400' }}
              p='7px'
              w='32px'
              h='32px'
              minW='unset'
              lineHeight='100%'
              borderRadius='10px'
              onClick={() => {
                setOpenModalSetup(true);
              }}
            >
              <Icon as={MdEdit} color='secondaryGray.600' w='18px' h='18px' />
            </Button>
          )}

        </Flex>

        <Text w='max-content' mb='4px' fontSize='md' px='22px' color='secondaryGray.600'
              fontWeight='400'>
          Hostesses
        </Text>
        <Text w='max-content' mb='16px' fontSize='md' px='22px' color='secondaryGray.900'
              fontWeight='400'>
          {activation?.setupHostesses}
        </Text>
        <HSeparator mb='16px' bg={paleGray} mt='0px' />

        <Text w='max-content' mb='4px' fontSize='md' px='22px' color='secondaryGray.600'
              fontWeight='400'>
          Other remarks
        </Text>
        <Text
          w='max-content'
          mb='16px'
          fontSize='md'
          px='22px'
          color='secondaryGray.900'
          fontWeight='400'
          whiteSpace={'pre-wrap'}
          maxWidth={'100%'}
        >
          {activation?.setupOtherRemarks || 'none'}
        </Text>
      </Card>
    );
  };


  return (
    <>
      <Flex direction='column' zIndex='201' pos='fixed' ml={4}>
        <Text fontSize='33px' lineHeight='50px' zIndex='1' fontWeight='700'>
          Activation Details
        </Text>
      </Flex>
      {renderModalApproval()}
      {renderModalSetup()}
      <Card
        width={'100%'}
        minH={'80vh'}
        maxW={'920px'}
        m={'80px auto'}

      >
        <Flex direction='column' width='stretch'>
          {renderHeader()}
          <Flex direction='column' p={{ base: '10px', md: '30px' }}>
            {renderContacts()}
            {renderAddons()}
            {renderExtraRemarks()}
          </Flex>
          <Flex mb={'15px'} justifyContent={'end'} px={'60px'}>
            {isLate && isRequester ? (
              <Tooltip shouldWrapChildren
                       label={'No cancel and edit allowed 72 hour before activation. In emergency cases contact us.'}
                       aria-label='A tooltip'>
                <Button
                  alignItems='center'
                  justifyContent='center'
                  bg='secondaryGray.100'
                  _hover={{ bg: 'secondaryGray.400' }}
                  p='7px'
                  minW='unset'
                  h='32px'
                  lineHeight='100%'
                  borderRadius='10px'
                  disabled={true}
                >
                  <Icon as={MdEdit} color='secondaryGray.600' w='18px' h='18px' />
                </Button>
              </Tooltip>
            ) : (
              <Button
                alignItems='center'
                justifyContent='center'
                bg='secondaryGray.100'
                _hover={{ bg: 'secondaryGray.400' }}
                p='7px'
                minW='unset'
                h='32px'
                lineHeight='100%'
                borderRadius='10px'
                onClick={() => {
                  setOpenModal(true);
                }}
              >
                <Icon as={MdEdit} color='secondaryGray.600' w='18px' h='18px' />
              </Button>
            )}
          </Flex>
          <SimpleGrid columns={{ base: 2, md: 3, '2xl': 3 }} gap='20px' mb='20px'
                      px={{ base: '15px', md: '60px' }}>
            <Card borderRadius={'16px'} p={'22px'}
                  boxShadow={'0px 18px 40px rgba(112, 144, 176, 0.12)'}>
              <Text fontSize='md' color={'secondaryGray.600'}>
                Main status
              </Text>
              <Text fontSize='md' zIndex='1' fontWeight={'600'} mt={'5px'}
                    color={getApproveColor(activation?.approved, activation.status)}>
                {activation?.status === FilterStatus.CANCELLED
                  ? 'Cancelled'
                  : activation?.approved
                    ? ApproveStatus.APPROVED
                    : ApproveStatus.PENDING}
              </Text>
            </Card>
            <Card borderRadius={'16px'} p={'22px'}
                  boxShadow={'0px 18px 40px rgba(112, 144, 176, 0.12)'}>
              <Text fontSize='md' color={'secondaryGray.600'}>
                Approval by Butik
              </Text>
              <Flex justifyContent={'space-between'} alignItems={'center'} mt={'5px'}>
                <Text fontSize='md' zIndex='1' fontWeight={'600'}
                      color={getApproveColor(activation?.approvedByButik)}>
                  {activation?.approvedByButik ? ApproveStatus.APPROVED : ApproveStatus.PENDING}
                </Text>
                <Switch
                  isChecked={activation?.approvedByButik}
                  id={'approvedByButik'}
                  variant='main'
                  colorScheme="green"
                  color="green.500"
                  size='md'
                  onChange={changeApprovalByButik}
                />
              </Flex>
            </Card>
            <Card borderRadius={'16px'} p={'22px'}
                  boxShadow={'0px 18px 40px rgba(112, 144, 176, 0.12)'}>
              <Text fontSize='md' color={'secondaryGray.600'}>
                Approval by manager
              </Text>
              <Flex justifyContent={'space-between'} alignItems={'center'} mt={'5px'}>
                <Text fontSize='md' zIndex='1' fontWeight={'600'}
                      color={getApproveColor(activation?.approvedByManager)}>
                  {activation?.approvedByManager ? ApproveStatus.APPROVED : ApproveStatus.PENDING}
                </Text>
                <Switch
                  isChecked={activation?.approvedByManager}
                  id={'approvedByManager'}
                  variant='main'
                  colorScheme="green"
                  color="green.500"
                  size='md'
                  onChange={changeApprovalByManager}
                />
              </Flex>
            </Card>
          </SimpleGrid>
          <Box px={'60px'}>
            {renderSetup()}
          </Box>

        </Flex>

      </Card>
    </>
  );
};

export default OverviewActivationDetails;
