import { ComponentType, FC, useEffect, useState } from 'react';
import { MdOutlineCloudUpload } from 'react-icons/md';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from 'recoil';

import { BrandedButton } from 'components/buttons/BrandedButton';
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import {
  ActionTypeType,
  IActionTypeDTOForSave,
  IActionTypeState,
  IAddon,
  IBrand,
  ICategory, IImage,
  ILang,
  Lang,
  LangNames
} from 'shared/types';
import {
  brandsListSelector,
  currentRoleSelector,
  FilterActionType,
  GetActivationType,
  GetAddOns
} from 'data/selectors';
import { getActionTypeDataForSave, getDefaultActionTypeState, getQueryParams } from 'shared/utils';
import { brandAtom, languageAtom } from 'data/atoms';
import { AddOns, Dropzone } from './components';
import TextField from 'components/fields/TextField';
import { useNotification, useConfirm } from 'shared/hooks';
import { api } from 'utils/repository';
import { isEmpty, isNil, omit } from 'lodash';

import { ERROR_MESSAGE } from 'shared/constants';
import { Preloader } from '../../../../components/preloader/preloader';

const _CreateActionType: FC<RouteComponentProps<{ id?: string }>> = (props) => {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const activationCategories: ICategory[] = useRecoilValue(GetActivationType);
  const languages: ILang[] = useRecoilValue(languageAtom);
  const allBrands: IBrand[] = useRecoilValue(brandsListSelector);
  const currentBrandName = useRecoilValue(brandAtom);
  const defaultBrand = allBrands.find(b => b.name === currentBrandName);
  const defaultCategory = activationCategories[0];
  const [state, setState] = useState<IActionTypeState>(getDefaultActionTypeState(languages, defaultBrand));
  const role = useRecoilValue(currentRoleSelector);
  const allAddons: IAddon[] = useRecoilValue(GetAddOns);
  const refreshList = useRecoilRefresher_UNSTABLE(FilterActionType);
  const { successNotification, errorNotification } = useNotification();
  const history = useHistory();
  const { onConfirm } = useConfirm();
  const [loading, setLoading] = useState(false);

  const othersAddons = allAddons.filter(addon => addon.addonType.name === ActionTypeType.Required)
  const visibilityAddons = allAddons.filter(addon => addon.addonType.name === ActionTypeType.Visibility)
  const id = props.match.params?.id;
  const { search } = props.location;
  const { duplicate } = getQueryParams(search);

  useEffect(() => {
    if (id) {
      // здесь мы должны подтянуть данные
      Promise.all([
        api.getActionType(Lang.nl, id),
        api.getActionType(Lang.fr, id)
      ]).then(([nlActionType, frActionType]) => {
        setState(getDefaultActionTypeState(languages, defaultBrand, nlActionType, frActionType));
      })
    }
  }, [id]);

  const handleSave = async () => {
    let dataNl: IActionTypeDTOForSave = getActionTypeDataForSave(state, Lang.nl);
    let dataFr: IActionTypeDTOForSave = getActionTypeDataForSave(state, Lang.fr, true);

    if (
      isEmpty(dataNl.categoryId) ||
      isEmpty(dataNl.brandId) ||
      (isEmpty(dataNl.name) && isEmpty(dataFr.name)) ||
      isEmpty(dataNl.infoDocId) ||
      isEmpty(dataFr.infoDocId)
    ) {
      return errorNotification('You must fill required fields')
    }

    if(isEmpty(dataNl.name)) {
      return onConfirm(
        'Warning',
        `You only filled in the ${LangNames[Lang.fr]} name. Would you like us to duplicate name to ${LangNames[Lang.nl]}`
      ).then(() => {
        onSave({ ...dataNl, name: dataFr.name }, dataFr);
      });
    }

    if(isEmpty(dataFr.name)) {
      return onConfirm(
        'Warning',
        `You only filled in the ${LangNames[Lang.nl]} name. Would you like us to duplicate name to ${LangNames[Lang.fr]}`
      ).then(() => {
        onSave(dataNl, { ...dataFr, name: dataNl.name });
      });
    }

    onSave(dataNl, dataFr);
  }

    const onSave = async (
      dataNl: IActionTypeDTOForSave,
      dataFr: IActionTypeDTOForSave,
    ) => {
      if (isNil(id)) {
        // create
        try {
          const actionTypeNl = await api.createActionType(Lang.nl, omit(dataNl, 'infoDocPath'))
          await api.updateActionType(Lang.fr, actionTypeNl.id, omit(dataFr, 'infoDocPath'));
          successNotification('Action type is created');
          refreshList();
          history.push(`/${role}/settings/view_action_types_list`);
        } catch (err) {
          console.log(err);
          errorNotification(ERROR_MESSAGE);
        }
      }
      else if (duplicate) {
        // duplicate
        try {
          let actionTypeNl;
          if (state.transition[Lang.nl].infoDoc.fileUpdated) {
            actionTypeNl = await api.createActionType(Lang.nl, omit(dataNl, 'infoDocPath'));
          } else {
            const infoDocNl: IImage = await api.fileDuplicate({ path: dataNl.infoDocPath });
            actionTypeNl = await api.createActionType(Lang.nl, { ...omit(dataNl, ['infoDocId', 'infoDocPath']), infoDocId: infoDocNl.id });
          }
          if (state.transition[Lang.fr].infoDoc.fileUpdated) {
            await api.updateActionType(Lang.fr, actionTypeNl.id, omit(dataFr, 'infoDocPath'));
          } else {
            const infoDocFr: IImage = await api.fileDuplicate({ path: dataFr.infoDocPath });
            await api.updateActionType(Lang.fr, actionTypeNl.id, { ...omit(dataFr, ['infoDocId', 'infoDocPath']), infoDocId: infoDocFr.id });
          }
          successNotification('Action type is duplicated');
          refreshList();
          history.push(`/${role}/settings/view_action_types_list`);
        } catch (err) {
          console.log(err);
          errorNotification(ERROR_MESSAGE);
        }
      }
      else {
        // update
        try {
          await api.updateActionType(Lang.nl, id, state.transition[Lang.nl].infoDoc.fileUpdated
            ? omit(dataNl, 'infoDocPath')
            : omit(dataNl, ['infoDocPath','infoDocId']))
          await api.updateActionType(Lang.fr, id, state.transition[Lang.fr].infoDoc.fileUpdated
            ? omit(dataFr, 'infoDocPath')
            : omit(dataFr, ['infoDocPath','infoDocId']));
          successNotification('Action type is created');
          refreshList();
          history.push(`/${role}/settings/view_action_types_list`);
        } catch (err) {
          console.log(err);
          errorNotification(ERROR_MESSAGE);
        }
      }
    }

  const renderGeneralInfo = (lang: Lang) => {
    return (
      <FormControl>
        <Card mb="20px">
          <Flex direction="column" mb="30px" ms="10px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              General info
            </Text>
          </Flex>
          <InputField
            mb="25px"
            id="name"
            label="Name"
            placeholder="Name"
            value={state.transition[lang].name}
            onChange={(e: any) =>
              setState( {
                ...state,
                transition: {
                  ...state.transition,
                  [lang]: {
                    ...state.transition[lang],
                    name: e.target.value,
                  }
                }

              })
            }
          />
          <Flex direction="column">
            <FormLabel
              ms="10px"
              htmlFor="currency"
              fontSize="sm"
              color={textColor}
              fontWeight="bold"
              _hover={{ cursor: 'pointer' }}
            >
              Brand
            </FormLabel>
            <Select
              borderRadius={16}
              id="currency"
              value={state.brand.id}
              onChange={(e) => {
                setState({
                  ...state,
                  brand: allBrands.find(b => b.id === e.target.value),
                })
              }}
            >
              {allBrands.map(brand => (<option key={brand.id} value={brand.id}>{brand.name}</option>))}
            </Select>
          </Flex>
          <Flex direction="column">
            <FormLabel
              ms="10px"
              htmlFor="currency"
              fontSize="sm"
              mt="20px"
              color={textColor}
              fontWeight="bold"
              _hover={{ cursor: 'pointer' }}
            >
              Category
            </FormLabel>
            <Select
              borderRadius={16}
              id="currency"
              me="20px"
              value={state.category.id}
              placeholder={'please select category...'}
              onChange={(e) => {
                const category = activationCategories.find(i => i.id === e.target.value);
                setState({
                  ...state,
                  category,
                })
              }}
            >
              {activationCategories.map(cat => (<option key={cat.id} value={cat.id}>{cat.name}</option>))}
            </Select>
          </Flex>
        </Card>
      </FormControl>
    );
  }

  const renderPanel = (lang: ILang) => {
    return (
      <TabPanel>
        <SimpleGrid
          mb="20px"
          columns={{ sm: 1, md: 1, lg: 2 }}
          spacing={{ base: '20px', xl: '20px' }}
        >
          {/* Column Left */}
          <Flex direction="column">
            {renderGeneralInfo(lang.name)}
            <Box gridArea="1 / 1 / 2 / 2">
              <SimpleGrid
                columns={{ base: 1, md: 2, '2xl': 2 }}
                mb="5px"
                gap="20px"
              >
                <AddOns
                  label="Add-ons (Visibility)"
                  lang={lang.name}
                  currentAddonsList={visibilityAddons}
                  addedAddonsList={state.addons.visibility}
                  onChange={(newList: IAddon[]) => setState({
                    ...state,
                    addons: {
                      visibility: newList,
                      others: state.addons.others,
                    },
                  })}
                />
                <AddOns
                  lang={lang.name}
                  label="Options (Required)"
                  currentAddonsList={othersAddons}
                  addedAddonsList={state.addons.others}
                  onChange={(newList: IAddon[]) => setState({
                    ...state,
                    addons: {
                      visibility: state.addons.visibility,
                      others: newList,
                    },
                  })}
                />
              </SimpleGrid>
            </Box>
          </Flex>
          {/* Column Right */}
          <Flex direction="column">
            <Card p="30px" pb="75px">
              <Text color={textColor} fontSize="xl" fontWeight="700" mb="30px">
                Info doc
              </Text>
              <Dropzone
                content={
                  <Box maxW="100%">
                    <Icon
                      as={MdOutlineCloudUpload}
                      w="80px"
                      h="80px"
                      color="brandColor.400"
                    />
                    <Text
                      mb="12px"
                      fontSize="lg"
                      w="100%"
                      maxW="100%"
                      fontWeight="700"
                      color="brandColor.500"
                      whiteSpace="pre-wrap"
                    >
                      Drag & Drop your file here, or{' '}
                      <Text
                        as="span"
                        fontSize="lg"
                        fontWeight="700"
                        color="brandColor.400"
                      >
                        click to browse
                      </Text>
                    </Text>
                    <Text
                      fontSize="sm"
                      fontWeight="500"
                      color="secondaryGray.500"
                      whiteSpace="pre-wrap"
                    >
                      PDF files are allowed
                    </Text>
                  </Box>
                }
                imagePath={state.transition[lang.name]?.infoDoc?.path}
                onLoading={setLoading}
                onChange={(image) => {
                  setState( {
                    ...state,
                    transition: {
                      ...state.transition,
                      [lang.name]: {
                        ...state.transition[lang.name],
                        infoDoc: {
                          ...image,
                          fileUpdated: true,
                        },
                      }
                    }

                  })
                }}
              />
            </Card>
            <Card mt="5" p="30px" pb="55px">
              <Text color={textColor} fontSize="xl" fontWeight="700" mb="30px">
                Extra info
              </Text>
              <TextField
                id="description"
                mb="0px"
                h="140px"
                placeholder="Some extra info / description…"
                value={state.transition[lang.name].extraInfo}
                onChange={(e: any) =>
                  setState( {
                    ...state,
                    transition: {
                      ...state.transition,
                      [lang.name]: {
                        ...state.transition[lang.name],
                        extraInfo: e.target.value,
                      }
                    }

                  })
                }
              />
            </Card>
          </Flex>
        </SimpleGrid>
      </TabPanel>
    );
  }

  return (
    <Box pt={{ base: '180px', md: '130px', xl: '130px' }}>
      <Preloader show={loading} />
      <Flex align="center" w="100%" justifyContent="flex-end">
        <Text mr="5" color={textColor} fontSize="lg" fontWeight="500" me="12px">
          Status
        </Text>

        <Switch
          mr="5"
          colorScheme="orange"
          isChecked={state.active}
          onChange={e => {
            setState({
              ...state,
              active: e.target.checked,
            });
          }}
        />
        <BrandedButton onClick={handleSave}>
          <Text color="brandColor.300" as="span">
            SAVE CHANGES
          </Text>
        </BrandedButton>
      </Flex>
      <Tabs>
        <TabList>
          {languages.map(lang => (<Tab name={lang.name} key={lang.langId}>{LangNames[lang.name]}</Tab>))}
        </TabList>

        <TabPanels>
          {languages.map(renderPanel)}
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export const CreateActionType = withRouter(_CreateActionType) as ComponentType<{}>;
