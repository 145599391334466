import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Box, Button, Checkbox, Flex, FormControl, FormLabel,
  Input, InputGroup, InputRightElement, Text, Heading, Icon,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { isEmpty } from 'lodash';

import DefaultAuth from 'layouts/auth/variants/Default';
import illustration from 'assets/img/auth/auth_bg.jpg';
import { authAtom, brandAtom } from 'data/atoms';
import { api } from 'utils/repository';
import { useColors, useNotification } from 'shared/hooks';
import jwt_decode from 'jwt-decode';
import { getCurrentRole } from 'shared/utils';
import { BrandName, IUser } from 'shared/types';

function SignIn() {
  const {
    textColor,
    textColorSecondary,
    textColorDetails,
    textColorBrand,
  } = useColors();
  const history = useHistory();
  const [, setAuth] = useRecoilState(authAtom);
  const [userEmail, setUserEmail] = useState('');
  const [token, setToken] = useState('');
  const [isError, setError] = useState('');
  const { errorNotification } = useNotification();
  const [currentBrandName, setBrandName] = useRecoilState(brandAtom);

  const loginHandler = () => {
    api.otpLogin({
      email: userEmail,
      token: token
    })
      .then((res) => {
        localStorage.setItem('user', res.token);
        setAuth(res.token);
        const user: IUser = jwt_decode(res.token);
        const { brandName, role } = getCurrentRole(user, currentBrandName);
        if (isEmpty(role)) {
          return Promise.reject({ message : 'Role not found' });
        }

        setBrandName(brandName as BrandName);
        localStorage.setItem('brand', brandName);

        return Promise.resolve(role);
      })
      .then((role) => {
        history.push(`/${role}/dashboard`);
      })
      .catch((error) => {
        errorNotification(error.message);
        setError('Please check if your email or password typed correctly!');
      });
  };

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: '100%', md: 'max-content' }}
        w="100%"
        mx={{ base: 'auto', lg: '0px' }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: '30px', md: '60px' }}
        px={{ base: '25px', md: '0px' }}
        mt={{ base: '40px', md: '14vh' }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            BUTIK Activation Tool
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Enter your email to sign in!
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: '100%', md: '420px' }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: 'auto', lg: 'unset' }}
          me="auto"
          mb={{ base: '20px', md: 'auto' }}
        >
          <FormControl isRequired>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Email
            </FormLabel>
            <Input
              required
              variant="auth"
              fontSize="sm"
              ms={{ base: '0px', md: '0px' }}
              type="email"
              placeholder="katrien@telenet.be"
              mb="24px"
              fontWeight="500"
              size="lg"
              id="email"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
            />
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              display="flex"
            >
              Authorization code
            </FormLabel>
            <InputGroup size="md">
              <Input
                id="code"
                required
                fontSize="sm"
                placeholder="Enter the authorization code"
                mb="24px"
                size="lg"
                type={'number'}
                value={token}
                variant="auth"
                onChange={(e) => setToken(e.target.value)}
              />
            </InputGroup>
            <Flex justifyContent="flex-end" align="center" mb="24px">
              <NavLink to="/auth/lost-my-access">
                <Text
                  color={textColorBrand}
                  fontSize="sm"
                  w="124px"
                  fontWeight="500"
                >
                  Lost my access
                </Text>
              </NavLink>
            </Flex>
            <Button
              onClick={loginHandler}
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
            >
              Sign In
            </Button>
          </FormControl>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColorDetails} fontWeight="400" fontSize="14px">
              {"Don't have an account yet?"}
              <NavLink to="/auth/sign-up/default">
                <Text
                  color={textColorBrand}
                  as="span"
                  ms="5px"
                  fontWeight="500"
                >
                  Register here
                </Text>
              </NavLink>
            </Text>
          </Flex>
          <Text color="red">{isError}</Text>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
