import { Box, Grid } from '@chakra-ui/react';
import EventCalendar from 'components/calendar/EventCalendar';
import Card from 'components/card/Card';
import Filter from '../components/Filter';

import { useRecoilState, useRecoilValue } from 'recoil';

import {
  brandSelector,
  currentRoleSelector,
  GetActivationsList,
  GetUsersInfo,
} from 'data/selectors';
import {
  CalendarData,
  FilterStatus,
  IBrand,
  ICategory,
  INewActivationResponse, IUser
} from 'shared/types';
import { useEffect, useState } from 'react';
import { DateClickArg } from '@fullcalendar/interaction';
import { useHistory } from 'react-router-dom';

import { api } from "utils/repository";
import { useConfirm, useNotification } from 'shared/hooks';
import EventContent from './EventContent';
import { EventChangeArg } from '@fullcalendar/react';
import { getCalendarBg } from 'shared/utils';
import {
  activationAtom,
  activationStatusDetail,
  currentActivationCategory,
  forceUpdateActivationList
} from 'data/atoms';
import { defaultStepStatuses, initialActivationData } from '../create-activation/constants';

const transformActivationListToCalendarData = (list: INewActivationResponse[]): CalendarData[] => {
  return list.map(activation => ({
    id: activation.id,
    status: activation.status,
    title: activation.shopName,
    borderColor: "transparent",
    start: activation.actionDate,
    end: activation.actionDate,
    backgroundColor: getCalendarBg(activation.status),
    textColor: '#1A202C',
    className: "info",
    allDay: true,
    activation,
  }))

}
export default function Calendar() {
  // hooks
  const role = useRecoilValue(currentRoleSelector);
  const user = useRecoilValue<IUser>(GetUsersInfo);
  const [, setActivation] = useRecoilState(activationAtom);
  const [, setCurrentActivation] = useRecoilState<ICategory>(currentActivationCategory);
  const [, setStepDetail] = useRecoilState(activationStatusDetail);
  const [refreshCounter, refreshTableData] = useRecoilState(forceUpdateActivationList);
  const history = useHistory();
  const { errorNotification, successNotification } = useNotification();
  const [status, setStatus] = useState(FilterStatus.ALL);
  const activationsList = useRecoilValue(GetActivationsList);
  const [data, setData] = useState<CalendarData[]>(transformActivationListToCalendarData(activationsList));
  let [isAwaiting, setIsAwaiting] = useState(true);
  const { onConfirm } = useConfirm();
  const brand = useRecoilValue<IBrand>(brandSelector);

  useEffect(() => {
    refreshTableData(n => n + 1);
    setTimeout(() => {
      setIsAwaiting(false);
    }, 1000)

  }, [])

  useEffect(() => {
    refresh();
  }, [refreshCounter])

  const handleNewActivation = (info: DateClickArg) => {

    onConfirm(
      'Confirm creating new activation',
      'Are you sure want to create new activation?',
      'Yes',
    )
      .then(() => {
        setActivation(initialActivationData);
        setCurrentActivation({} as ICategory);
        setStepDetail(defaultStepStatuses);
        history.push({
          pathname: `/${role}/overview/create_activation`,
          state: { actionDate: info.dateStr }
        })
      })

  }

  const handleChangeDate = async (info: EventChangeArg, ...rest: any[]) => {

    const newDate = new Date(info.event.startStr)
    const activationId = info.oldEvent.extendedProps.activation.id;
    const status = info.oldEvent.extendedProps.activation.status;
    onConfirm(
      'Confirm change date of activation',
      `Are you sure you want to change the date of the Activation ${info?.oldEvent?.extendedProps?.activation?.actionType?.name}`,
      'Yes',
    )
      .then(() => {
        setData(state => state.map(ev => {
          if (ev.id === info.event.id) {
            const newDateStr = newDate.toISOString().split('T')[0]
            ev.start = newDateStr
            ev.end = newDateStr
          }
          return ev
        })
        )
        api.updateActivationActionDate(
          user.lang,
          brand.id,
          newDate,
          activationId,
          status === FilterStatus.ACTIVE
        ).then(() => {
          successNotification('Action date changed');
          refreshTableData(n => n + 1);
        })
          .catch(() => {
            errorNotification('Error while updating action date')
          })
      })
      .catch(() => info.revert())
  }

  const refresh = () => {
    setIsAwaiting(true)
    setData(transformActivationListToCalendarData(activationsList));
    setTimeout(() => setIsAwaiting(false), 1000)
  }

  return (
    <Box pt={{ base: '100px', md: '80px', xl: '80px' }}>
      <Filter
        setStatus={setStatus}
        status={status}
        hiddenFilters={true}
      />
      <Grid
        gap={{ base: '20px', xl: '20px' }}
        display={{ base: 'block', lg: 'grid' }}
      >
        <Card minH="680px">
          {!isAwaiting
            && <EventCalendar
              initialDate={new Date().toISOString().split('T')[0]}
              calendarData={status === FilterStatus.ALL
                ? data
                : data.filter(ev => ev.status === status)}
              dateClick={handleNewActivation}
              eventContent={(info) => <EventContent info={info} />}
              eventChange={handleChangeDate}

            />}
        </Card>
      </Grid>
    </Box>
  );
}
