// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading, Icon,
  Input,
  Link,
  Select,
  SimpleGrid,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import illustration from 'assets/img/auth/auth_bg.jpg';
import { languageAtom } from 'data/atoms';
import { brandsListSelector } from 'data/selectors';
import DefaultAuth from 'layouts/auth/variants/Default';
import { useState } from 'react';
import { MdArrowDropDown } from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { IBrand, IRegDTO } from 'shared/types';
import { api } from 'utils/repository';
import { FaChevronLeft } from 'react-icons/fa';

function SignUp() {
  const languages = useRecoilValue(languageAtom); //global languages
  const selectBrand: IBrand[] = useRecoilValue(brandsListSelector);
  const [isLoading, setIsLoading] = useState('');
  const [isError, setIsError] = useState('');
  const [checked, setChecked] = useState(false);
  const [isSend, setIsSend] = useState(false);

  const [register, setRegister] = useState<IRegDTO>({
    lang: "",
    email: "",
    brands: [{
      brandId: ""
    }],
    firstName: "",
    lastName: ""
  });

  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");

  const registerHandler = async () => {
    api.registration(register)
      .then(() => {
        setIsSend(true);
      })
      .catch((error) => {
        console.error("There was an error!", error.message);
      });
  };

  return (
    <>
      <DefaultAuth illustrationBackground={illustration} image={illustration}>
        <Flex
          w="100%"
          maxW="max-content"
          mx={{ base: "auto", lg: "0px" }}
          me="auto"
          h="100%"
          justifyContent="center"
          mb={{ base: "30px", md: "60px" }}
          px={{ base: "25px", md: "0px" }}
          mt={{ base: "40px", md: "8vh" }}
          flexDirection="column"
        >
          <Box me="auto">
            <Heading
              color={textColor}
              fontSize={{ base: "34px", lg: "36px" }}
              mb="10px"
            >
              BUTIK Activation Tool
            </Heading>
            {!isSend && (
              <Text
                mb="36px"
                ms="4px"
                color={textColorSecondary}
                fontWeight="400"
                fontSize="md"
              >
                Fill in this form to register!
              </Text>
            )}
          </Box>
          {!isSend ? (
            <Flex
              zIndex="2"
              direction="column"
              w={{ base: "100%", md: "420px" }}
              maxW="100%"
              background="transparent"
              borderRadius="15px"
              mx={{ base: "auto", lg: "unset" }}
              me="auto"
              mb={{ base: "20px", md: "auto" }}
            >
              <Text color="green" mb={2} mt={2}>
                {" "}
                {isLoading}
              </Text>

              <FormControl isRequired>
                <SimpleGrid
                  columns={{ base: 1, md: 2 }}
                  gap={{ sm: "10px", md: "15px" }}
                >
                  <Flex direction="column">
                    <FormLabel
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      display="flex"
                    >
                      Brand
                    </FormLabel>
                    <Select
                      icon={<MdArrowDropDown />}
                      placeholder="Select brand"
                      borderRadius={16}
                      mb="5px"
                      value={register.brands[0].brandId}
                      onChange={(e) =>
                        setRegister({
                          ...register,
                          brands: [{
                            brandId: e.target.value
                          }]
                        })
                      }
                    >
                      {selectBrand.map((brand, key) => (
                        <option key={key} value={brand.id}>
                          {brand.name}
                        </option>
                      ))}
                    </Select>
                  </Flex>
                  <Flex direction="column">
                    <FormLabel
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      display="flex"
                    >
                      Language
                    </FormLabel>
                    <Select
                      icon={<MdArrowDropDown />}
                      borderRadius={16}
                      mb="5px"
                      placeholder="Select language"
                      value={register.lang}
                      onChange={(e) =>
                        setRegister({
                          ...register,
                          lang: e.target.value
                        })
                      }
                    >
                      {languages.map((role, key) => (
                        <option key={key} value={role.name}>
                          {role.name}
                        </option>
                      ))}
                    </Select>
                  </Flex>
                  <Flex direction="column">
                    <FormLabel
                      display="flex"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="8px"
                    >
                      First name
                    </FormLabel>
                    <Input
                      id="first-name"
                      required={true}
                      fontSize="sm"
                      ms={{ base: "0px", md: "4px" }}
                      placeholder="First name"
                      variant="auth"
                      mb="5px"
                      size="lg"
                      value={register.firstName}
                      onChange={(e) =>
                        setRegister({
                          ...register,
                          firstName: e.target.value
                        })
                      }
                    />
                  </Flex>
                  <Flex direction="column">
                    <FormLabel
                      display="flex"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="8px"
                    >
                      Last name
                    </FormLabel>
                    <Input
                      id="last-name"
                      required={true}
                      variant="auth"
                      fontSize="sm"
                      placeholder="Last name"
                      mb="5px"
                      size="lg"
                      value={register.lastName}
                      onChange={(e) =>
                        setRegister({
                          ...register,
                          lastName: e.target.value
                        })
                      }
                    />
                  </Flex>
                </SimpleGrid>

                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                  mt={'15px'}
                >
                  Email
                </FormLabel>
                <Input
                  required={true}
                  variant="auth"
                  fontSize="sm"
                  type="email"
                  placeholder="eg. katrien@telenet.be"
                  mb="24px"
                  size="lg"
                  value={register.email}
                  onChange={(e) =>
                    setRegister({
                      ...register,
                      email: e.target.value
                    })
                  }
                />

                <Flex
                  justifyContent="space-between"
                  align="center"
                  mb="24px"
                  mt="4"
                >
                  <FormControl display="flex" alignItems="start" isRequired>
                    <Checkbox
                      id="remember-login"
                      colorScheme="brandScheme"
                      me="10px"
                      mt="3px"
                      name="terms"
                      isChecked={checked}
                      onChange={(e) => setChecked(e.target.checked)}
                      required
                    />
                    <FormLabel
                      htmlFor="remember-login"
                      mb="0"
                      fontWeight="normal"
                      color={textColor}
                      fontSize="sm"
                    >
                      By creating an account means you agree to the{" "}
                      <Link fontWeight="500">Terms and Conditions,</Link> and
                      our <Link fontWeight="500">Privacy Policy</Link>
                    </FormLabel>
                  </FormControl>
                </Flex>

                <Button
                  disabled={
                    register.email &&
                    register.firstName &&
                    register.brands[0].brandId &&
                    register.lang &&
                    register.lastName &&
                    checked
                      ? false
                      : true
                  }
                  type="submit"
                  variant="brand"
                  fontSize="14px"
                  fontWeight="500"
                  w="100%"
                  h="50"
                  mb="24px"
                  onClick={registerHandler}
                >
                  Create my account
                </Button>
              </FormControl>

              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="start"
                maxW="100%"
                mt="0px"
              >
                <Text color={textColorDetails} fontWeight="400" fontSize="sm">
                  Already registered?
                  <NavLink to="/auth/sign-in">
                    <Text
                      color={textColorBrand}
                      as="span"
                      ms="5px"
                      fontWeight="500"
                    >
                      Sign in
                    </Text>
                  </NavLink>
                </Text>
              </Flex>
              <Text color="red">{isError}</Text>
            </Flex>
          ) : (
            <Flex
              zIndex="2"
              direction="column"
              w={{ base: "100%", md: "420px" }}
              maxW="100%"
              background="transparent"
              borderRadius="15px"
              mx={{ base: "auto", lg: "unset" }}
              me="auto"
              mb={{ base: "20px", md: "auto" }}
            >
              <NavLink
                to="/auth/sign-in/default"
                style={() => ({
                  width: "fit-content",
                  marginTop: "40px"
                })}>
                <Flex align="center" ps={{ base: "25px", lg: "0px" }} pt={{ lg: "0px", xl: "0px" }} w="fit-content">
                  <Icon as={FaChevronLeft} me="12px" h="13px" w="8px" color="secondaryGray.600" />
                  <Text ms="0px" fontSize="sm" color="secondaryGray.600">
                    Back to sign in
                  </Text>
                </Flex>
              </NavLink>
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="start"
                maxW="100%"
                m="auto"
                mt="150px"
              >
                <Heading mb={5} as="h4" size="md" textAlign="center">
                  Thanks for your account request!
                </Heading>
                <Text fontSize="lg">
                  Once the admin has approved your request, you will be notified
                  via email
                </Text>
              </Flex>
            </Flex>
          )}
        </Flex>
      </DefaultAuth>
    </>
  );
}

export default SignUp;
