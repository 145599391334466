import { Flex, Heading, Text, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { BrandedButton } from 'components/buttons/BrandedButton';
import Card from 'components/card/Card';

const CardItem = ({
    title,
    description,
    onClick
}: {
    title: string
    description: string
    onClick: () => void
}) => {
  const textColorBid = useColorModeValue('brand.500', 'white');

  return (
    <Card
      minH={{ base: '150px', xl: '239px' }}
      borderRadius="20px"
      p="20px"
      border={textColorBid}
      borderColor={textColorBid}
      dir="column"
      boxShadow={'base'}
      justifyContent="space-between"
    >
      <Flex direction={'column'}>
        <Heading as="h3" fontSize={'20px'} fontWeight={'600'} mb={'8px'}>
          {title}
        </Heading>
        <Text fontSize={'16px'} color={'secondaryGray.600'} lineHeight={'26px'} whiteSpace="pre-line">
          {description}
        </Text>
      </Flex>
      <BrandedButton
        colorWhite
        customWidth={'128px'}
        onClick={onClick}
      >
        Select
      </BrandedButton>
    </Card>
  );
};

export default CardItem;
