import axios from 'axios';

export async function getRoles(token: string) {
  const header = {
    Authorization: `Bearer ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  const roleApiUrl = process.env.REACT_APP_ROLE_LIST;
  try {
    const response = await axios.get(roleApiUrl, { headers: header });
    return response.data.results;
  } catch (error: any) {
    throw new Error(`Error in 'GetJsonData(${roleApiUrl})': ${error.message}`);
  }
}

export async function getAddOnType(token: string) {
  const header = {
    Authorization: `Bearer ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  const addOnTypeUrl = process.env.REACT_APP_ADDON_TYPE_LIST_KEY;
  try {
    const response = await axios.get(addOnTypeUrl, { headers: header });
    return response.data.results;
  } catch (error: any) {
    throw new Error(
      `Error in 'GetJsonData(${addOnTypeUrl})': ${error.message}`
    );
  }
}
export async function getAllUsers(token: string) {
  const header = {
    Authorization: `Bearer ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  const userListUrl = process.env.REACT_APP_USER + 'list';
  try {
    const response = await axios.get(userListUrl, { headers: header });
    return response.data.results;
  } catch (error: any) {
    throw new Error(`Error in 'GetJsonData(${userListUrl})': ${error.message}`);
  }
}
export async function getAccountById(token: string, id: string) {
  const header = {
    Authorization: `Bearer ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  const userListUrl = process.env.REACT_APP_USER + id;
  try {
    const response = await axios.get(userListUrl, { headers: header });
    return response.data;
  } catch (error: any) {
    throw new Error(`Error in 'GetJsonData(${userListUrl})': ${error.message}`);
  }
}

export function groupBy(list: any[], itemsGetter: (i: any) => void) {
  const group = new Map();
  list.forEach((item) => {
    const items = itemsGetter(item);
    const collection = group.get(items);
    if (!collection) {
      group.set(items, [item]);
    } else {
      collection.push(item);
    }
  });
  return group;
}

export async function getAddOns(token: string) {
  const header = {
    Authorization: `Bearer ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  const addOnListUrl = process.env.REACT_APP_ADDON + 'list';
  try {
    const response = await axios.get(addOnListUrl, { headers: header });
    return response.data.results;
  } catch (error: any) {
    throw new Error(
      `Error in 'GetJsonData(${addOnListUrl})': ${error.message}`
    );
  }
}

export async function editAddOn(token: string, id: string, addOn: any, data:any) {
  const header = {
    Authorization: `Bearer ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  const body = {
    imageId: addOn.imageId,
    addonTypeId: !addOn.addonTypeId ? data.addonType.id : addOn.addonTypeId,
    name: !addOn.name ? data.name : addOn.name
    // lang: addOn.lang
  };

  const editAddOnUrl = process.env.REACT_APP_ADDON + id;
  try {
    const response = await axios.patch(editAddOnUrl, body, { headers: header });
    return response.data;
  } catch (error: any) {
    throw new Error(
      `Error in 'GetJsonData(${editAddOnUrl})': ${error.message}`
    );
  }
}

export async function deleteAddOn(token: string, id: string) {
  const header = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json; charset=utf-8'
  };
  const deleteAddOnUrl = process.env.REACT_APP_ADDON + id;
  try {
    const response = await axios.delete(deleteAddOnUrl, { headers: header });
    return response.data.results;
  } catch (error: any) {
    throw new Error(
      `Error in 'GetJsonData(${deleteAddOnUrl})': ${error.message}`
    );
  }
}
export async function getActivationTypes() {
  const header = {
    // Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json; charset=utf-8'
  };
  const deleteAddOnUrl = 'data/activationType.json';
  try {
    const response = await axios.get(deleteAddOnUrl, { headers: header });
    return response.data.results;
  } catch (error: any) {
    throw new Error(
      `Error in 'GetJsonData(${deleteAddOnUrl})': ${error.message}`
    );
  }
}
export async function getActionTypes() {
  const header = {
    //Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json; charset=utf-8'
  };
  const deleteAddOnUrl = 'data/actionType.json';
  try {
    const response = await axios.get(deleteAddOnUrl, { headers: header });
    return response.data.results;
  } catch (error: any) {
    throw new Error(
      `Error in 'GetJsonData(${deleteAddOnUrl})': ${error.message}`
    );
  }
}
