// Chakra imports
import { Box, Flex, Icon, Text } from '@chakra-ui/react';
// Custom components
import { BrandedButton } from 'components/buttons/BrandedButton';
import { sortUserState, userSorting } from 'data/atoms';
import { FilterUsers } from 'data/selectors';
import { Header } from './users/components/Header';
import SettingOverviewTable from './users/components/SettingOverviewTable';
import { columnsApprovedOverview } from './users/variables/columnsApprovedOverview';
import { columnsRequestersOverview } from './users/variables/columnsRequestersOverview';
//Assets
import { HiOutlinePlusSm } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useEffect } from 'react';
import { isEmpty } from 'lodash';

const SettingsUsers = () => {
  const history = useHistory();
  const data = useRecoilValue(FilterUsers);
  const [action, setAction] = useRecoilState(sortUserState); //chosen status
  const [, setTabState] = useRecoilState(userSorting);

  useEffect(() => {
    if (action !== 'approved' && isEmpty(data)) {
      setAction('approved');
      setTabState('approved');
    }
  }, [data]);

  const createUser = () => {
    history.push('/admin/settings/create_user');
  };
  return (
    <Box pt={{ base: '180px', md: '130px', xl: '130px' }}>
      <Flex align="center" w="100%" justifyContent="flex-end">
        <BrandedButton onClick={createUser}>
          <Icon
            as={HiOutlinePlusSm}
            w="22px"
            h="22px"
            mr="2"
            color="brandColor.300"
          />
          <Text color="brandColor.300" as="span">
            CREATE USER
          </Text>
        </BrandedButton>
      </Flex>
      <Header />

      <SettingOverviewTable
        tableData={data}
        columnsData={
          action !== 'pending'
            ? columnsApprovedOverview
            : columnsRequestersOverview
        }
      />
    </Box>
  );
};

export default SettingsUsers;
