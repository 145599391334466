import React from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';

import 'assets/css/App.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import { App } from './app';

ReactDOM.render(
  <RecoilRoot>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </RecoilRoot>,
  document.getElementById('root')
);
