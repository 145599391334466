import { Box, Flex, Icon, Text } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { HiOutlinePlusSm } from 'react-icons/hi';

import { currentRoleSelector, FilterActionType } from 'data/selectors';
import { BrandedButton } from 'components/buttons/BrandedButton';
import Card from 'components/card/Card';
import { Header } from './components/Header';
import TableActionTypeOverview from './components/TableActionTypeOverview';
import { columnsDataUsersOverview } from './constants';

export default function SettingsActionTypes() {
  const actionTypes = useRecoilValue(FilterActionType);
  const role = useRecoilValue(currentRoleSelector);

  return (
    <Box pt={{ base: '180px', md: '130px', xl: '130px' }}>
      <Flex align="center" w="100%" justifyContent="flex-end">
        <NavLink to={`/${role}/settings/create_action_type`}>
          <BrandedButton>
            <Icon
              as={HiOutlinePlusSm}
              w="22px"
              h="22px"
              color="brandColor.300"
              mr="2"
            />
            <Text color="brandColor.300" as="span">
              ADD ACTION TYPE
            </Text>
          </BrandedButton>
        </NavLink>
      </Flex>


      <Card px="0px" mt="5">
        <TableActionTypeOverview
          tableData={actionTypes}
          columnsData={columnsDataUsersOverview}
        />
      </Card>
    </Box>
  );
}
