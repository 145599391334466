import { Button, Tooltip, useColorModeValue } from '@chakra-ui/react';
import { ReactNode } from "react";
export function BrandedButton(props: {
  children: ReactNode;
  hidden?: boolean;
  size?: (string & {}) | "sm" | "md" | "lg" | "xs"
  disabled?: boolean
  tooltip?: string
  colorWhite?: boolean;
  customWidth?: string;
  onClick?(): void;
}) {
  const { children, onClick, size = 'md', disabled, hidden, tooltip, colorWhite, customWidth } = props;
  const textColor = useColorModeValue(colorWhite ? 'white' : 'brandColor.900', 'white');

  if (hidden) {
    return null;
  }

  const renderButton = () => {
    return (
      <Button
        disabled={disabled}
        width={customWidth || 'auto'}
        fontWeight={'500'}
        bg="brandColor.400"
        borderRadius={50}
        m="1"
        onClick={onClick}
        color={textColor}
        _hover={{ bg: 'brandColor.100' }}
        _focus={{}}
        size={size}
      >
        {children}
      </Button>
    );
  }

  if (tooltip) {
    return (
      <Tooltip shouldWrapChildren label={tooltip} aria-label='A tooltip'>
        {renderButton()}
      </Tooltip>
    )
  }

  return renderButton();
}
