import { Suspense, useEffect } from 'react';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { useRecoilState, useRecoilValue } from 'recoil';

import UserLayout from 'layouts/admin';
import AuthLayout from 'layouts/auth';
import ManagerLayout from 'layouts/manager';
import RequesterLayout from 'layouts/requester';
import { authAtom, brandAtom } from 'data/atoms';
import { baseTheme, telenetTheme } from 'theme/theme';
import { BrandName } from 'shared/types';
import { DebugRecoil } from './components/Debug/debug';
import { ConfirmPopup } from './components/confirm-popup/ConfirmPopup';
import { PrivateRoute } from './PrivateRoute';

export const App = () => {
  const user = useRecoilValue(authAtom);

  const [brandName, setBrandName] = useRecoilState(brandAtom);
  const theming = brandName === BrandName.Telenet ? telenetTheme : baseTheme;

  useEffect(() => {
    const brand: BrandName = localStorage.getItem('brand') as BrandName;

    if ([BrandName.Base, BrandName.Telenet].includes(brand)) {
      setBrandName(brand);
    }
  }, [])

  return (
    <ChakraProvider theme={theming}>
      <Suspense fallback={<div>Loading..</div>}>
        <DebugRecoil />
        <ConfirmPopup />
        <HashRouter>
          <Switch>
            <Route path={`/auth`} component={AuthLayout} />
            <PrivateRoute path={`/admin`} component={UserLayout} />
            <PrivateRoute path={`/manager`} component={ManagerLayout} />
            <PrivateRoute path={`/requester`} component={RequesterLayout} />
            <Redirect from="/" to="/auth" />
          </Switch>
        </HashRouter>
      </Suspense>
    </ChakraProvider>
  );
}

