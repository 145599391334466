// Chakra imports
import {
  Box,
  Flex,
  Tab,
  TabList,
  Tabs,
  Text,
  useColorModeValue
} from '@chakra-ui/react';

// Create action type components
import { HSeparator } from 'components/separator/Separator';
import { sortState } from 'data/atoms';
// Create action type components
import { addOnSorting } from 'data/atoms';
import { GetAddOns, SortAddOns } from 'data/selectors';
import { useRecoilState, useRecoilValue } from 'recoil';

export const Header = () => {
  let [tabState, setTabState] = useRecoilState(addOnSorting); //filtering atom
  const addOns = useRecoilValue(GetAddOns); // All Addons
  const sorteddOns = useRecoilValue(SortAddOns); // Sorted Addons
  const [, sortbyName] = useRecoilState(sortState);

  // Chakra Color Mode

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const paleGray = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');
  return (
    <Tabs variant="soft-rounded" colorScheme="brandTabs">
      <TabList mx="0" overflowX={{ sm: 'scroll', lg: 'unset' }} mt={10}>
        <Tab
          pb="0px"
          flexDirection="column"
          onClick={function () {
            setTabState('All');
          }}
          me="50px"
          bg="unset"
          _selected={{
            bg: 'none'
          }}
          _focus={{ border: 'none' }}
          minW="max-content"
        >
          <Flex align="center">
            <Text color={textColor} fontSize="lg" fontWeight="500" me="12px">
              All
            </Text>
            <Text color="secondaryGray.600" fontSize="md" fontWeight="400">
              {addOns.length}
            </Text>
          </Flex>
          <Box
            height="4px"
            w="100%"
            transition="0.1s linear"
            bg={tabState === 'All' ? 'brandColor.400' : 'transparent'}
            mt="15px"
            borderRadius="30px"
          />
        </Tab>
        {sorteddOns.map((value, key) => {
          return (
            <Tab
              key={key}
              onClick={function () {
                setTabState(value[0]);
                sortbyName(value[0]);
              }}
              pb="0px"
              me="50px"
              bg="unset"
              _selected={{
                bg: 'none'
              }}
              _focus={{ border: 'none' }}
              minW="max-content"
              flexDirection="column"
            >
              <Flex align="center">
                <Text
                  color={textColor}
                  fontSize="lg"
                  fontWeight="500"
                  me="12px"
                >
                  {value[0]}
                </Text>
                <Text color="secondaryGray.600" fontSize="md" fontWeight="400">
                  {value[1].length}
                </Text>
              </Flex>
              <Box
                height="4px"
                w="100%"
                transition="0.1s linear"
                bg={
                  tabState === `${value[0]}` ? 'brandColor.400' : 'transparent'
                }
                mt="15px"
                borderRadius="30px"
              />
            </Tab>
          );
        })}
      </TabList>

      <HSeparator mb="30px" bg={paleGray} mt="0px" />
    </Tabs>
  );
};
