import { Flex, Grid, Text, useColorModeValue } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';

import Details from 'assets/img/ecommerce/header_bg.png';
import { currentRoleSelector, GetActivationType, GetUsersInfo } from 'data/selectors';

import Card from 'components/card/Card';
import { VSeparator } from 'components/separator/Separator';
import ActivationType from './components/ActivationType';
import MiniCalendar from 'components/calendar/MiniCalendar';


export default function Default() {
  const user = useRecoilValue(GetUsersInfo);
  const role = useRecoilValue(currentRoleSelector);
  const categories = useRecoilValue(GetActivationType);

  const paleGray = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');


  const renderLeft = () => {
    return (
      <>
        <VSeparator mt='40px' mb='50px' mx='20px' bg={paleGray} display={{ base: 'none', xl: 'flex' }} />
        <Flex display={{ base: 'none', '2xl': 'flex' }} width={{ md: '40%', lg: '40%', xl: '30%', '2xl': '23%' }} >
          <Card alignItems='center' flexDirection='column' gridArea='1 / 2 / 2 / 3' w='100%'>
            <Grid
              templateColumns={{ md: 'repeat(2, 1fr)', lg: '1fr' }}
              display={{ base: 'block', '3xl': 'grid' }}
              gridColumnGap='20px'>
              <MiniCalendar
                gridArea={{ md: '1 / 1 / 2 / 2;', lg: '1 / 1 / 2 / 2' }}
                selectRange={false}
                mb='20px'
              />
            </Grid>
          </Card>
        </Flex>
      </>
    )
  }

  return (
    <Flex direction={{ base: 'column', xl: 'row' }} pt={{ base: '130px', md: '80px', xl: '80px' }} minH={'89vh'}>
      <Flex direction='column' mb={{ base: '20px', xl: 'unset' }}>
        <Card
          backgroundImage={Details}
          backgroundRepeat='no-repeat'
          bgSize='cover'
          bgPosition='10%'
          flexDirection='row'
          p={{ base: '20px', md: '50px' }}
          py='50px'
          borderRadius='30px'>
          <Flex direction='column' color='white' h='100%' w='100%'>
            <Text
              lineHeight='100%'
              fontSize={{ sm: 'lg', md: '30px', lg: '36px', xl: '40px' }}
            >
              Hi <Text as={'span'} fontWeight='bold'>{user.firstName}</Text>,
            </Text>
            <Text fontSize={{ base: 'md', md: 'xl' }} fontWeight='regular'>
              what do you want to do today?
            </Text>
          </Flex>
        </Card>
        {categories.map(category => {
          return (
            <>
              <Text mt="3rem" fontSize={'2xl'} fontWeight="bold" align="left" key={category.name}>
                {`Request ${category.name.toLowerCase()}`}
              </Text>
              <ActivationType categoryId={category.id} key={category.id} />
            </>
          );
        })}

      </Flex>
    </Flex>
  );
}
