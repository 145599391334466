import {Route} from "react-router-dom";
import {IRoute} from "./types";

export const getRoute = (role: string): boolean => {
  return window.location.pathname !== `/${role}`;
};

export const getActiveRoute = (routes: IRoute[]): string => {
  let activeRoute = '';

  for (let i = 0; i < routes.length; i++) {
    if (routes[i].collapse) {
      let collapseActiveRoute = getActiveRoute(routes[i].items);
      if (collapseActiveRoute !== activeRoute) {
        return collapseActiveRoute;
      }
    } else {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].name;
      }
    }
  }

  return activeRoute;
};

export const getActiveNavbar = (routes: IRoute[]): boolean => {
  let activeNavbar = false;
  for (let i = 0; i < routes.length; i++) {
    if (routes[i].collapse) {
      let collapseActiveNavbar = getActiveNavbar(routes[i].items);
      if (collapseActiveNavbar !== activeNavbar) {
        return collapseActiveNavbar;
      }
    } else {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return true;
      }
    }
  }
  return false;
};

export const getActiveNavbarText = (routes: IRoute[]) => {
  let activeNavbar = false;

  routes.forEach((route) => {
    if (route.collapse) {
      const collapseActiveNavbar = getActiveNavbarText(route.items);

      if (collapseActiveNavbar !== activeNavbar) {
        return collapseActiveNavbar;
      }
    }

    else if (window.location.href.indexOf(route.layout + route.path) !== -1) {
      return route.secondary;
    }
  });

  return activeNavbar;
};

export const getRouteForRole = (routes: IRoute[], role: string): any => {
  return routes.map((prop, key) => {
    if (prop.layout === `/${role}`) {
      return (
        <Route
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
        />
      );
    }

    if (prop.collapse) {
      return getRouteForRole(prop.items, role);
    }

    return null;
  });
};
