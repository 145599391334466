// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  SimpleGrid,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';

export default function Contact(props: any) {
  const {
    cardName,
    cardInfo,
    avatar,
    avatar2,
    name,
    name2,
    email,
    email2,
    tel,
    tel2,
    details,
    detailsTel,
    ...rest
  } = props;

  // Chakra Color Mode
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'secondaryGray.600';
  return (
    <Card
      direction="column"
      py="20px"
      alignSelf="center"
      w="100%"
      minH="300px"
      justifyContent="start"
      bgPosition="10%"
      {...rest}
    >
      <Flex direction="column">
        <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
          {cardName}
        </Text>
        {cardInfo && (
          <Text fontSize="md" color={textColorSecondary}>
            {cardInfo}
          </Text>
        )}
      </Flex>
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }}>
        <Flex mt="20px" mb="10px" align="center">
          <Avatar src={avatar} h="87px" w="87px" me="20px" />

          <Box>
            <Text color={textColor} fontSize="md" fontWeight="700">
              {name}
            </Text>
            <Text color="secondaryGray.600" fontSize="sm" fontWeight="400">
              {email}
            </Text>
            <Text color="secondaryGray.600" fontSize="sm" fontWeight="400">
              {tel}
            </Text>
          </Box>
        </Flex>
        {name2 !== '' && (
          <Flex mt="20px" mb="10px" align="center">
            <Avatar src={avatar2} h="87px" w="87px" me="20px" />
            <Box>
              <Text color={textColor} fontSize="md" fontWeight="700">
                {name2}
              </Text>
              <Text color="secondaryGray.600" fontSize="sm" fontWeight="400">
                {email2}
              </Text>
              <Text color="secondaryGray.600" fontSize="sm" fontWeight="400">
                {tel2}
              </Text>
            </Box>
          </Flex>
        )}
      </SimpleGrid>

      <Flex mt="10px" display="column" w="40%">
        <Text fontSize="sm" color={textColorPrimary}>
          {details}
        </Text>
        <Text fontSize="sm" fontWeight="500">
          {detailsTel}
        </Text>
      </Flex>
    </Card>
  );
}
