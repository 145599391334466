import { SearchIcon } from '@chakra-ui/icons';
import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  useColorModeValue
} from '@chakra-ui/react';

interface Props {
  setSearchQuery?: (searchQuery: string) => void;
  searchQuery?: string;
  borderRadius?: any;
}
export function SearchBar({
  borderRadius,
  setSearchQuery,
  searchQuery,
  ...rest
}: Props) {
  // Pass the computed styles into the `__css` prop
  // Chakra Color Mode
  const searchIconColor = useColorModeValue('gray.700', 'white');
  const searchColor = useColorModeValue('gray.700', 'white');
  const inputBg = useColorModeValue('transparent', 'navy.800');
  return (
    <InputGroup
      {...rest}
      zIndex="0"
      bg={inputBg}
      border="1px solid"
      color={searchColor}
      borderColor={useColorModeValue('secondaryGray.100', 'whiteAlpha.100')}
      borderRadius="16px"
      _placeholder={{ color: 'secondaryGray.600' }}
      me={{ base: '10px', md: '20px' }}
    >
      <InputLeftElement>
        <IconButton
          aria-label="1"
          bg="inherit"
          borderRadius="inherit"
          _hover={{}}
          _active={{
            bg: 'inherit',
            transform: 'none',
            borderColor: 'transparent'
          }}
          _focus={{
            boxShadow: 'none'
          }}
          icon={<SearchIcon color={searchIconColor} w="15px" h="15px" />}
        />
      </InputLeftElement>
      <Input
        w={{
          base: '100px',
          md: '270px',
          lg: '490px',
          xl: '600px',
          '2xl': '800px',
          '3xl': '800px'
        }}
        maxW="100%"
        variant="search"
        fontSize="sm"
        bg={inputBg}
        color={searchColor}
        fontWeight="500"
        _placeholder={{ color: 'gray.400', fontSize: '14px' }}
        borderRadius={borderRadius ?? '30px'}
        placeholder="Search..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
    </InputGroup>
  );
}
