import { useDropzone } from 'react-dropzone';
import { Box, Button, Flex, useColorModeValue } from '@chakra-ui/react';
import { FC, ReactNode, useEffect } from 'react';
import { api } from 'utils/repository';
import { IImage } from 'shared/types';
import { getDomainForFile } from '../../../../../shared/utils';

export const Dropzone:FC<{
  content: ReactNode,
  imagePath: string;
  onChange(image: IImage): void;
  onLoading(loading: boolean): void;
}> = (props) => {
  const { content, imagePath, onChange, onLoading } = props;
  const {acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: 'application/pdf'
  });
  const bg = useColorModeValue('gray.100', 'navy.700');
  const borderColor = useColorModeValue('gray.300', 'whiteAlpha.100');

  useEffect(() => {
    if (acceptedFiles[0]) {
      const formdata = new FormData();
      formdata.append("file", acceptedFiles[0], acceptedFiles[0].name);
      onLoading(true);
      api.uploadFile(formdata).then((response) => {
        const image: IImage = response.data;
        onChange(image);
      }).finally(() => {
        onLoading(false);
      });

    }
  }, [acceptedFiles]);

  return (
    <>
      <Flex
        align="center"
        justify="center"
        bg={bg}
        border="1px dashed"
        borderColor={borderColor}
        borderRadius="16px"
        w="100%"
        maxW="100%"
        h={{ base: '208px', '3xl': '300px' }}
        cursor="pointer"
        {...getRootProps()}
      >
        <input
          {...getInputProps()}
          type="file"
          name={'upload'}
          accept="application/pdf"
        />
        <Button variant="no-effects">{content}</Button>
      </Flex>
      {imagePath && (<a rel="noreferrer" href={`${getDomainForFile()}${imagePath}`} target={'_blank'}>
        <Box width="100%" textAlign={'center'} color={'red.500'} >File uploaded (click for download)</Box>
      </a>)}
      </>
  );
}
